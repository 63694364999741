import { Route, Switch } from 'react-router-dom'
import VtoProvider from '../vto/providers/VtoProvider'
import PersonalTaxInformation from './PersonalTaxInformation'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { useContext, useEffect } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import VTO from '../vto/core/components/VTOMain/VTO'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            height: '100%',
            width: '100%',
            padding: '1rem',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                padding: '0',
            }
        },
    }
})

const PersonalInfoTaxRoutes = () => {
    const classes = useStyles()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    useEffect(() => {
        setActiveRouteHeading('Personal Tax Information')
    }, [setActiveRouteHeading])

    return (
        <VtoProvider>
            <Switch>
                <Route path="/tax-organizer/personal" exact>
                    <PersonalTaxInformation />
                </Route>
                <Route path={`/tax-organizer/personal/:vtoYear/:id`}>
                    <Container className={classes.container}>
                        <VTO />
                    </Container>
                </Route>
            </Switch>
        </VtoProvider>
    )
}

export default PersonalInfoTaxRoutes
