import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
    archiveMessage,
    getMessage,
    markReadMessage,
    removeAttachment,
    replyToMessage
} from '../../services/apiService/messageCenter';
import Loader from '../common/Loader';
import RefreshIcon from '@material-ui/icons/Refresh';
import UploadIcon from '../../assets/icons-svg/Upload-Cloud.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { documentFilter } from '../../services/uploadService';
import UiErrorInfoDialog from '../common/ui/UiErrorInfoDialog';
import { connect } from 'react-redux';
import { AppData, ApplicationStore } from '../../models';
import UiConfirmationDialog from '../common/ui/UiConfirmationDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { loadNotificationCount } from '../../store/actions/count';
import { uploadFile } from '../../services/apiService/document';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

interface MessageThreadProps {
    appData: AppData;
    selectMessage?: any;
    updateMessageList?: any;
    archiveToActiveRefresh?: any;
    loadNotificationCount: any;
}

function MessageThread({
    appData,
    selectMessage,
    updateMessageList,
    archiveToActiveRefresh,
    loadNotificationCount
}: MessageThreadProps) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => ({
        seconderyActionButtonOutline: {
            height: 36,
            color: `${colorTheme.black100} !important`,
        },
        buttonGap: {
            marginRight: theme.spacing(2),
        },
        listRoot: {
            width: '100%',
            height: 'calc(100vh - 328px)',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh - 258px)',
            },
        },
        navListItemGutters: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        messageBody: {
            marginTop: theme.spacing(1),
            whiteSpace: 'pre-line',
            wordWrap: 'break-word',
        },
        statusIcon: {
            position: 'relative',
            top: '3px',
            marginRight: theme.spacing(0),
        },
        fileIcon: {
            marginRight: theme.spacing(1),
        },
        removeIcon: {
            padding: 0,
            position: 'relative',
            top: '-7px',
        },
        attachmentText: {
            maxWidth: 'calc(100% - 80px)',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'inline-block',
            color: colorTheme.blue600,
            textDecoration: 'none',
        },
        subjectText: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        sendButtonDIsabled: {
            opacity: 0.5,
        },
        'messageSubject': {
            zIndex: 100,
            background: 'white'
        },
        'messageInputMobile': {
            position:'sticky',
            bottom: 0,
            background: 'white',
            '& textarea' : {
                maxHeight: '78px',
                overflowY: 'scroll'
                
            }
        },
        'messageSubjectDivider': {
            position: 'sticky',
            top: '100px'
        }
    }))();

    let { id }: any = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let isArchivedDB = params.get('archivedDB') === 'true';
    let [message, setMessage] = useState<any>();
    let [loading, setLoading] = useState(true);
    let [body, setBody] = useState('');
    const scrollRef = useRef<HTMLDivElement>(null);
    let [showArchiveModal, setShowArchiveModal] = useState(false);
    let [showAttachmentRemoveModal, setShowAttachmentRemoveModal] = useState(
        false
    );
    let [attachment, setAttachment] = useState<any>({});
    let [errors, setErrors] = useState([]);
    let [uploading, setUploading] = useState(false);
    let [replying, setReplying] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadMesage = (
        shouldUpdateMessageList?: boolean,
        refresh?: boolean
    ) => {
        getMessage(appData.current_account_id, id, isArchivedDB).then((m: any) => {
            if (refresh) {
                shouldUpdateMessageList =
                    m.messages.length > message.messages.length;
            }
            setMessage(m);
            setLoading(false);
            scrollBottom();
            markAsRead(m);
            if (shouldUpdateMessageList && updateMessageList) {
                updateMessageList(m);
            }
        });
    };

    useEffect(() => {
        if (selectMessage) {
            selectMessage(id);
        }
        setLoading(true);
        loadMesage();

    }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

    const markAsRead = (message: any) => {
        if(!appData.user.accountant_mode) {
            let id = message.messages
                .filter((m: any) => m.is_read === false)
                .map((m: any) => m.id);
    
            if (id?.length) {
                markReadMessage(appData.current_account_id, message.id, {
                    id,
                }, isArchivedDB).then(() => {
                    loadNotificationCount();
                });
            }
        }
    };

    const relocateArchivedMessages = () => {
        isArchivedDB = false;
        const params = new URLSearchParams(location.search) ;
        if (params.get('archivedDB') === 'true') {
            params.set('archivedDB', 'false');
        }
        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        });
    }

    const reply = () => { 
        setReplying(true);
        replyToMessage(appData.current_account_id, message.id, { body }, isArchivedDB)
            .then((res: any) => {
                setBody('');
                setReplying(false);
                if (message.status === 'archived') {
                    if(isArchivedDB) {
                        relocateArchivedMessages();
                    }
                    setLoading(true);
                    loadMesage();
                    if (archiveToActiveRefresh) {
                        archiveToActiveRefresh();
                    }
                } else {
                    loadMesage(true);
                }
            })
            .catch(() => {
                setLoading(false);
                setReplying(false);
            });
    };

    const scrollBottom = () => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const history = useHistory();

    const handleModalClose = (result: any) => {
        setShowArchiveModal(false);
        if (result === true) {
            setLoading(true);
            archiveMessage(appData.current_account_id, message.id)
                .then(() => {
                    history.push('/reload_message_center');
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const handleAttachmentModalOpen = (attachment: any) => {
        setAttachment(attachment);
        setShowAttachmentRemoveModal(true);
    };
    const handleAttachmentModalClose = (result: any) => {
        setShowAttachmentRemoveModal(false);
        if (result === true) {
            removeAttachment(message.id, attachment.mId, attachment.id, isArchivedDB)
                .then(() => {
                    setAttachment({});
                    loadMesage();
                    if (
                        message.messages.findIndex(
                            (m: any) => m.id === attachment.mId
                        ) ===
                            message.messages.length - 1 &&
                        archiveToActiveRefresh
                    ) {
                        archiveToActiveRefresh();
                    }
                })
                .catch(() => {
                    setAttachment({});
                    setLoading(false);
                });
        }
    };

    const onFileChange = (e: any) => {
        let files = e?.target?.files || [];
        let filesToUpload: File[] = [];
        let fileErrors: any = [];
        Array.from(files).forEach((f) => {
            let check = documentFilter(f as File);
            if (check === true) {
                filesToUpload.push(f as File);
            } else {
                fileErrors.push({ message: check });
            }
        });
        setErrors(errors.concat(fileErrors));
        if (filesToUpload.length) {
            setUploading(true);
            Promise.all(
                filesToUpload.map((f) =>
                    uploadFile(
                        `api/message-center/account/${appData.current_account_id}/conversation/${id}/upload?archived_db=${isArchivedDB}`,
                        f
                    )
                )
            )
                .then(() => {
                    if (message.status === 'archived') {
                        if(isArchivedDB) {
                            relocateArchivedMessages();
                        }
                        setLoading(true);
                        loadMesage();
                        if (archiveToActiveRefresh) {
                            archiveToActiveRefresh();
                        }
                    } else {
                        loadMesage(true);
                    }
                })
                .catch((err) => {
                    let check: any = (
                        <Typography variant='body1'>
                            {err.statusText}
                        </Typography>
                    );
                    let arr: any = [];
                    arr.push({ message: check });
                    setErrors(fileErrors.concat(arr));
                })
                .finally(() => {
                    setUploading(false);
                });
        }
        e.target.value = '';
    };
    const Archived = (
        <Fragment>
            <img
                className={classes.statusIcon}
                alt={`Archive`}
                src={require(`../../assets/icons-svg/Archived.svg`).default}
            />
            <Typography
                component='span'
                variant='subtitle2'
                color='textSecondary'
                gutterBottom={false}
            >
                {isArchivedDB ? "Archived" : "Closed"}
            </Typography>
        </Fragment>
    );

    const getIconType = (mime: string) => {
        switch (mime) {
            case 'image/jpeg':
                return 'Image-Attachment';
            case 'image/png':
                return 'Image-Attachment';
            case 'application/pdf':
                return 'PDF';
            default:
                return 'Generic';
        }
    };
    const messageBody = (message: any) => {
        if (!message.body && message.attachments.length === 0) {
            return (
                <Typography
                    variant='body2'
                    className={classes.messageBody}
                    color='textSecondary'
                >
                    This file has been removed.
                </Typography>
            );
        } else {
            return (
                <Fragment>
                    <Typography
                        component='span'
                        variant='body2'
                        color='textPrimary'
                        className={classes.messageBody}
                    >
                        {message.body}
                    </Typography>
                    {message.attachments.map((a: any, index: number) => {
                        return (
                            <Fragment key={index}>
                                <img
                                    className={classes.fileIcon}
                                    alt={`File`}
                                    src={
                                        require(`../../assets/icons-svg/${getIconType(
                                            a.mimeType
                                        )}.svg`).default
                                    }
                                />
                                <a
                                    className={classes.attachmentText}
                                    href={a.download_link}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {a.file_name}
                                </a>
                                {!isArchivedDB && (
                                    <IconButton
                                        className={classes.removeIcon}
                                        aria-label="close"
                                        onClick={() =>
                                            handleAttachmentModalOpen({
                                                mId: message.id,
                                                id: a.id,
                                            })
                                        }
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Fragment>
                        )
                    })}
                </Fragment>
            );
        }
    };
    return (
        <Grid container direction="row" style={{ height: '100%' }}>
            <UiConfirmationDialog
                open={showArchiveModal}
                message={
                    <Fragment>
                        <Typography
                            variant="body1"
                            style={{ wordWrap: 'break-word' }}
                            gutterBottom
                        >
                            Do you want to archive the {message?.subject}
                            <br />
                            conversation?
                        </Typography>
                        <Typography variant="body1">
                            You can undo this from the Archived tab
                        </Typography>
                    </Fragment>
                }
                handleClose={handleModalClose}
                confirmButtonText="Archive"
                cancelButtonText="Cancel"
            />
            <UiConfirmationDialog
                open={showAttachmentRemoveModal}
                message={
                    <Fragment>
                        <Typography variant="body1" gutterBottom>
                            Do you really want to remove this document?
                            <br />
                            This can not be undone.
                        </Typography>
                    </Fragment>
                }
                handleClose={handleAttachmentModalClose}
                confirmButtonText="Ok"
                cancelButtonText="Cancel"
            />
            {errors.map((e: any, index: number) => {
                return (
                    <UiErrorInfoDialog
                        key={index}
                        open={true}
                        message={e.message}
                        handleClose={() => {
                            let arr = [...errors]
                            arr.splice(index, 1)
                            setErrors(arr)
                        }}
                    />
                )
            })}
            {loading ? (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Loader />
                </Grid>
            ) : (
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                >
                    <div style={{ width: '100%' }}>
                        <Grid
                            container
                            alignItems="center"
                            className={classes['messageSubject']}
                        >
                            <Grid
                                item
                                md={6}
                                xs={10}
                                sm={10}
                                container
                                alignItems="center"
                                direction="row"
                            >
                                <Hidden mdUp>
                                    <IconButton
                                        component={Link}
                                        to="/message_center"
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Hidden>

                                <Grid item xs zeroMinWidth>
                                    {message.status === 'archived'
                                        ? Archived
                                        : ''}
                                    <Tooltip
                                        title={message.subject}
                                        aria-label="subject"
                                    >
                                        <Typography
                                            variant="h6"
                                            className={classes.subjectText}
                                            noWrap
                                        >
                                            {message.subject}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={2}
                                sm={2}
                                container
                                justify="flex-end"
                            >
                                <Hidden smDown>
                                    {/* {message.status === 'archived' || appData.user.accountant_mode ? (
                                        ''
                                    ) : (
                                        <Button
                                            className={
                                                classes.seconderyActionButtonOutline
                                            }
                                            classes={{
                                                root: classes.buttonGap,
                                            }}
                                            variant='outlined'
                                            startIcon={
                                                <img
                                                    alt={`Private`}
                                                    src={ArchiveIcon}
                                                />
                                            }
                                            onClick={handleModalOpen}
                                        >
                                            Archive
                                        </Button>
                                    )} */}
                                    <Button
                                        className={
                                            classes.seconderyActionButtonOutline
                                        }
                                        variant="outlined"
                                        startIcon={<RefreshIcon />}
                                        onClick={() => {
                                            setLoading(true)
                                            loadMesage(false, true)
                                        }}
                                    >
                                        Refresh
                                    </Button>
                                </Hidden>
                                <Hidden mdUp>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {/* {message.status === 'archived' ? (
                                                ''
                                            ) : (
                                                <MenuItem onClick={handleClose}>
                                                    <Button
                                                        className={
                                                            classes.seconderyActionButtonOutline
                                                        }
                                                        startIcon={
                                                            <img
                                                                alt={`Private`}
                                                                src={
                                                                    ArchiveIcon
                                                                }
                                                            />
                                                        }
                                                        onClick={
                                                            handleModalOpen
                                                        }
                                                    >
                                                        Archive
                                                    </Button>
                                                </MenuItem>
                                            )} */}
                                        <MenuItem onClick={handleClose}>
                                            <Button
                                                className={
                                                    classes.seconderyActionButtonOutline
                                                }
                                                startIcon={<RefreshIcon />}
                                                onClick={() => {
                                                    setLoading(true)
                                                    loadMesage(false, true)
                                                }}
                                            >
                                                Refresh
                                            </Button>
                                        </MenuItem>
                                    </Menu>
                                </Hidden>
                            </Grid>
                        </Grid>
                        <Hidden mdUp>
                            <Divider
                                variant="fullWidth"
                                className={classes['messageSubjectDivider']}
                            ></Divider>
                        </Hidden>
                        <div>
                            <List className={classes.listRoot} component="div">
                                {message.messages.map(
                                    (i: any, index: number) => (
                                        <div
                                            key={index}
                                            ref={
                                                index ===
                                                message.messages.length - 1
                                                    ? scrollRef
                                                    : null
                                            }
                                        >
                                            <ListItem
                                                classes={{
                                                    gutters:
                                                        classes.navListItemGutters,
                                                }}
                                                alignItems="flex-start"
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt="User Avatar"
                                                        src={
                                                            i.accountant
                                                                ?.photo_link ||
                                                            require(`../../assets/icons-svg/User-Avatar.svg`)
                                                                .default
                                                        }
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Fragment>
                                                            <div>
                                                                {i.portal_user
                                                                    ? 'You'
                                                                    : i
                                                                          .accountant
                                                                          .first_name +
                                                                      ' ' +
                                                                      i.accountant.last_name.substr(
                                                                          0,
                                                                          1
                                                                      )}
                                                            </div>
                                                            <div>
                                                                <Typography
                                                                    variant="caption"
                                                                    color="textSecondary"
                                                                >
                                                                    {moment(
                                                                        i.date *
                                                                            1000
                                                                    ).format(
                                                                        'DD MMM YYYY h:mm A'
                                                                    )}
                                                                </Typography>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    secondary={messageBody(i)}
                                                />
                                            </ListItem>
                                            {index ===
                                            message.messages.length - 1 ? (
                                                ''
                                            ) : (
                                                <Divider variant="inset" />
                                            )}
                                        </div>
                                    )
                                )}
                            </List>
                        </div>
                    </div>   
                    <div>
                        <Grid
                            container
                            direction="column"
                            justify="flex-end"
                            alignItems="stretch"
                            style={{ marginBottom: '24px' }}
                        >
                            <Hidden smDown>
                                <div>
                                    <TextField
                                        fullWidth
                                        placeholder="Write your message"
                                        size="small"
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        value={body}
                                        onChange={(e) =>
                                            setBody(e.target.value)
                                        }
                                        onBlur={(event) => {
                                            setBody(
                                                event.target.value?.trim()
                                            )
                                        }}
                                        disabled={
                                            appData.user.accountant_mode
                                        }
                                    />
                                </div>
                            </Hidden>

                            <Box component="div" marginTop="8px">
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <div>
                                        <Button
                                            component="label"
                                            className={
                                                classes.seconderyActionButtonOutline
                                            }
                                            startIcon={
                                                <img
                                                    alt={`Upload`}
                                                    src={UploadIcon}
                                                />
                                            }
                                            endIcon={
                                                uploading ? (
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                ) : null
                                            }
                                            disabled={
                                                appData.user.accountant_mode
                                            }
                                        >
                                            <Typography variant="button">
                                                Upload Document
                                            </Typography>
                                            <input
                                                type="file"
                                                hidden
                                                onChange={onFileChange}
                                                multiple
                                            />
                                        </Button>
                                    </div>
                                    <div>
                                        <Hidden smDown>
                                            {replying ? (
                                                <Loader size={25} />
                                            ) : (
                                                <Button
                                                    disabled={
                                                        !body ||
                                                        body?.trim() ===
                                                            '' ||
                                                        appData.user
                                                            .accountant_mode
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    onClick={reply}
                                                    endIcon={
                                                        <img
                                                            alt={`Send`}
                                                            src={
                                                                require(`../../assets/icons-svg/Send.svg`)
                                                                    .default
                                                            }
                                                        />
                                                    }
                                                >
                                                    Send
                                                </Button>
                                            )}
                                        </Hidden>
                                    </div>
                                </Grid>
                            </Box>
                            <Hidden mdUp>
                                <div
                                    className={
                                        classes['messageInputMobile']
                                    }
                                >
                                    <TextField
                                        fullWidth
                                        placeholder="Write your message"
                                        size="small"
                                        variant="outlined"
                                        multiline
                                        disabled={
                                            appData.user.accountant_mode
                                        }
                                        value={body}
                                        onChange={(e) =>
                                            setBody(e.target.value)
                                        }
                                        onBlur={(event) => {
                                            setBody(
                                                event.target.value?.trim()
                                            )
                                        }}
                                        InputProps={{
                                            endAdornment: replying ? (
                                                <Loader
                                                    justifyContent="flex-end"
                                                    size={20}
                                                />
                                            ) : (
                                                <IconButton
                                                    disabled={
                                                        !body ||
                                                        body?.trim() ===
                                                            '' ||
                                                        appData.user
                                                            .accountant_mode
                                                    }
                                                    classes={{
                                                        disabled:
                                                            classes.sendButtonDIsabled,
                                                    }}
                                                    type="submit"
                                                    onClick={reply}
                                                    style={{ padding: 0 }}
                                                >
                                                    <img
                                                        alt={`Send`}
                                                        src={
                                                            require(`../../assets/icons-svg/SendDark.svg`)
                                                                .default
                                                        }
                                                    />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </div>
                            </Hidden>
                        </Grid>
                    </div>
                </Grid>
            )}
        </Grid>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps, { loadNotificationCount })(MessageThread);