import { ArrowForward } from '@material-ui/icons'
import { commonStyles, toRem16 } from '../../../../styles/commonStyles'
import { useStyles } from './IntroStep'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { Hidden, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'
import UiText from '../../../common/ui/UiText'
import OverviewStep from './OverviewStep'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import IntroVideo from '../../vto/core/components/IntroVideo'
import { useEffect, useState } from 'react'
import { setInLocalStorage } from '../../../../services/localStorageService'
import { getFolders, searchDocuments } from '../../../../services/apiService/document'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { useVtoContext } from '../../vto/providers/VtoProvider'
import LoadingAnimations from '../../../common/LoadingAnimations'
import Loader from '../../../common/Loader'

export const uselocalStyles = makeStyles<Theme, ThemeColors>(
    (theme: Theme) => ({
        mt32: {
            marginTop: '2rem',
        },
        counterConatiner: {
            display: 'flex',
            gap: toRem16(16),
            textAlign: 'left',
            marginBottom: toRem16(16),
        },
        counterItem: {
            backgroundColor: (colorTheme) => colorTheme.teal200,
            color: (colorTheme) => colorTheme.grey400,
            borderRadius: '50%',
            minWidth: '40px',
            height: '40px',
            lineHeight: '40px',
            textAlign: 'center',
            fontWeight: 600,
        },
        stepInfo: {
            '& > :nth-child(1), & > :nth-child(2)': {
                marginBottom: toRem16(12),
            },
        },
    })
)

const DetailedStep = ({
    setOpenVideoModal,
    goToNextStep,
    no1040form = true,
    setOcrFileId
}: any) => {
    const common = commonStyles()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const classes = uselocalStyles(colorTheme)
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )
    const { currentAccountId } = useCurrentStore()
    const { selectedYear } = useVtoContext()

    const [has1040Form, setHas1040Form] = useState(false)
    const [searchingFile, setSearchingFile] = useState(false)


    const searchFilesInFolders = async (folders: any[], currentAccountId: string) => {
        for (const folder of folders) {
            const res = await searchDocuments(currentAccountId, {
                path: folder.path,
                type: 'personal',
                perPage: 10,
                page: 1
            });
            const files = res?.items;
            if (res?.items?.length > 0) {
                if(files.length === 1) {
                    const fileId = files[0].id
                    setOcrFileId(fileId)
                    setSearchingFile(false)
                    setHas1040Form(true)
                } else {
                    if(files?.length > 1) {
                        const filesWithPreview = 
                            files.filter((file: any) => file.filename.endsWith("_preview"));
                        if(filesWithPreview.length === 1) {
                            const fileId = filesWithPreview[0].id
                            setOcrFileId(fileId)
                            setSearchingFile(false)
                            setHas1040Form(true)
                        } else {
                            setSearchingFile(false)
                        }
                    }
                }
                break; // Stop the loop if files are found
            } else {
                console.log(`No files found in folder: ${folder.path}. Moving to the next folder.`);
            }
        }
    };


    useEffect(() => {
        setSearchingFile(true)
        getFolders(currentAccountId).then((folders: any) => {
            if(folders) {
                const yearsRange =  Array.from({ length: selectedYear - 2021 }, (_, i) => (selectedYear - 1) - i);
                const filteredFolders = folders.filter((folder: any) => 
                    (folder.title === "Personal Tax Information" || folder.title === 'Tax Return') && 
                    yearsRange.some(year => folder.path.includes(`ty${year}`))
                );
                searchFilesInFolders(filteredFolders.reverse(), currentAccountId);
                setSearchingFile(false)
                setHas1040Form(false)
            }
        });
    }, [currentAccountId, selectedYear])

    if(searchingFile) {
        return (
            <div className={styles.loadingContainer}>
                <Loader/>
            </div>
        )
    }

    return (
        <div className={styles.detailedContainer}>
            <OverviewStep hideBtn={true} />
            <div className={`${common.mt32} ${common.mb40}`}>
                <div className={classes.counterConatiner}>
                    <div className={classes.counterItem}>1</div>
                    <UiText weight="semi_bold_600">
                        {`Your accountants have collected ${
                            has1040Form ? 'data' : 'information' 
                        } from you throughout the year and compiled it here${
                            has1040Form
                                ? ''
                                : ', but you’ll also need to upload a past tax return'
                        }`}
                    </UiText>
                </div>
                <div className={classes.counterConatiner}>
                    <div className={classes.counterItem}>2</div>
                    <UiText weight="semi_bold_600">
                        <div className={classes.stepInfo}>
                            <div>
                                Your accountant cannot begin working on your
                                taxes until you have confirmed your tax
                                information and submitted this form.
                            </div>
                            <div>
                                The video below will help you get started.
                                Review and answer all questions to the best of
                                your ability. If you have questions or concerns,
                                leave them in the comments at the end.
                            </div>
                            <div>
                                You can access instant help at any time in this
                                process after you click the “Next” button below.
                            </div>
                        </div>
                    </UiText>
                </div>
                <div className={classes.counterConatiner}>
                    <div className={classes.counterItem}>3</div>
                    <UiText weight="semi_bold_600">
                        Once you submit, an accountant will immediately begin
                        working on your return and will contact you when they
                        are ready to review.
                    </UiText>
                </div>
            </div>
            <div className={common.mb40}>
                <div className={styles.fixedButtonContainer}>
                    <UiButton
                        fullWidth
                        btnType="tertiary"
                        endIcon={<ArrowForward />}
                        handleClick={goToNextStep}
                        label="Next"
                    />
                </div>
            </div>
            <div className={styles.mb10}>
                <IntroVideo width={'100%'} />
            </div>
            <Hidden mdUp>
                <div className={styles.spacer}></div>
            </Hidden>
        </div>
    )
}

export default DetailedStep
