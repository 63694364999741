import Loader from '../../assets/animations/Abacus-Loader.json'
import { Theme, makeStyles } from '@material-ui/core'
import UiText from './ui/UiText'
import { commonStyles } from '../../styles/commonStyles'
import UiLottie from './ui/UiLottie'

const useStyles = makeStyles<Theme>((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
        },
        centered: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
        },
    }
})

const LoadingAnimations = ({ description, customNode }: { description?: string, customNode?: string | React.ReactNode }) => {
    const styles = useStyles()
    const commonClasses = commonStyles()

    return (
        <div className={styles.container}>
            <div>
                <UiLottie width={160} lottieData={Loader} />
                <div className={styles.centered}>
                    {description && <UiText
                        className={commonClasses.mt16}
                        variant="hatchback_125"
                    >
                        {description}
                    </UiText>}
                    {customNode}
                </div>
            </div>
        </div>
    )
}

export default LoadingAnimations
