import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import moment from 'moment';
import { ReactElement, useState } from "react";
import * as React from "react";
import { connect, useDispatch } from 'react-redux';
import { ApplicationStore, Business } from '../../models';
import { saveBusinessDetails } from '../../services/apiService/business';
import BusinessEditForm from "../common/BuinsessEditForm";
import Loader from "../common/Loader";
import { formSubmitErrorHandler } from '../../services/formService';
import { loadPostAuthData } from '../../store/actions/appData';

interface IProp {
  data?: Business | null;
  toggleView: (refreshDetails?: boolean) => void;
  onBusinessSettingPage?: boolean;
  accountId: string;
  businessId: string;
  accountantMode: boolean | undefined;
  loadPostAuthData: any
}

let initialValues: Business = {
  name: "",
  ein: "",
  description: "",
  industry: "",
  business_type: "",
  form_state: "",
  address: {
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    county: ""
  },
  phone_type_us: 'Yes',
  business_info: {
    was_operational: false,
    entity_type: ''
  },
};

const validate = (values: Business) => {
  const errors: Record<string, string> = {};

   if (!moment(values.form_date, "YYYY-MM-DD", true).isValid()) {
    errors.form_date = "Invalid date format";
  } else if (moment(values.form_date, "YYYY-MM-DD").isAfter(moment(), "day")) {
    errors.form_date = "Invalid date";
  }

  return errors;
};

const BusinessSettingEdit: React.FC<IProp> = ({
  data,
  toggleView,
  onBusinessSettingPage = true,
  accountId,
  businessId,
  accountantMode,
  loadPostAuthData
}): ReactElement => {
  const dispatch = useDispatch()
  const classes = makeStyles((theme: Theme) => {
    return {
      btnContainer: {
        backgroundColor: "white",
        zIndex: 100,
        position:'sticky',
        top: 0,
        left: 0
      },
      btnDivider: {
        marginTop: theme.spacing(3),
      },
      toggleBtn: {
        margin: "0px 10px 0px 0",
      },
    };
  })();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  //Initializing existing data
  if (data) {
    data.phone_type_us = data.is_us_phone ? 'Yes' : 'No';
    initialValues = data;
  }

  const handleFormSubmit = (
      values: Business,
      actions: FormikHelpers<Business>
  ) => {
      setIsFormSubmitting(true);
      values.is_us_phone = values.phone_type_us === 'Yes';
      formSubmitErrorHandler(
          saveBusinessDetails(values, accountId, businessId).then(
              (response: any) => {
                  setIsFormSubmitting(false);
                  loadPostAuthData(accountId).then(() => {
                    dispatch({type: 'RESET_CATEGORIES'});
                });
                toggleView(true)
              }
          ),
          () => {
              setIsFormSubmitting(false);
          },
          actions.setFieldError
      );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleFormSubmit}
      validate={validate}
    >
      {({ submitForm, handleSubmit, setFieldValue }) => {
        return (
          <Box>
            {
              onBusinessSettingPage && (
                <div className={classes.btnContainer}>
                  <Container>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => toggleView()}
                      className={classes.toggleBtn}
                      disabled={isFormSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                      className={classes.toggleBtn}
                      disabled={isFormSubmitting}
                    >
                      Save Changes
                    </Button>
                    {isFormSubmitting ? <Loader></Loader> : null}
                  </Container>
                  <Divider className={classes.btnDivider} />
                </div>
              )
            }
            <Container fixed disableGutters={onBusinessSettingPage ? false : true}>
              { data ? <Form onSubmit={handleSubmit}>
                <BusinessEditForm data={data} setField={setFieldValue} onBusinessSettingPage={onBusinessSettingPage} />
                {
                  isFormSubmitting
                    ? <Loader />
                    : !onBusinessSettingPage && (
                      <Grid justify="flex-end" container>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => toggleView()}
                          className={classes.toggleBtn}
                          disabled={isFormSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          disabled={isFormSubmitting}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    )
                }
              </Form> : 
                <Box height="80vh">
                    <Loader />
                </Box>
            }
            </Container>
          </Box>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: ApplicationStore) => ({
  accountId: state.appData.current_account_id,
  businessId: state.appData.current_business_id,
  accountantMode: state.appData.user.accountant_mode
});
export default connect(mapStateToProps, { loadPostAuthData })(BusinessSettingEdit);
