import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import UiDialog from '../../../common/ui/UiDialog'
import UiButton from '../../../common/ui/UiButton'
import TaxWidgetHeader from './TaxWidgetHeader'
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { commonStyles, toRem16 } from '../../../../styles/commonStyles'
import {
    renderFor,
    STATUS_MAPPING,
    USER_INFO_MESSAGES,
} from '../constants/tax-prep.const'
import UiText from '../../../common/ui/UiText'
import IconList from '../../../common/IconList'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const styles = (status: any, colorTheme: ThemeColors) =>
    makeStyles((theme: Theme) => ({
        container: {
            width: '100%',
            minHeight: '22.5rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        widgetDescription: {
            fontSize: '1rem',
            fontWeight: 400,
            margin: '0.5rem 0 1rem 0',
        },
        bordered: {
            paddingBottom: '3.2rem',
            minHeight: '17.6rem',
            boxSizing: 'border-box',
            padding: '1.5rem',
            [theme.breakpoints.down('xs')]: {
                padding: '1rem',
                minHeight: '18.6rem',
            },
        },
        clockIcon: {
            width: '40px',
            height: '40px',
            background: colorTheme.grey100,
            borderRadius: '800px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        continueButton: {
            width: 40,
            height: 40,
            borderRadius: 8,
            backgroundColor: colorTheme.grey100,
        },
        flexGrid: {
            justifyContent: 'space-between',
            marginTop: status ? theme.spacing(3) : 0,
        },
        accountantContainer: {
            display: 'flex',
            padding: '0.8rem 1.5rem',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                padding: '0.7rem 1.5rem 0.7rem 1rem',
            },
        },
        accountantContainerInfo: {
            marginLeft: '1rem',
        },
        videoWatchIcon: {
            '&: .MuiButton-startIcon': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
        },
        noDataAccountantContainer: {
            display: 'flex',
            padding: '0.8rem 1.5rem',
            alignItems: 'center',
            borderTop: `1px solid ${colorTheme.grey200}`,
            [theme.breakpoints.down('xs')]: {
                padding: '0.7rem 1.5rem 0.7rem 1rem',
            },
        },
        taxWidgetHeading: {
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.125rem',
            },
        },
        buttonStyle: {
            
            marginRight: toRem16(12)
        }
    }))

export default function IncompleteApplication(props: any) {
    const [showVideo, setShowVideo] = useState(false)
    const history = useHistory()
    const { colorTheme } = useThemeContext()
    const theme = styles(props.status, colorTheme)()
    const commonStyle = commonStyles()

    const getStarted = () => {
        history.push(`/tax-organizer/${props.vtoType.toLowerCase()}`)
    }
    const returnIframe = () => {
        const video = 'https://www.youtube.com/embed/pWImXONua00'
        return {
            __html: `<iframe src="${video}" style="width: 100%;" height="700" frameBorder="0"></iframe>`,
        }
    }

    const redirectToVTO = (vtoType: any) => {
        vtoType && history.push(`/tax-organizer/${vtoType.toLowerCase()}`)
    }

    return (
        <div className={theme.container}>
            <Box className={theme.bordered}>
                {props.status && (
                    <TaxWidgetHeader
                        status={props.status}
                        title={`Your ${props.vtoType} Tax Status`}
                        vtoWidgetData={props.vtoWidgetData}
                    />
                )}
                <Grid
                    container
                    alignItems={'center'}
                    className={theme.flexGrid}
                >
                    <Box mr={'5px'}>
                        <UiText
                            className={theme.taxWidgetHeading}
                            variant="suv_150"
                            weight="bold_700"
                        >
                            {props.status
                                ? STATUS_MAPPING.Started.title
                                : `Start ${
                                      props.vtoType === renderFor.BUSINESS
                                          ? 'Business'
                                          : 'Personal'
                                  } Tax Prep`}
                        </UiText>
                    </Box>
                </Grid>

                <Typography className={theme.widgetDescription}>
                    {USER_INFO_MESSAGES.INCOMPLETE}
                </Typography>

                <div>
                    <span className={`${theme.buttonStyle}`}>
                        <UiButton
                            handleClick={(e) => {
                                e.stopPropagation()
                                getStarted()
                            }}
                            label={props.status ? 'Continue' : 'Get Started'}
                            btnType="tertiary"
                        />
                    </span>
                    {/* <WatchVideo
                        customClass={theme.videoWatchIcon}
                        commonTheme={theme}
                    ></WatchVideo> */}
                </div>

                <UiDialog
                    open={showVideo}
                    title=""
                    handleClose={() => {
                        setShowVideo(false)
                    }}
                    hideTitleSection={true}
                    size="md"
                    contentStyles={{ padding: '0 !important' }}
                >
                    <div dangerouslySetInnerHTML={returnIframe()}></div>
                </UiDialog>
            </Box>
            <Box
                onClick={(e) => e.stopPropagation()}
                className={theme.noDataAccountantContainer}
            >
                <Box className={theme.clockIcon}>{IconList['clock']}</Box>
                <Typography
                    component="p"
                    className={theme.accountantContainerInfo}
                >
                    Accountant not yet assigned
                </Typography>
            </Box>
        </div>
    )
}
