import { useState, useEffect } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom'
import { Box, Container, makeStyles, Theme } from '@material-ui/core'
import BusinessSetting from '../settings/BusinessSetting'
import EntityManagement from '../entity-management/EntityManagement'
import Calendar from '../calender/Calender'
import NewBank from '../bookkeeping/banking/NewBank'
import InvoiceRoutes from '../routing/InvoiceRoutes'
import ErrorPage from '../core/ErrorBoundary/ErrorPage'
import { MessageCenterRoutes } from '../routing/MessageCenterRoutes'
import { JournalRoutes } from '../routing/JournalRoutes'

import { connect } from 'react-redux';
import { ApplicationStore } from '../../models';
import AdditionalProducts from '../sales/AdditionalProducts';
import UpgradePortalAccess from '../sales/UpgradePortalAccess';
import PartnerProducts from '../sales/PartnerProducts';
import { loadNotificationCount } from '../../store/actions/count';
import BusinessOnboarding from '../settings/BusinessOnboarding';
import ReportTypesRoutes from '../routing/ReportTypesRoutes';
import { ReconciliationsRoutes } from '../routing/ReconciliationsRoutes';
import ContactRoutes from '../routing/ContactRoutes';
import ProductServicesRoutes from '../routing/ProductServicesRoutes'
import ManageCategories from '../bookkeeping/manage-categories/ManageCategories'
import ChartOfAccounts from '../bookkeeping/chart-of-accounts/ChartOfAccounts'
import EstimatedTaxOrganizer from '../taxes/estimated-tax-organizer/EstimatedTaxOrganizer'
import BusinessExtension from '../taxes/business-extension/BusinessExtension'
import PersonalExtension from '../taxes/personal-extension/PersonalExtension'
import CompanyDetailsRoutes from '../routing/Payroll/CompanyDetailsRoutes'
import EmployeesRoutes from '../routing/Payroll/EmployeesRoutes'
import AdditionalStateTaxes from '../taxes/additional-state-taxes/AdditionalStateTaxes'
import TaxData from '../tax-planner/tax-data/TaxData'
import TaxProjections from '../tax-planner/tax-projections/TaxProjections'
import TaxSuggestions from '../tax-planner/tax-suggestions/TaxSuggestions'
import DraftReturns from '../tax-planner/draft-returns/DraftReturns'
import LocationRoutes from '../routing/LocationRoutes';
import Purposes from '../mileage-log/purposes/Purposes'
import ProfileDetails from '../profile/ProfileDetails'
import UpdatePaymentMethod from '../sales/UpdatePaymentMethod'
import Billing from '../sales/Billing'
import { isCanView } from '../../services/aclService'
import Documents from '../documents/Documents'
import ReactGA from 'react-ga'
import { TransactionRoutes } from '../routing/TransactionRoutes'
import ExcludedTransactions from '../bookkeeping/transections/ExcludedTransactions'
import ReceiptsScan from '../bookkeeping/receiptscan/ReceiptsScan'
import { BankingRoutes } from '../routing/BankingRoutes'
import JoinAdvisorMeetingPopup from '../guided-discovery/JoinAdvisorMeetingPopup'
import { getUpcomingEvents } from '../../services/apiService/appointment'
import { useTimer } from '../../hooks/useTimer'
import { DiscoveryCallEvent } from '../../models/event'
import TripsRoutes from '../routing/TripsRoutes'
import { toRem16 } from '../../styles/commonStyles'
import {
    DISCOVERY_CALL_TOPIC,
    REASSIGNED_DISCOVERY_CALL_TOPIC,
    DISCOVERY_VIP_CALL_TOPIC,
    TEN_MINUTES_BEFORE,
    SEVENTY_MINUTES_BEFORE,
    THIRTY_MINUTES_BEFORE,
} from '../guided-discovery/constants'

import moment from 'moment'
import DriftWidget from './common/DriftWidget'
import { Vehicles } from '../mileage-log/vehicles/Vehicles'
import { ContractorRoutes } from '../routing/ContractorRoutes';
import DashboardRoutes from '../dashboard/DashboardRoutes';
import BusinessInfoTaxRoutes from '../taxes/business-tax-information/BusinessTaxRoutes';
import PersonalInfoTaxRoutes from '../taxes/personal-tax-information/PersonalTaxRoutes';
import { BankDetailsProvider } from '../bookkeeping/banking/provider/BankDetailsProvider';
import PurposesRoutes from '../routing/PurposeRoute';
import FiltersProvider from '../mileage-log/trips/Provider/FiltersProvider';


interface AppViewProps {
    businessId: string
    sections: []
    accountId: string
    loadNotificationCount: () => void
    accountantMode: boolean
}

function AppView(props: AppViewProps) {
    const classes = makeStyles((theme: Theme) => {
        return {
            content: {
                margin: `${toRem16(24)} 0 0 0`,
                
                width: '100%',

                // Code for - page scroll should be on container, not on fixed header
                display: 'flex',
                flex: 1,
                minHeight: 0,
                overflow: 'auto',
                flexDirection: 'column',
                minWidth: 0,
                //end of scroll related code

                [theme.breakpoints.down('md')]: {
                    paddingTop: 0,
                    marginTop: '0.5rem'
                },
            },
        }
    })()

    const { sections, loadNotificationCount, accountId } = props

    const history = useHistory()
    let location = useLocation()
    const [openModal, setOpenModal] = useState(false)
    const [discoveryEventDetail, setDiscoveryEventDetail] = useState<
        DiscoveryCallEvent | undefined
    >()
    const sessionDetails = discoveryEventDetail?.gd_session_details
    const hasBusinessId = sessionDetails?.selected_business_id
    // TO DO - need to remove this check once we release GD for full.
    const showGdModal = sessionDetails?.show_gd_modal
    const isValidCall = sessionDetails && hasBusinessId && showGdModal
    const shouldLaunchIstantly = sessionDetails?.launch_modal_instantly
    const isThirtyMinRemaining =
        discoveryEventDetail &&
        discoveryEventDetail?.start_date - THIRTY_MINUTES_BEFORE <
            moment().unix()

    const expiryTimer =  shouldLaunchIstantly ? undefined : isValidCall &&
    discoveryEventDetail?.start_date &&
    isThirtyMinRemaining
        ? discoveryEventDetail?.start_date - TEN_MINUTES_BEFORE
        : undefined

    const timer = useTimer({
        expiry: expiryTimer ,
        onTimeout: () => {
            setOpenModal(true)
        },
    })

    useEffect(() => {
        if(sessionDetails && isValidCall) {
            shouldLaunchIstantly && setOpenModal(true)
            if (discoveryEventDetail) {
                const isMeetingStarted =
                    discoveryEventDetail?.start_date - TEN_MINUTES_BEFORE <
                    moment().unix()
                const isMeetingHasNotEnded =
                    sessionDetails?.expires_at > moment().unix()
                if ((  isMeetingStarted && isMeetingHasNotEnded)) {
                    setOpenModal(true)
                }
            }
        }
    }, [
        shouldLaunchIstantly,
        discoveryEventDetail,
        isValidCall,
        sessionDetails,
        sessionDetails?.expires_at,
    ])

    useEffect(() => {
        let route: any = sections.find((r: any) =>
            r.page.includes(location.pathname)
        )
        if (!route) {
            route = sections.find((r: any) =>
                location.pathname.includes(r.page)
            )
        }
        if (!route || !isCanView(route)) {
            history.push('/dashboard')
        } else {
            document.title = route.title + ' | 1-800Accountant'
            ReactGA.pageview(
                location.pathname + location.search,
                undefined,
                'portal-web'
            )
        }
    }, [location, sections]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadNotificationCount()
    }, [location, accountId, loadNotificationCount])

    useEffect(() => {
        getUpcomingEvents(accountId, {
            rql: `start%5Fdate=ge=${SEVENTY_MINUTES_BEFORE}`,
        }).then((res: any) => {
            const discoveryDetails = res?.find((event: DiscoveryCallEvent) =>
                [
                    DISCOVERY_CALL_TOPIC,
                    REASSIGNED_DISCOVERY_CALL_TOPIC,
                    DISCOVERY_VIP_CALL_TOPIC,
                ].includes(event.appointment_topic)
            )
            if (res.length) {
                setDiscoveryEventDetail(discoveryDetails)
            }
        })
    }, [accountId])

    return (
        <div className={classes.content} id="content-scroll">
            <JoinAdvisorMeetingPopup
                open={openModal}
                handleClose={() => {
                    setOpenModal(false)
                }}
                callDetails={discoveryEventDetail}
            />
            <Switch>
                {props.businessId ||
                location.pathname === '/profile/business/new' ||
                location.pathname === '/error404' ? null : (
                    <Redirect
                        to={{
                            pathname: '/profile/business/new',
                            state: { from: location },
                        }}
                    />
                )}
                <Route path="/dashboard" exact>
                    <DashboardRoutes />
                </Route>
                <Route path="/settings/business" exact>
                    <Box>
                        <BusinessSetting />
                    </Box>
                </Route>
                <Route path="/entity-management" exact>
                    <EntityManagement />
                </Route>
                <Route path="/documents">
                    <Documents />
                </Route>
                <Route path="/calendar" exact>
                    <Calendar />
                </Route>

                {/* Bookkeeping */}
                <Route path="/transactions">
                    <BankDetailsProvider>
                        <TransactionRoutes />
                    </BankDetailsProvider>
                </Route>
                <Route path="/excluded_transactions" exact>
                    <ExcludedTransactions />
                </Route>
                <Route path="/journal">
                    <JournalRoutes />
                </Route>
                <Route path="/bank_accounts/new" exact>
                    <Container maxWidth="lg">
                        <BankDetailsProvider>
                            <NewBank />
                        </BankDetailsProvider>
                    </Container>
                </Route>
                <Route path="/bank_accounts">
                    <BankDetailsProvider>
                        <BankingRoutes />
                    </BankDetailsProvider>
                </Route>
                <Route path="/reconcile">
                    <ReconciliationsRoutes />
                </Route>
                <Route path="/reports">
                    <ReportTypesRoutes />
                </Route>
                <Route path="/invoices">
                    <InvoiceRoutes />
                </Route>
                <Route path="/receipts-scan" exact>
                    <ReceiptsScan />
                </Route>
                <Route path="/contacts">
                    <ContactRoutes />
                </Route>
                <Route path="/products">
                    <ProductServicesRoutes />
                </Route>
                <Route path="/categories">
                    <ManageCategories />
                </Route>
                <Route path="/settings/chart-of-accounts" exact>
                    <ChartOfAccounts />
                </Route>
                {/* Bookkeeping end */}

                {/* Taxes */}
                <Route path="/tax-organizer/business" >
                    <BusinessInfoTaxRoutes />
                </Route>
                <Route path="/tax-organizer/personal">
                    <PersonalInfoTaxRoutes />
                </Route>
                <Route path="/estimated-tax-organizer" exact>
                    <EstimatedTaxOrganizer />
                </Route>
                <Route path="/tax-extensions/business" exact>
                    <BusinessExtension />
                </Route>
                <Route path="/tax-extensions/personal" exact>
                    <PersonalExtension />
                </Route>
                <Route path="/additional-state-taxes" exact>
                    <AdditionalStateTaxes />
                </Route>
                {/* Taxes ends */}

                {/* Tax Planner */}
                <Route path="/tax-data" exact>
                    <TaxData />
                </Route>
                <Route path="/tax-projections" exact>
                    <TaxProjections />
                </Route>
                <Route path="/tax-suggestions" exact>
                    <TaxSuggestions />
                </Route>
                <Route path="/draft-returns" exact>
                    <DraftReturns />
                </Route>
                {/* Tax Planner ends */}

                {/* Payroll */}
                <Route path="/payroll/company">
                    <CompanyDetailsRoutes />
                </Route>
                <Route path="/payroll/employees">
                    <EmployeesRoutes />
                </Route>
                {/* Payroll Ends*/}

                {/* Contractor 1099 */}
                <Route path='/contractors/file-1099-nec'>
                    <ContractorRoutes />
                </Route>
                <Route path='/contractors/manage'>
                    <ContractorRoutes />
                </Route>
                {/* Contractor 1099 ends*/}

                {/* Mileage Log */}
                <Route path={['/mileage/trips', '/mileage/purposes']}>
                    <FiltersProvider>
                        <Switch>
                            <Route path="/mileage/trips">
                                <TripsRoutes />
                            </Route>
                            <Route path="/mileage/purposes">
                                <PurposesRoutes />
                            </Route>
                        </Switch>
                    </FiltersProvider>
                </Route>
                <Route path="/mileage/vehicles" exact>
                    <Vehicles />
                </Route>
                <Route path="/mileage/locations">
                    <LocationRoutes />
                </Route>
                {/* Mileage Log ends */}

                <Route path="/profile/details">
                    <ProfileDetails />
                </Route>

                <Route path="/purchase/billing">
                    <Billing />
                </Route>

                <Route path="/purchase/update-payment-method">
                    <UpdatePaymentMethod />
                </Route>

                <Route path="/purchase/products">
                    <AdditionalProducts />
                </Route>

                <Route path="/purchase/upgrade-portal-access">
                    <UpgradePortalAccess />
                </Route>

                <Route path="/purchase/partner-products">
                    <PartnerProducts />
                </Route>

                <Route path="/business/new_account" exact>
                    <BusinessOnboarding newAccount={true} />
                </Route>
                <Route path="/error404">
                    <ErrorPage />
                </Route>

                {/* Add new routes above this */}
                <Redirect
                    exact
                    from="/reload_message_center"
                    to="/message_center"
                />
                <Route path="/message_center">
                    <MessageCenterRoutes />
                </Route>
                <Redirect to="/dashboard" />
            </Switch>
            {/* will remove later: commented due to einstein bot updates */}
            {/* <DriftWidget /> */}
            {/**TODO- Need to remove from here*/}
        </div>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
    accountId: state.appData.current_account_id,
    sections: state.config.local.combined_route_sections,
    accountantMode: state.appData.user.accountant_mode,
})

export default connect(mapStateToProps, { loadNotificationCount })(AppView)