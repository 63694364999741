import { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ReturnComplete from './tax-prep-statuses/ReturnComplete'
import IncompleteApplication from './tax-prep-statuses/IncompleteApplication'
import TaxPreparationAndNeedInfoState from './tax-prep-statuses/TaxPreparationAndNeedInfoState'
import { mergeStyle } from '../../../styles/commonStyles'
import { renderFor, USER_INFO_MESSAGES } from './constants/tax-prep.const'
import { ReactComponent as PaperIcon } from '../../../assets/icons-svg/PaperDocument.svg'
import { useWidgetContext } from './provider/WidgetProvider'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    flexStretch: {
        display: 'flex',
        flex: 1,
        alignSelf: 'stretch',
    },
    simplePrepCard: {
        width: '100%',
        borderRadius: '0.5rem',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`
    },
    completedCard: {
        width: '100%',
        borderRadius: '0.5rem',
        color: (colorTheme) => colorTheme.primaryWhite,
        position: 'relative',
    },
    taxPrepCard: {
        borderRadius: '0.5rem',
        padding: theme.spacing(4),
        position: 'relative',
        cursor: 'pointer',
        '& p': {
            fontSize: '14px',
            marginTop: theme.spacing(3),
        },
        '& h2': {
            marginTop: '0 !important',
            marginBottom: theme.spacing(1),
        },
    },
    taxPrepCardBordered: {
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`
    },
    buttonSpacingRight: {
        marginRight: theme.spacing(3),
    },
    buttonDisabled: {
        // may be move to global?
        background: (colorTheme) => colorTheme.grey2300,
    },
    rightArrowIcon: {
        // may be make this global?
        background: (colorTheme) => colorTheme.grey100,
        width: '40px',
        height: '40px',
        borderRadius: '0.5rem',
    },
    cardGreen: {
        background: (colorTheme) => `linear-gradient(76.6deg, ${colorTheme.tertiary}, 56.76%, ${colorTheme.green100} 100% )`,
        color: (colorTheme) => colorTheme.primaryWhite,
        minHeight: '22.5rem',
    },
    paperIcon: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: -1,
    },
}))

export default function BusinessAndPersonal(props: any) {
    const [businessStatus, setBusinessStatus] = useState<string>('')
    const [personalStatus, setPersonalStatus] = useState<string>('')
    const [businessTaxApId, setBusinessTaxApId] = useState<string>('')
    const [personalTaxApId, setPersonalTaxApId] = useState<string>('')
    const [documentUploadedStatuses, setDocumentUploadedStatuses] = useState([])
    const [eFileUploadStatuses, setEFileUploadStatuses] = useState([])

    const {
        taxPrepData: { business: businessData, personal: personalData },
        taxYear,
        config,
        appData,
        featureConfig,
    } = useWidgetContext()
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const history = useHistory()
    const vtoExtensionsWidgetHideForStatus =
        featureConfig.vtoExtensionsWidgetHideForStatus

    useEffect(() => {
        const widgetUpload = config?.apiConfig?.widget_upload
        const documentUploadedStatuses = widgetUpload?.documents_upload_status
        const eFileUploadStatuses = widgetUpload?.e_file_upload_status
        setDocumentUploadedStatuses(documentUploadedStatuses)
        setEFileUploadStatuses(eFileUploadStatuses)
    }, [config])
    useEffect(() => {
        setBusinessStatus(
            businessData?.tax_preparation_current_step?.title ?? ''
        )
        setPersonalStatus(
            personalData?.tax_preparation_current_step?.title ?? ''
        )
    }, [personalData, businessData])

    useEffect(() => {
        setPersonalTaxApId(personalData?.tax_ap_id ?? undefined)
        setBusinessTaxApId(businessData?.tax_ap_id ?? undefined)
    }, [businessData, personalData])

    const isNeedAdditionalInfoState = (type: renderFor): boolean => {
        return type === renderFor.BUSINESS
            ? businessData?.is_additional_info_requested ?? false
            : personalData?.is_additional_info_requested ?? false
    }
    const redirectToVTO = (type: renderFor) => {
        history.push(`/tax-organizer/${type.toLowerCase()}`)
    }
    const getTaxPrepStatus = (type: renderFor): string => {
        const status =
            type === renderFor.BUSINESS
                ? businessData?.tax_preparation_status
                : personalData?.tax_preparation_status
        return status
    }
    const getTaxPrepApStatus = (type: renderFor): string => {
        const status =
            type === renderFor.BUSINESS
                ? businessData?.tax_ap_status
                : personalData?.tax_ap_status
        return status
    }
    const userInfoMessageAccordingToStatus = (type: renderFor) => {
        const status = getTaxPrepStatus(type)
        if (status === 'client_review') {
            return USER_INFO_MESSAGES.CLIENT_REVIEW
        }
        if (status === 'e_file') {
            return USER_INFO_MESSAGES.E_FILE
        }

        if (status === 'manager_review') {
            return USER_INFO_MESSAGES.INTERNAL_REVIEW
        }

        if (status === 'preparation') {
            return USER_INFO_MESSAGES.PREPARATION
        }
        return USER_INFO_MESSAGES.UPLOAD_DOC_STATUS
    }
    const isHideStatus = (status: string): boolean => {
        return status === vtoExtensionsWidgetHideForStatus
    }

    const renderStatusComponent = (
        type: renderFor,
        status: string,
        taxApId: string | undefined
    ) => {
        const taxWidgetApStatus =
            type === renderFor.BUSINESS
                ? businessData?.tax_ap_status
                : personalData?.tax_ap_status

        const vtoWidgetData =
            type === renderFor.BUSINESS ? businessData : personalData

        if (isNeedAdditionalInfoState(type)) {
            return (
                <Box className={classes.simplePrepCard}>
                    <TaxPreparationAndNeedInfoState
                        status={'Additional Info Needed'}
                        title={`Your ${type} Tax Status`}
                        handleViewMessage={() =>
                            history.push('/message_center')
                        }
                        isAditionInfoStatus
                        details={USER_INFO_MESSAGES.ADDITIONAL_INFO_NEEDED}
                        taxYear={taxYear}
                        taxApId={taxApId}
                        accountId={appData.current_account_id}
                        vto={
                            type === renderFor.BUSINESS
                                ? businessData?.id
                                : personalData?.id
                        }
                        vtoWidgetData={vtoWidgetData}
                        renderFor={type}
                        taxPrepStatus={getTaxPrepApStatus(type)}
                        documentUploadedStatuses={documentUploadedStatuses}
                        eFileUploadStatuses={eFileUploadStatuses}
                        currentState={
                            type === renderFor.BUSINESS
                                ? businessStatus
                                : personalStatus
                        }
                    />
                </Box>
            )
        }

        if (status === 'Started' || !status) {
            return (
                <Box className={classes.simplePrepCard}>
                    <IncompleteApplication
                        status={status}
                        commonTheme={classes}
                        vtoType={type}
                        vtoWidgetData={vtoWidgetData}
                    />
                </Box>
            )
        }
        if (
            status === 'Completed' ||
            (taxWidgetApStatus === 'discarded_schedule_c' &&
                type === renderFor.BUSINESS) ||
            status === 'E-File'
        ) {
            return (
                <Box
                    className={mergeStyle(
                        classes.completedCard,
                        classes.cardGreen
                    )}
                >
                    <div className={classes.paperIcon}>
                        <PaperIcon />
                    </div>

                    <ReturnComplete
                        taxYear={taxYear}
                        commonTheme={classes}
                        title={`Your ${type} Tax Status`}
                        status={status}
                        renderFor={type}
                        widgetData={
                            type === renderFor.BUSINESS
                                ? businessData
                                : personalData
                        }
                    />
                </Box>
            )
        }
        return (
            <Box className={classes.simplePrepCard}>
                <TaxPreparationAndNeedInfoState
                    status={status}
                    title={`Your ${type} Tax Status`}
                    details={userInfoMessageAccordingToStatus(type)}
                    taxApId={taxApId}
                    taxYear={taxYear}
                    accountId={appData.current_account_id}
                    vto={
                        type === renderFor.BUSINESS
                            ? businessData?.id
                            : personalData?.id
                    }
                    renderFor={type}
                    taxPrepStatus={getTaxPrepApStatus(type)}
                    vtoWidgetData={vtoWidgetData}
                    documentUploadedStatuses={documentUploadedStatuses}
                    eFileUploadStatuses={eFileUploadStatuses}
                />
            </Box>
        )
    }

    return (
        <>
            {!isHideStatus(businessStatus) && (
                <Grid item xs={12} md={6} className="d-flex">
                    {renderStatusComponent(
                        renderFor.BUSINESS,
                        businessStatus,
                        businessTaxApId
                    )}
                </Grid>
            )}
            {!isHideStatus(personalStatus) && (
                <Grid item xs={12} md={6} className="d-flex">
                    {renderStatusComponent(
                        renderFor.PERSONAL,
                        personalStatus,
                        personalTaxApId
                    )}
                </Grid>
            )}
        </>
    )
}
