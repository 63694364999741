import React, { useState, createContext, useEffect } from 'react'

import { useStyles } from '../../../styles/styles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import AdminLoginBanner from '../../core/AdminLoginBanner'
import AppDrawer from '../../core/AppDrawer'
import AppToolbar from '../../core/AppToolbar'
import AppView from '../../core/AppView'
import { useLocation } from 'react-router-dom'
import useDeviceSize from '../../../hooks/useDeviceSize'

export const ActiveRoutingContext = createContext<any>({})
export const ActiveTourStepContext = createContext<any>({})

const ActiveRoutingProvider = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [activeRouteHeading, setActiveRouteHeading] = useState('')
    const [activeRouteHeadingIcon, setActiveRouteHeadingIcon] = useState(null)
    const [activeTourStep, setActiveTourStep] = useState(-1)
    const [showActiveRouteHeading, setShowActiveRouteHeading] = useState(false);
    const location = useLocation();
    const { isSmDevice } = useDeviceSize();

    useEffect(() => {
        const regex = /^\/tax-organizer\/(business|personal)\/\d{4}\/[^/]+\/[^/]+$/;
        const vtoMenuRegex = /^\/tax-organizer\/(business|personal)\/\d{4}\/[^/]+$/;
        const isTargetPath = regex.test(location.pathname);
        const isVTOMenuPath = vtoMenuRegex.test(location.pathname);
        if (isVTOMenuPath && isSmDevice) {
            setShowActiveRouteHeading(false);
        } else {
            setShowActiveRouteHeading(!isTargetPath)
        }
      }, [location.pathname]);

    return (
        <ActiveRoutingContext.Provider
            value={{
                activeRouteHeading,
                setActiveRouteHeading,
                activeRouteHeadingIcon,
                setActiveRouteHeadingIcon,
                activeTourStep,
                setActiveTourStep,
                showActiveRouteHeading
            }}
        >
            <div className={classes.drawerAndHeaderContainer}>
                <AdminLoginBanner />
                <ActiveTourStepContext.Provider
                    value={{
                        activeTourStep,
                        setActiveTourStep,
                    }}
                >
                    <div className={classes.leftNavigationAndContent}>
                        <div className={classes.leftNavigationAndContent}>
                            <AppDrawer
                                drawerOpen={drawerOpen}
                                setDrawerOpen={setDrawerOpen}
                            />

                            <div className={classes.headerAndContent}>
                                <AppToolbar setDrawerOpen={setDrawerOpen} />
                                <AppView />
                            </div>
                        </div>
                    </div>
                </ActiveTourStepContext.Provider>
            </div>
        </ActiveRoutingContext.Provider>
    )
}

export default ActiveRoutingProvider
