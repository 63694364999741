import { Container, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { toRem16 } from '../../styles/commonStyles'
import { ActivityFeed } from './ActivityFeed/ActivityFeed'
import { UpcomingEventdDeadlines } from './UpcomingEventsAndDeadlines'
import WidgetProvider from './tax-prep/provider/WidgetProvider'
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider'
import DashboardItems from './Items/DashboardItems'
import useDeviceSize from '../../hooks/useDeviceSize'
import TaxEngagement from './TaxEngagementPlan/TaxEngagement'
import DashboardUtilities from './Utility/DashboardUtilities'
import DashboardProvider from './Provider/DashboardProvider'
import TaxEngagementProvider from './TaxEngagementPlan/TaxEngagementProvider'
import EstimatedTaxSavingHeader from './TaxEngagementPlan/EstimatedTaxSavingHeader'
import IncomeStatement from './ReportWidgets/IncomeStatement'
import Invoices from './ReportWidgets/Invoices'
import BalanceSheet from './ReportWidgets/BalanceSheet'
import Expenses from './ReportWidgets/Expenses'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { getAuthToken } from '../../services/apiService';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import TaxSeasonReminderAlert from './TaxSeasonReminderAlert'
import { useCurrentStore } from '../common/hooks/useCurrentStore'

// Declare a global variable embedded_svc of type any
declare var embedded_svc: any;

interface HiddenFields {
    Authenticated: string;
    contactID?: string;
    leadID?: string;
}

function DashboardBeta() {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const { isMobileDevice } = useDeviceSize()
    const {
        appData: { user },
    } = useCurrentStore()
    const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => 
        createStyles({
            dashboardContainer: {
                display: 'flex',
            },
            leftPanelContents: {
                flex: 2,
                marginRight: toRem16(24),
            },
            message: {
                margin: '1rem 0 1rem 0',
            },
            reportWidgets: {
                display: 'flex',
                flexDirection: isMobileDevice ? 'column' : 'row',
                gap: '1rem',
                marginTop: '1rem',
                '& .MuiLinearProgress-colorPrimary': {
                    backgroundColor: (colorTheme) => `${colorTheme.grey100} !important`,
                }
            },
        })
    )
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)

    const fetchToken = async () => {
        try {
            const response: any = await getAuthToken();
            const token = response?.token;
            return token;
        } catch (error) {
            return null;
        }
    };
    
    const clearChatSession = () => {
        const einstein_config = (window as any).ApiConfig
        localStorage.removeItem(
            `${einstein_config.einstein_org_id}_WEB_STORAGE`
        )
        sessionStorage.removeItem(
            `${einstein_config.einstein_org_id}_WEB_STORAGE`
        )

        window?.embeddedservice_bootstrap?.userVerificationAPI?.clearSession()
            .then(() => {})
    }

    const hiddenFields: HiddenFields = {
        Authenticated: "True"
    };
    
    if (user.hasOwnProperty('sf_contact_id')) {
        hiddenFields.contactID = user.sf_contact_id as string;
    } else if (user.hasOwnProperty('sf_lead_id')) {
        hiddenFields.leadID = user.sf_lead_id as string;
    }

    useEffect(() => {
        setActiveRouteHeading('Dashboard')
    }, [setActiveRouteHeading])

    useEffect(() => {
        const handleBeforeUnload = () => {
          clearChatSession();
        };
      
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

      useEffect(() => {
        const initializeChat = async () => {
            // const token = await fetchToken();
            // if (!token) return;
    
            const script = document.createElement('script');
            const einstein_config = (window as any).ApiConfig;
            script.src = einstein_config.einstein_org_src;
            script.async = false;
            document.body.appendChild(script);
            const Element = document.getElementById('embeddedMessaging');
    

            script.addEventListener('load', function() {
                try {
                    // Ensuring Embedded Service settings are configured
                    window.embeddedservice_bootstrap.settings.devMode = false;
                    window.embeddedservice_bootstrap.settings.element = Element;
                    window.embeddedservice_bootstrap.settings.language = 'en_US'; // Example language, can be customized;
                    
                    //@ts-ignore
                    // function launchChat() {
                    //     window.embeddedservice_bootstrap?.utilAPI?.launchChat()
                    //         .then(() => {
                    //             // Adding the event handler after chat launch
                    //             if (embedded_svc) {
                    //                 embedded_svc.addEventHandler(
                    //                     'afterMaximize',
                    //                     function (data: any) {}
                    //                 );
                    //             }
                    //         })
                    // }
    
                    // Initializing Embedded Service with the provided configuration
                    window.embeddedservice_bootstrap.init(
                        einstein_config.einstein_org_id,
                        einstein_config.einstein_deployment_name,
                        einstein_config.einstein_deployment_site,
                        {
                            scrt2URL: einstein_config.einstein_deployment_cert,
                        }
                    );
                                  
                    // Launch the chat window after initialization
                    // setTimeout(function () {
                    //     launchChat();
                    // }, 250);

                    setTimeout(function () {
                        const embeddedMessagingElement = document.getElementById('embedded-messaging');
                        if(embeddedMessagingElement){
                            embeddedMessagingElement.style.zIndex = "99999999";
                        }
                    }, 1000);
    
                    // Handling when the Embedded Messaging is ready
                    window.addEventListener('onEmbeddedMessagingReady', function (e) {
                        // may be needed later
                        // window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken(
                        //     {
                        //         identityTokenType: 'JWT',
                        //         identityToken: token,
                        //     }
                        // );
                        console.log("prechat-fields", hiddenFields);
                        window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(hiddenFields);
                    });
    
                    // Event when the chat conversation ends
                    window.addEventListener('onEmbeddedMessagingConversationEnded', function () {
                        clearChatSession();
                    });
    
                    // Event when the identity token expires
                    window.addEventListener('onEmbeddedMessagingIdentityTokenExpired', async () => {
                        // const newToken = await fetchToken();
                        // if (!newToken) return;
                        // window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken(
                        //     {
                        //         identityTokenType: 'JWT',
                        //         identityToken: newToken,
                        //     }
                        // );
                    });
    
                } catch (err) {
                }
            });
            
            // kept for testing purposes in case the script fails to load in testing env
            // script.addEventListener('error', function(errorEvent) {
            //     console.error('Error loading the script', errorEvent);
            // });
        };
    
        initializeChat();
    }, []);

    return (
        <DashboardProvider>
            <TaxEngagementProvider>
                {isMobileDevice && <EstimatedTaxSavingHeader />}
                {isMobileDevice && <TaxSeasonReminderAlert />}
                <Container maxWidth="lg">
                    <div
                        className={
                            isMobileDevice ? '' : classes.dashboardContainer
                        }
                    >
                        <div
                            className={
                                isMobileDevice ? '' : classes.leftPanelContents
                            }
                        >
                            <WidgetProvider>
                                <DashboardItems />
                            </WidgetProvider>
                            <div className={classes.reportWidgets}>
                                <IncomeStatement />
                                <Invoices />
                            </div>
                            <div className={classes.reportWidgets}>
                                <BalanceSheet />
                                <Expenses />
                            </div>

                            <UpcomingEventdDeadlines />
                            <ActivityFeed />
                        </div>
                        {!isMobileDevice && <TaxEngagement />}
                        <DashboardUtilities />
                    </div>
                </Container>
            </TaxEngagementProvider>
        </DashboardProvider>
    )
}

export default React.memo(DashboardBeta)
