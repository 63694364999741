export const TAX_PLANNING_APPOINTMENT_DEFLECTION_SUBTOPICS = [
    { topicName: "Tax return Status", link: "https://1800accountant.com/portal/#/tax-organizer/business" },
    { topicName: "Help submitting tax info", link: "https://1800accountant.my.site.com/support/s/article/Submitting-Your-tax-information" },
    { topicName: "Extension Filing Help", link: "https://1800accountant.my.site.com/support/s/article/How-can-I-request-an-extension" },
    { topicName: "Issues with Transactions & Bookkeeping", link: "https://1800accountant.my.site.com/support/s/topic/0TOPJ0000000CVq4AM/bookkeeping" },
    { topicName: "Technical Support / Bank Connection", link: "https://1800accountant.my.site.com/support/s/article/How-do-I-sync-my-bank-account-in-the-portal" },
    { topicName: "Review Completed Return with Advisor", link: "scheduler" },
    { topicName: "Other/General advice", link: "scheduler" }
]

export const BTP_TOPIC = "Business Tax Preparation";
export const PTP_TOPIC = "Personal Tax Preparation";
export const PTP_TOPIC_LINK = "https://1800accountant.com/portal/#/tax-organizer/personal";