export const YEAR2022 = 2022
export const MULTI_STEP = 'multi-step'
export enum VTO_STEPS {
    INTRODUCTION_STEP = 'introduction',
    INCOME = 'INCOME',
    ADDITIONAL_QUESTIONS = 'Additional Questions',
    SOLE_PROPRIETORSHIP = 'Sole Proprietorship',
    UPLOAD_CENTER = 'upload_center',
    SUBMIT_VTO = 'submit_vto',
    FORM_SCAN_STEP = '1040_form_scan',
    PREPARE_TO_SUBMIT = 'prepare_submit_tax',
    RECOMMENDED_PACKAGES = 'recommendations',
    SUBMIT_TAX = 'submit_tax',
    BUSINESS_RENTAL_ACTIVITY = 'business_rental_activity',
}

export const BUSINESS_MOBILE_ROUTE = '/tax-organizer/business/:year/:id/:menuItem'
export const PERSONAL_MOBILE_ROUTE = '/tax-organizer/personal/:year/:id/:menuItem'
export const BUSINESS_VTO_ROUTE = '/tax-organizer/business/:year/:id'
export const PERSONAL_VTO_ROUTE = '/tax-organizer/personal/:year/:id'
export const BUSINESS_ROUTE = `/tax-organizer/business`
export const PERSONAL_ROUTE = `/tax-organizer/personal`
export const BANKING_ROUTE = `/bank_accounts`
export const CLIENTBOOKS_SOFTWARE = 'clientbooks'

export const SW_TO_CHECK = ['clientbooks']

export const CREDENTIALS_ERRORS = ['update_credentials', 'update_mfa']

export const BOOKKEEPING_SOFTWARES = [
    {
        key: 'clientbooks',
        title: 'ClientBooks',
    },
    {
        key: 'quickbooksdesktop',
        title: 'QuickBooks Desktop',
    },
    {
        key: 'quickbooksonline',
        title: 'QuickBooks Online',
    },
    {
        key: 'wave',
        title: 'Wave',
    },
    {
        key: 'spreadsheet',
        title: 'Spreadsheet',
    },
    {
        key: 'other',
        title: 'Other',
    },
]
export const CONSENT_TEXT = ` Federal law requires this consent form be provided to you. Unless authorized by law, we cannot disclose 
    your tax return information to third parties for purposes other than those related to the preparation and 
    filing of your tax return without your consent. If you consent to the disclosure of your tax return information, 
    Federal law may not protect your tax return information from further use or distribution. You are not 
    required to complete this form. Because our ability to disclose your tax return information to our service 
    providers affects the tax return preparation service(s) that we provide to you and its (their) cost, we may 
    decline to provide you with tax return preparation services or change the terms (including the cost) of the 
    tax return preparation services that we provide to you if you do not sign this form. If you agree to the 
    disclosure of your tax return information, your consent is valid for the amount of time that you specify. 
    If you do not specify the duration of your consent, your consent is valid for one year from the date of signature.
    This consent to disclose may result in your tax return information being disclosed to a service provider 
    located outside the United States, including your personally identifiable information such as your Social 
    Security Number (“SSN”). Both 1800Accountant in the United States that will disclose your SSN and our service 
    provider located outside the United States that will receive or otherwise access your SSN maintain an adequate 
    data protection safeguard (as required by the regulations under 26 U.S.C. section 7216) to protect privacy 
    and prevent unauthorized access of tax return information. If you consent to the disclosure of your tax return information, 
    federal agencies may not be able to enforce United States laws that protect the privacy of your tax return information against 
    a service provider located outside of the United States to whom the information is disclosed.If you believe your tax return 
    information has been disclosed or used improperly in a manner unauthorized by law or without your permission, you may contact 
    the Treasury Inspector General for Tax Administration (TIGTA) by telephone at 1-800-366-4484, or by email at complaints@tigta.treas.gov.
    1800Accountant will not disclose your tax return information for any other purpose in connection with this consent, except as 
    required or permitted by law. By checking box, you authorize 1800Accountant to disclose your tax return information as described above.`

export const RETIREMENT_APPOINTMENT_TEXT = `Are you interested in speaking with someone about decreasing your tax liability by setting up a retirement plan for your small business?`
export const LIFE_INSURANCE_APPOINTMENT_TEXT = `In these uncertain times, ensuring you have enough life insurance coverage is more important than ever.  Would you like to speak to someone to determine whether your current life insurance coverage is adequate and the most effective way to increase it given your current tax and financial situation?`

export const SUBMIT_VTO = {
    PREPARE_TO_SUBMIT: 'Prepare to Submit Your Tax Information',
    RECOMMENDED_PACKAGES: 'Exclusive offers for your business',
    SUBMIT_TAX_INFO: 'Submit Your Tax Info',
    SUBMIT_SUCCESS: 'Thanks for confirming your information!',
    PAYMENT_PROCESSING: 'Processing Your Payment',
    SUBMIT_WITH_ADD_ONS: 'Submit Your Info & Add Services'
}

export const NOT_ALLOWED_STEPS_IN_MENU = ['submit_vto']
export const INTRODUCTION_STEP = 'introduction'
export const NO_INCOME_STEP = 'no_income'
export const NO_DEDUCTIONS_STEP = 'no_deductions'
export const FORM_SCAN_STEP = '1040_form_scan'

// Submit flow
export const aboutPackagePage = [
    `Audit Defense does not include returns prepared by parties outside of 1-800Accountant. This service entails phone, fax, and/or preparation of letter correspondences for income tax notices issued by the Internal Revenue or Applicable State Tax Agency. This service does not include in-person appearances to the respective tax agencies.`,
    `The bookkeeper may periodically request information from the taxpayer for confirmation of transactions, along with bank account statements for reconciliation purposes. This service does not include any audited or attested financial statements, nor CPA Comfort Letter.`,
    `Your dedicated payroll specialist may require you to initially register with your applicable state agency as an employer. Your dedicated payroll specialist shall provide guidance on registration, tailored to your specific situation.`,
    `Package discounts do not apply to the Business Tax Preparation, Personal Tax Preparation, Full-Service Bookkeeping, Full-Service Payroll or Tax Advisory products.`
]

export enum expeditePage {
    OPTION = 'option',
    SELECTED = 'selected',
    NOT_AVAILABLE = 'not available',
}



export const StepTitleMapping: any = {
    'business_type': 'Business Entity Type',
    'business_info': 'Business Info',
    'business_automobiles': 'Vehicles',
    'business_payments': 'Employees'
}

export const RETIREMENT_APPOINTMENT_MIN_PROFIT_REQUIREMENT = 75000;

export const GreaterThanZeroFields = [
    "business.home_expenses_home_size",
    "business.home_office_size", 
    "number_of_shares",
    "days_rented"
]
export const HiddenFaqSidebarSections = [
    'introduction',
    'upload_center',
    '1040_form_scan',
]