import { Container, Theme, makeStyles } from '@material-ui/core'
import { useVtoContext } from '../vto/providers/VtoProvider'
import { useContext, useEffect, useState } from 'react'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import PersonalTaxInfo from './PersonalTaxInfo'
import MenuDropdown from '../../common/MenuDropdown'
import { toRem16 } from '../../../styles/commonStyles'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { VtoYearData, yearIndexMapping } from '../vto/core/Utility'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        container: {
            // height: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        menuStyle: {
            [theme.breakpoints.down('sm')]: {
                padding: '0 1rem',
            },
            '& .MuiButtonBase-root': {
                height: `${toRem16(40)} !important`,
                padding: `${toRem16(14)} !important`,
                border: (colorTheme) => `1px solid ${colorTheme.black100}`,
                width: toRem16(360),
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: `${toRem16(56)} !important`,
                },
                '& .MuiButton-label': {
                    [theme.breakpoints.up('sm')]: {
                        justifyContent: 'flex-start',
                        '& svg': {
                            marginLeft: 'auto',
                        },
                    },
                },
            },
        },
        iframeView: {
            height: `calc(100vh - ${toRem16(210)})`,
            overflow: 'hidden',
            marginTop: '2rem',
        },
    }
})

const PersonalTaxInformation = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [showIframe, setShowIframe] = useState(false)

    const {
        dropdownIndex,
        SetDropdownIndex,
        SetSelectedYear,
        showYearDropdown,
    } = useVtoContext()
    let query = new URLSearchParams(useLocation().search)

    useEffect(() => {
        setActiveRouteHeading('Personal Tax Information')
    }, [setActiveRouteHeading])

    const iframe = () => {
        return {
            __html: `<iframe src="${
                window.origin
            }/portal-embed/#/tax-organizer/personal?${
                query || ''
            }" width="100%" height="100%"></iframe>`,
        }
    }

    const IframeView = () => {
        return (
            <div
                className={classes.iframeView}
                dangerouslySetInnerHTML={iframe() as any}
            ></div>
        )
    }

    return (
        <Container className={classes.container}>
            {showYearDropdown && (
                <div className={classes.menuStyle}>
                    <MenuDropdown
                        options={VtoYearData}
                        selectedIndex={dropdownIndex}
                        setSelectedIndex={(index: number) => {
                            const year = yearIndexMapping[index]
                            SetDropdownIndex(index)
                            SetSelectedYear(year)
                            setShowIframe(year < 2023)
                        }}
                    />
                </div>
            )}
            {showIframe ? <IframeView /> : <PersonalTaxInfo />}
        </Container>
    )
}

export default PersonalTaxInformation
