import AssignedAccountant from './AssignedAccountant'
import { Box, Theme, makeStyles } from '@material-ui/core'
import {
    TAX_ACCOUNTANT_MAPPING_AP,
    OWNER_MAPPING_AP,
    STATUS_COMPLETED,
} from './constants/tax-prep.const'
import IconList from '../../common/IconList'
import UiText from '../../common/ui/UiText'
import { useWidgetContext } from './provider/WidgetProvider'
import { renderFor } from './constants/tax-prep.const'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    accountantContainer: {
        padding: '0.625rem 1.5rem',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        [theme.breakpoints.down('xs')]: {
            padding: '0.625rem 1rem',
        },
    },
    noDataAccountantContainer: {
        display: 'flex',
        padding: '0.625rem 1.5rem',
        alignItems: 'center',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        [theme.breakpoints.down('xs')]: {
            padding: '0.7rem 1.5rem 0.7rem 1rem',
        },
    },
    accountantContainerInfo: {
        marginLeft: '1rem',
    },
    clockIcon: {
        width: '2.5rem',
        height: '2.5rem',
        background: (colorTheme) => colorTheme.grey100,
        borderRadius: '50rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    completedStausWidget: {
        borderTop: 'none',
        padding: '1rem 1.5rem',
    },
    listRootCompleted: {
        borderRadius: '4px',
        backdropFilter: 'blur(10px)',
        zIndex: 2,
        background: (colorTheme) => colorTheme.white100,
        color: (colorTheme) => colorTheme.black100,
        margin: '0.7rem 1.5rem',
        padding: '0.5rem 1rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0.8rem',
            padding: '0.6rem',
        },
    },
}))

const AccountantContainer = ({
    taxAdvisoryStatus,
    status,
    type,
    widgetStatus,
    taxApStatus,
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const { taxPrepData } = useWidgetContext()
    let accountantType = null;
    if(!taxAdvisoryStatus) {
        accountantType  = taxPrepData?.taxAccountant;
    }
    else {
        accountantType = OWNER_MAPPING_AP.includes(status)
            ? taxPrepData?.owner
            : TAX_ACCOUNTANT_MAPPING_AP.includes(status)
            ? taxPrepData?.taxAccountant
            : null
    }
    const accountant =
        type === renderFor.BUSINESS
            ? accountantType?.business
            : accountantType?.personal

    return (
        <>
            <div onClick={(e) => e.stopPropagation()}>
                {accountant ? (
                    <div
                        className={`${classes.accountantContainer} ${
                            (taxApStatus === 'Completed' ||
                                taxApStatus === 'E-File' ||
                                status === 'discarded_schedule_c') &&
                            classes.completedStausWidget
                        } `}
                    >
                        <AssignedAccountant
                            appoinmentTopic={[`${type} Tax Preparation`]}
                            name={`${accountant?.first_name} ${accountant?.last_name?.slice(0,1)}`}
                            role={accountant?.roles}
                            profileImage={accountant?.photoLink}
                            accountantId={accountant?.id}
                            status={widgetStatus}
                            statusIcons={status}
                        />
                    </div>
                ) : (
                    <div
                        className={`${
                            widgetStatus === STATUS_COMPLETED &&
                            classes.listRootCompleted
                        } ${classes.noDataAccountantContainer}`}
                    >
                        <Box className={classes.clockIcon}>
                            {IconList['clock']}
                        </Box>
                        <UiText className={classes.accountantContainerInfo}>
                            Accountant not yet assigned
                        </UiText>
                    </div>
                )}
            </div>
        </>
    )
}

export default AccountantContainer
