import {
    Button,
    FormControlLabel,
    Hidden,
    Radio,
    RadioGroup,
    Theme,
    makeStyles,
    useMediaQuery,
} from '@material-ui/core'
import UiText from '../../../../common/ui/UiText'
import { toRem16 } from '../../../../../styles/commonStyles'
import { useEffect, useState } from 'react'
import { useVtoContext } from '../../providers/VtoProvider'
import { UPLOAD_TYPES, VTO_TYPES, VtoUploadTypes } from '../../models/VtoDetails.model'
import { Alert } from '@material-ui/lab'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import UiButton from '../../../../common/ui/UiButton'
import IndividualUpload from './IndividualUpload'
import MailOrFaxUpload from './MailOrFaxUpload'
import { getRequiredFiles } from './UploadFileUtils'
import Loader from '../../../../common/Loader'
import { commonVtoStyles } from '../../core/styles/commonVtoStyles'
import { useFormikContext } from 'formik'
import { NavigationButton } from './ImportDocuments'
import leftArrow from '../../../../../assets/icons-svg/LeftArrow.svg'
import { hasReviewedAllSections, isAllStepDoneExceptSubmit } from '../../common/SectionUtil'
import { isVTOFormDirty } from '../../common/VtoUtils'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            padding: '1rem',
            maxWidth: '40rem',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10rem',
                width: '100%',
                padding: '1rem',
            }
        },
        topMargin: {
            marginTop: '1rem',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0',
            }
        },
        labeledTop: {
            marginTop: '0.5rem',
        },
        mailingInfo: {
            marginTop: toRem16(40),
        },
        alertMessage: {
            marginTop: '1rem',
            marginBottom: '1rem',
            backgroundColor: (colorTheme) => colorTheme.red300 + ' !important',
            color: (colorTheme) => colorTheme.primaryBlack + ' !important',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
            }
        },
        sectionMargin: {
            marginTop: '1.5rem',
        },
        fixedButtonContainer: {
            width: 'fit-content',    
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                width: '100%',
                boxSizing: 'border-box',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                textAlign: 'center'
            },
        },
        skipBtn: {
            color: (colorTheme) => colorTheme.blue200,
        },
        questionsContainer: {
            margin: '1rem 0',
        },
        infoText: {
            color: (colorTheme) => colorTheme.black100,
            margin: '1.5rem 0',
            [theme.breakpoints.down('xs')]: {
                margin: '0'
            }
        },
        sectionHeadingContainer: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            left: 0,
            right: 0,
            zIndex:1,
            padding: '0 28px 0 28px'
        },
        headerPlaceholder:{
            height: toRem16(36),
        },
    }
})

const RequiredDocumentsStep = () => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const classes = commonVtoStyles(colorTheme)
    const smDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    const { values, setFieldValue, initialValues }: {
        values: any,
        setFieldValue: any,
        initialValues: any,
    } = useFormikContext()
    const {
        currentStep,
        currentVtoConfig,
        currentVtoDetails,
        currentYearMetaData,
        isPersonalVTO,
        updateVTO,
        setFullSectionView,
        handlePrev,
        setHighlightIncompleteSections,
        goToPreviousStep,
        setShowUnsavedChangesModal,
        menuItems,
        highlightIncompleteSections,
        isBusinessVTO,
        currentVto,
        selectedYear
    } = useVtoContext()
    const history = useHistory();

    const [submitType, setSubmitType] = useState<VtoUploadTypes>(
        currentVtoDetails?.uploads?.upload_type || UPLOAD_TYPES.INDIVIDUALLY
    )
    const [apiLoading,setApiLoading] = useState(false)

    const uploadTypes = currentVtoConfig?.upload_types
    const upload = uploadTypes?.individually
    const outside = uploadTypes?.outside

    const isMailOrFaxUpload = submitType === UPLOAD_TYPES.OUTSIDE
    const isIndividualUpload = submitType === UPLOAD_TYPES.INDIVIDUALLY

    const handleChange = (event: any) => {
        setSubmitType(event?.target.value)
        if (values?.uploads) {
            values.uploads.upload_type = event?.target.value
        }
    }

    const uploadSectionData =
        currentYearMetaData?.formattedSections['upload_center']
    const { filesCountData, requiredFilesCount } = getRequiredFiles(values, currentYearMetaData)
    
    const hasFileUploads = Object.keys(filesCountData)?.length > 0

    const isCompleted = currentYearMetaData?.Steps && isAllStepDoneExceptSubmit(
        menuItems,
        requiredFilesCount,
        values,
        currentYearMetaData
    )

    const sectionsReviewed = hasReviewedAllSections(menuItems, values);

    useEffect(()=>{
        if(!values?.uploads?.upload_type) {
            setFieldValue(`uploads.upload_type`, UPLOAD_TYPES.INDIVIDUALLY)
        }
    },[setFieldValue, values?.uploads?.upload_type])

    const submitUploadsData = () => {
        if(isCompleted && sectionsReviewed) {
            setApiLoading(true);
            updateVTO(values?.id, {...values, sections_reviewed: [...new Set([...values.sections_reviewed, currentStep])]}, ()=> {
                setFullSectionView(true)
                setApiLoading(false)
            }, true)
        } else {
            setApiLoading(true);
            updateVTO(values?.id, {...values, sections_reviewed: [...new Set([...values.sections_reviewed, currentStep])]}, ()=> {
                setApiLoading(false)
                setHighlightIncompleteSections(true)
            })
        }
    }

    const handleGoToPreviousStep = () => {
        const dirty = isVTOFormDirty(initialValues, values);
        if(!dirty) return goToPreviousStep();
        setShowUnsavedChangesModal(true)
    }

    const handleRedirectToMenu = () => {
        const vtoType = isBusinessVTO ? VTO_TYPES.BUSINESS : VTO_TYPES.PERSONAL
        history.push(`/tax-organizer/${vtoType}/${selectedYear}/${currentVto?.id}`)
    }

    const renderAllSectionsRequired = () => (
        <div className={highlightIncompleteSections ? "" : styles.labeledTop}>
            {highlightIncompleteSections ? (
                <Alert severity="error" className={styles.alertMessage}>
                    <UiText variant="motorcycle_90">
                        All sections must be completed in order to submit your
                        tax info.
                    </UiText>
                </Alert>
            ) : (
                <UiText variant="motorcycle_90">
                    *All sections must be completed in order to submit your tax
                    info.
                </UiText>
            )}
        </div>
    )

    const renderSubmitActionButton = () => (
        <div className={styles.fixedButtonContainer}>
            <UiButton
                customClass={styles.topMargin}
                btnType="tertiary"
                label="Submit Your Tax Info"
                handleClick={submitUploadsData}
            />

            {(!isCompleted || !sectionsReviewed) && renderAllSectionsRequired()}

            <Hidden mdUp>
                    <Button
                        className={styles.skipBtn}
                        variant="text"
                        onClick={handleGoToPreviousStep}
                    >
                        <UiText weight="semi_bold_600">Previous</UiText>
                    </Button>
            </Hidden>
        </div>
    )


    if(apiLoading) {
        return <div className={classes.centeredLoader}><Loader /></div>
    }

    return (
        <>
            <Hidden smDown>
                <div className={classes.stickyTop}>
                    <NavigationButton
                        label="Previous"
                        startIconSvg={leftArrow}
                        handleClick={handleGoToPreviousStep}
                    />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className={styles.sectionHeadingContainer}>
                    <UiText variant="hatchback_125" weight="medium_500">
                        {requiredFilesCount || ''} Required Documents
                    </UiText>

                    <UiButton
                        btnType="hyperlink"
                        customLabel={
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Menu
                            </UiText>
                        }
                        handleClick={handleRedirectToMenu}
                    />
                </div>
                <div className={styles.headerPlaceholder}></div>
            </Hidden>
            <div className={styles.container}>
                <Hidden smDown>
                    <div className={styles.labeledTop}>
                        <UiText weight="semi_bold_600" variant="suv_150">
                            Required Documents
                        </UiText>
                    </div>
                </Hidden>
                {!hasFileUploads ? (
                    <div className={styles.labeledTop}>
                        <UiText>
                            Based on your answers to the questions in this{' '}
                            {isPersonalVTO ? 'Personal' : 'Business'}
                            Tax Information you don't have required forms to
                            upload yet.
                        </UiText>

                        {renderSubmitActionButton()}
                    </div>
                ) : (
                    <div className={styles.labeledTop}>
                        <UiText>
                            Based on the answers to the previous questions we
                            have compiled a list of forms required from you.
                        </UiText>
                        <div className={styles.questionsContainer}>
                            <UiText
                                className={styles.labeledTop}
                                variant="hatchback_125"
                                weight="semi_bold_600"
                            >
                                How would you like to submit your documents?
                            </UiText>

                            <RadioGroup
                                className={styles.labeledTop}
                                name="upload_type"
                                value={submitType}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value={UPLOAD_TYPES.INDIVIDUALLY}
                                    control={<Radio />}
                                    label={
                                        <UiText>
                                            {upload?.title
                                                ? `Upload Files`
                                                : ''}
                                        </UiText>
                                    }
                                />

                                <FormControlLabel
                                    value={UPLOAD_TYPES.OUTSIDE}
                                    control={<Radio />}
                                    label={<UiText>{outside?.title}</UiText>}
                                />
                            </RadioGroup>
                        </div>
                        <div className={styles.sectionMargin}>
                            {isIndividualUpload && (
                                <IndividualUpload
                                    uploadSectionData={uploadSectionData}
                                    requiredFilesData={filesCountData}
                                />
                            )}
                            {isMailOrFaxUpload && (
                                <MailOrFaxUpload
                                    requiredFilesData={filesCountData}
                                />
                            )}
                        </div>
                        {renderSubmitActionButton()}
                    </div>
                )}
            </div>
        </>
    )
}

export default RequiredDocumentsStep
