import { Container, Hidden, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom'
import DashboardBeta from './DashboardBeta';
import DashboardProvider from './Provider/DashboardProvider';
import { TaxEngagementPlan } from './TaxEngagementPlan/TaxEngagementPlan';
import TaxEngagementProvider from './TaxEngagementPlan/TaxEngagementProvider';
import TaxSeasonReminderAlert from './TaxSeasonReminderAlert';


const useStyles = makeStyles(() => {
    return {
        container: {
            height: '100%',
        },
    };
})

const DashboardRoutes = () => {
    const classes = useStyles()
    return (
        <Switch>
            <Fragment>
                <Hidden mdUp>
                    <Route 
                        path="/dashboard" 
                        render={()=> (
                            <DashboardBeta/>
                        )}
                        exact
                    />
                    <Route 
                        path="/dashboard/tax-plan" 
                        render={()=> (
                            <Container maxWidth="lg" className={classes.container}>
                                <DashboardProvider>
                                    <TaxEngagementProvider>
                                        <TaxEngagementPlan />
                                    </TaxEngagementProvider>
                                </DashboardProvider>
                            </Container>
                        )}
                        exact
                    />
                </Hidden>
                <Hidden smDown>
                    <Route 
                        path="/dashboard" 
                        render={()=> (
                            <>
                                <TaxSeasonReminderAlert/>
                                <Container maxWidth="lg" fixed>
                                    <DashboardBeta/>
                                </Container>
                            </>
                        )}
                        exact
                    />
                </Hidden>
            </Fragment>
        </Switch>
    )
}


export default DashboardRoutes