import { isFilledAnswer } from '../../../../common/VtoQuestionUtils'
import * as Yup from 'yup'
import { getValueByModelName, IgnoreValidationForZero } from '../../../../common/VtoUtils'

const REQUIRED_FIELD_ERROR = 'We need this from you'
const INVALID_EMAIL_ERROR = 'This must be a valid email'

const emailSchema = Yup.string().email()
const numberSchema = Yup.number()



export const validateCheckbox = (
    value: string | undefined
): string | undefined => {
    if (!isFilledAnswer(value)) {
        return REQUIRED_FIELD_ERROR
    }
}


export const validateTextField = (currentFieldValue: any, props: any) => {
    const { options, vtoDetails, isRequired } = props
    const { required, equal, confirmFor } = options || {}

    if ((required || isRequired) && !isFilledAnswer(currentFieldValue)) {
        return REQUIRED_FIELD_ERROR
    }
    if(props?.type === 'email') {
        if (!emailSchema.isValidSync(currentFieldValue)) {
            return INVALID_EMAIL_ERROR
        }
    }

    if(options?.equal) {
        const equalModelField = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${equal?.model}`
        : equal?.model

        const equalModalValue = getValueByModelName(equalModelField, vtoDetails)

        if (currentFieldValue && equalModalValue !== currentFieldValue) {
            return `Data you entered is not consistent with ${confirmFor || `Account Number`} you entered`
        }
        
    } else {
        return null 
    }
}

export const validateEmailField = (
    value: string | undefined
): string | undefined => {
    if (!isFilledAnswer(value)) {
        return REQUIRED_FIELD_ERROR
    } else {
        if (!emailSchema.isValidSync(value)) {
            return INVALID_EMAIL_ERROR
        }
    }
}

// TODO: Add more custom  validation for the field
export const validateSelectField = (
    value: string | undefined
): string | undefined => {
    if (!isFilledAnswer(value)) {
        return REQUIRED_FIELD_ERROR
    }
}

// TODO: Add more custom  validation for the field
export const validateRadioField = (
    value: string | undefined
): string | undefined => {
    if (!isFilledAnswer(value)) {
        return REQUIRED_FIELD_ERROR
    }
}

// TODO: Add more custom  validation for the field
export const validateDateField = (
    value: string | undefined
): string | undefined => {
    if (!isFilledAnswer(value)) {
        return REQUIRED_FIELD_ERROR
    }
}

export const validateMaskField = (value: string | undefined | number) => {
    if (!isFilledAnswer(value)) {
        return REQUIRED_FIELD_ERROR
    }
}

export const validateNumberField = (currentFieldValue: any, props: any) => {
    const { options, vtoDetails, isRequired, model } = props
    const { required, min, max, lessValidation } = options || {}
    if((currentFieldValue === 0 || currentFieldValue === '0')  && (IgnoreValidationForZero.includes(model))) {
        return null; // Skip validation and exit early
    }
    if ((required || isRequired) && !isFilledAnswer(currentFieldValue)) {
        return REQUIRED_FIELD_ERROR
    }
    if (options?.min && !numberSchema.min(min).isValidSync(currentFieldValue)) {
        return `This field should be greater than or equal to ${options?.min}`
    }
    if (options?.max && !numberSchema.max(max).isValidSync(currentFieldValue)) {
        return `This field should be less than or equal to ${options?.max}`
    }

    if(!currentFieldValue && (required || isRequired)) {
        return REQUIRED_FIELD_ERROR
    }

    /**
     * LESS VALIDATION Structure - 
     * ex: "options": {
                "lessValidation": {
                    "model": "quesion_fieldName",
                    "title": "Some title"
                },
            }
     */

    if(props?.parentModel === 'business.owners' && 
        props?.model === 'share_percent' &&
        ownershipValidation.isInvalid(vtoDetails)) {
        return options?.hint
    }
    if (lessValidation) {
        const parentModelField = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${lessValidation?.model}`
        : lessValidation?.model

        const parentModelValue = getValueByModelName(parentModelField, vtoDetails)

        if (currentFieldValue && Number(parentModelValue) < Number(currentFieldValue)) {
            return `This amount should be equal or less than ${lessValidation.title}`
        }
    } else {
        return null
    }
}

export const ownershipValidation = {
    isInvalid: (data: any) => {
        if (data.type !== 1) {
            return true
        }
        if (
            !data.business ||
            !data.business.owners ||
            data.business.owners.length === 0
        ) {
            return true
        }

        let shareTotal: number | null = 0
        let shareDecimal: number = 0

        data.business.owners.forEach((owner: any) => {
            if (
                owner.share_percent === undefined ||
                owner.share_percent === null
            ) {
                shareTotal = null
            } else {
                const decimalLength = (
                    owner.share_percent.toString().split('.')[1] || ''
                ).length
                shareDecimal = Math.max(shareDecimal, decimalLength)
                shareTotal =
                    shareTotal === null
                        ? null
                        : shareTotal + parseFloat(owner.share_percent)
            }
        })

        shareTotal =
            shareTotal === null
                ? null
                : parseFloat(shareTotal.toFixed(shareDecimal)) // Prevent .99999999 JS float behavior

        if (shareTotal === null) {
            return true
        }
        if (shareTotal === 100) {
            return false
        }
        if (shareTotal > 100) {
            return true
        }
        if (shareTotal < 100) {
            return true
        }

        return true
    },
    isValidShares: (data: any) => {
        if (data.type !== 1) {
            return false
        }

        if (
            !data.business ||
            !data.business.owners ||
            data.business.owners.length === 0
        ) {
            return false
        }

        let shareTotal: number | null = 0

        data.business.owners.forEach((owner: any) => {
            if (
                owner.number_of_shares === undefined ||
                owner.number_of_shares === null ||
                owner.number_of_shares === 0
            ) {
                shareTotal = null
            } else {
                shareTotal =
                    shareTotal === null
                        ? null
                        : shareTotal + parseInt(owner.number_of_shares, 10)
            }
        })

        if (shareTotal === null) {
            return false
        } else {
            return shareTotal > 0
        }
    },
}
