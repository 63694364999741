import React, { useEffect, useState } from 'react';
import {
    Box,
    Theme,
    makeStyles,
    Typography,
    Grid,
    styled,
} from '@material-ui/core'

import DownloadIcon from '../../../../assets/icons-svg/Download.svg'
import { getVtoPdfUrl } from '../../../../services/apiService'
import UiButton from '../../../common/ui/UiButton'
import { useHistory } from 'react-router-dom'
import {
    DOCUMENT_UPLOAD_BUTTONS,
    STATUS_CLIENT_REVIEW,
    STATUS_MAPPING,
    STATUS_TEXT,
    USER_INFO_MESSAGES,
    renderFor,
} from '../constants/tax-prep.const'
import DocumentsUploadModal from '../modals/DocumentsUploadModal'
import UploadIcon from '../../../../assets/icons-svg/UploadIcon.svg'
import TaxWidgetHeader from './TaxWidgetHeader'
import UiText from '../../../common/ui/UiText'
import Form8879ESignModal from '../modals/Form8879ESignModal'
import useModal from './hooks/useModal'
import Form8879UploadModal from '../modals/Form8879UploadModal'
import StatesModal from '../modals/StatesModal'
import TaxPrice from './TaxPrice'
import { useWidgetContext } from '../provider/WidgetProvider'
import {
    TAX_TYPE_8879_FLOW,
    INTERNAL_REVIEW,
    FEDERAL_TAX_TYPE,
} from '../constants/tax-prep.const'
import AccountantContainer from '../AccountantContainer'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../styles/commonStyles'

interface Accountant {
    first_name: string
    last_name: string
    photoLink: string
    roles: string
    id: string
    zone?: null | string
}
interface Props {
    status: string
    title: string
    details?: string
    handleViewMessage?: () => void
    isAditionInfoStatus?: boolean
    taxAccountant?: Accountant
    taxYear: number
    accountId: number
    vto: number
    renderFor: string
    taxPrepStatus: string
    vtoWidgetData: any
    taxApId: string | undefined
    documentUploadedStatuses: string[]
    eFileUploadStatuses: string[]
    currentState?: string
    owner?: Accountant
}
const DOCUMENTS_HEADER = 'Upload Tax Documents'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        width: '100%',
        minHeight: '22.5rem',
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    btnContainer: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            '& .MuiTypography-button': {
                fontSize: '0.9rem',
            },
        },
    },
    taxWidgetDescription: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        marginTop: '0.5rem',
    },
    flexGrid: {
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
    },
    continueButton: {
        width: 40,
        height: 40,
        borderRadius: 8,
        backgroundColor: (colorTheme) => colorTheme.grey100,
    },
    viewMessageButton: {
        [theme.breakpoints.down('xs')]: {
            marginRight: '0.3rem',
        },
    },
    noBorder: {
        borderBottom: 'none',
    },
    bordered: {
        minHeight: '18.6rem',
        boxSizing: 'border-box',
        padding: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
    taxRefundContainer: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        margin: '1rem 0',
    },
    taxRefundContainerTitle: {
        fontWeight: 400,
        letterSpacing: '0.01rem',
    },
    taxRefundContainerPriceInfo: {
        fontWeight: 700,
        letterSpacing: '0.02rem',
    },
    hyperTextColor: {
        color: (colorTheme) => colorTheme.blue200,
        cursor: 'pointer',
        padding: '0',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.01em',
        margin: 0,
    },
    uploadButton: {
        padding: toRem16(10),
        [theme.breakpoints.down('xs')]: {
            padding: '0.625rem 0.5rem',
        },
        '&: .MuiButton-startIcon': {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
    },
    taxWidgetHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.125rem',
        },
    },
    accountantContainer: {
        padding: '0.625rem 1.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0.625rem 1rem',
        },
    },
}))

const TaxRefundDetails = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
})

function TaxPreparationAndNeedInfoState(props: Props) {
    const {
        status,
        title,
        details,
        isAditionInfoStatus,
        handleViewMessage,
        accountId,
        taxYear,
        vto,
        renderFor: type,
        taxPrepStatus,
        vtoWidgetData,
        taxApId,
        documentUploadedStatuses,
        eFileUploadStatuses,
        currentState,
    } = props

    const { stateTax } = useWidgetContext()
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const history = useHistory()
    const [taxInfoDownloadUrl, setTaxInfoDownloadUrl] = useState<string>('')
    const [isOpenDocumentsUploadModal, setIsOpenDocumentsUploadModal] =
        useState<boolean>(false)

    const { open, openModal, closeModal } = useModal()
    const {
        open: openEsign,
        openModal: openEsignModal,
        closeModal: closeEsignModal,
    } = useModal()

    const {
        open: openStates,
        openModal: openStatesModal,
        closeModal: closeStatesModal,
    } = useModal()

    const showESignFlow = () => {
        if (
            props?.renderFor === renderFor.BUSINESS &&
            TAX_TYPE_8879_FLOW.includes(vtoWidgetData?.tax_ap_task_type) &&
            vtoWidgetData?.federal_tax
        ) {
            return true
        }

        return false
    }

    const openEsignModalHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (TAX_TYPE_8879_FLOW.includes(vtoWidgetData.tax_ap_task_type)) {
            openEsignModal()
        } else {
            openModal()
        }
    }

    useEffect(() => {
        const taxInfoUrl = getVtoPdfUrl(accountId, taxYear, vto)
        setTaxInfoDownloadUrl(taxInfoUrl)
    }, [accountId, taxYear, vto])

    const redirectToVTO = (vtoType: any) => {
        vtoType && history.push(`/tax-organizer/${vtoType.toLowerCase()}`)
    }
    const openUploadDocumentsModal = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsOpenDocumentsUploadModal(true)
    }
    /* const openUploadSuccessModal = () => {
        setIsOpenUploadSuccess(true)
    }
    const closeUploadSuccessModal = () => {
        setIsOpenUploadSuccess(false)
    }*/
    /* const disableUploadButton = () => {
        if (isACAStatus()) {
            vtoWidgetData.is_form_8879_received = true
            return
        }
        vtoWidgetData.is_info_received = true
    }*/

    const isDocumentUploadStatus = (): boolean => {
        return documentUploadedStatuses.includes(taxPrepStatus)
    }

    const isACAStatus = (): boolean => {
        return eFileUploadStatuses.includes(taxPrepStatus)
    }
    const isShowDocumentsUpload = (): boolean => {
        return isDocumentUploadStatus() || isACAStatus()
    }
    const getDocumentsUploadLabel = (): string => {
        if (isACAStatus()) {
            return DOCUMENT_UPLOAD_BUTTONS.UPLOAD_FORM_8879
        }
        return DOCUMENT_UPLOAD_BUTTONS.UPLOAD_DOCUMENTS
    }
    const getStatusText = () => {
        if (isACAStatus()) {
            return STATUS_TEXT.NEED_AUTHORIZATION
        }
        const taxWidgetTitle =
            //@ts-ignore
            status && (STATUS_MAPPING[status]?.title || status)
        return taxWidgetTitle || STATUS_TEXT.TAX_PREPARATION_STATUS
    }
    const isForm8879Received = (): boolean => {
        return vtoWidgetData.is_form_8879_received
    }
    const isDisableUpload = (): boolean => {
        if (isACAStatus()) {
            return isForm8879Received()
        }
        return false
    }
    const documentUploadStatus = () => {
        return <span dangerouslySetInnerHTML={{ __html: details! }}></span>
    }

    const getFedralTaxType = () => {
        const taxType = vtoWidgetData?.federal_tax?.type
        return taxType === 'Owes'
            ? FEDERAL_TAX_TYPE.FEDERAL_TAX_DUE
            : taxType === 'NA'
            ? FEDERAL_TAX_TYPE.FEDERAL_TAX_RETURN
            : FEDERAL_TAX_TYPE.FEDERAL_TAX_REFUND
    }

    return (
        <div className={classes.container}>
            <Box className={classes.bordered}>
                <TaxWidgetHeader
                    title={title}
                    status={currentState ? currentState : status}
                    vtoWidgetData={vtoWidgetData}
                />
                <Grid
                    container
                    alignItems={'center'}
                    className={classes.flexGrid}
                >
                    <Box mr={'5px'}>
                        <UiText
                            className={classes.taxWidgetHeading}
                            variant="suv_150"
                            weight="bold_700"
                        >
                            {getStatusText()}
                        </UiText>
                    </Box>
                </Grid>

                <Typography className={classes.taxWidgetDescription}>
                    {isACAStatus()
                        ? showESignFlow()
                            ? USER_INFO_MESSAGES.ACA_STATUS_TEXT
                            : USER_INFO_MESSAGES.ACA_STATUS_TEXT_NO_ESIGN
                        : documentUploadStatus()}
                </Typography>

                <Box mt={3}>
                    <Grid className={classes.btnContainer}>
                        {isAditionInfoStatus && (
                            <Box mr={3} className={classes.viewMessageButton}>
                                <UiButton
                                    handleClick={(e) => {
                                        e.stopPropagation()
                                        handleViewMessage && handleViewMessage()
                                    }}
                                    customClass={classes.viewMessageButton}
                                    label="View Message"
                                    btnType="tertiary"
                                />
                            </Box>
                        )}
                        {status !== INTERNAL_REVIEW && (
                            <Box>
                                {status !== STATUS_CLIENT_REVIEW ? (
                                    isShowDocumentsUpload() ? (
                                        <UiButton
                                            handleClick={
                                                openUploadDocumentsModal
                                            }
                                            label={getDocumentsUploadLabel()}
                                            btnType={
                                                status === STATUS_CLIENT_REVIEW
                                                    ? 'tertiary'
                                                    : 'secondary'
                                            }
                                            customClass={classes.uploadButton}
                                            startIconSvg={
                                                status !== 'Client Review'
                                                    ? UploadIcon
                                                    : ''
                                            }
                                            disabled={isDisableUpload()}
                                        />
                                    ) : (
                                        <UiButton
                                            handleClick={(e) => {
                                                e.stopPropagation()
                                                window.open(
                                                    `${taxInfoDownloadUrl}`,
                                                    '_blank'
                                                )
                                            }}
                                            startIconSvg={DownloadIcon}
                                            label="Tax Info"
                                            btnType="secondary"
                                        />
                                    )
                                ) : showESignFlow() ? (
                                    <UiButton
                                        handleClick={openEsignModalHandler}
                                        label={'Complete Form 8879'}
                                        btnType="tertiary"
                                        customClass={classes.uploadButton}
                                    />
                                ) : (
                                    <UiButton
                                        handleClick={(e) => {
                                            e.stopPropagation()
                                            openModal()
                                        }}
                                        label="Upload Form 8879"
                                        btnType={'tertiary'}
                                        customClass={classes.uploadButton}
                                        disabled={isDisableUpload()}
                                    />
                                )}
                            </Box>
                        )}
                    </Grid>
                    {status === STATUS_CLIENT_REVIEW && showESignFlow() && (
                        <Box className={classes.taxRefundContainer}>
                            {vtoWidgetData.federal_tax && (
                                <>
                                    <TaxRefundDetails>
                                        <Typography
                                            className={
                                                classes.taxRefundContainerTitle
                                            }
                                            component="span"
                                        >
                                            {getFedralTaxType()}
                                        </Typography>
                                        <TaxPrice
                                            type={
                                                vtoWidgetData?.federal_tax?.type
                                            }
                                            amount={
                                                vtoWidgetData?.federal_tax
                                                    ?.amount
                                            }
                                        />
                                    </TaxRefundDetails>
                                </>
                            )}

                            {vtoWidgetData.state_taxes && (
                                <>
                                    <TaxRefundDetails
                                        style={{ marginTop: '12px' }}
                                    >
                                        <Typography
                                            className={
                                                classes.taxRefundContainerTitle
                                            }
                                            component="span"
                                        >
                                            {vtoWidgetData?.state_taxes[0]
                                                ?.type === 'NA'
                                                ? 'State Tax Return'
                                                : stateTax < 0
                                                ? 'State Tax Due'
                                                : 'State Tax Refund'}
                                        </Typography>
                                        {vtoWidgetData.state_taxes?.length >
                                        1 ? (
                                            <TaxPrice amount={stateTax} />
                                        ) : (
                                            <TaxPrice
                                                type={
                                                    vtoWidgetData
                                                        ?.state_taxes[0]?.type
                                                }
                                                amount={
                                                    vtoWidgetData
                                                        ?.state_taxes[0]?.amount
                                                }
                                            />
                                        )}
                                    </TaxRefundDetails>
                                    {vtoWidgetData.state_taxes?.length > 1 && (
                                        <p
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                openStatesModal()
                                            }}
                                            className={classes.hyperTextColor}
                                        >
                                            See All States
                                        </p>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            <AccountantContainer
                taxAdvisoryStatus = {vtoWidgetData?.hasTaxAdvisoryPermission}
                status={vtoWidgetData?.tax_ap_status}
                type={props.renderFor}
            />

            <DocumentsUploadModal
                open={isOpenDocumentsUploadModal}
                title={DOCUMENTS_HEADER}
                handleClose={() => {
                    setIsOpenDocumentsUploadModal(false)
                }}
                vtoType={type as renderFor}
                taxYear={taxYear}
                vtoId={vto}
                taxPrepStatus={taxPrepStatus}
                taxApId={taxApId}
            />

            <Form8879UploadModal
                open={open}
                handleClose={closeModal}
                vtoType={type as renderFor}
                taxYear={taxYear}
                taxPrepStatus={taxPrepStatus}
                taxApId={taxApId}
                flow={'upload'}
            />

            <StatesModal open={openStates} handleClose={closeStatesModal} />

            <Form8879ESignModal
                open={openEsign}
                handleClose={closeEsignModal}
                vtoType={type as renderFor}
                taxYear={taxYear}
                vtoId={vto}
                taxPrepStatus={taxPrepStatus}
                taxApId={taxApId}
            />
        </div>
    )
}

export default TaxPreparationAndNeedInfoState
