import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Typography,
} from '@material-ui/core'
import DownloadIcon from '../DownloadIcon'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DocumentIcon from '../../../../assets/icons-svg/DownloadDocumentIcon.svg'
import { ApplicationStore } from '../../../../models/store'
import { connect } from 'react-redux'
import { getVtoPdfUrl } from '../../../../services/apiService/taxPrepService'
import { commonStyles, mergeStyle } from '../../../../styles/commonStyles'
import TaxWidgetHeader from './TaxWidgetHeader'
import { USER_INFO_MESSAGES } from '../constants/tax-prep.const'
import UiText from '../../../common/ui/UiText'
import AccountantContainer from '../AccountantContainer'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { downloadFile } from '../../../taxes/vto/common/VtoUtils';

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    buttonDownload: {
        background: colorTheme => colorTheme.primaryWhite,
        color: colorTheme => colorTheme.tertiary,
        padding: '6px 8px 6px 8px',
        borderRight: colorTheme =>  `1px solid ${colorTheme.tertiary}`,
        '&:hover': {
            background: colorTheme =>  `${colorTheme.primaryWhite}`,
        },
        '& img': {
            height: '20px',
            width: '20px',
        },
        '& svg': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            width: '10.75rem',
        },
    },
    singleFileDownload: {
        background: colorTheme => colorTheme.primaryWhite,
        color: colorTheme => colorTheme.tertiary,
        '&:hover': {
            background: colorTheme => `${colorTheme.primaryWhite}`,
        },
    },
    bordered: {
        minHeight: '17.5rem',
        boxSizing: 'border-box',
        padding: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
    downloadText: {
        borderRight: colorTheme => `1px solid ${colorTheme.tertiary}`,
        height: '36px',
        paddingRight: '8px',
    },
    completeIcon: {
        width: '18.67px',
        marginRight: theme.spacing(1),
    },
    downloadIcon: {
        '& img': {
            width: '20px',
        },
    },
    buttonTaxInfo: {
        color: colorTheme =>  colorTheme.primaryWhite,
        border: colorTheme => `1px solid ${colorTheme.white100}`,
        '&:hover': {
            border: colorTheme => `1px solid ${colorTheme.white100}`,
        },
        marginLeft: '0.75rem',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    downloadMenu: {
        '& ul': {
            width: '350px',
            '& a': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'none',
                color: colorTheme => colorTheme.black100,
                fontSize: '15px',
            },
            '& li': {
                padding: '16px',
            },
            '& li:nth-child(odd)': {
                borderBottom: colorTheme => `1px solid ${colorTheme.grey200} !important`,
            },
        },
        '& img': {
            marginRight: theme.spacing(1),
        },
    },
    buttonDisabled: {
        background: colorTheme => colorTheme.grey2300,
    },
    paperIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -1,
    },
    flexGrid: {
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
    },
    taxWidgetDescription: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        marginTop: '0.5rem',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '1rem',
        },
    },
    taxWidgetHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.125rem',
        },
    },
    completeStepContainer: {
        width: '100%',
        position: 'relative',
        height: '100%',
        minHeight: '22.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
    },
}))
interface FileUploads {
    download_link: string
    filename: string
    id: string
    is_preview_available: boolean
    mime_type: string
    portal_account_id: string
    preview_link: string
    size: number
    upload_date: number
}

function ReturnComplete(props: any) {
    const { colorTheme } = useThemeContext()
    const { widgetData, taxYear, accountId, title, renderFor, status }: any =
        props

    const fileUploads: FileUploads[] = widgetData.uploads.tax_returns ?? []
    const vto = widgetData.id
    const classes = styles(colorTheme)
    const commonStyle = commonStyles()
    const [avatarEl, setAvatarEl] = useState<Element | null>(null)
    const [taxInfoDownloadUrl, setTaxInfoDownloadUrl] = useState<string>('')

    const taxWidgetApStatus = widgetData?.tax_ap_status

    const isDiscarded = () => {
        return taxWidgetApStatus === 'discarded_schedule_c' ? true : false
    }

    const getTaxStatus = () => {
        return isDiscarded()
            ? 'Combined with your Personal Tax Return'
            : 'Tax Return Completed'
    }

    const getTaxWidgetDescription = () => {
        return isDiscarded()
            ? USER_INFO_MESSAGES.DISCARDED_C
            : USER_INFO_MESSAGES.TAX_RETURN_COMPLETED
    }

    useEffect(() => {
        if(vto) {
            const taxInfoUrl = getVtoPdfUrl(accountId, taxYear, vto)
            setTaxInfoDownloadUrl(taxInfoUrl)
        }
    }, [accountId, taxYear, vto])
    return (
        <div className={classes.completeStepContainer}>
            <Box className={classes.bordered}>
                <TaxWidgetHeader title={title} status="Completed" />
                <Grid
                    container
                    alignItems={'center'}
                    className={classes.flexGrid}
                >
                    <Box mr={'5px'}>
                        <UiText
                            className={classes.taxWidgetHeading}
                            variant="suv_150"
                            weight="bold_700"
                        >
                            {getTaxStatus()}
                        </UiText>
                    </Box>
                </Grid>
                <Typography className={classes.taxWidgetDescription}>
                    {getTaxWidgetDescription()}
                </Typography>

                {!isDiscarded() && (
                    <Box className={classes.buttonContainer} mt={3}>
                        {fileUploads.length > 1 ? (
                            <>
                                <Button
                                    variant="outlined"
                                    className={mergeStyle(
                                        classes.buttonDownload,
                                        'mr3'
                                    )}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setAvatarEl(e.currentTarget)
                                    }}
                                >
                                    <DownloadIcon color={colorTheme.tertiary} />
                                    <span
                                        className={mergeStyle(
                                            commonStyle.ml1,
                                            classes.downloadText,
                                            'flex-center'
                                        )}
                                    >
                                        Download
                                    </span>
                                    {!!avatarEl ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </Button>

                                <Menu
                                    open={!!avatarEl}
                                    anchorEl={avatarEl}
                                    getContentAnchorEl={null}
                                    className={classes.downloadMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    onClose={(e) => {
                                        setAvatarEl(null)
                                    }}
                                    MenuListProps={{ disablePadding: true }}
                                    elevation={4}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {fileUploads.map((option: any) => (
                                        <MenuItem key={option}>
                                            <img
                                                src={DocumentIcon}
                                                alt="Download document"
                                            />
                                            <a
                                                href={option.download_link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {option.filename}
                                            </a>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        ) : (
                            <span
                                className="mr3"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Button
                                    variant="outlined"
                                    disabled={!fileUploads.length}
                                    className={
                                        fileUploads.length
                                            ? classes.singleFileDownload
                                            : classes.buttonDisabled
                                    }
                                    startIcon={
                                        <DownloadIcon
                                            color={
                                                fileUploads.length
                                                    ? colorTheme.tertiary
                                                    : colorTheme.grey200
                                            }
                                        />
                                    }
                                    onClick={() => {
                                        fileUploads[0].download_link &&
                                            downloadFile(fileUploads[0].download_link)
                                    }}
                                >
                                    <Typography variant="button">
                                        Download{' '}
                                    </Typography>
                                </Button>
                            </span>
                        )}
                        <Box>
                            <a
                                href={taxInfoDownloadUrl}
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                                className={`${classes.downloadIcon}`}
                                rel="noreferrer"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Button
                                    variant="outlined"
                                    startIcon={
                                        <DownloadIcon color={colorTheme.primaryWhite} />
                                    }
                                    color="primary"
                                    className={`${classes.buttonTaxInfo}`}
                                >
                                    <Typography variant="button">
                                        {' '}
                                        Tax Info{' '}
                                    </Typography>
                                </Button>
                            </a>
                        </Box>
                    </Box>
                )}
            </Box>
            <AccountantContainer
                taxAdvisoryStatus = {widgetData?.hasTaxAdvisoryPermission}
                status={widgetData?.tax_ap_status}
                type={renderFor}
                widgetStatus="completed"
                taxApStatus={status}
            />
        </div>
    )
}
const mapStateToProps = (state: ApplicationStore) => ({
    accountId: state.appData.current_account_id,
})
export default connect(mapStateToProps)(ReturnComplete)
