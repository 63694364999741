import { makeStyles, Theme, Tooltip } from '@material-ui/core'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import Icon from '../../../../../../common/Icon'
import UiText from '../../../../../../common/ui/UiText'
import { InfoOutlined } from '@material-ui/icons'
import { useVtoContext } from '../../../../providers/VtoProvider'
import InfoTooltip from '../../../../../../common/InfoTooltip'
import { hasPermission } from '../../../../../../../services/aclService'
import { useCurrentStore } from '../../../../../../common/hooks/useCurrentStore'

const useStyle = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        listStyle: {
            listStyle: 'none',
            marginBottom: toRem16(16),
        },
        list: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        product: {
            display: 'flex',
            gap: toRem16(16),
        },
        prodctName: {
            textAlign: 'left',
        },
        infoIcon: {
            display: 'flex',
            alignItems: 'center',
        },
    }
})

export default function ProductList({ product, auditDefenseDisplayed }: any) {
    const theme = useThemeContext()
    const { title, description, superScript, code } = product
    const styles = useStyle(theme.colorTheme)
    const { currentVtoYearConfig }: any = useVtoContext()
    const { currentAccount, personalAccount } = useCurrentStore()

    const productTooltip = () => {
        const tooltipText = currentVtoYearConfig?.product_tooltip[code]
        return tooltipText ? tooltipText : description
    }
    if (
        !hasPermission('AUDIT_BUSINESS', currentAccount) &&
        !hasPermission('AUDIT_PERSONAL', personalAccount)
    ) {
        if (title === 'Personal Audit Defense') {
            return <span /> // OEHA-24642 to comibine both audit defense
        }
    }

    return (
        <li key={product.title} className={styles.listStyle}>
            <div className={styles.list}>
                <div className={styles.product}>
                    <Icon icon="CheckmarkGreen" />
                    <UiText className={styles.prodctName}>
                        {`${title}`
                            .replace('Business Audit Defense', 'Audit Defense')
                            .replace('Personal Audit Defense', 'Audit Defense')}
                        {superScript && <sup>{superScript}</sup>}
                    </UiText>
                </div>
                <InfoTooltip
                    tooltipText={productTooltip()}
                    customNode={<InfoOutlined className={styles.infoIcon} />}
                />
            </div>
        </li>
    )
}
