import React from 'react'
import { FilterList } from '@material-ui/icons'
import {
    Button,
    Divider,
    Theme,
    makeStyles,
    IconButton,
    Chip,
    useMediaQuery,
} from '@material-ui/core'
import UiDialog from '../../../../common/ui/UiDialog'
import { useCallback, useEffect, useState } from 'react'
import Icon from '../../../../common/Icon'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import { commonVtoStyles } from '../../core/styles/commonVtoStyles'
import UiText from '../../../../common/ui/UiText'
import SearchBox from '../../../../common/SearchBox'
import { useCurrentStore } from '../../../../common/hooks/useCurrentStore'
import { getFolders, searchDocuments } from '../../../../../services/apiService'
import { buildTree } from '../../../../../services/folderService'
import {
    DocumentQueryObject,
    Folder,
    SearchDocumentApiParams,
    UploadedFile,
    paginatedDocumentResponse,
} from '../../../../../models'
import { useDispatch } from 'react-redux'
import { showError } from '../../../../../store/actions/feedback'
import DocumentsTree from '../../../../documents/DocumentsTree'
import { useVtoContext } from '../../providers/VtoProvider'
import { toRem16 } from '../../../../../styles/commonStyles'
import moment from 'moment'
import DocumentFilter from './ImportDocument/DocumentFilter'
import DocumentList from '../../../../documents/DocumentList'
import {
    convertBytesToSize,
    convertToBytes,
} from '../../../../../utils/documentUtil'
import Loader from '../../../../common/Loader'
import { useScroll } from '../../../../../hooks/useScroll'
import { importFile } from '../../../../../services/vto/vtoFileUpload'
import { DocumentType, TaxStepFileType } from '../../models/vto.document-model'
import { requestErrorHandler } from '../../../../../services/formService'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { getChipsData } from './ImportDocument/ImportDocUtlities'
import leftArrow from '../../../../../assets/icons-svg/LeftArrow.svg'
import UiButton from '../../../../common/ui/UiButton'
import UiChip from '../../../../common/ui/UiChip'

export enum SelectView {
    All = 'all',
    Checked = 'checked',
    Folder = 'folder',
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        dialogContainer: {
            height: `calc(100vh - ${toRem16(150)})`,
        },
        rootDialogContainer: {
            '& .MuiDialogContent-root': {
                padding: '0px',
            },
        },
        listViewContainer: {
            height: '92%',
            '& .dialog-header': {
                padding: '1.5rem 1rem 1rem 1.5rem',
            },
            [theme.breakpoints.down('sm')]: {
                height: '100%',
            }
        },
        documentContainer: {
            display: 'flex',
            marginTop: '1rem',
            height: `calc(100% - 5.5rem)`,
            [theme.breakpoints.down('sm')]: {
                height: `calc(100% - 2.5rem)`,
            }
        },
        documentMobileContainer: {
            marginTop: '0'
        },
        folderTree: {
            flex: '0.5',
            paddingLeft: '1.5rem',
            [theme.breakpoints.down('sm')]: {
                flex: '1',
                padding: '0'
            }
        },
        documentList: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
        },
        dividerStyle: {
            margin: '0 1rem 0 1rem',
        },
        searchInput: {
            display: 'flex',
            marginBottom: '1rem ',
            '& .search-box': {
                marginTop: toRem16(6),
                width: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                margin: '0 1rem',
            },
        },
        documentTreeView: {
            overflowY: 'auto',
            maxHeight: `calc(100% - ${toRem16(60)})`,
        },
        docListContainer: {
            height: `calc(100% - 1rem)`,
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: `calc(100% - 0.5rem)`,
            }
        },
        importButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem',
            marginTop: '1rem',
            marginRight: '1rem',
            '& .cancel-button': {
                marginRight: '1rem',
            },
            '& .loader-button': {
                display: 'inline-flex',
            },
        },
        scrollDiv: {
            height: toRem16(80),
        },
        textStyles: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '1rem',
            }
        },
        fileSelector: {
            display: 'flex',
            paddingLeft: '1rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            cursor: 'pointer',
            borderRadius: toRem16(4),
        },
        selectedHeader: {
            display: 'flex',
            paddingLeft: '1rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            cursor: 'pointer',
            borderRadius: toRem16(4),
            background: (colorTheme) => colorTheme.cyan100,
        },
        documentCenterBtn: {
            color: (colorTheme) => colorTheme.black100,
        },
        navBtn: {
            width: 'fit-content',
        },
        clearFilter: {
            '& .MuiButton-label': {
                color: (colorTheme) => colorTheme.blue200,

            }
        },
        fixedButtonContainer: {
            width: '360px',
            margin: 'auto',
            zIndex: 100,
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                width: '100%',
                boxSizing: 'border-box',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            },
        },
        cancelBtn: {
            color: (colorTheme) => colorTheme.blue200,
        },
        prevBtn: {
            marginLeft: '0.875rem',
            marginBottom: '0.5rem',
        },
        chipStyles: {
           position: 'relative',
           top: `-${toRem16(2)}`,
           marginLeft: `${toRem16(6)}`,
        },
        chipRootStyles: {
            color: (colorTheme) => `${colorTheme.primaryWhite }!important`,
            height: `${toRem16(24)}`,
            backgroundColor: colorTheme => `${colorTheme.primaryGreen} !important`,
        },
        chipText: {
            paddingLeft: toRem16(3),
        },
        chipContainer: {
            display: 'flex',
            '& .chip-details': {
                marginTop: toRem16(4),
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '1rem'
            }
        },
        xsP16: {
            [theme.breakpoints.down('xs')]:{
                padding: '0 1rem'
            }
        }
    }
})

export const NavigationButton = ({ label, startIconSvg, handleClick }: any) => (
    <UiButton
        btnType="hyperlink"
        label={label}
        startIconSvg={startIconSvg}
        customClass={useStyles(useThemeContext().colorTheme).navBtn}
        handleClick={handleClick}
    />
)

export const filterDefaultValues: DocumentQueryObject = {
    minDate: moment().startOf('day').subtract(4, 'days').unix(),
    maxDate: moment().endOf('day').unix(),
    period: 'all',
    size: {
        operator: 'gt',
        value: 0,
    },
    fileSizeType: 'KB',
}

const defaultPaginatedValue = {
    items: [],
    itemsCount: 0,
    page: 1,
    pageCount: 1,
    perPage: 10,
}

const ImportDocuments = ({
    setFileList,
    fileType,
    selectMultiple = true,
}: {
    setFileList?: React.Dispatch<React.SetStateAction<UploadedFile[]>>
    fileType?: DocumentType | TaxStepFileType
    selectMultiple?: boolean
}) => {
    const { colorTheme }: {colorTheme: ThemeColors} = useThemeContext()
    const styles = commonVtoStyles(colorTheme)
    const classes = useStyles(colorTheme)
    const dispatch = useDispatch()
    const { currentAccountId, personalAccountId } = useCurrentStore()
    const { selectedYear, currentVtoDetails } =
        useVtoContext()

    const [chipsToDisplay, setChipsToDisplay] = useState<
        { label: string; searchKey: 'period' | 'size' }[]
    >([])
    const modalContainer = document.getElementById('doc-container')
    const [ref, entry] = useScroll({
        root: modalContainer,
        rootMargin: '0px',
    })

    const [importing, setImporting] = useState<boolean>(false)
    const [openFilterView, setOpenFilterView] = useState(false)
    const [showDocumentPage, setShowDocumentPage] = useState(false)
    const [selectedView, setSelectedView] = useState<
        'all' | 'checked' | 'folder'
    >(SelectView.All)
    const [queryObject, setQueryObject] =
        useState<DocumentQueryObject>(filterDefaultValues)
    const [openModal, setOpenModal] = useState(false)
    const [apiLoading, setApiLoading] = useState(false)
    //document list states
    const [term, setTerm] = useState('')
    const [files, setFiles] =
        useState<paginatedDocumentResponse<UploadedFile>>()
    const [checkedFiles, setCheckedFiles] = useState<any[]>([])
    const [folders, setFolders] = useState<Folder[]>([])
    const [businessFolderTree, setBusinessFolderTree] = useState<any>(null)
    const [personalFolderTree, setPersonalFolderTree] = useState<any>(null)
    const [folderPath, setFolderPath] = useState<any>({})
    const [allFilesData, setAllFilesData] = useState<any>({})

    const fileSizeInByte = convertToBytes(
        queryObject.size.value,
        queryObject.fileSizeType
    )

    const checkedFilesCount = checkedFiles.length ? checkedFiles.length : ''
    const checkedFileIds = checkedFiles.map((checkedFile) => checkedFile.id)
    const smDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    const isFilterOpenInSmDevice = smDevice && openFilterView

    const formattedQueryParams = useCallback(() => {
        const { period, minDate, maxDate, size } = queryObject
        return {
            ...(period === 'all'
                ? null
                : { minDate: minDate, maxDate: maxDate }),
            term,
            period,
            ...(folderPath?.path ? {path: folderPath?.path} : ''),
            ...(size.operator === 'gt'
                ? { minSize: fileSizeInByte }
                : { maxSize: fileSizeInByte }),
        }
    }, [fileSizeInByte, queryObject, term, folderPath])

    const changeSelectedView = (type: SelectView) => {
        setSelectedView(type)
    }

    const isAllViewSelected = selectedView === SelectView.All
    const isCheckedViewSelected = selectedView === SelectView.Checked

    const handleShowDocumentPage = () => {
        smDevice && setShowDocumentPage(true)
    }

    const handleSearch = () => {
        setFiles(() => undefined)
        const params = formattedQueryParams()
        setApiLoading(true)
        getDocuments({ ...params })
        handleShowDocumentPage()
    }

    const getDocuments = useCallback(
        (params: SearchDocumentApiParams) => {
            searchDocuments(currentAccountId, params)
                .then((response) => {
                    if (response) {
                        const { itemsCount, page, perPage, pageCount, items } =
                            response
                        setFiles((prev: any) => ({
                            ...prev,
                            items:
                                page === 1 ? items : prev.items.concat(items),
                            page: page,
                            pageCount: pageCount,
                            perPage: perPage,
                            itemsCount: itemsCount,
                            hasMore: page < pageCount,
                            loading: false,
                        }))
                        setApiLoading(false)
                        if(page === 1 && !folderPath?.path){
                            setAllFilesData(response)
                        }
                    }
                })
                .catch((err) => {
                    dispatch(
                        showError({ errorCode: err?.status, showError: true })
                    )
                })
        },
        [currentAccountId, dispatch, smDevice]
    )

    const arrangeTaxFolders = useCallback((tree) => {
        let taxFolders: any = {}
        if (!tree?.folders['/taxes']) {
            return taxFolders
        }
        Object.keys(tree?.folders['/taxes']?.folders as any)
            .sort(
                (a, b) =>
                    tree.folders['/taxes'].folders[a].order -
                    tree.folders['/taxes'].folders[b].order
            )
            .forEach((key: string) => {
                taxFolders[key] = tree?.folders['/taxes'].folders[key]
            })

        tree.folders['/taxes'].folders = {
            ...taxFolders,
        }
    }, [])

    const loadFolders = useCallback(() => {
        setFolders([])
        getFolders(currentAccountId) 
            .then((res) => {
                const businessTree = buildTree(
                    res,
                    currentAccountId,
                    'business'
                )
                const personalTree = buildTree(
                    res,
                    personalAccountId,
                    'personal'
                )
                arrangeTaxFolders(businessTree)
                arrangeTaxFolders(personalTree)
                setBusinessFolderTree(() => businessTree)
                setPersonalFolderTree(() => personalTree)
                setFolders(res)
            })
            .catch((err) => {
                dispatch(showError({ errorCode: err?.status, showError: true }))
            })
    }, [currentAccountId, personalAccountId, arrangeTaxFolders, dispatch])

    const filterDocuments = (
        result: DocumentQueryObject | boolean,
        searchTerm: string = term
    ) => {
        setApiLoading(true)
        setFiles((prev: any)=>({
            ...prev,
            ...defaultPaginatedValue,
        }))
        setCheckedFiles([])
        if (result && typeof result === 'object') {
            const convertedSize: { size: number; sizeType: 'MB' | 'KB' } =
                convertBytesToSize(result?.size?.value)
            setQueryObject({
                ...result,
                size: { ...result.size, value: convertedSize.size },
                fileSizeType: convertedSize.sizeType,
            })
            const { minDate, maxDate, size, period } = result
            const chips: any = getChipsData(result)
            setChipsToDisplay([...chips])
            getDocuments({
                ...(period === 'all'
                    ? null
                    : { minDate: minDate, maxDate: maxDate }),
                term: searchTerm,
                period,
                ...(size.operator === 'gt'
                    ? { minSize: size?.value }
                    : { maxSize: size?.value }),
                type: 'all',
                ...(folderPath?.path 
                    ? { path: folderPath?.path  }
                    : null),
            })
        }
    }

    const onFolderClick = () => {
        setChipsToDisplay([])
        setQueryObject(filterDefaultValues)
        handleShowDocumentPage()
    }

    const clearChipFilters = () => {
        setApiLoading(true)
        setChipsToDisplay([])
        getDocuments({
            period: 'all',
            term: '',
        })
        setQueryObject(filterDefaultValues)
    }

    const handleDeleteChips = (searchKey: 'period' | 'size', index: number) => {
        const newChips = [...chipsToDisplay];
        newChips.splice(index, 1);
        setChipsToDisplay(newChips);
      
        const queryData = formattedQueryParams()
        setApiLoading(true)
        if (searchKey === 'period') {
            delete queryData.minDate
            delete queryData.maxDate
            
            setQueryObject({
                ...queryObject,
                period: 'all',
            })
            getDocuments({
                ...queryData,
                period: 'all',
            })
        } else if (searchKey === 'size') {
            getDocuments({
                ...queryData,
                minSize: 0,
            })
            setQueryObject({
                ...queryObject,
                size: {
                    operator: 'gt',
                    value: 0,
                },
            })
        }
    }
    /**
     * Check/Uncheck flow of the selected documents
     */
    const handleCheckedFiles = (
        checked: boolean,
        selectedFile: UploadedFile
    ) => {
        if (checked) {
            setCheckedFiles((prev: any) => [...prev, selectedFile])
        } else {
            setCheckedFiles((prev: any) =>
                prev.filter((file: any) => file.id !== selectedFile.id)
            )
        }
    }

    const fetchFolderDocuments = (folder: Folder) => {
        setApiLoading(true)
        setFolderPath(folder)
        getDocuments({
            path: folder.path,
            term,
        })

    }

    const loadMoreFiles = useCallback(() => {
        const currentPage = files?.page as number
        const params = formattedQueryParams()
        setFiles((prev: any) => ({
            ...prev,
            loading: true,
        }))
        getDocuments({
            page: currentPage + 1,
            type: 'all',
            ...params,
        })
    }, [files?.page, formattedQueryParams, getDocuments])

    const checkedFilesText = () => {
        if (checkedFiles.length > 0) {
            return (
                <div
                    className={classes?.chipStyles}
                >
                    <UiChip
                        label={checkedFiles.length}
                        customRootStyles={classes.chipRootStyles}
                        size="small"
                    />
                </div>
                
            )
        }
    }

    const handleImportFiles = async () => {
        if (selectMultiple) {
            setImporting(true);
            for (const fileId of checkedFileIds) {
                try {
                    await importFile(selectedYear, currentVtoDetails.id, {
                        files: [fileId], // Import one file at a time
                        type: fileType as DocumentType,
                    })
                    setFileList?.((prev) => [...prev, checkedFiles.find(f => f.id === fileId)]);
                } catch (error) {
                    console.error("Error importing file:", fileId, error);
                }
            }
    
            setImporting(false);
            setOpenModal(false);
        } else {
            setFileList?.([checkedFiles[0]]);
            setOpenModal(false);
        }
    };

    const fetchDocuments = useCallback(() => {
        setApiLoading(true)
        getDocuments({
            period: 'all',
            term: '',
        })
    }, [getDocuments])

    useEffect(() => {
        if (openModal) {
            loadFolders()
            setFiles(defaultPaginatedValue)
            setFolders([])
            setCheckedFiles([])
            fetchDocuments()
            setFolderPath({})
            setQueryObject(filterDefaultValues)
            setChipsToDisplay([])
            setTerm('')
        }
        
    }, [fetchDocuments, loadFolders, openModal])

    const isScrollEnd = !!entry?.isIntersecting

    useEffect(() => {
        if (isScrollEnd) {
            files?.hasMore && !files?.loading && loadMoreFiles()
        }
    }, [files?.hasMore, files?.loading, isScrollEnd, loadMoreFiles])

    const renderSearchBox = () => {
        return (
            <div className={classes.searchInput}>
                <div className="search-box">
                    <SearchBox
                        searchTerm={term}
                        setInput={setTerm}
                        placeHolder={'Search Document Center'}
                        notched={false}
                        fullWidth={true}
                        clearSearch={() => {
                            setTerm('')
                            setCheckedFiles([])
                            filterDocuments(queryObject, '')
                        }}
                        onKeyPressEvent={(event) => {
                            if (event.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                    />
                </div>
                <IconButton
                    color="secondary"
                    className={styles.filterButton}
                    onClick={() => setOpenFilterView(!openFilterView)}
                >
                    <FilterList />
                </IconButton>
            </div>
        )
    }

    return (
        <div>
            <Button
                className={styles.topMargin}
                variant="outlined"
                color="secondary"
                startIcon={<Icon icon="layer" />}
                onClick={() => {
                    setOpenModal(true)
                }}
            >
                <UiText
                    className={styles.documentCenterBtn}
                    variant={smDevice ? 'car_100' : 'motorcycle_90'}
                    weight="semi_bold_600"
                >
                    Browse the Document Center
                </UiText>
            </Button>

            <UiDialog
                open={openModal}
                fullScreen={smDevice}
                handleClose={() => {
                    setOpenModal(false)
                }}
                title="Select Files to Import"
                size="lg"
                hideTitleSection={true}
                customRootClass={classes.rootDialogContainer}
            >
                <div className={classes.dialogContainer}>
                    <div className={classes.listViewContainer}>
                        {!smDevice && (
                            <div className="dialog-header">
                                <UiText variant="suv_150" weight="bold_700">
                                    Select Files to Import
                                </UiText>
                            </div>
                        )}
                        <Divider className={classes.bott} />

                        <div
                            className={`${classes.documentContainer} ${
                                isFilterOpenInSmDevice &&
                                classes.documentMobileContainer
                            }`}
                        >
                            {(!smDevice || (smDevice && !showDocumentPage)) && (
                                <div className={classes.folderTree}>
                                    {!isFilterOpenInSmDevice &&
                                        renderSearchBox()}
                                    {openFilterView ? (
                                        <div>
                                            <DocumentFilter
                                                queryObject={queryObject}
                                                applyFilters={(filterData) => {
                                                    smDevice && setOpenFilterView(false)
                                                    handleShowDocumentPage()
                                                    filterDocuments(filterData)
                                                }}
                                                resetFilter={() => {
                                                    //setFolderPath({})
                                                    filterDocuments(filterDefaultValues)
                                                }}
                                                hideFilters={() => {
                                                    setOpenFilterView(false)
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={classes.documentTreeView}
                                        >
                                            <div
                                                className={
                                                    isCheckedViewSelected
                                                        ? classes.selectedHeader
                                                        : classes.fileSelector
                                                }
                                                onClick={() => {
                                                    changeSelectedView(
                                                        SelectView.Checked
                                                    )
                                                }}
                                            >
                                                <UiText variant="motorcycle_90">
                                                    Selected Files{' '}
                                                </UiText>
                                                {checkedFilesText()}
                                            </div>
                                            <div
                                                className={
                                                    isAllViewSelected
                                                        ? classes.selectedHeader
                                                        : classes.fileSelector
                                                }
                                                onClick={() => {
                                                    setChipsToDisplay([])
                                                    setQueryObject(filterDefaultValues)
                                                    fetchDocuments()
                                                    changeSelectedView(
                                                        SelectView.All
                                                    )
                                                    setFolderPath({})
                                                }}
                                            >
                                               <UiText
                                                    variant="motorcycle_90"
                                                    className={
                                                        styles.filterTextStyles
                                                    }
                                                >
                                                    All Files { allFilesData?.itemsCount ? `(${allFilesData?.itemsCount})`: ''}
                                                </UiText>
                                            </div>
                                            <div className={classes.xsP16}>
                                            <DocumentsTree
                                                folders={folders}
                                                hideEmptyFolders={true}
                                                businessFolderTree={
                                                    businessFolderTree
                                                }
                                                personalFolderTree={
                                                    personalFolderTree
                                                }
                                                showHeaders={true}
                                                openFolderFiles={(
                                                    folder: Folder
                                                ) => {
                                                    fetchFolderDocuments(folder)
                                                    onFolderClick()
                                                }}
                                                changeView={() => {
                                                    changeSelectedView(
                                                        SelectView.Folder
                                                    )
                                                }}
                                                foldersPath={folderPath}

                                            />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!smDevice && (
                                <Divider
                                    orientation="vertical"
                                    className={classes.dividerStyle}
                                />
                            )}
                            {(!smDevice || (smDevice && showDocumentPage)) && (
                                <div className={classes.documentList}>
                                    {smDevice && !openFilterView && (
                                        <div className={classes.prevBtn}>
                                            <NavigationButton
                                                label="previous"
                                                startIconSvg={leftArrow}
                                                handleClick={() =>
                                                    setShowDocumentPage(false)
                                                }
                                            />
                                        </div>
                                    )}

                                    {!isFilterOpenInSmDevice && (
                                        <div>
                                            {term && (
                                                <UiText
                                                    variant="hatchback_125"
                                                    weight="semi_bold_600"
                                                    className={
                                                        classes.textStyles
                                                    }
                                                >
                                                    Results for "{term}"
                                                </UiText>
                                            )}

                                            {!isFilterOpenInSmDevice &&
                                                smDevice &&
                                                renderSearchBox()}

                                            {chipsToDisplay?.length > 0 && (
                                                <div
                                                    className={
                                                        classes.chipContainer
                                                    }
                                                >
                                                    <div className="chip-details">
                                                        {chipsToDisplay.map(
                                                            (chip, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    label={
                                                                        chip.label
                                                                    }
                                                                    onDelete={() => {
                                                                        handleDeleteChips(
                                                                            chip.searchKey,
                                                                            index
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                    <Button
                                                        variant="text"
                                                        className={
                                                            classes.clearFilter
                                                        }
                                                        onClick={() => {
                                                            clearChipFilters()
                                                        }}
                                                    >
                                                        Clear Filters
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        className={classes.docListContainer}
                                        id="doc-container"
                                    >
                                        {smDevice && openFilterView ? (
                                            <div>
                                                <DocumentFilter
                                                    queryObject={queryObject}
                                                    applyFilters={(
                                                        filterData
                                                    ) => {
                                                        setOpenFilterView(false)
                                                        filterDocuments(
                                                            filterData
                                                        )
                                                    }}
                                                    resetFilter={() => {
                                                        setOpenFilterView(false)
                                                        filterDocuments(
                                                            filterDefaultValues
                                                        )
                                                    }}
                                                    hideFilters={() => {
                                                        setOpenFilterView(false)
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <>{apiLoading ? <Loader/> : <DocumentList
                                                files={
                                                    isCheckedViewSelected
                                                        ? checkedFiles
                                                        : files
                                                }
                                                handleSelectFile={(file) => {
                                                    //this will be empty no handling required
                                                }}
                                                term={term}
                                                folderView={false}
                                                accountId={currentAccountId}
                                                businessFolderTree={
                                                    businessFolderTree
                                                }
                                                personalFolderTree={
                                                    personalFolderTree
                                                }
                                                folderPath={folderPath}
                                                showCheckbox={true}
                                                checkedFiles={checkedFiles}
                                                handleCheckedFiles={(
                                                    checked,
                                                    selectedFile
                                                ) => {
                                                    handleCheckedFiles(
                                                        checked,
                                                        selectedFile
                                                    )
                                                }}
                                                hideAllFilesText={chipsToDisplay.length > 0}
                                                isCheckedViewSelected={isCheckedViewSelected}
                                            />}</>
                                        )}

                                        {!isCheckedViewSelected && (
                                            <div
                                                className={classes.scrollDiv}
                                                ref={ref}
                                            >
                                                {files?.loading && <Loader />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!smDevice ? (
                        <>
                            <Divider variant="fullWidth" />
                            <div className={classes.importButton}>
                                {importing ? (
                                    <div className={'loader-button'}>
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        <Button
                                            className="cancel-button"
                                            onClick={() => {
                                                setOpenModal(false)
                                            }}
                                            variant="text"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={handleImportFiles}
                                            disabled={checkedFiles.length === 0}
                                        >
                                           Import {checkedFilesCount} {(checkedFiles?.length === 1) ? 'File' : 'Files'}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </>
                    ) : !openFilterView ? (
                        <div className={classes.fixedButtonContainer}>
                            {importing ? (
                                <div className={'loader-button'}>
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    <UiButton
                                        fullWidth
                                        btnType="tertiary"
                                        handleClick={handleImportFiles}
                                        disabled={checkedFiles.length === 0}
                                        customLabel={<UiText weight='semi_bold_600'>{`Import ${checkedFilesCount} ${(checkedFiles?.length === 1) ? 'File' : 'Files'}`}</UiText>}
                                    />

                                    <Button
                                        className={classes.cancelBtn}
                                        variant="text"
                                        onClick={() => setOpenModal(false)}
                                    >
                                        <UiText weight="semi_bold_600">
                                            Cancel Import
                                        </UiText>
                                    </Button>
                                </>
                            )}
                        </div>
                    ) : null}
                </div>
            </UiDialog>
        </div>
    )
}

export default ImportDocuments
