import { Button, Hidden, Theme, makeStyles } from '@material-ui/core'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import UiText from '../../../../../../common/ui/UiText'
import UiButton from '../../../../../../common/ui/UiButton'
import { PackageCard } from './PackageCard'
import { aboutPackagePage } from '../../../../models/vto.const'
import { useVtoContext } from '../../../../providers/VtoProvider'
import Loader from '../../../../../../common/Loader'
import useDeviceSize from '../../../../../../../hooks/useDeviceSize'

interface StepThreeProps {
    packages: any
    handlePackageSelection: (id: string) => void
    handleNoThanks: () => void
    noPackageSelected: any
    handlePrevious: () => any
    submitStyle: any
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        stepThreeContainer: {
            marginBlock: toRem16(16),
        },
        packagesContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            marginBottom: toRem16(24),
            gap: toRem16(24),
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: '1fr 1fr 1fr',
            },
            [theme.breakpoints.down('sm')]: {
                gap: '2rem',

                '& > div:nth-child(2)':{
                    order: '-1'
                }
            }
        },
        details: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: toRem16(24),
            marginBottom: toRem16(48),
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
            },
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'center',
            '& .MuiTypography-button': {
                fontWeight: 600,
            },
        },
        noThanksHelper: {
            textAlign: 'center',
            marginBottom: toRem16(48),
            [theme.breakpoints.down('sm')]: {
                marginTop: '1rem',
                textAlign: 'left',
            },
        },
        notes: {
            display: 'flex',
            gap: toRem16(8),
            marginBottom: toRem16(4),
        },
    }
})

export default function UpsellPackages(props: StepThreeProps) {
    const {
        packages,
        handlePackageSelection,
        handleNoThanks,
        handlePrevious,
        submitStyle
    } = props
    const theme = useThemeContext()
    const { loading } = useVtoContext()
    const styles = useStyles(theme.colorTheme)
    const { isSmDevice } = useDeviceSize();

    if (loading) {
        return <Loader />
    }

    return (
        <div className={styles.stepThreeContainer}>
            <div>
                <div className={styles.details}>
                    <UiText variant={isSmDevice ? "hatchback_125" : "suv_150"}>
                        Take the stress out of managing your business
                        finances—choose a package that fits your needs and
                        unlock expert accounting support that saves you time and
                        money.
                    </UiText>
                    <UiText variant={isSmDevice ? "hatchback_125" : "suv_150"}>
                        Offers only available through this online experience.
                    </UiText>
                    <Hidden smDown>
                        <UiText variant="motorcycle_90">
                            We will add your selection to your subscription and
                            charge the card that is on your account.
                        </UiText>
                    </Hidden>
                </div>

                <div className={styles.packagesContainer}>
                    {packages.map((item: any, index: any) => (
                        <PackageCard
                            item={item}
                            handleSelect={handlePackageSelection}
                            index={index}
                            key={`${index}_package_card`}
                        />
                    ))}
                </div>

                <div className={styles.btnContainer}>
                    <UiButton
                        handleClick={handleNoThanks}
                        btnType="hyperlink"
                        label="No Thanks"
                    />
                </div>
                <div className={styles.noThanksHelper}>
                    <UiText variant="moped_75">
                        This will opt out of any upgrades you selected when
                        reviewing your tax information.
                    </UiText>
                </div>

                <div>
                    {aboutPackagePage.map((item: any, serial: number) => (
                        <div className={styles.notes} key={`desc_${serial}`}>
                            <UiText variant="motorcycle_90">
                                {serial + 1}.
                            </UiText>
                            <UiText variant="motorcycle_90">{item}</UiText>
                        </div>
                    ))}
                </div>
                <Hidden mdUp>
                    <div className={submitStyle.mobileSpacing} />
                    <div className={submitStyle.fixedButtonContainer}>
                        <div className={submitStyle.nextPrevContainer}>
                            <Button
                                className={submitStyle.skipBtn}
                                variant="text"
                                onClick={() => handlePrevious()}
                            >
                                <UiText weight="semi_bold_600">Previous</UiText>
                            </Button>
                        </div>
                    </div>
                </Hidden>
            </div>
        </div>
    )
}
