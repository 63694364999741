import {
    storeAuthData,
    checkAuth,
    isLoggedIn,
} from '../../services/authService';
import { Redirect, useLocation } from 'react-router-dom';
import LoaderPage from '../common/LoaderPage';
import { connect } from 'react-redux';
import { loadPostAuthData } from '../../store/actions/appData';
import { setAuthData } from '../../store/actions/auth';
import { setInLocalStorage } from '../../services/localStorageService';

function AuthInit(props: any) {
    let query = useQuery();
    let params: any = {};
    query.forEach((v, k) => {
        params[k] = v;
    });

    if (!params?.access_token && isLoggedIn()) {
        return (
            <Redirect
                to={{
                    pathname: '/dashboard',
                }}
            />
        );
    } else {
        initAuth(params, props);
        return <LoaderPage />;
    }
}

function initAuth(params: any, props: any) {
    if (params.access_token) {
         setInLocalStorage('review_dialog', {
            showReview : true
        });
        storeAuthData({
            access_token: params.access_token,
            expires_in: params.expires_in,
            logged_in: new Date().getTime(),
        });
        props
            .loadPostAuthData()
            .then(redirectToPreviousPage.bind(null, params), checkAuth);
    } else if (isLoggedIn()) {
        return (
            <Redirect to={params.back_uri ? params.back_uri : '/dashboard'} />
        );
    } else {
        checkAuth(params.back_uri || '');
    }
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function redirectToPreviousPage(params: any) {
    let redirectURL
    if (params.redirect) {
        redirectURL = params.redirect
    } else {
        redirectURL =
            params.is_adp_tos_redirect === 'true'
                ? '/dashboard?is_adp_tos_redirect=true'
                : '/dashboard'
    }
    
    if (params.access_token) {
        //prevent reload to dashboard if page reloaded after setting business
        var back_uri = params.back_uri
            ? window.decodeURIComponent(params.back_uri)
            : redirectURL;
        window.location.href = '#' + back_uri;
    }
}

export default connect(null, { loadPostAuthData, setAuthData })(AuthInit);