import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import defaultLogo from '../../assets/logo/Logo.svg';
import PurposeIcon from '../../assets/icons-svg/Purpose.svg';
import {
    makeStyles,
    Theme,
    Hidden,
    Menu,
    MenuItem,
    Divider,
    Collapse,
    Typography,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { connect, useDispatch } from 'react-redux';
import ToDoContent from './extended/ToDoContent';
import NotificationsContent from './extended/NotificationContent';
import TeamContent from './extended/TeamContent';
import { ApplicationStore, Count, Business, GDSessionDetails } from '../../models';
import TourGuidePopper from '../dashboard/TourGuidePopper';
import { ActiveTourStepContext } from '../routing/Providers/ActiveRoutingProvider';
import {
    hasPermission,
    isCanView,
    isLinkVisible,
    isOnPage,
} from '../../services/aclService';
import NewMessageModal from '../message_center/NewMessageModal';
import JoinAdvisorMeetingPopup from '../guided-discovery/JoinAdvisorMeetingPopup';
import { loadPostAuthData } from '../../store/actions/appData';
import { setLoader } from '../../store/actions/loading';
import LoaderOverlay from '../common/LoaderOverlay';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../styles/models/Colors.interface';
import { getPartnerLogo, isWhiteLabelClient } from '../common/whiteLabel/updateThemeAttibutes';
import { getFromLocalStorage } from '../../services/localStorageService';
import  UiText  from '../../components/common/ui/UiText';
import { FORMATIONS_LEAD } from '../dashboard/TodoSection/Todo.const';


const SUPPORT_CENTER = 'https://1800accountant.com/support';

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        navigationRoot: {
            padding: theme.spacing(1),
            overflow: 'overlay',
            minHeight: 0,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            flex: 1,
        },
        businessButton: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`, 
            boxSizing: 'border-box',
            borderRadius: '4px',
            marginBottom: theme.spacing(1),
        },
        drawer: {
            flexShrink: 0,
            textSize: '14px',
        },
        drawerContentParent: {
            minHeight: 0,
            display: 'flex',
            borderRight: (colorTheme) => `1px solid ${colorTheme.grey700}`,
            '& .MuiDrawer-paperAnchorDockedLeft': {
                borderRight: 0,
            },
        },
        drawerPaper: {
            zIndex: 1301,
            position: 'static',
        },
        tabbedContentParent: {
            '& .MuiDrawer-paper': {
                position: 'static',
            },
        },
        collapseSection: {
            minHeight: 'auto !important',
        },
        extendedDrawer: {
            flexShrink: 0,
            textSize: '14px',
        },
        extendedDrawerPaper: {
            width: 'max-content',
            left: '240px !important',
        },
        extendedDrawerMobile: {
            width: '100%',
            flexShrink: 0,
            textSize: '14px',
        },
        extendedDrawerPaperMobile: {
            width: '100%',
        },
        logoRoot: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '64px',
            ...theme.mixins.toolbar,
        },
        listItemText: {
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            fontWeight: 500,
        },
        expandableListItemText: {
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        notification: {
            height: '20px !important',
            color: (colorTheme) => `${colorTheme.primaryWhite} !important`,
            backgroundColor: theme.palette.primary.main + ' !important',
            fontWeight: 'bold',
            cursor: 'pointer !important',
        },
        notificationLabel: {
            padding: '3px 10px !important',
        },
        navListItemGutters: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        backedByBusinessText : {
            padding: '0 0 0 2.5rem'
        },
        navListItemIconRoot: {
            minWidth: '32px',
        },
        listItemRoot: {
            backgroundColor: (colorTheme) => `${colorTheme.primaryWhite} !important`,
            '&:hover': {
                backgroundColor:
                    theme.palette.action.selected + ' !important',
            },
        },
        selectedNavItem: {
            background: (colorTheme) => `${colorTheme.green500} !important`,
        },
        businessDropdownPaper: {
            left: '8px !important',
            boxShadow: (colorTheme) => `0px 8px 10px ${colorTheme.grey1300}, 0px 3px 14px ${colorTheme.grey700}, 0px 5px 5px ${colorTheme.grey1400}`,
        },
        businessDropdownList: {
            padding: 0,
            width: 230,
        },
        businessDropdownListItem: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        nested: {
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            '&:hover': {
                backgroundColor:
                    theme.palette.action.selected + ' !important',
            },
        },
        endIcon: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    };
});
function AppDrawer(props: {
    drawerOpen: boolean;
    setDrawerOpen: Function;
    config: any;
    businessId: string;
    accountId: string;
    appData: any;
    count: Count;
    personalAccount: any;
    loadPostAuthData: any;
    setLoader: any;
    loading: boolean;
    accessLevel: any;
    business?: Business
    accountantMode?: boolean | undefined;
}) {
    const [extendedDrawerOpen, setExtendedDrawerOpen] = useState('');
    const [openIndex, setOpenIndex] = useState<any>({});
    const [openModal, setOpenModal] = useState(false)
    const [meetingDetails, setMeetingDetails] = useState<{
        gd_session_details: GDSessionDetails
    }>()
    
    const [businessEl, setBusinessEl] = useState<null | HTMLElement>();

    const anchorRefGuide2 = useRef(null);
    const [guideRef2, setGuideRef2] = useState(null);

    const anchorRefGuide3 = useRef(null);
    const [guideRef3, setGuideRef3] = useState(null);

    const { activeTourStep } = useContext(ActiveTourStepContext);

    const history = useHistory();

    const dispatch = useDispatch();

    const { setDrawerOpen } = props;
    const { user } = props.appData;
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme);

    // For TeamContent
    const [openNewMessageModal, setOpenNewMessageModal] = useState(false);
    const [messageTopic, setMessageTopic] = useState('');
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const expandableSections = props.config.local.sections.expandable_sections;
    const [logoPath, setLogoPath] = useState<string>('');

    useEffect(() => {
        if (!guideRef2 && anchorRefGuide2.current) {
            setGuideRef2(anchorRefGuide2.current);
        }
        if (!guideRef3 && anchorRefGuide3.current) {
            setGuideRef3(anchorRefGuide3.current);
        }
    }, []);

    useEffect(() =>{
       if(!user || !user.partner_details){
           setLogoPath(defaultLogo);
           return;
       }
       const partnerLogoUrl = getPartnerLogo(user.partner_details.partner_key ?? '');
       setLogoPath(partnerLogoUrl);
    },[logoPath, user])

    useEffect(() => {
        const findActiveExpandableSectionsIndexBasedOnUrl = (
            path: string,
            expandableSections: Array<any>
        ): number => {
            for (
                let index = 0;
                index <= expandableSections.length - 1;
                index++
            ) {
                for (let childRoute of expandableSections[index].child) {
                    if (path.includes(childRoute.page)) {
                        return index;
                    }
                }
            }
            return -1;
        };
        const index = findActiveExpandableSectionsIndexBasedOnUrl(
            history?.location.pathname,
            expandableSections
        );
        setOpenIndex({ [index]: true });
    }, [history?.location.pathname, expandableSections]);

    // disable the employee link if the company is not created
    function isLinkDisabled(pageObj: any) {
        if(props?.business?.is_adp_flow) {
            return ( pageObj.page === '/payroll/employees' &&
            !props?.business?.is_adp_company_created)
        } else {
            return (
                ( pageObj.page === '/payroll/employees' &&
                 !props?.business?.has_payroll_company_created)
            );
        }
    }

    const shouldShowLink = (
        section: any,
        account: any,
    ) => {
        if (section.permission) {
            return hasPermission(section.permission, account) && isLinkVisible(section);
        }
        return isLinkVisible(section);
    }

    function ExpandableSectionChild(props: { child_section: any, account: any }) {
        const { child_section, account } = props;
        return shouldShowLink(child_section, account) && (
            <ListItem
                disabled={
                    !isCanView(props.child_section) ||
                    isLinkDisabled(props.child_section)
                }
                component={Link}
                to={props.child_section.page}
                button
                dense
                classes={{
                    root: classes.nested,
                    gutters: classes.navListItemGutters,
                    selected: classes.selectedNavItem,
                }}
                selected={isOnPage(props.child_section.page)}
                onClick={() => {
                    setExtendedDrawerOpen('');
                    setDrawerOpen(false);
                }}
                data-cy={`expandable-section-child-${props.child_section.title}`}
            >
                <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                    {/* <img
                      alt={`${props.child_section.title} Icon`}
                      src={
                          require(`../../assets/icons-svg/${props.child_section.icon}.svg`)
                              .default
                      }
                  /> */}
                </ListItemIcon>
                <ListItemText
                    primary={props.child_section.title}
                    primaryTypographyProps={{ className: classes.listItemText }}
                />
            </ListItem>
        );
    }

    function expandableSection(props: { section: any, account: any }, index: number) {
        const isFormationPartnerLogin = props?.account?.extra_info?.lead_source === FORMATIONS_LEAD
        const isPartnerLogin = () : boolean =>{
            const user = getFromLocalStorage('user');
            return user && user.partner_details;
        }
        const renderSubIconOrText = (iconTitle: string, isPartnerLogin : boolean) =>{ 
            if(isPartnerLogin){
                return (
                    <UiText variant ='moped_75' weight = 'regular_400' className={classes.backedByBusinessText}>
                         Backed by 1&#x2011;800Accountant
                    </UiText>
                )
            }
            return (
                <img
                    className={classes.navListItemGutters}
                    alt={`${iconTitle} Icon`}
                    src={
                        require(`../../assets/icons-svg/${iconTitle}.svg`)
                            .default
                    }
                />
            )
        }
        return isLinkVisible(props.section) ? (
            <Fragment key={props.section.name}>
                <ListItem
                    button
                    dense
                    classes={{
                        root: classes.listItemRoot,
                        gutters: classes.navListItemGutters,
                    }}
                    onClick={() =>
                        setOpenIndex(openIndex[index] ? {} : { [index]: true })
                    }
                    data-cy={`expandable-sections-${props.section.title}`}
                >
                    {props.section.icon && (
                        <ListItemIcon
                            classes={{ root: classes.navListItemIconRoot }}
                        >
                            <img
                                alt={`${props.section.title} Icon`}
                                src={
                                    require(`../../assets/icons-svg/${props.section.icon}.svg`)
                                        .default
                                }
                            />
                        </ListItemIcon>
                            )}
                    <ListItemText
                        primary={props.section.title}
                        primaryTypographyProps={{
                            className: classes.expandableListItemText,
                        }}
                    />
                    {openIndex[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {props.section.sub_icon && !isFormationPartnerLogin ? (
                    renderSubIconOrText(props.section.sub_icon, isPartnerLogin())
                ) : null}

                <Collapse
                    in={openIndex[index]}
                    timeout="auto"
                    className={classes.collapseSection}
                >
                    <List component="nav">
                        {props.section.child.map((c: any, index: number) => (
                            <ExpandableSectionChild
                                key={index}
                                child_section={c}
                                account={props.account}
                            />
                        ))}
                    </List>
                </Collapse>
            </Fragment>
        ) : null;
    }

    function PortalSection({ section }: { section: any }) {
        return (
            <ListItem
                component={Link}
                to={section.page}
                button
                dense
                classes={{
                    root: classes.listItemRoot,
                    gutters: classes.navListItemGutters,
                    selected: classes.selectedNavItem,
                }}
                selected={
                    useRouteMatch({
                        path: section.page,
                        exact: section.route_match === false ? false : true,
                    }) && !extendedDrawerOpen
                        ? true
                        : false
                }
                onClick={() => {
                    setExtendedDrawerOpen('');
                    setOpenIndex({});
                    setDrawerOpen(false);
                }}
                data-cy={`portal-section-child-${section.title}`}
            >
                <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                    <img
                        alt={`${section.title} Icon`}
                        src={
                            require(`../../assets/icons-svg/${section.icon}.svg`)
                                .default
                        }
                    />
                </ListItemIcon>
                <ListItemText
                    primary={section.title}
                    primaryTypographyProps={{ className: classes.listItemText }}
                />
                {section.name === 'messages' && (
                    <Typography variant="body2" style={{ paddingRight: '9px' }}>
                        {props.count['total-unread-count']?.count
                            ? props.count['total-unread-count'].count
                            : ''}
                    </Typography>
                )}
            </ListItem>
        );
    }

    const showBusinessDetailsLink = () => {
        const data = props.appData;
        return data?.current_business && data?.current_business_id;
    };

    const detailsLinkClickHandler = () => {
        setBusinessEl(null);
        history.push('/settings/business');
    };

    const infoSection = (s: any) => {
        return (
            <div ref={anchorRefGuide3} key={s.name}>
                <ListItem
                    button
                    dense
                    classes={{
                        root: classes.listItemRoot,
                        gutters: classes.navListItemGutters,
                        selected: classes.selectedNavItem,
                    }}
                    selected={extendedDrawerOpen === s.name}
                    onClick={() => setExtendedDrawerOpen(s.name)}
                >
                    <ListItemIcon
                        classes={{ root: classes.navListItemIconRoot }}
                    >
                        <img
                            alt={`${s.title} Icon`}
                            src={
                                require(`../../assets/icons-svg/${s.icon}.svg`)
                                    .default
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={s.title}
                        primaryTypographyProps={{
                            className: classes.listItemText,
                        }}
                    />
                    {s.name !== 'team' &&
                        (extendedDrawerOpen === s.name ? (
                            <Typography
                                variant="body2"
                                style={{ paddingRight: '9px' }}
                            >
                                {props.count['total-notification-count']?.count
                                    ? props.count['total-notification-count']
                                          .count
                                    : ''}
                            </Typography>
                        ) : props.count['total-notification-count']?.count ? (
                            <Chip
                                label={
                                    props.count['total-notification-count']
                                        .count
                                }
                                size="small"
                                className='notificationLabelChip'
                                classes={{
                                    root: classes.notification,
                                    labelSmall: classes.notificationLabel,
                                }}
                            />
                        ) : (
                            ''
                        ))}
                </ListItem>
                {s.name === 'team' ? (
                    <TourGuidePopper
                        open={activeTourStep === 3}
                        anchor={guideRef3}
                        contentText="Easily contact your professional accountant team at any point in the portal."
                        currentStep={3}
                        placement="right"
                    ></TourGuidePopper>
                ) : null}
            </div>
        );
    };

    const businessMenuClickHandler = (account: any) => {
        props.setLoader(true);
        props.loadPostAuthData(account.id).then(() => {
            props.setLoader(false);
            dispatch({ type: 'RESET_CATEGORIES' });
            if(history.location.pathname !== '/dashboard'){
                history.push('/dashboard');
            }
        });
        setBusinessEl(null);
    };
    
    const businessList = () => {
        const businessAccounts = props.appData.accounts.filter(
            (account: any) =>
                account.account_type === 'business' &&
                account.business?.id !== props.appData.current_business_id
        );
        return businessAccounts?.length > 0
            ? businessAccounts.map((account: any, index: number) => {
                  return (
                      <MenuItem
                          dense
                          classes={{
                              root: classes.businessDropdownListItem,
                          }}
                          key={index}
                          onClick={() => businessMenuClickHandler(account)}
                      >
                          <ListItemIcon
                              classes={{
                                  root: classes.navListItemIconRoot,
                              }}
                          >
                              <img alt="Business Icon" src={PurposeIcon} id="business-icon"/>
                          </ListItemIcon>
                          <ListItemText
                              primary={account.business?.name || 'New Business'}
                          />
                      </MenuItem>
                  );
              })
            : [];
    };

    const drawerContent = (
        <>
            <Link to="/dashboard">
                <div className={classes.logoRoot}>
                    <img src={logoPath} alt="1800-Accountant logo" height="40" />
                </div>
            </Link>
            {props.businessId ? (
                <List
                    classes={{
                        root: classes.navigationRoot,
                    }}
                    component="nav"
                >
                    <div ref={anchorRefGuide2}>
                        <ListItem
                            button
                            dense
                            className={classes.businessButton}
                            onClick={(event: any) =>
                                setBusinessEl(event.currentTarget)
                            }
                            data-cy='change-business-btn'
                        >
                            <ListItemIcon
                                classes={{ root: classes.navListItemIconRoot }}
                            >
                                <img alt="Business Icon" src={PurposeIcon} />
                            </ListItemIcon>
                            <ListItemText
                                primary={props.appData.current_business.name}
                                primaryTypographyProps={{
                                    className: classes.listItemText,
                                }}
                            />
                            {!!businessEl ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </ListItem>
                        <TourGuidePopper
                            open={activeTourStep === 2}
                            anchor={guideRef2}
                            contentText="Access and update your business's details, and chart of accounts. If you have multiple businesses, you can toggle between them here as well."
                            currentStep={2}
                            placement="right"
                        ></TourGuidePopper>
                    </div>
                    <Menu
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorEl={businessEl}
                        open={Boolean(businessEl)}
                        data-cy="open-business-list"
                        onClose={() => setBusinessEl(null)}
                        classes={{
                            list: classes.businessDropdownList,
                            paper: classes.businessDropdownPaper,
                        }}
                        MenuListProps={{ disablePadding: true }}
                    >
                        {showBusinessDetailsLink()
                            ? [
                                  <MenuItem
                                      disabled={
                                          !isCanView({ acl: 'FREE' })
                                      }
                                      dense
                                      classes={{
                                          root: classes.businessDropdownListItem,
                                      }}
                                      key={1}
                                      data-cy="view-business-detail"
                                      onClick={detailsLinkClickHandler}
                                  >
                                      <ListItemIcon
                                          classes={{
                                              root: classes.navListItemIconRoot,
                                          }}
                                      >
                                          <img
                                              alt={`Menu Vertical Icon`}
                                              src={
                                                  require(`../../assets/icons-svg/Vertical.svg`)
                                                      .default
                                              }
                                          />
                                      </ListItemIcon>
                                      <ListItemText primary="Details" />
                                  </MenuItem>,
                                  ...businessList(),
                                  <Divider key={2} variant="fullWidth" />,
                              ]
                            : null}
                            <MenuItem
                                component={Link}
                                to="/business/new_account"
                                dense
                                classes={{
                                    root: classes.businessDropdownListItem,
                                }}
                                onClick={() => setBusinessEl(null)}
                                disabled={!props.accessLevel.platform && props.accountantMode}
                            >
                                <ListItemIcon
                                    classes={{
                                        root: classes.navListItemIconRoot,
                                    }}
                                >
                                    <img
                                        alt={`Add Icon`}
                                        src={
                                            require(`../../assets/icons-svg/Add.svg`)
                                                .default
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Add Business" />
                            </MenuItem>
                   
                    </Menu>
                    {props.config.local.sections.info_sections.map((s: any) =>
                        infoSection(s)
                    )}
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    {props.config.local.sections.portal_sections.map(
                        (s: any) => (
                            <PortalSection key={s.name} section={s} />
                        )
                    )}
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    {expandableSections.map((s: any, index: number) => {
                        const account = props.personalAccount;
                        const isEmptySection = s.child.filter((item: any) => {
                            return shouldShowLink(item, account);
                        }).length === 0;

                        return !isEmptySection ? expandableSection(
                            { section: s, account: props.personalAccount },
                            index
                        ) : null;
                    })}
                    {
                        !isWhiteLabelClient() && 
                    <>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <ListItem
                        component={'a'}
                        href={SUPPORT_CENTER}
                        target='blank'
                        button
                        dense
                        classes={{
                            root: classes.listItemRoot,
                            gutters: classes.navListItemGutters,
                            selected: classes.selectedNavItem,
                        }}
                        onClick={() => {
                            setExtendedDrawerOpen('');
                            setOpenIndex({});
                            setDrawerOpen(false);
                        }}
                        data-cy={`portal-section-child-supportCenter`}
                    >
                        <ListItemIcon classes={{ root: classes.navListItemIconRoot }}>
                            <img
                                alt={`support center Icon`}
                                src={
                                    require(`../../assets/icons-svg/Help.svg`)
                                        .default
                                }
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={'Support Center'}
                            primaryTypographyProps={{ className: classes.listItemText }}
                        />
                            <ListItemIcon classes={{root: classes.endIcon}}>
                                <img
                                    alt={`${''} Icon`}
                                    src={
                                        require(`../../assets/icons-svg/TouchZone.svg`)
                                            .default
                                    }
                                />
                            </ListItemIcon>
                    </ListItem>
                    </>
                }
                </List>
            ) : null}
        </>
    );

    const getTabContent = (key: string, element?: React.ReactNode) => {
        switch (key) {
            case 'to_do':
                return (
                    <ToDoContent width={element ? '' : '370px'}>
                        {element}
                    </ToDoContent>
                );
            case 'notifications':
                return (
                    <NotificationsContent
                        width={element ? '' : '400px'}
                        onClick={setExtendedDrawerOpen}
                        setOpenModal={setOpenModal}
                        setMeetingDetails={setMeetingDetails}
                    >
                        {element}
                    </NotificationsContent>
                );
            case 'team':
                return (
                    <TeamContent
                        width={element ? '' : '390px'}
                        setDrawerState={setExtendedDrawerOpen}
                        setOpenNewMessageModal={setOpenNewMessageModal}
                        setMessageTopic={setMessageTopic}
                    >
                        {element}
                    </TeamContent>
                );
        }
    };

    const handleNewMessageModalClose = (result: { id: string }) => {
        setOpenNewMessageModal(false);
        setExtendedDrawerOpen('');
        // if (result?.id) {
        //     setExtendedDrawerOpen('');
        // }
    };

    return (
        <Fragment>
            {!isMdUp && (
                <div
                    className={classes.drawerContentParent}
                    id="drawerContentParentForMobile"
                >
                    <Drawer
                        variant="temporary"
                        open={props.drawerOpen}
                        className={classes.drawer}
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left"
                        onClose={() => setDrawerOpen(false)}
                        ModalProps={{
                            container: document.getElementById(
                                'drawerContentParentForMobile'
                            ),
                            keepMounted: true, // Better open performance on mobile.
                            style: { position: 'absolute' },
                        }}
                    >
                        {drawerContent}
                    </Drawer>
                </div>
            )}

            {!isSmDown && (
                <div className={classes.drawerContentParent}>
                    <Drawer
                        variant="permanent"
                        className={classes.drawer}
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left"
                    >
                        {drawerContent}
                    </Drawer>
                </div>
            )}

            {/* extended nav */}

            {/* desktop */}
            <Hidden smDown implementation="js">
                <Drawer
                    variant="temporary"
                    open={extendedDrawerOpen ? true : false}
                    className={`${classes.extendedDrawer} extendedDrawerCustom`}
                    classes={{ paper: classes.extendedDrawerPaper }}
                    BackdropProps={{ style: { left: '240px' } }}
                    onClose={() => setExtendedDrawerOpen('')}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {getTabContent(extendedDrawerOpen)}
                </Drawer>
            </Hidden>

            {/* mobile & tab */}
            {!isMdUp && (
                <div
                    className={classes.tabbedContentParent}
                    id="tabbedContentParentForMobile"
                >
                    <Drawer
                        variant="temporary"
                        open={extendedDrawerOpen ? true : false}
                        className={classes.extendedDrawerMobile}
                        classes={{ paper: classes.extendedDrawerPaperMobile }}
                        onClose={() => setExtendedDrawerOpen('')}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            container: document.getElementById(
                                'tabbedContentParentForMobile'
                            ),
                            style: { position: 'absolute' },
                        }}
                    >
                        {getTabContent(
                            extendedDrawerOpen,
                            <IconButton
                                onClick={() => setExtendedDrawerOpen('')}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                    </Drawer>
                </div>
            )}
            <NewMessageModal
                open={openNewMessageModal}
                handleClose={handleNewMessageModalClose}
                topic={messageTopic}
            />
            <JoinAdvisorMeetingPopup 
                open={openModal}
                handleClose={()=> {
                    setOpenModal(false)
                }}
                callDetails={meetingDetails}
            />
            {props.loading ? <LoaderOverlay /> : null}
        </Fragment>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
    businessId: state.appData.current_business_id,
    accountId: state.appData.current_account_id,
    appData: state.appData,
    count: state.count,
    personalAccount: state.appData.personal_account,
    loading: state.loading,
    accessLevel: state.auth.accessLevel,
    business: state.appData.current_business,
    accountantMode: state.appData.user.accountant_mode
});
export default connect(mapStateToProps, { loadPostAuthData, setLoader })(
    AppDrawer
);
