import {
    Button,
    Drawer,
    Hidden,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core'
import UiAlert from '../../../../common/ui/UiAlert'
import UiText from '../../../../common/ui/UiText'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { ArrowForward, InfoOutlined } from '@material-ui/icons'
import { showInfo } from '../../../../../store/actions/feedback'
import { isValidDocumentMime } from '../../../../../utils/documentUtil'
import { useCurrentStore } from '../../../../common/hooks/useCurrentStore'
import { useDispatch } from 'react-redux'
import { acceptedFormat } from '../../../vto/steps/UploadStep/UploadFileUtils'
import { commonStyles, toRem16 } from '../../../../../styles/commonStyles'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { useEffect, useRef, useState } from 'react'
import Icon from '../../../../common/Icon'
import ImportDocuments from '../../../vto/steps/UploadStep/ImportDocuments'
import FileActions from '../../../vto/steps/UploadStep/ImportDocument/FileActions'
import UiButton from '../../../../common/ui/UiButton'
import {
    removeUploadedFile,
    uploadFileToVto,
    startOCR,
    getOCRProgress,
    importFile
} from '../../../../../services/vto/vtoFileUpload'
import { useVtoContext } from '../../../vto/providers/VtoProvider'
import { ScanResult } from '../../../vto/models/vto-file-upload'
import { useHistory } from 'react-router-dom'
import Loader from '../../../../common/Loader'
import UiLottie from '../../../../common/ui/UiLottie'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import { COLORS } from '../../../../../variables/colors'
import { FORM_SCAN_STEP, PERSONAL_ROUTE } from '../../../vto/models/vto.const'
import { getUploadedDocImageType } from '../../../vto/personal/Upload1040Form'
import { truncateText } from '../../../vto/common/VtoUtils'
import { VTO_TYPES } from '../../../vto/models/VtoDetails.model'
import LoadingAnimations from '../../../../common/LoadingAnimations'
import { updateVtoData } from '../../../../../services/vto/vtoCore'
import { getFromLocalStorage, removeFromLocalStorage } from '../../../../../services/localStorageService'


const useStyles = makeStyles<Theme, {colorTheme:ThemeColors, isUnderVTOSection:boolean}>((theme: Theme) => {
    return {
        topMargin: {
            marginTop: '1.5rem',
        },
        alertMargin: {
            width: ({isUnderVTOSection}) => isUnderVTOSection ? 'max-content' : 'auto',
            margin: '1.5rem 0 2.5rem 0',
            '& .MuiAlert-icon': {
                alignSelf: 'flex-start',
            },
            '& .MuiTypography-root': {
                color: ({colorTheme}) => colorTheme.black100,
                textAlign: 'left',
            },
            [theme.breakpoints.down('sm')]: {
                margin: '1.5rem 0',
                width: 'auto !important'
            },
        },
        importDocStyle: {
            margin: '1.5rem 0 1.5rem 0',
        },
        alertMessage: {
            marginTop: '1rem',
            marginBottom: '1rem',
            backgroundColor: ({colorTheme}) => colorTheme.red300 + ' !important',
            color: ({colorTheme}) => colorTheme.primaryBlack + ' !important',
        },
        dropzone: {
            padding: '0.8rem',
            marginTop: '1rem',
            borderRadius: '0.5rem',
            border: ({colorTheme}) => '2px dashed ' + colorTheme.grey200,
            [theme.breakpoints.down('xs')]: {
                padding: '1rem',
            },
        },
        container: {
            display: 'flex',
            justifyContent: ({isUnderVTOSection}) => isUnderVTOSection ? 'flex-start' : 'center',
            alignItems: 'center',
            textAlign: ({isUnderVTOSection}) => isUnderVTOSection ? 'left' : 'center',
            marginTop: '1rem',
            [theme.breakpoints.down('sm')]: {
                textAlign: ({isUnderVTOSection}) => isUnderVTOSection ? 'left' : 'left',
                padding: '0 1rem',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
        },
        uploadButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1.25rem',
            marginBottom: '1rem',
        },
        scanBtn: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: '1.5rem',
            marginBottom: '1rem',
        },
        importContainer: {
            display: 'flex',
            justifyContent: ({isUnderVTOSection}) => isUnderVTOSection ? 'flex-start' : 'center',
            marginBottom: '1.5rem',
            [theme.breakpoints.down('sm')]: {
             marginBottom: 0
            },
        },
        scanDoc: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            '& .scanBtn': {
                marginTop: '1rem',
                marginBottom: '1rem',
            },
        },
        fileList: {
            display: 'flex',
            marginTop: '1rem',
            alignItems: 'center',
            marginBottom: toRem16(15),
            '& span': {
                padding: '0 0 0 0.5rem',
            },
        },
        renderedFiles: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            borderRadius: '0.5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            marginBottom: '2rem',
        },
        loadingContainer: {
            marginTop: '1rem',
            height: toRem16(400),
            display: 'flex',
            flexDirection: 'column',
            alignItems: ({isUnderVTOSection}) => isUnderVTOSection ? 'flex-start' : 'center',
            
        },
        OCRSuccessContainer: {
            marginTop: '1rem',
            padding: '1rem',
            position: 'relative',
            color: ({colorTheme}) => colorTheme.black100,
            '& > div:has(> svg)': {
                margin: ({isUnderVTOSection}) => isUnderVTOSection ? '0 !important' : '0 auto',
            },
            '& button': {
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                },
            },
            [theme.breakpoints.up('md')]: {
                textAlign: ({isUnderVTOSection}) => isUnderVTOSection ? 'left' : 'center',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0 1rem',
                textAlign: 'left',
            },
        },
        uploadBtn: {
            background: ({colorTheme}) => colorTheme.primary,
            color: ({colorTheme}) => colorTheme.primaryWhite,
            borderRadius: '4px',
            padding: '0.8rem',
            marginTop: '1rem',
            '&:hover': {
                background: ({colorTheme}) => colorTheme.primary,
            },
        },
        fixedButtonContainer: {
            width: '360px',
            margin: ({isUnderVTOSection}) => isUnderVTOSection ? 0 : 'auto',
            display: ({isUnderVTOSection}) => isUnderVTOSection ? 'flex' : 'block',
            flexDirection: 'column',
            
            [theme.breakpoints.down('sm')]: {
                position: ({isUnderVTOSection}) => isUnderVTOSection ? 'fixed' : 'static',
                width: '100%',
                boxSizing: 'border-box',
                bottom: 0,
                left: 0,
                right: 0,
                padding: ({isUnderVTOSection}) => isUnderVTOSection ? '1rem' : 0,
                backgroundColor: ({colorTheme}) => colorTheme.primaryWhite,
                borderTop: ({colorTheme, isUnderVTOSection}) => isUnderVTOSection ? `1px solid ${colorTheme.grey200}`: 'none',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            },
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            width: '100%',
            '& > button': {
                marginTop: '0.5rem',
            },
        },
        addDocument: {
            width: '100%',
            marginBottom: '1rem',
            '& .MuiButtonBase-root': {
                width: '100%',
            },
        },
        spacer: {
            height: '60px',
        },
        skipBtn: {
            color: ({colorTheme}) => colorTheme.blue200,
        },
        importBtn: {
            marginBottom: '1rem',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '0',
            },
        },
        mobileContainer: {
            overflow: 'auto',
            marginBottom: '5rem',
            [theme.breakpoints.down('sm')]: {
                scrollbarWidth: 'none', // For Firefox
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
        },
        drawerPaper: {
            width: '100%',
            height: '18rem',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
        },
        drawerHeader: {
            padding: '0 1rem 0.75rem 1rem',
        },
        drawerContent: {
            padding: '1rem',
            maxHeight: `calc(${toRem16(400)} - ${toRem16(74)})`,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
                '& button': {
                    width: '100%',
                },
            },
            '& > div:first-of-type': {
                marginBottom: '0.5rem',
            },
        },
        importContent: {
            '& > button p': {
                fontWeight: 600,
                fontSize: '1rem !important',
            },
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            maxHeight: toRem16(24),
        },
        iconRoot: {
            color: ({colorTheme}) => colorTheme.grey800,
        },
        scanAlertContainer: {
            marginLeft: ({isUnderVTOSection}) => isUnderVTOSection ? 0 : 'auto',
            marginRight: ({isUnderVTOSection}) => isUnderVTOSection ? 0 : 'auto',
            maxWidth: toRem16(680),
        },
        h30: {
            maxHeight: toRem16(30),
        },
        mb16: {
            marginBottom: '1rem',
        },
        mb32: {
            marginBottom: '2rem',
        },
        mt32: {
            marginTop: '2rem',
        },
        mb40: {
            marginBottom: toRem16(40),
        },
        mtb16: {
            marginBottom: '1rem',
            marginTop: '1rem'
        },
        mt24: {
            marginTop: '1.5rem'
        },
        sectionHeadingContainer: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            backgroundColor: ({colorTheme}) => colorTheme.primaryWhite,
            left: 0,
            right: 0,
            zIndex:1,
            padding: '0 28px 0 28px'
        },
        headerPlaceholder:{
            height: toRem16(36),
        }
    }
})

enum RESULT {
    SCAN_FAILED = 'failed',
    SCAN_SUCCESS = 'done',
}

const POLLING_INTERVAL = 5000; // 5 seconds in milliseconds
const MAX_DURATION = 180000;

const Individual1040FormUpload = ({ vtoDetails, setIntroStep, isUnderVTOSection = false, goToNextIntroStep, ocrFileId }: any) => {
    const { colorTheme } = useThemeContext()
    const { currentConfig, currentAccountId } = useCurrentStore()
    const styles = useStyles({colorTheme, isUnderVTOSection})
    const common = commonStyles()
    const { 
        selectedYear, 
        currentVtoDetails, 
        updateVTO, 
        currentStep, 
        menuItems, 
        setCurrentStep,
        setParentStep,
        isBusinessVTO,
    } = useVtoContext()
    const [OCRDetails, setOCRDetails] = useState<ScanResult>()
    const [fileList, setFileList] = useState<any>([])
    const [alertMessage, setAlertMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [apiLoading, setApiLoading] = useState<boolean>(false)
    const [searching, setSearching] = useState<boolean>(false)
    const [scaningOCR, setScaningOCR] = useState<boolean>(false)
    const [progressValue, setProgressValue] = useState(0)
    const [uploadingFile, setUploadingFile] = useState(false)
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    const [anchorEl, setAnchorEl] = useState<boolean>(false)

    const dispatch = useDispatch()
    const history = useHistory()
    const dropzoneRef = useRef<DropzoneRef>(null)
    
    const intervalIdRef = useRef<any>(null)

    const currentVto = vtoDetails || currentVtoDetails

    const allowedMimeTypes =
        currentConfig.apiConfig.file_center.allowed_mime_types
    const allowedDocumentTypes =
        currentConfig.apiConfig.file_center.allowed_extensions

    const SCAN_FAILED = OCRDetails?.status === RESULT.SCAN_FAILED
    const SCAN_SUCCESS = OCRDetails?.status === RESULT.SCAN_SUCCESS

    const handleOpen = () => {
        setAnchorEl(true)
    }

    const handleClose = () => {
        setAnchorEl(false)
    }

    const nextSection = () => {
        if (menuItems) {
            const nextStep =
                menuItems[
                    menuItems?.findIndex(
                        (step: any) => step?.name === currentStep
                    ) + 1
                ]
            setCurrentStep(nextStep.name)
            setParentStep(nextStep)
        }
    }

    const goToVtoSection = () => {
        const currentURL = history.location.pathname
        setApiLoading(true)
        if (PERSONAL_ROUTE === currentURL) {
            updateVtoData(selectedYear, currentVto.id, {
                sections_reviewed: [...new Set([...currentVto.sections_reviewed, FORM_SCAN_STEP])]
            }).then((res) => {
                if (res) {
                    setApiLoading(false)
                    goToNextIntroStep()
                }
            })
        } else {
            updateVTO(currentVto.id, { sections_reviewed: [...new Set([...currentVto.sections_reviewed, currentStep])] }, (steps: any) => {
                nextSection()
                setApiLoading(false)
            })
        }
    }

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )

    const uploadFiles = (files: File[], callback?: (res?: any) => void) => {
        setFileList(files)
        const formData = new FormData()
        formData.append('vtoId', currentVto.id)
        formData.append('type', 'ocr')
        formData.append('files', files[0])
        uploadFileToVto(selectedYear, currentVto.id, formData)
            .then((res) => {
                if (res) {
                    setFileList(res)
                    callback?.(res)
                    setUploadingFile(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleUploadFile = (files: File[]) => {
        setAlertMessage('')
        setOCRDetails(undefined)
        if (!files.length) {
            return
        }
        if (files.length > 1) {
            setAlertMessage('Please upload only one file')
            return
        }

        if (isFileTypeUnsupported(files)) {
            handleFileUnsupported()
            return
        }
        uploadFiles(files)
    }

    const handleFileUnsupported = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <div>File(s) has unsupported format.</div>
                        <div>
                            Please upload only{' '}
                            <b>{acceptedFormat.join(', ')}</b>
                        </div>
                    </div>
                ),
            })
        )
    }

    const removeFile = (fileId: string) => {
        removeUploadedFile(selectedYear, currentVto.id, {
            file: fileId,
            type: 'ocr',
        }).then((res) => {
            setFileList([])
        })
    }

    const RenderUploadedFiles = () => {
        return fileList?.map((file: any, index: number) => {
            const imageType = getUploadedDocImageType(file.mime_type)
            const fileName = truncateText(file?.name || file?.filename);

            return (
                <div 
                    key={`${file.name}-${index}`}
                >
                    <div className={styles.renderedFiles}>
                        <div className={styles.fileList}>
                            <img
                                src={
                                    require(`../../../../../assets/icons-svg/${imageType}.svg`)
                                        .default
                                }
                                alt="document"
                            />
                            <span>{fileName}</span>
                        </div>
                        {!uploadingFile && (
                            <div>
                                <FileActions
                                    vtoDetails={vtoDetails}
                                    selectedFile={file}
                                    fileType={'any' as any}
                                    removeFile={() => setFileList([])}
                                    fetchVto={false}
                                    openDialog={() => {
                                        removeFile(file?.id)
                                        dropzoneRef?.current?.open()
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )
        })
    }

    const RenderOCRSuccess = () => (
        <>
        {renderMenuButton()}
        <div className={styles.OCRSuccessContainer}>
            <UiLottie
                lottieData={require('../../../../../assets/animations/Thumsup.json')}
            />
            <div className={`${styles.mt32} ${styles.mb40}`}>
                <UiText variant="suv_150" weight="bold_700">
                    Your Form 1040 was imported successfully!
                </UiText>
                <UiText className={styles.mtb16}>
                    We will add all relevant information to this year’s tax info
                    for you to confirm.
                </UiText>
                {isUnderVTOSection && (
                    <div
                        className={`${styles.alertMargin}`}
                    >
                        <UiAlert
                            severity="info"
                            customContent={
                                <UiText variant="motorcycle_90">
                                    Information you already provided was not
                                    replaced.
                                </UiText>
                            }
                            icon="InfoIcon"
                            iconColor={colorTheme.blue200}
                        />
                    </div>
                )}
            </div>
            <div className={styles.fixedButtonContainer}>
                <UiButton
                    fullWidth
                    btnType="tertiary"
                    endIcon={<ArrowForward />}
                    handleClick={()=> {
                        goToVtoSection()
                    }}
                    customLabel={
                        isUnderVTOSection ? (
                            <UiText weight="semi_bold_600">Continue</UiText>
                        ) : (
                            <UiText>Next</UiText>
                        )
                    }
                />
            </div>
        </div>
        </>
    )

    const scanOCR = (fileId?: string) => {
        setScaningOCR(true)
        setProgressValue(0)
        startOCR(
            selectedYear,
            currentVto.id,
            {
                fileId: fileId ? fileId : fileList?.[0]?.id
            },
        )
        pollScanProgress()
    }

    const pollScanProgress = () => {
        const getProgress = () => {
            setProgressValue((prev) => (prev + 2))
            getOCRProgress(selectedYear, currentVto.id)
                .then((res) => {
                    if (res) {
                        if (res.status === RESULT.SCAN_SUCCESS) {
                            setOCRDetails(res)
                            setScaningOCR(false)
                            clearInterval(intervalIdRef.current)
                        }
                        if (res.status === RESULT.SCAN_FAILED) {
                            setScaningOCR(false)
                            setOCRDetails({...res, status: RESULT.SCAN_SUCCESS})
                            clearInterval(intervalIdRef.current)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    clearInterval(intervalIdRef.current)
                    //setAlertMessage('Failed, please try again later')
                    setScaningOCR(false)
                })
        }

        // Start polling every 5 seconds
        intervalIdRef.current = setInterval(getProgress, POLLING_INTERVAL);

        // Stop polling after 3 minutes
        setTimeout(() => {
            clearInterval(intervalIdRef.current); // Stop the polling
            setOCRDetails(((prev: any)=> {
                return {
                    ...prev,
                    status: RESULT.SCAN_SUCCESS
                }
            }))
            setScaningOCR(false)
        }, MAX_DURATION);
    }

    const handleCancel = () => {
        clearInterval(intervalIdRef.current);
        setScaningOCR(false)
    }

    const handleRedirectToMenu = () => {
        const vtoType = isBusinessVTO ? VTO_TYPES.BUSINESS : VTO_TYPES.PERSONAL
        history.push(`/tax-organizer/${vtoType}/${selectedYear}/${currentVto?.id}`)
    }

    /**
     * Import the file and start the scan
     */
    const importAndScan = (fileId: string) => {
        importFile(selectedYear, currentVto.id, {
            files: [fileId],
            type: 'ocr',
        }).then((res) => {
            if(res) {
                setFileList(res)
                scanOCR(fileId)
                setLoading(false)
            }
        })
    }

    /**
     * Check if the file has been uploaded in OCR section
     * if not, then check for the folder and find the file. 
     * if file found then upload the file and delete the folder from ocr file.
     * & start the scan.
     */
    useEffect(() => {
        if(currentVto) {
            const ocrDetails = currentVto.ocr
            // upload & delete , scan with the uploaded file id
            if(ocrDetails?.files.length === 0) {
                const scanFileId = ocrFileId
                if(scanFileId) {
                    setLoading(true)
                    importAndScan(scanFileId)
                } else {
                    setLoading(false)
                }
            } else {
                setFileList(ocrDetails.files)
                setOCRDetails({...ocrDetails, status: RESULT.SCAN_SUCCESS})
                setLoading(false)
            }
        }
    }, [])


    const renderMenuButton = () =>
        isUnderVTOSection ? (
            <Hidden mdUp>
                <div className={styles.sectionHeadingContainer}>
                    <UiText variant="hatchback_125" weight="medium_500">
                        1040 Form Scan
                    </UiText>

                    <UiButton
                        btnType="hyperlink"
                        customLabel={
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Menu
                            </UiText>
                        }
                        handleClick={handleRedirectToMenu}
                    />
                </div>
                <div className={styles.headerPlaceholder}></div>
            </Hidden>
        ) : null

    if (loading || searching || scaningOCR) {
        return (
            <div className={styles.loadingContainer}>
                <LoadingAnimations 
                    customNode={<div>
                        <UiText variant='suv_150' weight='bold_700' className={styles.mt32}>
                            Scanning your 1040
                        </UiText>
                        <UiText className={styles.mt32}>
                            This process can take up to a three minutes.
                        </UiText>
                    </div>}
                />
            </div>
        )
    }
    if (apiLoading) {
        return (
            <>
            {renderMenuButton()}
            <div className={styles.loadingContainer}>
                <Loader size={30} />
            </div>
            </>
        )
    }

    if (SCAN_SUCCESS || SCAN_FAILED) {
        return <RenderOCRSuccess />
    }

    return (
        <>
        {renderMenuButton()}
        <div className={styles.container}>
            <div
                className={''}
            >
                <UiText
                    variant={smDevice ? 'hatchback_125' : 'suv_150'}
                    weight={smDevice ? 'semi_bold_600' : 'bold_700'}
                >
                    Save time by importing {!smDevice && <br />}
                    your Form 1040 from last year
                </UiText>
                <UiText className={styles.topMargin}>
                    We will scan your Form 1040 and automatically add all
                    relevant information to this year’s tax info for you to
                    confirm.
                </UiText>
                <div className={styles.alertMargin}>
                    <UiAlert
                        severity="info"
                        customContent={
                            <UiText variant="motorcycle_90">
                                Make sure the document starts with your Form
                                1040, and is not password
                                <br /> protected.
                            </UiText>
                        }
                        icon="InfoIcon"
                        iconColor={colorTheme.blue200}
                    />
                </div>
                {fileList.length === 0 && (
                    <div>
                        <Dropzone
                            onDrop={handleUploadFile}
                            ref={dropzoneRef}
                            accept={acceptedFormat[3]}
                        >
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <div
                                        {...getRootProps({})}
                                        className={styles.dropzone}
                                        style={{
                                            display: smDevice
                                                ? 'none'
                                                : 'block',
                                        }}
                                    >
                                        <div>
                                            <input
                                                {...getInputProps()}
                                                name="files[]"
                                                accept={acceptedFormat[3]}
                                            />
                                        </div>
                                        <UiText variant="suv_150">
                                            Drag and drop a file here to upload
                                        </UiText>
                                        <div className={styles.uploadButton}>
                                            <Button
                                                className={styles.uploadBtn}
                                                disabled={false}
                                                startIcon={
                                                    <Icon
                                                        icon="upload"
                                                        svgColor={
                                                            COLORS.primaryWhite
                                                        }
                                                    />
                                                }
                                            >
                                                <UiText
                                                    variant="motorcycle_90"
                                                    weight="semi_bold_600"
                                                >
                                                    Upload File
                                                </UiText>
                                            </Button>
                                        </div>
                                        <div>
                                            <UiText
                                                variant="motorcycle_90"
                                                textColor="textSecondary"
                                            >
                                                Supported file types: .pdf,
                                            </UiText>
                                        </div>
                                    </div>
                                )
                            }}
                        </Dropzone>
                        {!smDevice && (
                            <>
                                <div className={styles.importDocStyle}>
                                    <ImportDocuments
                                        selectMultiple={false}
                                        setFileList={setFileList}
                                    />
                                </div>
                                <Button
                                    className={styles.skipBtn}
                                    variant="text"
                                    onClick={()=>{
                                        goToVtoSection( )
                                    }}
                                >
                                    <UiText weight="semi_bold_600">
                                        I was not required to file
                                    </UiText>
                                </Button>
                            </>
                        )}
                    </div>
                )}

                {fileList.length === 0 && smDevice && (
                    <div className={styles.buttonContainer}>
                        <div className={styles.addDocument}>
                            <Button
                                className={styles.uploadBtn}
                                disabled={false}
                                onClick={handleOpen}
                                startIcon={
                                    <Icon
                                        icon="add"
                                        svgColor={COLORS.primaryWhite}
                                    />
                                }
                            >
                                <UiText weight="semi_bold_600">
                                    Add Document
                                </UiText>
                            </Button>
                        </div>
                        <Button
                            className={styles.skipBtn}
                            variant="text"
                            onClick={goToVtoSection}
                        >
                            <UiText weight="semi_bold_600">Skip</UiText>
                        </Button>
                    </div>
                )}

                {fileList.length > 0 && (
                    <>
                        <div className={styles.importContainer}>
                            <RenderUploadedFiles />
                        </div>
                        <div className={styles.fixedButtonContainer}>
                            {uploadingFile ? (
                                <div className={styles.h30}>
                                    <Loader size={30} />
                                </div>
                            ) : (
                                <UiButton
                                    btnType="tertiary"
                                    customClass={styles.importBtn}
                                    fullWidth
                                    handleClick={() => {
                                        scanOCR()
                                    }}
                                    customLabel={
                                        <UiText weight="semi_bold_600">
                                            Scan & Import
                                        </UiText>
                                    }
                                />
                            )}
                            {!isUnderVTOSection && (
                                <>
                                    {!smDevice && (
                                        <Button
                                            className={styles.skipBtn}
                                            variant="text"
                                            onClick={goToVtoSection}
                                        >
                                            <UiText weight="semi_bold_600">
                                                Skip
                                            </UiText>
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
                {smDevice && (
                    <Drawer
                        id="filter-drawer"
                        anchor="bottom"
                        open={anchorEl}
                        onClose={handleClose}
                        classes={{ paper: styles.drawerPaper }}
                    >
                        <div className={styles.drawerHeader}>
                            <div role='button' onClick={handleClose} className={styles.iconContainer}>
                                <RemoveRoundedIcon
                                    fontSize="large"
                                    classes={{
                                        root: styles.iconRoot,
                                    }}
                                />
                            </div>
                            <UiText
                                variant="hatchback_125"
                                weight="semi_bold_600"
                            >
                                Add Document
                            </UiText>
                        </div>
                        <div className={styles.drawerContent}>
                            <div>
                                <Button
                                    className={styles.uploadBtn}
                                    disabled={false}
                                    //@ts-ignore
                                    onClick={() => dropzoneRef?.current?.open()}
                                    startIcon={
                                        <Icon
                                            icon="upload"
                                            svgColor={COLORS.primaryWhite}
                                        />
                                    }
                                >
                                    <UiText
                                        variant="motorcycle_90"
                                        weight="semi_bold_600"
                                    >
                                        Upload File
                                    </UiText>
                                </Button>
                            </div>
                            <div>
                                <UiText
                                    variant="motorcycle_90"
                                    textColor="textSecondary"
                                >
                                    Supported file types: .pdf,
                                </UiText>
                            </div>
                            <div className={styles.importContent}>
                                <ImportDocuments
                                    selectMultiple={false}
                                    setFileList={setFileList}
                                />
                                <UiButton
                                    customClass={styles.mt24}
                                    btnType="hyperlink"
                                    label="Cancel"
                                    handleClick={handleClose}
                                />
                            </div>
                        </div>
                    </Drawer>
                )}
            </div>
        </div>
        </>
    )
}

export default Individual1040FormUpload
