import {
    Badge,
    Box,
    Checkbox,
    CircularProgress,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import { UploadedFile, PaginatedResponse, FolderTree } from '../../models'
import NoFilesFound from '../../assets/images/NoFilesFound.png'
import { makeStyles } from '@material-ui/styles'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import { toRem16 } from '../../styles/commonStyles'
import UiText from '../common/ui/UiText'
import { th } from 'date-fns/locale'
import { ThemeColors } from '../../styles/models/Colors.interface'

export const getIconType = (mime: string) => {
    switch (mime) {
        case 'image/jpeg':
            return 'Image-Attachment'
        case 'image/png':
            return 'Image-Attachment'
        case 'application/pdf':
            return 'PDF'
        default:
            return 'Generic'
    }
}

const useStyles = makeStyles<Theme, ThemeColors>((theme)=> {
    return {
        link: {
            color: colorTheme => colorTheme.black200,
            '&:hover': {
                color: colorTheme => colorTheme.black200,
                textDecoration: 'underline',
            },
            wordBreak: 'break-word',
        },
        fileName: {
            marginLeft: '0.4rem',
        },
        breadcrumb: {
            textTransform: 'capitalize',
        },
        content: {
            overflowY: 'auto',
            height: 'calc(100vh - 350px)',
        },
        fileListing: {
            width: toRem16(30),
            height: toRem16(40),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        documentContainer: {
            marginTop: '1rem',
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '1rem',
                width: 'calc(100vw - 1.1rem)'
            }
        },
        emptyContent: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            overflow: 'hidden',
        },
        mobileTitle: {
            margin: '0 1rem'
        },
        maxContent: {
            minWidth: 'max-content'
        }
    }
})

export default function DocumentList({
    files,
    handleSelectFile,
    folderView,
    accountId,
    businessFolderTree,
    personalFolderTree,
    showCheckbox = false,
    checkedFiles,
    handleCheckedFiles,
    folderPath,
    term,
    hideAllFilesText,
    isCheckedViewSelected
}: {
    files: PaginatedResponse<UploadedFile> | undefined | UploadedFile[]
    handleSelectFile: (file: UploadedFile) => void
    folderView: boolean
    accountId: string
    businessFolderTree: FolderTree | undefined
    personalFolderTree: FolderTree | undefined
    showCheckbox?: boolean
    checkedFiles?: UploadedFile[]
    handleCheckedFiles?: (checked: boolean, files: UploadedFile) => void
    folderPath?: any
    term?: string
    hideAllFilesText?: boolean
    isCheckedViewSelected?: boolean
}) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    
    const checkedFileIds = checkedFiles?.map((file) => file.id) 

    const getFile = (file: UploadedFile, index: number) => (
        <Box
            key={index}
            display="flex"
            alignItems="center"
            className="cursor-pointer"
        >
            <div className={showCheckbox ? styles.maxContent : styles.fileListing}>
                {showCheckbox && (
                    <Checkbox
                        color="primary"
                        checked={checkedFileIds?.includes(file.id)}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckedFiles?.(event.target.checked, file)}
                    />
                )}
                <Badge
                    color="primary"
                    variant="dot"
                    overlap="circle"
                    invisible={file.is_read_by_user}
                >
                    <img
                        src={
                            require(`../../assets/icons-svg/${getIconType(
                                file.mime_type
                            )}.svg`).default
                        }
                        alt="document"
                    />
                </Badge>
            </div>
            <div onClick={() => handleSelectFile(file)}>
                <UiText className={showCheckbox ? styles.fileName : styles.link} >
                    {file.filename}
                </UiText>
            </div>
        </Box>
    )

    const getBreadCrumbsByPath = (path: string) => {
        const pathArray = path.split('/')
        const parentType = pathArray.shift()
        const getPathToDisplayArray = (folderTree: FolderTree) => {
            const finalFolder = pathArray.reduce(
                (
                    latestFolder: FolderTree,
                    item: string,
                    index: number,
                    array: string[]
                ): FolderTree => {
                    const path = array.slice(0, index + 1).join('/')
                    return (latestFolder?.folders as any)[`/${path}`]
                },
                folderTree
            )

            return finalFolder.pathToDisplay
        }
        if (parentType === 'business') {
            return getPathToDisplayArray(businessFolderTree as FolderTree)
        } else {
            return getPathToDisplayArray(personalFolderTree as FolderTree)
        }
    }

    const getFilesGroupedByFolder = () => {
        const formattedFiles = Array.isArray(files) ? { items: files } : files
        const grouped = formattedFiles?.items.reduce(
            (group: { [path: string]: UploadedFile[] }, file) => {
                const parentType =
                    file.portal_account_id === accountId
                        ? 'business'
                        : 'private'
                const filePathToDisplay = `${parentType}${file.path}`
                if (Array.isArray(group[filePathToDisplay])) {
                    group[filePathToDisplay].push(file)
                } else {
                    group[filePathToDisplay] = [file]
                }
                return group
            },
            {}
        ) as { [path: string]: UploadedFile[] }

        /**
         * Converted to array, to iterate it in UI
         */
        return (
            businessFolderTree &&
            personalFolderTree &&
            Object.keys(grouped).map((key) => ({
                breadcrumb: getBreadCrumbsByPath(key),
                files: grouped[key],
            }))
        )
    }

    const formattedFiles = Array.isArray(files) ? { items: files, itemsCount: files?.length } : files
    const smDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    
    return (
        <div className={formattedFiles?.items?.length ? styles.documentContainer : styles.emptyContent}>
            {formattedFiles ? (
                formattedFiles.items?.length ? (
                    <>
                       {showCheckbox && <UiText 
                        variant='suv_150'
                        weight='semi_bold_600'
                        gutterBottom={true}>
                            {(folderPath?.title)  ? (folderPath?.title) : `${(term || hideAllFilesText)? '' : isCheckedViewSelected ? 'Selected Files' : 'All Files'}`}
                        </UiText>}
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={smDevice ? styles.mobileTitle: ''}
                            gutterBottom
                        >
                            {formattedFiles?.itemsCount} Files
                        </Typography>
                        <Box mt={3} className={showCheckbox ? '' : styles.content}>
                            {folderView
                                ? formattedFiles.items.map((file, index) =>
                                      getFile(file, index)
                                  )
                                : getFilesGroupedByFolder()?.map(
                                      (group, groupIndex) => (
                                          <Box key={groupIndex} mt={4}>
                                              <Typography
                                                  variant="subtitle2"
                                                  color="textSecondary"
                                                  gutterBottom
                                                  className={`${styles.breadcrumb} ${smDevice ? styles.mobileTitle: ''}`}
                                              >
                                                  {group.breadcrumb}
                                              </Typography>
                                              <Box mt={1}>
                                                  {group.files.map(
                                                      (
                                                          file: UploadedFile,
                                                          fileIndex: number
                                                      ) =>
                                                          getFile(
                                                              file,
                                                              fileIndex
                                                          )
                                                  )}
                                              </Box>
                                          </Box>
                                      )
                                  )}
                        </Box>
                    </>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        {!showCheckbox && <img src={NoFilesFound} alt="Not found" />}
                        <Typography variant="h6" color="textSecondary">
                            No matching results
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {folderView
                                ? 'No documents were found'
                                : 'Try adjusting your search and/or filters'}
                        </Typography>
                    </Box>
                )
            ) : (
                <Box textAlign="center">
                    <CircularProgress size={24} />
                </Box>
            )}
        </div>
    )
}
