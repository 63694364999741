import { Button, Typography } from '@material-ui/core'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import UiDialog from '../../common/ui/UiDialog'
import { hasPermission } from '../../../services/aclService'
import { useStyles } from './InfoModal'
import { GetCurrentUserContactId } from '../AccountSetupItems/AccountSetupUtility'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { getCookie, setCookie } from '../../../services/cookieService'
import {
    CriteriaExcludeType,
    EstimatedTaxModalConfig,
    EstimatedTaxMonthWiseConfig,
} from '../../../models/estimated-tax-reminder-modal'
import {
    ESTIMATED_TAXES_PERMISSION,
    PAYROLL_PERMISSION,
} from '../TodoSection/Todo.const'
import { getFromLocalStorage } from '../../../services/localStorageService'

const today = new Date()
const EST_TAX_REMINDER_COOKIE = 'estimatedTaxesReminder'
const PAYROll_TAX_REMINDER_COOKIE = 'payrollTaxesReminderAccount_'

const getMeetingLink = (
    contactId: string,
    modalName: string,
    windowsRedirectName: string
) => {
    const url: string =
        'https://1800accountant.com/sfapi/schedule/index.php?WID=' +
        contactId +
        '&EO=1&TID=BNA&EmailCampaignID=payroll-filings-portal-quarterly-' +
        modalName +
        '&APSBJ=' +
        windowsRedirectName
    return url
}

function EstimatedTaxesReminderModal() {
    const classes = useStyles()
    const userContactId = GetCurrentUserContactId()
    const { currentConfig, personalAccount, currentAccount } = useCurrentStore()

    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [estimatedTaxConfig, setEstimatedTaxConfig] =
        useState<EstimatedTaxMonthWiseConfig | null>()

    const estTaxConfig: EstimatedTaxModalConfig =
        currentConfig.local.estimatedTaxesModal
    const textBody = estimatedTaxConfig
        ? estimatedTaxConfig.text.split('<br>').map((str: any, index: number) => <p key={index}>{str}</p>)
        : ''

    const hideModalInCookie = () => {
        const permissionCheck =
            estimatedTaxConfig?.criteriaToExclude?.permission
        const modalCookieName = getCookieName(permissionCheck as string)
        setCookie(
            modalCookieName as string,
            'dismissed',
            null,
            estimatedTaxConfig?.endDate
        )
    }

    /**
     * @returns string - ex. `January / JanuaryPayrollTaxes` etc
     * Iterate through the estimatedConfig and found the matching date then returns the key
     */
    const hasValidCriteria = useCallback(() => {
        const estTaxConfigValidKey = Object.keys(estTaxConfig).find(
            (key: any) => {
                let startDate = new Date(estTaxConfig?.[key]?.startDate)
                let endDate = new Date(estTaxConfig?.[key]?.endDate)
                if (today >= startDate && today <= endDate) {
                    return key
                }
            }
        )
        return estTaxConfigValidKey
    }, [estTaxConfig])

    const scheduleAppointment = () => {
        hideModalInCookie()
        if (userContactId) {
            const meetingLink = getMeetingLink(
                userContactId,
                estimatedTaxConfig?.portalQuarterlyModalName as string,
                estimatedTaxConfig?.windowsRedirectName as string
            )
            window.open(meetingLink, '_blank')
        }
        setOpenInfoModal(false)
    }

    const closeModal = () => {
        hideModalInCookie()
        setOpenInfoModal(false)
    }

    const getCookieName = useCallback(
        (permissionCheck: string) => {
            if (permissionCheck === PAYROLL_PERMISSION) {
                return PAYROll_TAX_REMINDER_COOKIE + currentAccount.id
            } else if (permissionCheck === ESTIMATED_TAXES_PERMISSION) {
                return EST_TAX_REMINDER_COOKIE
            }
        },
        [currentAccount.id]
    )

    const checkForPayrollPermission = useCallback(
        (permissionCheck: string, criteriaToExclude: CriteriaExcludeType) => {
            const advisoryStatus = criteriaToExclude?.advisory_status
            const hasPayrollPermission =
                hasPermission(permissionCheck, personalAccount) ||
                hasPermission(permissionCheck, currentAccount)
            const hasOnboardingAdvisoryStatus =
                personalAccount.advisory_status === advisoryStatus ||
                currentAccount.advisory_status === advisoryStatus

            return hasPayrollPermission || hasOnboardingAdvisoryStatus
                ? false
                : true
        },
        [currentAccount, personalAccount]
    )

    /**
     * show the modal based on given permission
     */
    const permissionCheckForModal = useCallback(
        (permissionCheck: string, criteriaToExclude: CriteriaExcludeType) => {
            if (permissionCheck === PAYROLL_PERMISSION) {
                return checkForPayrollPermission(
                    permissionCheck,
                    criteriaToExclude
                )
            } else if (permissionCheck === ESTIMATED_TAXES_PERMISSION) {
                return !hasPermission(permissionCheck, personalAccount)
            }
        },
        [checkForPayrollPermission, personalAccount]
    )
    
    const isPartnerLogin = () : boolean =>{
        const user = getFromLocalStorage('user');
        return user && user.partner_details;
    }

    useEffect(() => {
        const configKey = hasValidCriteria()
        if (configKey) {
            const criteriaToExclude = estTaxConfig[configKey]?.criteriaToExclude
            const permissionCheck = criteriaToExclude?.permission
            setEstimatedTaxConfig(
                estTaxConfig[configKey] as EstimatedTaxMonthWiseConfig
            )
            const cookieName = getCookieName(permissionCheck)
            const estimatedTaxesReminderCookie = getCookie(cookieName as string)
            const showModal = permissionCheckForModal(
                permissionCheck,
                criteriaToExclude
            )

            const partnerLogin = isPartnerLogin();
            if (showModal && !estimatedTaxesReminderCookie && !partnerLogin) {
                setOpenInfoModal(true)
            }
        }
    }, [estTaxConfig, getCookieName, hasValidCriteria, permissionCheckForModal])

    return (
        <UiDialog
            open={openInfoModal}
            handleClose={closeModal}
            title=""
            size="sm"
            customRootClass={classes.container}
        >
            <Fragment>
                <div>
                    <Fragment>
                        <Typography
                            className={classes.TextCenter}
                            variant="h6"
                            style={{ marginBottom: 24 }}
                        >
                            {estimatedTaxConfig
                                ? estimatedTaxConfig.heading
                                : ''}
                        </Typography>
                        <Typography
                            className={classes.TextCenter}
                            variant="body1"
                        >
                            {textBody}
                        </Typography>
                    </Fragment>
                </div>

                <div className={classes.formActions}>
                    <Button
                        variant="contained"
                        onClick={scheduleAppointment}
                        color="primary"
                    >
                        Schedule a No-Cost Consultation
                    </Button>
                </div>
            </Fragment>
        </UiDialog>
    )
}

export default EstimatedTaxesReminderModal
