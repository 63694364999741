import { AppData, Products } from '../../models';
import { getProducts } from '../../services/apiService';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const LOADING_PRODUCTS = 'LOADING_PRODUCTS';

export interface productAction {
    type: string;
    state?: Products;
}

export type Action = productAction;

export const setProducts = (data: Products): Action => ({
    type: SET_PRODUCTS,
    state: data,
});

export const loadingProducts = (): Action => ({
    type: LOADING_PRODUCTS,
});

export function loadProducts(params?: any) {
    return (dispatch: any, getState: any) => {
        dispatch(loadingProducts());
        const { appData }: { appData: AppData } = getState();
        getProducts(appData.current_account_id, params).then((res: any) => {
            dispatch(
                setProducts({ products: res, loading: false, loaded: true })
            );
        });
    };
}
