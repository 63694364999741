const IntroVideo = ({ width = '640', height = '315' }) => {
    const introVideoURL = `https://www.youtube.com/embed/knQ81S-1rak?si=trV3bs_FbH2lVwLo`
    return (
        <iframe
            width={width}
            height={height}
            src={introVideoURL}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    )
}

export default IntroVideo
