import { makeStyles, Theme } from '@material-ui/core'
import { connect } from "react-redux";
import useDeviceSize from '../../../hooks/useDeviceSize'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import UiText from '../../common/ui/UiText'
import AccountSetupItems from '../AccountSetupItems/AccountSetupItems'
import PermissionBlocks from '../AccountSetupItems/PermissionBlocks'
import {  STATUS_RETURN_COMPLETED } from '../tax-prep/constants/tax-prep.const'
import { useWidgetContext } from '../tax-prep/provider/WidgetProvider'
import { useGreeting } from '../tax-prep/tax-prep-statuses/hooks/useGreeting'
import TaxPrep from '../tax-prep/TaxPrep'
import TaxEngagement from '../TaxEngagementPlan/TaxEngagement'
import { FORMATIONS_LEAD } from '../TodoSection/Todo.const'
import { loadPostAuthData } from '../../../store/actions/appData'
import { useEffect } from 'react';
import { getFromLocalStorage, setInLocalStorage } from '../../../services/localStorageService';
import moment from 'moment';

const useLocalStyles = makeStyles((theme: Theme) => {
    return {
        message: {
            margin: '1rem 0 1rem 0'
        },
        block: {
            marginBottom: '1rem'
        }
    }
});

const DashboardItems = (props: {loadPostAuthData: any}) => {
    const {
        taxPrepData: { business: businessData, personal: personalData },
    } = useWidgetContext()
    const { currentUser, personalAccount, currentBusiness, currentBusinessId } = useCurrentStore()
    const styles = useLocalStyles()
    const greetingMessage = useGreeting()
    const { isMobileDevice } = useDeviceSize()
    const isBothTaxPrepCompleted =
        businessData?.tax_preparation_current_step.title === STATUS_RETURN_COMPLETED &&
        personalData?.tax_preparation_current_step.title === STATUS_RETURN_COMPLETED 

    const leadSource = personalAccount?.extra_info?.lead_source
    const showPermissionBlock = !(leadSource === FORMATIONS_LEAD)

    const showTaxWidget = !moment().isSame(moment(currentBusiness?.form_date, "YYYY-MM-DD"), "year")

    const syncBackgroundData = () => {
        const dataLoaded = getFromLocalStorage('dataLoaded');
    
        if (!dataLoaded) {
            props.loadPostAuthData(currentBusinessId).then(() => {
                setInLocalStorage('dataLoaded', 'true');
            });
        }
    }
    
    // Sync user data after 15 seconds due to background process in backend
    useEffect(() => {
        const timer = setTimeout(() => {
            syncBackgroundData();
        }, 15000); 
    
        return () => clearTimeout(timer);
    }, [currentBusinessId]);

    return (
        <div>
            <UiText variant={isMobileDevice ? "hatchback_125" : "suv_150"} weight="semi_bold_600" className={styles.message}> 
                👋 {greetingMessage}, {currentUser.first_name}
            </UiText>
            {showTaxWidget && (
                <div className={styles.block}>
                {!isBothTaxPrepCompleted && <TaxPrep />}
            </div>
            )}
            {showPermissionBlock && <PermissionBlocks />}
            {isMobileDevice && <TaxEngagement/> }
            <AccountSetupItems />
            {isBothTaxPrepCompleted && <TaxPrep />}
        </div>
    )
}

export default connect(null, {loadPostAuthData}) (DashboardItems)
