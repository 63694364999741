import React, { useState } from 'react';
import UiModal from '../../../common/ui/UiModal'
import {
    Box,
    Button,
    IconButton,
    makeStyles,
    Theme,
    TextField,
    Typography,
} from '@material-ui/core'
import Form8879UploadFlow from '../tax-prep-statuses/Form8879UploadFlow'
import UiText from '../../../common/ui/UiText'
import CloseIcon from '@material-ui/icons/Close'
import Icon from '../../../common/Icon'
import ThankYouPage from '../tax-prep-statuses/ThankYouPage'
import Form8879ErrorAlert from '../tax-prep-statuses/Form8879ErrorAlert'
import { useWidgetContext } from '../provider/WidgetProvider'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { form8879ESignatureSubmit } from '../../../../services/apiService'
import Loader from '../../../common/Loader'
import { ReviewDialog } from '../../../common/ReviewDialog'
import { ThemeColors } from '../../../../styles/models/Colors.interface';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    modalContentContainer: {
        width: '40rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        paddingBottom: '4rem',
        height: '50rem',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            borderRadius: 0,
            overflow: 'auto',
        },
    },
    modalContentContainerMainPage: {
        width: '40rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        paddingBottom: '4rem',
        [theme.breakpoints.down('xs')]: {
            height: '100svh',
            borderRadius: 0,
            overflow: 'auto',
            paddingBottom: '0rem',
        },
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1.375rem',
        boxSizing: 'border-box',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    modalHeaderMain: {
        borderBottom: `none`,
    },
    closeIconButton: {
        padding: 0,
    },
    modalContent: {
        padding: '0.5rem 3.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0.5rem 2rem',
        },
    },
    eSignFormContainer: {
        color: (colorTheme) => colorTheme.black100,
    },
    uploadFormContainer: {
        marginTop: '3rem',
        color: (colorTheme) => colorTheme.black100,
    },
    eSignBtn: {
        background: (colorTheme) => colorTheme.tertiary,
        color: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.25rem',
        padding: '0.75rem 0.875rem',

        '&:hover': {
            background: (colorTheme) => colorTheme.tertiary,
        },
        '&:disabled': {
            opacity: 0.25,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
    },
    uploadBtn: {
        background: (colorTheme) => colorTheme.primaryWhite,
        padding: '0.75rem 0.875rem',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        color: (colorTheme) => colorTheme.black100,
    },
    paragraphText: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    spanText: {
        display: 'inline-block',
        color: (colorTheme) => colorTheme.grey1000,
        marginLeft: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
        },
    },
    eSignContentContainer: {
        padding: '1.5rem 3.5rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            borderRadius: 0,
            overflow: 'auto',
            padding: '1rem 1rem',
        },
    },
    legalTextField: {
        marginTop: '1.5rem',
        boxSizing: 'border-box',
        maxWidth: '22rem',
        '& .MuiOutlinedInput-root': {
            padding: '0rem 9rem 0rem 0.8rem',
        },
        '& input': {
            padding: '0.5rem',
        },
        '&.Mui-focused': {
            color: 'red',
        },
    },
    eFileButton: {
        padding: '0.563rem 1rem',
        background: (colorTheme) => colorTheme.primary,
        color: (colorTheme) => colorTheme.primaryWhite,
        boxSizing: 'border-box',
        borderRadius: '0.25rem',
        marginTop: '2.5rem',
        '&:disabled': {
            opacity: 0.25,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
        '&:hover': {
            background: (colorTheme) => colorTheme.primaryWhite,
        },
    },
    signatureContainer: {
        color: (colorTheme) => colorTheme.tertiary,
    },
    signatureText: {
        border: (colorTheme) => `1px solid ${colorTheme.secondary}`,
        borderRadius: '0.5rem',
        padding: '0.2rem 3rem  0.2rem 0.5rem',
        width: 'fit-content',
        fontFamily: "'Caveat', cursive",
        color: (colorTheme) => colorTheme.blue400,
    },
    signatureTerm: {
        fontFamily: "'Caveat', cursive",
        fontWeight: 600,
        fontSize: '1.5rem',
    },
    signatureIcon: {
        '& svg': {
            height: '1.563rem!important',
            width: '1.563rem!important',
        },
    },
    signConfirmation: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '1.5rem',
    },
    digiText: {
        marginLeft: '0.5rem',
    },
    eSignContainer: {
        display: 'flex',
    },
    leftIcon: {
        marginRight: '0.5rem',
    },
    eSignTextField: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

type CompleteForm8879LandingViewType = {
    handleClose: () => void
    setView: Function
}

type CompleteForm8879eSignViewType = {
    handleClose: (showReviewModal? : boolean) => void
    setView: Function
    taxApId: string
    onShowReviewModal: (isShow: boolean) => void
}
export interface ESign{
    show_review_widget? : boolean
}
const CompleteForm8879LandingView = ({
    handleClose,
    setView,
}: CompleteForm8879LandingViewType) => {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)

    const handleLandingViewClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        handleClose()
    }
    return (
        <div className={classes.modalContentContainerMainPage}>
            <div
                className={`${classes.modalHeader} ${classes.modalHeaderMain}`}
            >
                <UiText variant="car_100" weight="medium_500"></UiText>
                <IconButton
                    aria-label="close"
                    classes={{ root: classes.closeIconButton }}
                    onClick={handleLandingViewClose}
                    data-cy="cross-modal-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.modalContent}>
                <div className={classes.eSignFormContainer}>
                    <UiText
                        variant="truck_175"
                        weight="medium_500"
                        textColor="inherit"
                    >
                        Complete Form 8879
                    </UiText>
                    <UiText className={classes.paragraphText}>
                        By signing Form 8879, you will authorize us to e-file
                        your returns on your behalf.
                    </UiText>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setView('esign_flow')
                        }}
                        className={classes.eSignBtn}
                        endIcon={
                            <Icon icon="rightArrow" svgColor={colorTheme.primaryWhite} />
                        }
                    >
                        <UiText weight="semi_bold_600">E-Sign the Form</UiText>
                    </Button>
                    <UiText
                        variant="motorcycle_90"
                        className={classes.spanText}
                    >
                        (This is the simplest option)
                    </UiText>
                </div>
                <div className={classes.uploadFormContainer}>
                    <UiText
                        variant="hatchback_125"
                        weight="semi_bold_600"
                        textColor="inherit"
                    >
                        Did you already sign Form 8879?
                    </UiText>
                    <UiText className={classes.paragraphText}>
                        If you already downloaded and signed Form 8879, you can
                        upload a copy of it here.
                    </UiText>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setView('document_flow')
                        }}
                        className={classes.uploadBtn}
                        endIcon={
                            <Icon
                                icon="rightArrow"
                                svgColor={colorTheme.black100}
                            />
                        }
                    >
                        <UiText
                            variant="motorcycle_90"
                            weight="semi_bold_600"
                            textColor="inherit"
                        >
                            Upload Your Signed Form
                        </UiText>
                    </Button>
                </div>
            </div>
        </div>
    )
}

const CompleteForm8879eSignView = ({
    handleClose,
    setView,
    taxApId,
    onShowReviewModal
}: CompleteForm8879eSignViewType) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [signed, setSigned] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false)
    const { isError, setIsError } = useWidgetContext()
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)

    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId

    const handleEsignSubmitButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setIsLoading(true)
        const term = `${firstName} ${lastName}`
        if (!taxApId || !firstName || !lastName || !accountId) {
            return
        }

        form8879ESignatureSubmit(term, accountId, taxApId)
            .then((res: ESign) => {
                setIsLoading(false)
                setShowReviewModal(res.show_review_widget ?? false)
                onShowReviewModal(res.show_review_widget ?? false)
                setView('thank_you_page')
            })
            .catch((error) => {
                setIsError(true)
                setIsLoading(false)
            })
    }

    const handleEsignButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setSigned(true)
    }
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={classes.modalContentContainer}
        >
             {showReviewModal && <ReviewDialog />}
            <div className={classes.modalHeader}>
                <div className={classes.eSignContainer}>
                    <Box
                        onClick={(e) => {
                            e.stopPropagation()
                            setView('landing')
                        }}
                        className={classes.leftIcon}
                    >
                        {' '}
                        <Icon icon="leftArrow" svgColor={colorTheme.grey400} />
                    </Box>

                    <UiText variant="hatchback_125" weight="medium_500">
                        Digitally Sign Form 8879
                    </UiText>
                </div>

                <IconButton
                    aria-label="close"
                    classes={{ root: classes.closeIconButton }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleClose()
                    }}
                    data-cy="cross-modal-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={classes.eSignContentContainer}>
                    <UiText variant="hatchback_125" weight="medium_500">
                        Officer’s Information
                    </UiText>
                    <div className={classes.eSignTextField}>
                        <TextField
                            onChange={(e) => setFirstName(e.target.value)}
                            id="outlined-full-width"
                            label="First Name (Required)"
                            className={classes.legalTextField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={signed === true}
                            variant="outlined"
                            value={firstName}
                        />
                        <TextField
                            onChange={(e) => setLastName(e.target.value)}
                            id="outlined-full-width"
                            label="Last Name (Required)"
                            className={classes.legalTextField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={signed === true}
                            variant="outlined"
                            value={lastName}
                        />
                    </div>

                    {signed ? (
                        <Box className={classes.signatureContainer} my={3}>
                            <div className={classes.signatureText}>
                                <Typography className={classes.signatureTerm}>
                                    {firstName} {lastName}
                                </Typography>
                            </div>
                            <div className={classes.signConfirmation}>
                                <Icon
                                    customClass={classes.signatureIcon}
                                    icon="checkedIcon"
                                />
                                <UiText
                                    className={classes.digiText}
                                    textColor="inherit"
                                    weight="semi_bold_600"
                                >
                                    Digitally Signed
                                </UiText>
                            </div>
                        </Box>
                    ) : (
                        <Box my={3}>
                            <Button
                                onClick={handleEsignButtonClick}
                                className={`${classes.eSignBtn}`}
                                disabled={
                                    firstName.trim() === '' ||
                                    lastName.trim() === ''
                                }
                            >
                                <UiText weight="semi_bold_600">
                                    Digitally Sign
                                </UiText>
                            </Button>
                            <UiText
                                variant="motorcycle_90"
                                className={classes.spanText}
                            >
                                (Required)
                            </UiText>
                        </Box>
                    )}

                    <Button
                        onClick={handleEsignSubmitButtonClick}
                        disabled={!signed}
                        className={classes.eFileButton}
                    >
                        <UiText variant="car_100" weight="semi_bold_600">
                            E-File My Tax Returns
                        </UiText>
                    </Button>
                    <Box mt={3}>
                        <UiText
                            variant="motorcycle_90"
                            textColor="textSecondary"
                        >
                            By proceeding, you are electronically signing IRS
                            Form 8879 which will authorize 1-800Accountant to
                            electronically file your federal tax return.
                        </UiText>
                    </Box>
                    {isError && (
                        <Box mt={4}>
                            <Form8879ErrorAlert />
                        </Box>
                    )}
                </div>
            )}
        </div>
    )
}

const Form8879ESignModal = ({
    open,
    handleClose,
    vtoType,
    taxYear,
    taxApId,
}: any) => {
    const [view, setView] = useState('landing')
    const [isShowReviewModal, setIsShowReviewModal] = useState<boolean>(false)
    const [isShowReviewForESign, setIsShowReviewForESign] = useState<boolean>(false)
    const handleModalClose = (showReviewModal? : boolean | unknown) => {
        setView('landing')
        handleClose()
        if(showReviewModal === true || isShowReviewForESign){
            setIsShowReviewModal(true)
       }
    }
    const onShowReviewModal = (isShow: boolean) =>{
        setIsShowReviewForESign(isShow)
    }
    const renderView = () => {
        switch (view) {
            case 'landing':
                return (
                    <CompleteForm8879LandingView
                        handleClose={handleModalClose}
                        setView={setView}
                    />
                )
            case 'document_flow':
                return (
                    <Form8879UploadFlow
                        handleClose={handleModalClose}
                        vtoType={vtoType}
                        taxYear={taxYear}
                        taxApId={taxApId}
                        setView={setView}
                    />
                )
            case 'esign_flow':
                return (
                    <CompleteForm8879eSignView
                        handleClose={handleModalClose}
                        setView={setView}
                        taxApId={taxApId}
                        onShowReviewModal = {onShowReviewModal}
                    />
                )
            case 'thank_you_page':
                return <ThankYouPage handleClose={handleModalClose} />
            default:
                return (
                    <CompleteForm8879LandingView
                        handleClose={handleModalClose}
                        setView={setView}
                    />
                )
        }
    }
    return (
        <>
        <UiModal open={open} handleClose={handleModalClose}>
            {renderView()}
        </UiModal>
        {
            isShowReviewModal && <ReviewDialog />
        }
        </>
    )
}

export default Form8879ESignModal
