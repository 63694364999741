import { Divider, makeStyles, Theme } from '@material-ui/core'
import parse from 'html-react-parser'
import UiFormFieldArray from './UiFormFieldArray'
import UiFormQuestionSet from './UiFormQuestionSet'
import UiFormControlSelection from './UiFormControl'
import UiText from '../../../../../common/ui/UiText'
import { getConfigByName } from '../VTOMain/services/config'
import { useVtoContext } from '../../../providers/VtoProvider'
import {
    validateDateField,
    validateRadioField,
    validateSelectField,
    validateTextField,
    validateNumberField,
} from '../VTOMain/utils/validator'
import { Alert } from '@material-ui/lab'
import { Fragment } from 'react'
import { UiFormExpenses, ExpensesProfit, ExpensesTotal } from './UiFormExpenses'
import { useFormikContext } from 'formik'
import FileUpload from '../../../steps/UploadStep/FileUploads'
import {
    getValueByModelName,
    hasConditionMatched,
    hasUpsellConditionMatched,
    IgnoreValidationForZero,
} from '../../../common/VtoUtils'
import UpsellInfoCard from '../UpsellInfoCard'
import { CHOICE_TYPE_QUESTION } from '../../../common/questions.const'
import Icon from '../../../../../common/Icon'
import UiFormCheckboxSet from './UIFormCheckboxSet'
import moment from 'moment'
import UiFileSetQuestions from './UiFileSetQuestions'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import UiStateFieldQuestions from './UiStateFieldQuestions'
import UiOptionsQuestions from './UiOptionsQuestions'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { AUTOCOMPLETE_FIELDS, COUNTRY_QUESTION_PATTERNS, NO_SHOW_HELPER_TEXT_FIELDS_PATTERNS, US_COUNTRY } from '../TaxPrepStatuses/constant'
import { FAQ } from '../../../models/VtoDetails.model'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { useCurrentStore } from '../../../../../common/hooks/useCurrentStore'
import { hasDefaultValue, isFilledAnswer } from '../../../common/VtoQuestionUtils'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        singleQuestionContainer: {
            marginBottom: '1rem',
            marginTop: '1rem',
        },
        singleQuestion: {
            marginTop: '0.8rem',
        },
        successContainer: {
            marginTop: '1.5rem',
            marginBottom: '0.8rem',
        },
        marginLeft: {
            marginLeft: '0.2rem',
        },
        dividerStyle: {
            margin: '1rem 0 0.5rem 0',
        },
        marginQuestion: {
            margin: '0.5rem 0 0.5rem 0',
        },
        bookkeepingActionBtnWrapper: {
            margin: '1rem 0',
            '& > .MuiAlert-root': {
                margin: '0.5rem 0',
            },
        },
        my16: {
            marginBottom: '1rem',
            marginTop: '1rem',
        },
        mb8: {
            marginBottom: '0.5rem',
        },
        alertRoot: {
            marginTop: '1rem',
            color: (colorTheme) => colorTheme.black100,
            backgroundColor: (colorTheme) => colorTheme.green1100,
            '& .MuiAlert-icon': {
                color: (colorTheme) => colorTheme.tertiary,
            },
        },
        faqContainer: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '0.5rem',
            paddingBottom: '1rem',
            gap: '0.5rem',
            color: (colorTheme) => colorTheme.blue200,
            '& p': {
                marginLeft: '0.5rem',
            },
            '& svg path': {
                stroke: (colorTheme) => colorTheme.blue200,
                strokeOpacity: 1,
            },
        },
        faqItem: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'max-content',
            maxWidth: '100%',
            textDecoration: 'underline',
        },
        infoText: {
            display: 'flex',
            '& .label': {
                marginLeft: toRem16(8),
            },
        },
    }
})

const hideUpsellAd = (type: string, upsell: any, debouncedValues: any) => {
    if (!upsell || type === CHOICE_TYPE_QUESTION) {
        return true
    }
    return !hasUpsellConditionMatched(upsell, debouncedValues)
}

export const QuestionTitle = ({ title, required }: any) => {
    if (!title) return null
    return (
        <UiText variant={'car_100'} weight={'regular_400'}>
            {parse(title)}{required && '*'}
        </UiText>
    )
}

export const TextMapping: any = {
    default:
        'Prefilled with the most common answer. Confirm that this is correct for your situation',
    admin: 'Prefilled by accountant based on last year’s tax return or other information you provided, you can change this if needed',
    prefilled: 'Prefilled with info from your account',
}

export const TextColorMapping: any = {
    default: 'blue',
    prefilled: 'blue',
    admin: 'yellow',
}

function generateRegexArrayFromMask(mask: string): RegExp[] | string[] {
    const regexArray: (RegExp | string)[] = []
    for (let i = 0; i < mask.length; i++) {
        if (!isNaN(parseInt(mask[i]))) {
            regexArray.push(/[X0-9]/)
        } else {
            regexArray.push(mask[i])
        }
    }
    //@ts-ignore
    return regexArray
}

const totalNumberOfDigit = (str: string) => {
    const digits = str.match(/\d/g)
    return digits ? digits.length : 0
}

const getSelectOptions = (configName: string, vtoConfig: any) => {
    if (!vtoConfig) return null
    const options = getConfigByName(configName, vtoConfig)
    return options
}

const UiInputRendered = (props: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const {
        currentVtoConfig,
        productPriceMap,
    } = useVtoContext()
    const { values, setFieldValue, setFieldError } = useFormikContext<any>()

    const {
        model,
        options,
        index,
        title,
        customPlaceholder,
        isRequired,
        onChange,
        fastField = true,
        initialHelperText = 'Testing',
        inputBoxColor = '',
        disable_condition,
        isPartOfCheckboxSet
    } = props
    let { type } = props;

    const {
        mask,
        placeholder,
        required,
        hint,
        future,
        cch_mask,
        min,
        max,
        hint_condition,
        addition_text,
    } = props?.options || {}
    const hasHint = hint_condition
        ? hasConditionMatched(hint_condition, values)
        : true
    const questionFieldName = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${model}`
        : model

  
    const isCountryQuestion = COUNTRY_QUESTION_PATTERNS.some((pattern) => pattern.test(questionFieldName));
    const handleStateValue = (value: any) => {
        const respectiveStateField = (() => {
            if (COUNTRY_QUESTION_PATTERNS[0].test(questionFieldName)) {
                return `${props?.parentModel}.${props?.index}.address.state`
            }
            if (COUNTRY_QUESTION_PATTERNS[1].test(questionFieldName)) {
                return 'personalInfo.general.address.state'
            }
            return null
        })()

        if (respectiveStateField && value !== US_COUNTRY) {
            setFieldValue(respectiveStateField, null)
            setTimeout(() => {
                setFieldError(respectiveStateField, '')
            }, 0)
        }
    }


    const QuestionTitle = ({ title }: any) => {
        if (!title) return null
        const requiredFieldsModel = ["personalInfo.upsell.audit_defense", "date", "assist_next_year", "deductions", "income"]
        const req = required || isRequired || requiredFieldsModel.includes(model);
        return (
            <UiText variant={'car_100'} weight={'regular_400'}>
                {parse(title)}{req && '*'}
            </UiText>
        )
    }

    if(AUTOCOMPLETE_FIELDS.includes(model)) {
        type = 'autocomplete'
    }
    switch (type) {
        case 'string':
            return (
                <>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestionContainer}>
                        <div className={styles.singleQuestion}>
                            <UiFormControlSelection
                                fastField={true}
                                placeholder={placeholder || customPlaceholder}
                                type="text"
                                fieldName={questionFieldName}
                                defaultValue=""
                                validationFunction={(value: any) =>
                                    validateTextField(value, {
                                        ...props,
                                        vtoDetails: values,
                                    })
                                }
                                showFloatingLabel={true}
                                cypressId={questionFieldName}
                                initialHelperText={initialHelperText}
                                inputBoxColor={inputBoxColor}
                            />
                        </div>
                    </div>
                </>
            )
        case 'text':
        case 'email':
            return (
                <div className={styles.singleQuestionContainer}>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={fastField}
                            placeholder={placeholder || customPlaceholder}
                            type="text"
                            validationFunction={(value: any) =>
                                required || isRequired
                                    ? validateTextField(value, {
                                          ...props,
                                          vtoDetails: values,
                                      })
                                    : undefined
                            }
                            fieldName={questionFieldName}
                            defaultValue=""
                            showFloatingLabel={true}
                            cypressId={model}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                </div>
            )
        case 'mask':
            return (
                <div className={styles.singleQuestionContainer}>
                    <QuestionTitle title={title} />

                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="mask"
                            validationFunction={(value: any) =>
                                validateTextField(value, {
                                    ...props,
                                    vtoDetails: values,
                                })
                            }
                            placeholder={placeholder}
                            label={title}
                            fieldName={questionFieldName}
                            mask={generateRegexArrayFromMask(
                                cch_mask ? cch_mask : mask
                            )}
                            minLength={totalNumberOfDigit(
                                cch_mask ? cch_mask : mask
                            )}
                            cypressId={model}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                </div>
            )
        case 'secure-mask':
            return (
                <div className={styles.singleQuestionContainer}>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="mask"
                            validationFunction={(value: any) =>
                                validateTextField(value, {
                                    ...props,
                                    vtoDetails: values,
                                })
                            }
                            placeholder={placeholder}
                            label={title}
                            fieldName={questionFieldName}
                            cypressId={model}
                            mask={generateRegexArrayFromMask(
                                cch_mask ? cch_mask : mask
                            )}
                            minLength={totalNumberOfDigit(
                                cch_mask ? cch_mask : mask
                            )}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                </div>
            )
        case 'number':
            return (
                <div className={styles.singleQuestionContainer}>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={false}
                            type="number"
                            showFloatingLabel={false}
                            label={title}
                            min={min}
                            max={max}
                            validationFunction={(value: any) =>
                                validateNumberField(value, {
                                    ...props,
                                    vtoDetails: values,
                                })
                            }
                            fieldName={questionFieldName}
                            cypressId={model}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                </div>
            )
        case 'money':
            return (
                <div className={styles.singleQuestionContainer}>
                    {title && <QuestionTitle title={title} />}
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="number"
                            showFloatingLabel={false}
                            disabled={props?.disabled}
                            placeholder={placeholder || customPlaceholder}
                            label={title}
                            validationFunction={(value: any) =>
                                validateNumberField(value, {
                                    ...props,
                                    vtoDetails: values,
                                })
                            }
                            fieldName={questionFieldName}
                            cypressId={model}
                            startIcon={<Icon icon={'dollarUpdated'} />}
                            onChange={onChange}
                            initialHelperText={initialHelperText}
                            onBlur={(event: any) => {
                                if (Number(event.target.value) === 0) {
                                    if (
                                        IgnoreValidationForZero.includes(model)
                                    ) {
                                        setFieldValue(questionFieldName, 0)
                                    } else {
                                        setFieldValue(questionFieldName, null)
                                    }
                                } else {
                                    setFieldValue(
                                        questionFieldName,
                                        Number(event.target.value)
                                            .toFixed(2)
                                            .toString()
                                    )
                                }
                            }}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                    {addition_text && hasHint && (
                        <div className={styles.successContainer}>
                            <Alert severity="info">
                                {parse(addition_text)}
                            </Alert>
                        </div>
                    )}
                </div>
            )
        case 'checkbox':
            return (
                <div className={styles.singleQuestionContainer}>
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={fastField}
                            type="checkbox"
                            label={title}
                            placeholder={placeholder || customPlaceholder}
                            fieldName={questionFieldName}
                            cypressId={model}
                            inlineLabel={title}
                            disabled={props?.disabled}
                            required={required || isRequired}
                            onChange={onChange}
                            initialHelperText={initialHelperText}
                            isPartOfCheckboxSet={isPartOfCheckboxSet}
                        />
                    </div>
                </div>
            )
        case 'select':
        case 'autocomplete':
            if (options?.hidden) return null

            let disableSelect = false
            const dropDownOptions = getSelectOptions(
                options.configName,
                currentVtoConfig
            )
            const questionModal = props?.parentModel
                ? `${props?.parentModel}.${index}`
                : model

            if (props?.disable_condition) {
                const getCurrentModel = props?.parentModel
                    ? getValueByModelName(questionModal, values)
                    : values
                disableSelect = hasConditionMatched(
                    disable_condition,
                    getCurrentModel
                )
            }
            return (
                <>
                    <QuestionTitle title={title} />
                    {dropDownOptions && (
                        <div className={styles.singleQuestion}>
                            <UiFormControlSelection
                                fastField={false}
                                placeholder={
                                    title || placeholder || customPlaceholder
                                }
                                type={type}
                                validationFunction={
                                    required && !disableSelect
                                        ? validateSelectField
                                        : undefined
                                }
                                showFloatingLabel={true}
                                optionsData={(dropDownOptions as any) || []}
                                fieldName={
                                    props?.parentModel
                                        ? `${props?.parentModel}.${
                                              index > -1 ? index : ''
                                          }.${model}`
                                        : model
                                }
                                optionKey={options?.optionsLabel || ''}
                                optionValue={options?.optionsKey || ''}
                                cypressId={model}
                                initialHelperText={initialHelperText}
                                hideDefaultSelectOption={true}
                                disabled={disableSelect}
                                onChange={
                                    isCountryQuestion
                                        ? handleStateValue
                                        : undefined
                                }
                                inputBoxColor={inputBoxColor}
                            />
                        </div>
                    )}
                    {hint && hasHint && (
                        <div className={styles.successContainer}>
                            <Alert severity="info">{parse(hint)}</Alert>
                        </div>
                    )}
                </>
            )
        case 'success':
            return (
                <div className={styles.successContainer}>
                    {options?.header ? (
                        <UiText>{parse(title)}</UiText>
                    ) : (
                        <Alert severity="info">{parse(title)}</Alert>
                    )}
                </div>
            )
        case 'date':
            return (
                <div className={styles.singleQuestionContainer}>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="KeyboardDatePicker"
                            validationFunction={
                                required ? validateDateField : undefined
                            }
                            placeholder="MM/DD/YYYY"
                            disableDatePickerToolbar={future ? false : true}
                            disableFutureDate={future ? false : true}
                            fieldName={questionFieldName}
                            cypressId={model}
                            onDateChange={(date: any) => {
                                const formatDate =
                                    moment(date).format('YYYY-MM-DD')
                                setFieldValue(questionFieldName, formatDate)
                            }}
                            shouldDisableDateFunction={(date: any) => {
                                if (!min) return false
                                const minDate = new Date(min) // Set the minimum date
                                const currentDate = new Date(date)
                                return currentDate < minDate
                            }}
                            endIcon={<CalendarIcon />}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                </div>
            )
        case 'choice':
            let titleWithPrice = title
            if (productPriceMap[questionFieldName]) {
                const product = productPriceMap[questionFieldName]
                const discountedPrice = `$${Math.round(
                    product?.discountPrice / 12
                )}`
                const originalPrice = product?.annualPrice / 12
                if (discountedPrice || originalPrice) {
                    titleWithPrice = title.replace(
                        '{$}',
                        discountedPrice || originalPrice
                    )
                }
            }

            return (
                <>
                    <QuestionTitle title={titleWithPrice} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="radio"
                            label={title}
                            fieldName={questionFieldName}
                            validationFunction={
                                required || options?.upsell
                                    ? validateRadioField
                                    : undefined
                            }
                            cypressId={model}
                            radioOptionsData={options?.choices}
                            onChange={(e: any) => {
                                setFieldValue(questionFieldName, e.target.value)
                            }}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                    <div className={styles.singleQuestion}>
                        {hint && (
                            <div className={styles.successContainer}>
                                <Alert severity="info">{parse(hint)}</Alert>
                            </div>
                        )}
                    </div>
                </>
            )
        case 'hidden':
            return null
        case 'bool':
            return (
                <>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        {hint && (
                            <div className={styles.successContainer}>
                                <Alert severity="info">{parse(hint)}</Alert>
                            </div>
                        )}
                    </div>
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="radio"
                            label={title}
                            key={title}
                            fieldName={questionFieldName}
                            validationFunction={
                                required ? validateRadioField : undefined
                            }
                            cypressId={model}
                            radioOptionsData={[
                                {
                                    label: 'Yes',
                                    value: true,
                                },
                                {
                                    label: 'No',
                                    value: false,
                                },
                            ]}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                            fieldOptions={options}
                        />
                    </div>
                </>
            )
        case 'file-bool':
            return (
                <>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="file-bool"
                            label={title}
                            fieldName={questionFieldName}
                            validationFunction={
                                required ? validateRadioField : undefined
                            }
                            cypressId={model}
                            radioOptionsData={[
                                {
                                    label: 'Yes',
                                    value: 1,
                                },
                                {
                                    label: 'No',
                                    value: 0,
                                },
                            ]}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                    {getValueByModelName(model, values) > 0 && (
                        <>
                            <QuestionTitle
                                title={`After completing this questionnaire, please
                            upload ${options?.file_name}`}
                            />
                            <FileUpload
                                key={model?.split('.')?.[1]}
                                fileType={model?.split('.')?.[1]}
                                isUploadCenter={false}
                            />
                        </>
                    )}
                </>
            )
        case 'questions-set':
            return (
                <>
                    {!props?.isFieldArray && <QuestionTitle title={title} />}
                    <UiFormQuestionSet
                        questions={props?.questions}
                        otherProps={{ ...props }}
                    />
                </>
            )
        case 'checkbox-set':
            return (
                <>
                    <QuestionTitle title={title} />
                    <UiFormCheckboxSet {...props} />
                </>
            )
        case 'option':
            return (
                <>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiOptionsQuestions {...props} />
                    </div>
                </>
            )
        case 'questions-list':
        case 'questions-list-selectable':
            return (
                <div className={styles.singleQuestion}>
                    <QuestionTitle title={title} />
                    {hint && (
                        <div className={styles.successContainer}>
                            <Alert severity="info">{parse(hint)}</Alert>
                        </div>
                    )}
                    {title && (
                        <Divider
                            className={styles.dividerStyle}
                            variant="fullWidth"
                        />
                    )}
                    <UiFormFieldArray {...props} index={index} />
                </div>
            )
        case 'file-count':
        case 'file-increment':
            //ie. -irs_s_corp_approval

            const fileType = model?.split('.')?.[1]
            const numbVal = getValueByModelName(questionFieldName, values)
            const showFile = min != null ? numbVal > min : true

            if (type === 'file-count' && options?.defaultValue && !numbVal) {
                setFieldValue(questionFieldName, options?.defaultValue)
            }
            return (
                <>
                    <QuestionTitle title={title} />
                    {!options?.hidden && (
                        <div
                            className={styles.singleQuestionContainer}
                            key={questionFieldName}
                        >
                            <div className={styles.singleQuestion}>
                                <UiFormControlSelection
                                    fastField={true}
                                    type="number"
                                    showFloatingLabel={false}
                                    label={title}
                                    validationFunction={(value: any) =>
                                        validateNumberField(value, {
                                            ...props,
                                            vtoDetails: values,
                                        })
                                    }
                                    fieldName={questionFieldName}
                                    cypressId={model}
                                    initialHelperText={initialHelperText}
                                    inputBoxColor={inputBoxColor}
                                />
                            </div>
                        </div>
                    )}
                    {showFile && (
                        <>
                            <QuestionTitle
                                title={`After completing this questionnaire, please
                                upload ${options?.file_name}`}
                            />
                            <FileUpload
                                key={`${fileType}`}
                                fileType={fileType}
                                isUploadCenter={false}
                            />
                        </>
                    )}
                    {hint && (
                        <div className={styles.successContainer}>
                            <Alert severity="info">{parse(hint)}</Alert>
                        </div>
                    )}
                </>
            )
        case 'expenses':
            const req = isRequired || required;
            return (
                <div className={styles.singleQuestionContainer}>
                    <UiText weight="medium_500">{title}{req&&'*'}</UiText>
                    {hint && hasHint && (
                        <div className={styles.successContainer}>
                            <Alert severity="info">{parse(hint)}</Alert>
                        </div>
                    )}
                    <div className={styles.singleQuestion}>
                        <UiFormExpenses {...props} />
                    </div>
                </div>
            )
        case 'expenses-profit':
            return (
                <div className={styles.singleQuestion}>
                    <ExpensesProfit {...props} />
                </div>
            )
        case 'expenses-total':
            return (
                <div className={styles.singleQuestion}>
                    <ExpensesTotal {...props} />
                </div>
            )
        case 'percentage':
            return (
                <div className={styles.singleQuestionContainer}>
                    <QuestionTitle title={title} />
                    <div className={styles.singleQuestion}>
                        <UiFormControlSelection
                            fastField={true}
                            type="number"
                            showFloatingLabel={false}
                            defaultValue={null}
                            label={title}
                            validationFunction={(value: any) =>
                                validateNumberField(value, {
                                    ...props,
                                    vtoDetails: values,
                                })
                            }
                            fieldName={questionFieldName}
                            cypressId={model}
                            initialHelperText={initialHelperText}
                            inputBoxColor={inputBoxColor}
                        />
                    </div>
                </div>
            )
        case 'file-set':
            return (
                <>
                    <UiFileSetQuestions {...props} />
                </>
            )
        case 'state-fields':
            return <UiStateFieldQuestions {...props} />
        default:
            return <div></div>
    }
}

// Based on the update where its admin or user update the objects accordingly
const updateAnswer = (vto: any, key: any, source: 'user' | 'admin') => {
    if (source === 'user') {
        vto.user_answered_questions[key] = true

        if (vto?.admin_answered_questions?.[key]) {
            delete vto?.admin_answered_questions[key]
        }
        if (vto?.default_questions?.[key]) {
            delete vto?.default_questions[key]
        }
    } else if (source === 'admin') {
        vto.admin_answered_questions[key] = true

        if (vto?.user_answered_questions?.[key]) {
            delete vto?.user_answered_questions[key]
        }
        if (vto?.default_questions?.[key]) {
            delete vto?.default_questions[key]
        }
    }
}


const MappingHelperText = ({styles, label}: {styles: any, label: string}) => {
    return (
        <div className={styles.infoText}>
                <Icon icon="prefilledInput" />
                <div className="label">{`${TextMapping?.[label]}`}</div>
        </div>
    )
}


const getQuestionFieldLabelMapping = (
    dataOriginMap: any, 
    questionFieldName: string, 
    styles: any,
    values: any
) => {
    let initialHelperText: any = null
    let inputBoxColor: any = null

    if (NO_SHOW_HELPER_TEXT_FIELDS_PATTERNS.some(pattern => pattern.test(questionFieldName))) {
        return {
            initialHelperText,
            inputBoxColor
        }
      }
    
    if (dataOriginMap[questionFieldName]) {
        const label = dataOriginMap[questionFieldName]
        if (label === 'default' || label === 'prefilled') {
            initialHelperText = <MappingHelperText label={label} styles={styles}/>
            inputBoxColor = TextColorMapping?.[label]
        } else {
            initialHelperText = ''
            inputBoxColor = TextColorMapping?.[label]
        }
    } else {
        if(Object.keys(values?.default_questions).includes(questionFieldName)) {
            initialHelperText = <MappingHelperText label={'default'} styles={styles}/>
            inputBoxColor = TextColorMapping?.['default']
        }
    }
    return {
        initialHelperText,
        inputBoxColor
    }
}


const UiFormOptions = (props: any) => {
    const { options, type, isPartOfCheckboxSet } = props
    const {
        loading,
        setOpenFaqs,
        dataOriginMap,
        setCurrentStepFaqs,
        currentVtoConfig,
    } = useVtoContext()
    const { currentUser } = useCurrentStore()
    const { colorTheme } = useThemeContext()
    const { values, setFieldValue }: any = useFormikContext()
    const styles = useStyles(colorTheme)
    
    const accountantMode = currentUser?.accountant_mode
    const upsellOptions = options?.upsell
    const questionDefaultVal = options?.defaultValue
    const FaqsList = currentVtoConfig?.faq_list || {}

    const questionFieldName = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${props?.model}`
        : props?.model
    const currentQuestionVal = getValueByModelName(questionFieldName, values)  
    
    const handleUserInput = (event: any) => {
        const { name } = event.target
        if (name) {
            updateAnswer(values, name, accountantMode ? 'admin' : 'user')
        }
    }

    const handlUserSelect = (event: any) => {
        const { name } = event.target
        if (name) {
            updateAnswer(values, name, accountantMode ? 'admin' : 'user')
            
        }
    }

    const handleFAQClick = (id: string) => {
        setCurrentStepFaqs((prev: FAQ[]) =>
            prev.map((faq) =>
                faq.id === id
                    ? { ...faq, selected: true }
                    : { ...faq, selected: false }
            )
        )
        setOpenFaqs(true)
    }

    if(hasDefaultValue(questionDefaultVal) 
        && (currentQuestionVal === null || currentQuestionVal === undefined)) {
            values.default_questions[questionFieldName] = true
        dataOriginMap[questionFieldName] = 'default'
        setFieldValue(questionFieldName, questionDefaultVal)
    }

    let { initialHelperText, inputBoxColor } = getQuestionFieldLabelMapping(
        dataOriginMap,
        questionFieldName,
        styles,
        values,
    );

    const newProps = { ...props, type: type, initialHelperText, inputBoxColor, isPartOfCheckboxSet }
    const faqs = FaqsList[newProps?.options?.faq?.configKey]  

    
    return (
        <Fragment>
            {!loading && (
                <div
                    onChange={handleUserInput}
                    onClick={handlUserSelect}
                    className={styles.marginQuestion}
                >
                    <UiInputRendered {...newProps} />
                </div>
            )}
            {!hideUpsellAd(type, upsellOptions, values) && (
                <UpsellInfoCard upsell={options?.upsell} />
            )}
            {faqs && (
                <div className={styles.faqContainer}>
                    {faqs.map((faq) => (
                        <div
                            role="button"
                            className={styles.faqItem}
                            onClick={() => handleFAQClick(faq.id)}
                        >
                            <Icon size={'20px'} icon="info" />
                            <UiText weight="semi_bold_600" variant="moped_75">
                                {faq.question}
                            </UiText>
                        </div>
                    ))}
                </div>
            )}
        </Fragment>
    )
}

export default UiFormOptions
