import {
    Button,
    List,
    ListItem,
    makeStyles,
    Theme,
    Typography,
    Icon,
    Hidden,
    useMediaQuery,
    IconButton,
    FormControlLabel,
    Radio,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { commonStyles, mergeStyle, toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'
import { useEffect, useState } from 'react'
import ChargeoverIframeModal from '../../sales/ChargeoverIframeModal'

import UiModal from '../../common/ui/UiModal'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { connect, useDispatch } from 'react-redux'
import { loadProducts } from '../../../store/actions/products'
import { ApplicationStore, Product, Products } from '../../../models'
import { loadPostAuthData } from '../../../store/actions/appData'
import { useADPCompanyContext } from '../provider/CompanyProvider'
import { SETUP_TYPE } from '../../../models/adp-payroll/company'

const styles = makeStyles<Theme, ThemeColors>((theme) => ({
    content: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        height: '100%',
    },
    title: {
        fontSize: toRem16(34),
        lineHeight: toRem16(40),
        textAlign: 'center',
    },
    smTitle: {
        marginTop: toRem16(20),
        marginLeft: toRem16(20),
    },
    marginContent: {
        marginTop: toRem16(40),
    },
    title2: {
        fontSize: toRem16(20),
    },
    listRoot: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '1rem',
    },
    listItem: {
        width: '100%',
        marginLeft: toRem16(7),
        paddingTop: toRem16(7),
        paddingBottom: toRem16(7),
    },
    info: {
        width: '100%',
        fontSize: theme.breakpoints.down('sm') ? toRem16(12) : toRem16(18),
        textAlign: 'center',
    },
    info2: {
        width: '100%',
        marginTop: toRem16(12),
        textAlign: 'center',
    },
    separator: {
        width: '100%',
        textAlign: 'center',
        margin: toRem16(50),
    },
    checkImg: {
        width: toRem16(22),
    },
    btnMargin: {
        marginTop: toRem16(18),
        marginBottom: toRem16(5),
    },
    modalContainer: {
        width: toRem16(544),
        backgroundColor: (colorTheme) => `${colorTheme.primaryWhite}`,
        borderRadius: '0.5rem',
        textAlign: 'center',
        padding: '3rem',
        position: 'relative',
    },
    question: {
        marginBottom: '2rem',
    },
    crossIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        top: toRem16(10),
        right: toRem16(10),
    },
    checkboxBorder: {
        border: '0.125rem solid',
        borderRadius: toRem16(8),
        padding: `${toRem16(10)} ${toRem16(16)} ${toRem16(10)} ${toRem16(16)}`,
        marginBottom: toRem16(20),
        cusor: 'pointer',
    },
    greenBorderColor: {
        borderColor: (colorTheme) => colorTheme.tertiary,
    },
    greyBorderColor: {
        borderColor: (colorTheme) => colorTheme.grey200,
    },
}))

const radioGroupLabel = [
    {
        label: 'I am setting up my business’s payroll for the first time',
        value: SETUP_TYPE.NEW,
        cyelement: 'new-payroll',
    },
    {
        label: 'I already ran payroll for my business this calendar year',
        value: SETUP_TYPE.OLD,
        cyelement: 'already-running-payroll',
    },
]

const PAYROLL_PRODUCT = ['PRL-1', 'PAYROLL_SETUP_FEE']
/**
 * ['Payroll', 'Payroll Setup Fee']
 */

interface GettingStartedProps {
    setIsLoading: (value: boolean) => void
    product: Products
}

function GettingStarted(props: GettingStartedProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [openChargeoverModal, setOpenChargeoverModal] = useState(false)
    const [productTobePurchased, setProductTobePurchased] = useState<string[]>(
        []
    )
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const commonStyle = commonStyles()
    const dispatch = useDispatch()
    const { product, setIsLoading } = props
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    const { isNewOrOld, setIsNewOrOld, setIsPurchaseDone } =
        useADPCompanyContext()

    const utmContent = 'Additional+Products'
    const handleClose = (message?: string) => {
        setOpenChargeoverModal(false)
        setIsOpen(false)
        if (message === 'success') {
            setIsPurchaseDone(true)
            setIsLoading(true)
            setTimeout(() => {
                dispatch(loadPostAuthData())
                setIsLoading(false)
            }, 15 * 1000)
        }
    }

    useEffect(() => {
        dispatch(loadProducts('upsell_products=true'))
    }, [])
    const activeClass = mergeStyle(
        classes.checkboxBorder,
        classes.greenBorderColor
    )
    const nonActiveClass = mergeStyle(
        classes.checkboxBorder,
        classes.greyBorderColor
    )

    const payrollProductIs = (productCodes: string[]): string[] => {
        const productIds: string[] = []
        if (product.products.length) {
            product.products.forEach((productData: any) => {
                if (productIds.length < productCodes.length) {
                    if (productCodes.includes(productData.code)) {
                        productIds.push(productData.product_id)
                    }
                } else {
                    return productIds
                }
            })
        }
        return productIds
    }

    const handleNext = () => {
        const codeBasedOnAns =
            isNewOrOld === SETUP_TYPE.NEW
                ? PAYROLL_PRODUCT
                : [PAYROLL_PRODUCT[0]]
        setProductTobePurchased(payrollProductIs(codeBasedOnAns))
        setOpenChargeoverModal(true)
    }

    const checkIcon = (
        <Icon>
            <img
                alt={`link`}
                className={classes.checkImg}
                src={require(`../../../assets/icons-svg/checkIcon.svg`).default}
            />
        </Icon>
    )

    return (
        <div className={classes.content} data-cy={'getting-started-screen'}>
            <Hidden xsDown>
                <Typography
                    variant={smDevice ? 'subtitle1' : 'h2'}
                    className={smDevice ? classes.smTitle : classes.title}
                >
                    Get Payroll with 1-800Accountant
                </Typography>
            </Hidden>
            <Hidden smUp>
                <UiText
                    className={classes.smTitle}
                    variant="suv_150"
                    weight="bold_700"
                >
                    Get Payroll with <br />
                    1-800Accountant
                </UiText>
            </Hidden>

            <div
                className={
                    smDevice
                        ? ''
                        : mergeStyle(
                              commonStyle.flex,
                              commonStyle.justifyCenter
                          )
                }
            >
                <List className={classes.listRoot}>
                    <ListItem>
                        {checkIcon}
                        <UiText className={classes.listItem}>
                            Tailored with the right features for your needs
                        </UiText>
                    </ListItem>
                    <ListItem>
                        {checkIcon}
                        <UiText className={classes.listItem}>
                            Pay your employees in just a few clicks
                        </UiText>
                    </ListItem>
                    <ListItem>
                        {checkIcon}
                        <UiText className={classes.listItem}>
                            We file your payroll taxes automatically
                        </UiText>
                    </ListItem>
                </List>
            </div>
            <div
                className={mergeStyle(
                    commonStyle.flex,
                    commonStyle.justifyCenter
                )}
            ></div>
            <div
                className={mergeStyle(
                    commonStyle.flex,
                    commonStyle.justifyCenter,
                    classes.btnMargin
                )}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsOpen(true)}
                >
                    Get Payroll
                </Button>
            </div>
            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.info2}
            >
                Payroll is handled through ADP
            </Typography>
            <UiModal open={isOpen} handleClose={() => setIsOpen(false)}>
                <div className={classes.modalContainer}>
                    <div className={classes.crossIcon}>
                        <IconButton onClick={() => setIsOpen(false)}>
                            <Close />
                        </IconButton>
                    </div>
                    <UiText
                        variant="suv_150"
                        weight="medium_500"
                        className={classes.question}
                    >
                        Have you already run payroll for this business during
                        this calendar year?
                    </UiText>
                    <div className={classes.marginContent}>
                        {radioGroupLabel.map((radioButton) => (
                            <div
                                className={
                                    radioButton.value === isNewOrOld
                                        ? activeClass
                                        : nonActiveClass
                                }
                                onClick={() => {
                                    setIsNewOrOld(radioButton.value)
                                }}
                                data-cy={radioButton?.cyelement}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={
                                                radioButton.value === isNewOrOld
                                            }
                                            onChange={() => {
                                                setIsNewOrOld(radioButton.value)
                                            }}
                                            value={radioButton.value}
                                            classes={{
                                                root: classes.greenCheckbox,
                                            }}
                                        />
                                    }
                                    label={radioButton.label}
                                />
                            </div>
                        ))}
                    </div>
                    <UiButton
                        btnType="tertiary"
                        handleClick={handleNext}
                        label="Next"
                    />
                </div>
            </UiModal>
            <ChargeoverIframeModal
                open={openChargeoverModal}
                productIds={productTobePurchased}
                handleClose={handleClose}
                utmContent={utmContent}
            />
        </div>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    product: state.product,
})

export default connect(mapStateToProps)(GettingStarted)
