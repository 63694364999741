import { Hidden, Theme, makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useVtoContext } from '../vto/providers/VtoProvider'
import { mergeStyle, toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import useDeviceSize from '../../../hooks/useDeviceSize'
import LoadingAnimations from '../../common/LoadingAnimations'
import UiLottie from '../../common/ui/UiLottie'
import { ApiRequestHandler } from '../../../services/apiService/RequestService'
import { createVTO } from '../../../services/vto/vtoCore'
import { VTO_TYPES, VtoType } from '../vto/models/VtoDetails.model'
import {
    BANKING_ROUTE,
    BOOKKEEPING_SOFTWARES,
    BUSINESS_ROUTE,
    CREDENTIALS_ERRORS,
    SW_TO_CHECK,
} from '../vto/models/vto.const'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { getBankAccountGroupList } from '../../../services/apiService/bankAccount'
import {
    getAutoCategorizationProcessStatus,
    triggerSyncCategorizeTransactions,
} from '../../../services/transactionsService'
import Loader from '../../common/Loader'
import ExpediteOption from './initialSteps/ExpediteOption'
import GetVTOStarted from './initialSteps/GetVTOStarted'
import Welcome from './initialSteps/Welcome'
import {
    expediteFirstDeadline,
    expediteSecondDeadline,
    getAlreadyVisitedData,
    hasExpeditedPermission,
} from '../vto/common/VtoUtils'
import { CollectSecondaryPaymentMethod } from './CollectSecondaryPaymentMethod'
import {
    getCreditCardCount,
    getCustomerPortalLoginUrl,
} from '../../../services/vto/vtoSubmit'
import { CardReminderDialog } from '../personal-tax-information/PersonalTaxIntro'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../store/actions/feedback'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        toSStyle: {
            color: (colorTheme) => colorTheme.black100,
            maxWidth: '100%',
            // height: '98%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            '& button': {
                maxWidth: '360px',
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
                height: 'auto',
                maxWidth: '100%',
                margin: '1rem auto 0 auto',
                padding: '1rem',
                '& button': {
                    maxWidth: '100%',
                },
            },
        },
        borderTopSm: {
            [theme.breakpoints.down('sm')]: {
                borderTop: (colorTheme) => `1px solid ${colorTheme.black100}`,

            },
        },
        bookkeepingCta: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyItems: 'center',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '2rem'
            },

            '& > button': {
                flex: 1,
                justifyContent: 'space-between',
            },

            [theme.breakpoints.up('sm')]: {
                maxWidth: '328px',
                margin: '0 auto',
            },
        },
        prevBtnContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& > button': {
                position: 'absolute',
                maxWidth: 'max-content',
                top: toRem16(60)
            },

            [theme.breakpoints.down('sm')]: {
                '& > button': {
                position: 'static',
                margin: '1rem 0 0 1rem',
            },
            }
        },
        fixedButtonContainer: {
            '& button span': {
                fontWeight: 600,
            },
            '& .tertiary-btn:disabled': {
                background: (colorTheme) => colorTheme.primary,
                opacity: '0.25',
            },
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            },
        },
        container: {
            maxWidth: '640px',
            margin: '0 auto',
            color: (colorTheme) => colorTheme.black100,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                textAlign: 'left',
            },
        },
        spacer: {
            height: '60px',
        },
        link: {
            color: (colorTheme) => colorTheme.blue200,
        },
        svg: {
            '& path': {
                fillOpacity: 1,
            },
        },
        progressBarContainer: {
            flex: 1,
            '& > div': {
                translate: '0 -0.5rem',
                maxWidth: toRem16(640),
                margin: '0 auto',
            },
        },
        mb40: {
            marginBottom: toRem16(40),
        },
        mt32: {
            marginTop: '2rem',
        },
        mb32: {
            marginBottom: '2rem',
        },
        my16: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        mxauto: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        fullWidth: {
            width: '100%',
        },
        smFullHeight: {
            [theme.breakpoints.down('sm')]:{
                height: '100%'
            }
        }
    }
})

export const destinations = {
    addNewCard: 'paymethods-creditcard',
    updateCard: 'paymethods',
}

export enum needToCollect {
    ZERO = 0,
    ONE = 1,
    TWO = 2,
}

const BusinessTaxIntro = () => {
    const [tosAccepted, setTosAccepted] = useState(false)
    const [step, setStep] = useState(1)
    const [bookkeepingStatus, setBookkeepingStatus] = useState<
        | 'idle'
        | 'loading'
        | 'syncing-transactions'
        | 'update-credentials'
        | 'no-connected-bank'
        | 'success'
    >('idle')
    const [vtoId, setVtoId] = useState('')
    const [selectedSoftware, setSelectedSoftware] = useState('')
    const [selectedExpediteProduct, setSelectedExpediteProduct] = useState<
        'expedite_upsell_opted' | 'extension_upsell_opted'
    >()
    const [cardReminderModal, setCardReminderModal] = useState<boolean>(false)
    const [apiLoading, setApiLoading] = useState<boolean>(false)
    const [expeditedConsent, setExpeditedConsent] = useState<boolean>(false)
    const [collectCard, setCollectCard] = useState<needToCollect>(
        needToCollect.ZERO
    )
    const [isCardCollected, setIsCardCollected] = useState<boolean>(false)
    const [fetchingURL, setFetchingURL] = useState<boolean>(false)
    const [cardBeforePaymentPage, setCardBeforePaymentPage] = useState<Number>()

    const {
        selectedYear,
        setCurrentVtoDetails,
        currentVtoDetails,
        setShowYearDropdown,
        updateVTO,
        refetchCurrentYearMetaData,
        isBusinessVTO,
    } = useVtoContext()
    const history = useHistory()

    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)
    const dispatch = useDispatch()
    const { isMobileDevice } = useDeviceSize()
    const {
        currentAccountId,
        currentBusinessId,
        currentAccount,
        currentUser,
        personalAccount,
    } = useCurrentStore()

    const isAdmin = currentUser?.accountant_mode
    const businessType = currentAccount?.business?.business_type
    const industry = currentAccount?.business?.industry

    const nextPageButton = () => {
        return (
            <div className={styles.fixedButtonContainer}>
                <UiButton
                    fullWidth
                    btnType="tertiary"
                    disabled={apiLoading}
                    endIcon={<ArrowForward />}
                    handleClick={handleNext}
                    label="Next"
                />
            </div>
        )
    }

    const shouldRenderExpeditedPage = () => {
        if (selectedYear === 2024 && !hasExpeditedPermission(isBusinessVTO)) {
            const first = expediteFirstDeadline(businessType, industry, isBusinessVTO)
            const second = expediteSecondDeadline(businessType, industry, isBusinessVTO)
            if (!first.hasToShow && !second.hasToShow) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const shouldRenderPaymentPage = () => {
        if (
            collectCard === needToCollect.ZERO ||
            isCardCollected ||
            getAlreadyVisitedData(personalAccount.id)
        ) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        if (step === 1) {
            setShowYearDropdown(true)
        } else {
            setShowYearDropdown(false)
        }

        const scrollDemo = document.getElementById('content-scroll')!;
        if (scrollDemo) {
            scrollDemo.scrollTo({ top: 0 });
          }
    }, [step])

    const handleNext = () => {
        if (step === 2) {
            if (shouldRenderPaymentPage()) {
                setStep(3)
            } else if (shouldRenderExpeditedPage()) {
                setStep(4)
            } else {
                setStep(5)
            }
            return
        } else if (step === 3) {
            if (shouldRenderExpeditedPage()) {
                setStep(4)
            } else {
                setStep(5)
            }
            return
        }
        setStep((prev) => prev + 1)
    }

    const handlePrev = () => {
        if (step === 5) {
            if (shouldRenderExpeditedPage()) {
                setStep(4)
            } else if (shouldRenderPaymentPage()) {
                setStep(3)
            } else {
                setStep(2)
            }
            return
        } else if (step === 4) {
            if (shouldRenderPaymentPage()) {
                setStep(3)
            } else {
                setStep(2)
            }
            return
        }
        setStep((prev) => prev - 1)
    }

    const checkCallectCardCase = () => {
        getCreditCardCount()
            .then((response: any) => {
                const { total_cards, total_expired_cards, success } = response
                setCardBeforePaymentPage(total_cards)
                if (
                    (total_cards === 0 ||
                        total_expired_cards === total_cards) &&
                    success
                ) {
                    setCollectCard(needToCollect.ONE)
                    getChargoverLogicURL()
                } else if (total_cards === 1 && total_expired_cards === 0) {
                    setCollectCard(needToCollect.TWO)
                } else if (total_cards === 2 && total_expired_cards === 1) {
                    setCollectCard(needToCollect.TWO)
                } else {
                    setCollectCard(needToCollect.ZERO)
                }
            })
            .catch((err: any) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err?.statusText || 'Something went wrong',
                    })
                )
            })
    }

    const getChargoverLogicURL = () => {
        setFetchingURL(true)
        getCustomerPortalLoginUrl({
            destination: destinations.addNewCard,
        })
            .then((data: any) => {
                setFetchingURL(false)
            })
            .catch((error: any) => {
                if (error.status === 400) {
                    setCollectCard(needToCollect.ZERO)
                } else {
                    dispatch(
                        showAlert({
                            alertType: 'error',
                            alertText:
                                error?.statusText || 'Something went wrong',
                        })
                    )
                }
            })
    }

    useEffect(() => {
        checkCallectCardCase()
    }, [])

    const getBankAccounts = (software: string) => {
        setBookkeepingStatus('syncing-transactions')
        getBankAccountGroupList(currentAccountId, currentBusinessId).then(
            (data) => {
                let aggregatedBankAcc = []

                for (const bankGroup of data) {
                    for (const acc of bankGroup.bank_accounts) {
                        if (
                            (acc.type === 'aggregated' &&
                                CREDENTIALS_ERRORS.includes(
                                    acc.aggregation_status.error
                                )) ||
                            (acc.type === 'plaid' && acc.error_code)
                        ) {
                            return setBookkeepingStatus('update-credentials')
                        } else if (!acc.disconnected && !acc.disconnected_at) {
                            aggregatedBankAcc.push(acc)
                        }
                    }
                }

                aggregatedBankAcc = aggregatedBankAcc.filter(Boolean)

                if (aggregatedBankAcc.length === 0) {
                    setBookkeepingStatus('no-connected-bank')
                } else {
                    handleTransactionSync(software)
                }
            }
        )
    }

    const handleTransactionSync = (software: string) => {
        getAutoCategorizationProcessStatus(
            currentAccountId,
            currentBusinessId
        ).then((res: any) => {
            if (!res.status) {
                triggerSyncCategorizeTransactions(
                    currentAccountId,
                    currentBusinessId
                ).then(() => setBookkeepingStatus('success'))
            }
        })
    }

    const handleSoftwareClick = (software: string) => {
        setSelectedSoftware(software)
        if (SW_TO_CHECK.includes(software)) {
            getBankAccounts(software)
        } else {
            setBookkeepingStatus('success')
        }
    }

    const createUpdateVto = (software: string) => {
        if (vtoId) {
            setBookkeepingStatus('loading')
            let payload: any = {
                business: {
                    software_for_bookkeeping: software,
                },
            }
            updateVTO(vtoId, { ...currentVtoDetails, ...payload }, () => {})
        } else {
            createVto(software)
        }
    }

    const createVto = (softwareUsed: string) => {
        setBookkeepingStatus('loading')
        let payload: any = {
            type: VtoType[VTO_TYPES.BUSINESS],
            is_uncategorised_transaction_check: 1,
            business: {
                software_for_bookkeeping: softwareUsed,
            },
            expedite_or_extension_upsell_consent: expeditedConsent,
        }
        if (selectedExpediteProduct) {
            payload[selectedExpediteProduct] = true
        }

        ApiRequestHandler(
            createVTO(selectedYear, payload).then((response: any) => {
                if (response) {
                    setBookkeepingStatus('success')
                    updateVTO(response.id, { ...response }, () => {
                        refetchCurrentYearMetaData()
                        moveToNext(response.id)
                    })
                    setVtoId(response.id)
                } else {
                    setCurrentVtoDetails(undefined)
                }
            })
        )
    }

    const moveToNext = (vtoId: string) => {
        history.push(`${BUSINESS_ROUTE}/${selectedYear}/${vtoId}`)
    }

    const redirectToBanking = () => {
        const url = history.createHref({ pathname: BANKING_ROUTE })
        window.open(url, '_blank')
        setBookkeepingStatus('idle')
    }

    const handleCheckCardCount = () => {
        setApiLoading(true)
        getCreditCardCount()
            .then((response: any) => {
                if (Number(response.total_cards) === collectCard) {
                    setIsCardCollected(true)
                    handleNext()
                } else {
                    setCardReminderModal(true)
                }
                setApiLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const ResultComponent = ({
        animationData,
        title,
        desc,
        customDescNode,
        btnLabel,
        btnIcon,
        linkLabel,
        handleBtnClick,
    }: any) => (
        <div>
            <UiLottie lottieData={animationData} />
            <div className={mergeStyle(styles.mt32, styles.mb40)}>
                <UiText variant="suv_150" weight="bold_700">
                    {title}
                </UiText>
                {customDescNode ? (
                    customDescNode
                ) : (
                    <UiText className={styles.my16}>{desc}</UiText>
                )}
            </div>
            {isMobileDevice && bookkeepingStatus === 'success' ? null : (
                <UiButton
                    fullWidth
                    btnType="tertiary"
                    endIcon={btnIcon ? <ArrowForward /> : null}
                    handleClick={handleBtnClick}
                    customLabel={
                        <UiText weight="semi_bold_600">{btnLabel}</UiText>
                    }
                />
            )}
            {linkLabel && (
                <div className={mergeStyle(styles.fullWidth, styles.mt32)}>
                    <UiButton
                        fullWidth
                        btnType="hyperlink"
                        customLabel={<UiText weight='semi_bold_600'>{linkLabel}</UiText>}
                        handleClick={() => setBookkeepingStatus('idle')}
                    />
                </div>
            )}

            <Hidden mdUp>
                {bookkeepingStatus === 'success' && (
                    <div>
                            <UiButton
                                fullWidth
                                btnType="tertiary"
                                handleClick={() =>
                                    createUpdateVto(selectedSoftware)
                                }
                                label="Next"
                            />
                    </div>
                )}
            </Hidden>
        </div>
    )

    const showPrevFloatingButton = isMobileDevice ? step >= 3 : step !== 1;
    return (
        <>
                {showPrevFloatingButton && (
                    <div className={styles.prevBtnContainer}>
                        <UiButton
                            btnType="hyperlink"
                            label="Previous"
                            handleClick={() => {
                                if (
                                    step === 5 &&
                                    bookkeepingStatus !== 'idle'
                                ) {
                                    setBookkeepingStatus('idle')
                                } else {
                                    handlePrev()
                                }
                            }}
                            icon={<ArrowBack />}
                        />
                    </div>
                )}

            <div
                className={`${styles.toSStyle} ${step === 1 ? styles.borderTopSm : ''} ${step === 3 ? styles.smFullHeight : ''}`}
            >
                {step === 1 && (
                    <GetVTOStarted
                        tosAccepted={tosAccepted}
                        setTosAccepted={setTosAccepted}
                        handleNext={handleNext}
                    />
                )}

                {step === 2 && (
                    <Welcome step={step} nextPageButton={nextPageButton} />
                )}

                {step === 3 && (
                    <CollectSecondaryPaymentMethod
                        handleCheckCardCount={handleCheckCardCount}
                        collectCard={collectCard}
                        fetchingURL={fetchingURL}
                        bypassSecondaryPayment={
                            personalAccount?.bypass_secondary_payment
                        }
                        isAdmin={isAdmin}
                        handleNext={handleNext}
                        cardBeforePaymentPage={cardBeforePaymentPage}
                    />
                )}

                {step === 4 && (
                    <ExpediteOption
                        handleNextPage={() =>
                            setStep((prev: number) => prev + 1)
                        }
                        businessType={businessType}
                        industry={industry}
                        setSelectedExpediteProduct={setSelectedExpediteProduct}
                        expeditedConsent={expeditedConsent}
                        setExpeditedConsent={setExpeditedConsent}
                        handlePrev={handlePrev}
                    />
                )}

                {step === 5 && (
                    <div className={styles.container}>
                        {bookkeepingStatus === 'idle' && (
                            <div>
                                <div className={styles.mb32}>
                                    <UiText variant="suv_150" weight="bold_700">
                                        What software did you use to prepare
                                        your bookkeeping this year?
                                    </UiText>
                                    <UiText
                                        className={mergeStyle(
                                            styles.my16,
                                            styles.mxauto
                                        )}
                                    >
                                        This helps us to identify any deductions
                                        available{' '}
                                        <Hidden smDown>
                                            <br />
                                        </Hidden>
                                        and can help speed up the preparation
                                        process.
                                    </UiText>
                                </div>
                                <div className={styles.bookkeepingCta}>
                                    {BOOKKEEPING_SOFTWARES.map((method) => (
                                        <UiButton
                                            handleClick={() =>
                                                handleSoftwareClick(method.key)
                                            }
                                            btnType="secondary"
                                            customLabel={
                                                <UiText
                                                    variant="motorcycle_90"
                                                    weight="semi_bold_600"
                                                >
                                                    {method.title}
                                                </UiText>
                                            }
                                            endIcon={<ArrowForward />}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                        {bookkeepingStatus === 'loading' && (
                            <div className={styles.mt32}>
                                <Loader />
                            </div>
                        )}
                        {bookkeepingStatus === 'syncing-transactions' && (
                            <div className={styles.mt32}>
                                <LoadingAnimations description="Syncing your transactions..." />
                            </div>
                        )}
                        {bookkeepingStatus === 'update-credentials' && (
                            <ResultComponent
                                animationData={require('../../../assets/animations/Grimacing.json')}
                                title="We’re having trouble syncing your transactions"
                                desc="Update your credentials so we can securely connect to your bank."
                                btnLabel="Update Credentials"
                                linkLabel="Choose a different bookkeeping method"
                                handleBtnClick={redirectToBanking}
                            />
                        )}

                        {bookkeepingStatus === 'no-connected-bank' && (
                            <ResultComponent
                                animationData={require('../../../assets/animations/Monocle.json')}
                                title="We don’t see a connected bank, but don’t worry!"
                                customDescNode={
                                    <UiText className={styles.my16}>
                                        If you want to use ClientBooks for your
                                        bookkeeping,{' '}
                                        <Hidden smDown>
                                            <br />
                                        </Hidden>
                                        you’ll need to connect an account.
                                    </UiText>
                                }
                                btnLabel="Connect a Bank Account"
                                linkLabel="Choose a different bookkeeping method"
                                handleBtnClick={redirectToBanking}
                            />
                        )}

                        {bookkeepingStatus === 'success' && (
                            <ResultComponent
                                animationData={require('../../../assets/animations/Thumsup.json')}
                                title="Great, you’re good to go!"
                                desc="You’ll easily be able to add your
                            bookkeeping data."
                                btnLabel="Next"
                                btnIcon
                                handleBtnClick={() =>
                                    createUpdateVto(selectedSoftware)
                                }
                            />
                        )}
                    </div>
                )}
            </div>
            <CardReminderDialog
                isOpen={cardReminderModal}
                handleClose={() => setCardReminderModal(false)}
            />
        </>
    )
}

export default BusinessTaxIntro
