import { Fragment, useEffect, useState } from 'react'
import { Divider, Hidden, Theme, makeStyles } from '@material-ui/core'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../styles/commonStyles'
import UiFormOptions from '../form-controls/UiFormOptions'
import {
    collectFaqs,
    getStepModel,
    hasConditionMatched,
    hasUpsellConditionMatched,
    isBalanceSheetSection,
} from '../../../common/VtoUtils'
import { useFormikContext } from 'formik'
import { useVtoContext } from '../../../providers/VtoProvider'
import {
    CHOICE_TYPE_QUESTION,
    SET_TYPE_QUESTION,
} from '../../../common/questions.const'
import UiText from '../../../../../common/ui/UiText'
import { deepCopy } from '../../../../../../utils/appUtil'
import { CLIENTBOOKS_SOFTWARE, MULTI_STEP, StepTitleMapping } from '../../../models/vto.const'
import { Alert } from '@material-ui/lab'
import { getStateFields } from '../../../common/VtoHelpers'
import UiButton from '../../../../../common/ui/UiButton'
import Icon from '../../../../../common/Icon'
import VTOUncategorizedTransactionModal from '../modals/VTOUncategorizedTransactionModal'
import useModal from '../../../../../common/hooks/useModal'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        container: {
            margin: '1.5rem 0 0 1rem',
            paddingRight: '2rem',

            '& .MuiTextField-root': {
                '& .MuiOutlinedInput-root.Mui-disabled':{
                    backgroundColor: colorTheme => colorTheme.grey2600
                }
            },

            [theme.breakpoints.down('sm')]: {
                margin: '1.5rem 0 0 0',
                paddingRight: 0
            }
        },
        alertContainer: {
            maxWidth: toRem16(480),
            marginBottom: '1.5rem',
            '& .MuiAlert-message': {
                color: (colorTheme) => `${colorTheme.black100}`,
            },
        },
        sectionHeader: {
            marginBottom: '2rem',
        },
        dividerStyle: {
            margin: '1rem 0',
        },
        bookkeepingActionBtnWrapper: {
            margin: '1rem 0',
            '& > .MuiAlert-root': {
                margin: '0.5rem 0',
            },
        },
        my16: {
            marginBottom: '1rem',
            marginTop: '1rem',
        },
        mb8: {
            marginBottom: '0.5rem',
        },
    }
})

const BookkeepingImportContent = ({
    styles,
    businessVTO,
    open,
    closeModal,
    openModal
}: any) => {
    return (
        <>
            <div className={styles.bookkeepingActionBtnWrapper}>
                {!businessVTO?.is_bookkeeping_data_imported ? (
                    <div>
                        <UiText className={styles.my16}>
                            Because you used ClientBooks to prepare your bookkeeping, you
                            can import your data directly into the tax organizer.
                        </UiText>
                        <UiButton
                            customClass={styles.mb8}
                            btnType="tertiary"
                            label={"Import Bookkeeping Data"}
                            handleClick={openModal}
                        />
                    </div>
                ) : (
                    <div>
                        <Alert classes={{ root: styles.alertRoot }} severity="success">
                            Your bookkeeping data was imported directly from ClientBooks.
                        </Alert>
                        <UiText className={styles.my16}>
                            Have you made changes to your books? Update your bookkeeping
                            data to make sure you have the latest.
                        </UiText>
                        <UiButton
                            icon={<Icon icon="refresh" />}
                            customClass={styles.mb8}
                            btnType="secondary"
                            customLabel={
                                <UiText weight="semi_bold_600">
                                    Update Bookkeeping Data
                                </UiText>
                            }
                            handleClick={openModal}
                        />
                    </div>
                )}
            </div>

            <VTOUncategorizedTransactionModal
                open={open}
                handleClose={closeModal}
            />
        </>
    )
   ;
} 

export default function SectionSummary(props: any) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { values, validateForm }: { values: any, validateForm: ()=> void } = useFormikContext()
    const { currentYearMetaData, parentStep, setCurrentStepFaqs, currentVtoConfig, currentVtoDetails } = useVtoContext()
    const [questionsData, setQuestionsData] = useState<any>([])
    const FaqsList = currentVtoConfig?.faq_list || {}
    const sectionData = currentYearMetaData?.formattedSections?.[props?.section]
    const sectionQuestions = sectionData?.questions
    const isMultiStep = parentStep?.type === MULTI_STEP
    const stateFields = getStateFields(values);
    const isBusinessVto = currentVtoDetails?.business
    const { open, openModal, closeModal} = useModal()

    const { stepModel, stepIndex } = getStepModel(parentStep)

    useEffect(() => {
        const sectionFormValues = isMultiStep
            ? values?.[stepModel][stepIndex]
            : values
        const allQuestions = deepCopy(sectionQuestions)

        const filteredQuestions = allQuestions?.filter((item: any) => {
            const parentShowCondition = item?.show_condition
            const matchType = item?.options?.show_condition_or ? 'some' : 'all'
            const showConditionMatched = hasConditionMatched(
                parentShowCondition,
                sectionFormValues,
                null,
                matchType
            )
            if (item?.type === SET_TYPE_QUESTION && showConditionMatched) {
                item.questions = item.questions.filter((question: any) => {
                    let showCondition = question.show_condition
                    let upsellCondition = question.options?.upsell
                    if (
                        question.type === CHOICE_TYPE_QUESTION &&
                        upsellCondition
                    ) {
                        return hasUpsellConditionMatched(
                            upsellCondition,
                            sectionFormValues
                        )
                    }
                    return hasConditionMatched(showCondition, sectionFormValues)
                })
                return item.questions?.length > 0
            } else {
                const upsellCondition = item.options?.upsell
                if (
                    item.type === CHOICE_TYPE_QUESTION &&
                    showConditionMatched
                ) {
                    return hasUpsellConditionMatched(
                        upsellCondition,
                        sectionFormValues
                    )
                }
                return showConditionMatched
            }
        })
        const faqs = collectFaqs(filteredQuestions, FaqsList, stateFields);
        setCurrentStepFaqs(faqs)
        setQuestionsData(filteredQuestions)
    }, [isMultiStep, sectionQuestions, stepIndex, stepModel, values])


    return (
        <div className={styles.container}>
            {sectionData?.description && 
                <Alert severity='info' className={styles.sectionHeader}>
                    {sectionData?.description}
            </Alert>}

            <Hidden smDown>
                <UiText
                    className={styles.sectionHeader}
                    variant="suv_150"
                    weight="semi_bold_600"
                >
                    {StepTitleMapping[props?.section] ? StepTitleMapping?.[props?.section] : sectionData?.title}
                </UiText>
            </Hidden>
            {
                // show bookkeeping import content only for balance sheet section and 
                // if clientbooks software is used
                isBalanceSheetSection(sectionData) && 
                    values?.business?.software_for_bookkeeping === CLIENTBOOKS_SOFTWARE && (
                    <BookkeepingImportContent
                        styles={styles}
                        businessVTO={isBusinessVto}
                        open={open}
                        closeModal={closeModal}
                        openModal={openModal}
                    />
                )
            }
            {questionsData?.map((question: any, index: number) => {
                if (question?.type === SET_TYPE_QUESTION) {
                    return (
                        <div key={`${question.type}-${index}`}>
                            {question?.options?.divided && (
                                <Divider
                                    variant="fullWidth"
                                    className={styles.dividerStyle}
                                />
                            )}
                            {question?.title && <UiText>{question?.title}</UiText>}
                            {question?.questions?.map(
                                (subQuestion: any, subIndex: number) => (
                                    <Fragment
                                        key={`${subIndex}-${subQuestion.type}`}
                                    >
                                        <UiFormOptions
                                            {...subQuestion}
                                            isSummaryPage={true}
                                            {...(isMultiStep
                                                ? {
                                                      parentModel: stepModel,
                                                      index: stepIndex,
                                                  }
                                                : {})}
                                        />
                                    </Fragment>
                                )
                            )}
                        </div>
                    )
                } else {
                    return (
                        <Fragment key={`${index}`}>
                            {(question?.options?.divided || (isBalanceSheetSection(sectionData) && index !== 0)) && (
                                <Divider
                                    variant="fullWidth"
                                    className={styles.dividerStyle}
                                />
                            )}
                            <UiFormOptions
                                {...question}
                                isSummaryPage={true}
                                {...(isMultiStep
                                    ? {
                                          parentModel: stepModel,
                                          index: stepIndex,
                                      }
                                    : {})}
                            />
                        </Fragment>
                    )
                }
            })}
        </div>
    )
}
