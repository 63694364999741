import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import UiDialog from '../common/ui/UiDialog';
import MyloAdEmbedded from './Cards/MyloAdEmbedded'
import { useCurrentStore } from '../common/hooks/useCurrentStore';

function MyloAdModal({ open, handleClose }: any) {
    const { currentUser } = useCurrentStore()
    const classes = makeStyles((theme: Theme) => ({
        container: {
            '& .MuiDialogTitle-root': {
                borderBottom: 'none',
                padding: '15px 45px 0px'
            }
        }
    }))();
    
    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title=''
            size='lg'
            customRootClass={classes.container}
        >
             <MyloAdEmbedded xcid={currentUser?.username} />
        </UiDialog>
    );
};

export default MyloAdModal;