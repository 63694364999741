import { makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ReactComponent as TiltedLabel } from '../../../../../../../assets/icons-svg/TiltedLabel.svg'
import UiText from '../../../../../../common/ui/UiText'
import ProductList from './ProductList'
import UiButton from '../../../../../../common/ui/UiButton'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(16),
            padding: toRem16(16),
            marginTop: toRem16(24),
        },
        productItem: {
            marginTop: toRem16(24),
        },
        spacingSm: {
            marginTop: '1rem',
        },
        centerBox: {
            textAlign: 'center',
        },
        flexBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        originalPrice: {
            textDecoration: 'line-through',
        },
        discount: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: toRem16(8),
            marginBottom: toRem16(16),
        },
        btnContainer: {
            margin: `${toRem16(16)} 0`,
        },
    }
})

export const SelectedPackage = ({
    selectedPackage,
    handleSubmit,
    submitDisabled,
}: any) => {
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    const { discountedPrice, totalPrice } = selectedPackage

    const packagePrice = () => {
        return roundOff(totalPrice, 12)
    }

    const roundOff = (price: number, dividedBy = 1) => {
        return Math.round(Number(price / dividedBy))
    }

    return (
        <div className={styles.container}>
            {selectedPackage && (
                <>
                    <UiText
                        className={styles.spacingSm}
                        textAlign="center"
                        weight="bold_700"
                    >
                        {selectedPackage.packageTitle}
                    </UiText>
                    <UiText className={styles.spacingSm}>
                        {selectedPackage.extraInfo}
                    </UiText>
                    <div className={styles.productItem}>
                        {selectedPackage.products.map((product: any) => (
                            <ProductList product={product} />
                        ))}
                    </div>
                    {handleSubmit && (
                        <div className={styles.centerBox}>
                            <div className={styles.flexBox}>
                                <UiText variant="suv_150" weight="bold_700">
                                    ${roundOff(discountedPrice, 12)}
                                </UiText>
                                <UiText
                                    weight="bold_700"
                                    className={styles.frequency}
                                >
                                    /month
                                </UiText>
                            </div>
                            <div>
                                <UiText className={styles.originalPrice}>
                                    ${packagePrice()}/month
                                </UiText>
                            </div>
                            <UiText
                                variant="motorcycle_90"
                                weight="medium_500"
                                textColor="primary"
                            >
                                <span className={styles.discount}>
                                    <TiltedLabel /> Save 20% when you purchase
                                    today
                                </span>
                            </UiText>
                            <UiText textColor="primary">
                                <span>{`Billed annually. Total due today $${roundOff(
                                    discountedPrice
                                )}`}</span>
                            </UiText>
                            <div className={styles.btnContainer}>
                                <UiButton
                                    btnType="tertiary"
                                    label={'Submit & Add Upgrades'}
                                    customClass={styles.btn}
                                    handleClick={handleSubmit}
                                    disabled={submitDisabled}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
