import { useState } from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import UiDialog from '../../../../../../common/ui/UiDialog'
import UiText from '../../../../../../common/ui/UiText'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { vtoRenewSubscription } from '../../../../../../../services/vto/vtoSubmit'
import { useVtoContext } from '../../../../providers/VtoProvider'
import UiButton from '../../../../../../common/ui/UiButton'
import Loader from '../../../../../../common/Loader'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../../../store/actions/feedback'
import { loadPostAuthData } from '../../../../../../../store/actions/appData'
import { excluded } from './WithPackages'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        earlyRenewal: {
            display: 'flex',
            flexDirection: 'column',
            gap: toRem16(32),
            alignItems: 'center',
            marginBottom: toRem16(16),
        },
        dialogContainer: {
            height: '550px',
        },
        blueText: {
            textDecoration: 'none',
            color: (colorTheme) => colorTheme.blue200,
            cursor: 'pointer',
        },
    }
})

const EarlyRenewalModal = (props: any) => {
    const [purchaseUrl, setPurchaseUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const { closeRenewalModal, renewaldata, submitValues } = props
    const dispatch = useDispatch()
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    const { selectedYear, isBusinessVTO }: any = useVtoContext()
    const contactTeam1800 = '1-800-222-6868'

    const handleRenew = () => {
        const vtoYear = selectedYear
        const utmSource = 'Portal'
        const utmTerm = 'Client'
        const utmMedium = 'Client+Portal'
        const utmContent = isBusinessVTO
            ? 'Business VTO Early Renewal'
            : 'Personal VTO Early Renewal'
        const utmParams = `?utm_source=${utmSource}&utm_term=${utmTerm}&utm_medium=${utmMedium}&utm_content=${utmContent}`
        setLoading(true)
        vtoRenewSubscription(vtoYear, isBusinessVTO, utmContent)
            .then((data: any) => {
                setLoading(false)
                if (data.code && data.code === 400) {
                    closeRenewalModal()
                } else {
                    const hasTopay = data.need_to_pay
                    const hasPaymentDone = data.payment_successful
                    setPurchaseUrl(data.payment_link)
                    /** card add & checkout flow */
                    if (hasTopay && !hasPaymentDone) {
                        const redirectUrl = data.payment_link + utmParams
                        setPurchaseUrl(redirectUrl)
                    }
                    /** auto checkout flow */
                    if (!hasTopay && hasPaymentDone) {
                        dispatch(
                            showAlert({
                                alertType: 'success',
                                alertText: `Your subscription is successfully renewed`,
                            })
                        )
                        submitValues(renewaldata.formValues)
                        closeRenewalModal()
                        setTimeout(dispatch(loadPostAuthData()), 10 * 1000)
                        props.setExcludedProdut(
                            isBusinessVTO ? excluded.BTP : excluded.PTP
                        )
                    }
                }
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err?.message || `Renewal payment failed`,
                    })
                )
                setLoading(false)
                handleSaveBeforeClosing()
            })
    }

    const handleSaveBeforeClosing = () => {
        closeRenewalModal()
        submitValues(renewaldata.formValues, true)
    }

    return (
        <UiDialog
            open={renewaldata.isModalOpen}
            handleClose={handleSaveBeforeClosing}
            title="Renew Subscription"
            size="sm"
            disableBackdropClick
            customContentStyle={styles.dialogContainer}
        >
            <>
                {purchaseUrl ? (
                    <iframe
                        src={purchaseUrl}
                        title="Renew Subscription"
                        style={{ width: '99%', height: '100%' }}
                    ></iframe>
                ) : (
                    <div className={styles.earlyRenewal}>
                        <UiText
                            textAlign="center"
                            variant="truck_175"
                            weight="medium_500"
                        >
                            Your subscription renewal has not yet been processed
                        </UiText>
                        <UiText>
                            Your subscription is set to automatically renew on{' '}
                            {renewaldata.renewalDate} but if you would like to
                            work on your taxes now, simply renew a little early.
                        </UiText>
                        <div>
                            {loading ? (
                                <Loader />
                            ) : (
                                <UiButton
                                    btnType="tertiary"
                                    label="Renew Your Subscription Now"
                                    handleClick={handleRenew}
                                />
                            )}
                        </div>
                        <UiText textAlign="center" variant="motorcycle_90">
                            <span>
                                {`Only your ${
                                    isBusinessVTO ? 'Business' : 'Personal'
                                } Tax Preparation subscription will be renewed.`}{' '}
                            </span>
                            <span>
                                If you would like assistance with this
                                transaction, please contact our billing
                                department at{' '}
                            </span>
                            <a
                                href={`tel:${contactTeam1800}`}
                                className={styles.blueText}
                            >
                                {contactTeam1800}
                            </a>
                            .
                        </UiText>
                    </div>
                )}
            </>
        </UiDialog>
    )
}

export default EarlyRenewalModal
