import { Hidden, Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useVtoContext } from '../vto/providers/VtoProvider'
import { commonStyles, toRem16 } from '../../../styles/commonStyles'
import Loader from '../../common/Loader'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import IntroStep from './InitialSteps/IntroStep'
import DetailedStep from './InitialSteps/DetailedStep'
import UiButton from '../../common/ui/UiButton'
import { ArrowBack } from '@material-ui/icons'
import { ApiRequestHandler } from '../../../services/apiService/RequestService'
import { VTO_TYPES, VtoType } from '../vto/models/VtoDetails.model'
import { createVTO, getVtoYearInfo } from '../../../services/vto/vtoCore'
import Individual1040FormUpload from './Steps/1040/Individual1040FormUpload'
import { STATUS_SUBMITTED } from '../vto/core/components/TaxPrepStatuses/constant'
import BusinessDetailsReminderModal from './BusinessDetailsReminderModal'
import ExpediteOption from '../business-tax-information/initialSteps/ExpediteOption'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { useDispatch } from 'react-redux'
import {
    expediteFirstDeadline,
    expediteSecondDeadline,
    getAlreadyVisitedData,
    hasExpeditedPermission,
} from '../vto/common/VtoUtils'
import { CollectSecondaryPaymentMethod } from '../business-tax-information/CollectSecondaryPaymentMethod'
import {
    getCreditCardCount,
    getCustomerPortalLoginUrl,
} from '../../../services/vto/vtoSubmit'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import {
    destinations,
    needToCollect,
} from '../business-tax-information/BusinessTaxIntro'
import { showAlert } from '../../../store/actions/feedback'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { PERSONAL_ROUTE } from '../vto/models/vto.const'
import { useHistory } from 'react-router-dom'

enum STEPS {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    FOURTH = 'FOURTH',
    FIFTH = 'FIFTH',
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        toSStyle: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            height: '98%',
            flexDirection: 'column',
            alignItems: 'center',
            '& button': {
                maxWidth: '360px',
            },
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                maxWidth: '100%',
                margin: '1rem auto 0 auto',
                padding: '1rem',
                '& button': {
                    maxWidth: '100%',
                },
            },
        },
        borderTopSm: {
            [theme.breakpoints.down('sm')]: {
                borderTop: (colorTheme) => `1px solid ${colorTheme.black100}`,
            },
        },
        tosMargin: {
            marginTop: '2rem',
            marginBottom: '1.5rem',
        },
        imageStyle: {
            height: '100%',
            width: '100%',
            maxHeight: `${toRem16(400)}`,
            maxWidth: `${toRem16(600)}`,
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        list: {
            counterReset: 'item',
            textAlign: 'left',
            listStyleType: 'none',
            paddingLeft: 0,

            '& > li': {
                counterIncrement: 'item',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.5rem',
            },

            '& > li:before': {
                content: 'counter(item, decimal)',
                backgroundColor: (colorTheme) => colorTheme.teal200,
                color: (colorTheme) => colorTheme.grey400,
                borderRadius: '50%',
                display: 'inline-block',
                minWidth: '40px',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                marginRight: '1rem',
                fontWeight: 600,
            },
        },
        bookkeepingCta: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyItems: 'center',

            '& > button': {
                flex: 1,
                justifyContent: 'space-between',
            },

            [theme.breakpoints.up('sm')]: {
                maxWidth: '328px',
                margin: '0 auto',
            },
        },
        prevBtnContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            '& > button': {
                maxWidth: 'max-content',
            },
            [theme.breakpoints.down('sm')]: {
                '& > button': {
                margin: '1rem 0 0 1rem',
            },
            }
        },
        fixedButtonContainer: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            },
        },
        spacer: {
            height: '60px',
        },
    }
})

export const CardReminderDialog = ({ isOpen, handleClose }: any) => {
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    return (
        <UiDialog
            open={isOpen}
            handleClose={handleClose}
            size="sm"
            contentStyles={smDevice ? {} : { maxHeight: '600px' }}
        >
            <div style={{ padding: '2rem' }}>
                <UiText>
                    You have to add new card detail to go to next page
                </UiText>
            </div>
        </UiDialog>
    )
}

const goBack = (currentStep: STEPS): STEPS => {
    switch (currentStep) {
        case STEPS.FIFTH:
            return STEPS.FOURTH
        case STEPS.FOURTH:
            return STEPS.THIRD
        case STEPS.THIRD:
            return STEPS.SECOND
        case STEPS.SECOND:
            return STEPS.FIRST
        case STEPS.FIRST:
        default:
            return STEPS.FIRST
    }
}

const PersonalTaxIntro = () => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)
    const [tosAccepted, setTosAccepted] = useState(false)
    const [loading, setLoading] = useState(false)
    const commonClasses = commonStyles()
    const [vtoDetails, setVtoDetails] = useState<any>()
    const [openReminderPopup, setOpenReminderPopup] = useState(false)
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    const { isMobileDevice } = useDeviceSize();
    const [apiLoading, setApiLoading] = useState<boolean>(false)
    const [introStep, setIntroStep] = useState<STEPS>(STEPS.FIRST)
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [cardReminderModal, setCardReminderModal] = useState<boolean>(false)
    const [selectedExpediteProduct, setSelectedExpediteProduct] = useState<
        'expedite_upsell_opted' | 'extension_upsell_opted'
    >()
    const [expeditedConsent, setExpeditedConsent] = useState<boolean>(false)
    const [collectCard, setCollectCard] = useState<needToCollect>(
        needToCollect.ZERO
    )
    const [isCardCollected, setIsCardCollected] = useState<boolean>(false)
    const [fetchingURL, setFetchingURL] = useState<boolean>(false)

    const { selectedYear, 
        setShowYearDropdown, 
        isBusinessVTO, 
        setCurrentStep,
        setParentStep,
        updateVTO
       } = useVtoContext()
    const { currentAccount, personalAccount, currentUser } = useCurrentStore()
    const history = useHistory()
    const dispatch = useDispatch()
    const businessType = currentAccount?.business?.business_type
    const industry = currentAccount?.business?.industry
    const isAdmin = currentUser?.accountant_mode
    const [ocrFileId, setOcrFileId] = useState<string | null>(null)
    const [cardBeforePaymentPage, setCardBeforePaymentPage] = useState<Number>()

    useEffect(() => {
        if (introStep === STEPS.FIRST) {
            setShowYearDropdown(true)
        }else {
            setShowYearDropdown(false)
        }

        const scrollDemo = document.getElementById('content-scroll')!;
        if (scrollDemo) {
            scrollDemo.scrollTo({ top: 0 });
          }
    }, [introStep, smDevice])

    const checkCallectCardCase = () => {
        getCreditCardCount()
            .then((response: any) => {
                const { total_cards, total_expired_cards, success } = response
                setCardBeforePaymentPage(total_cards)
                if (
                    (total_cards === 0 ||
                        total_expired_cards === total_cards) &&
                    success
                ) {
                    setCollectCard(needToCollect.ONE)
                    getChargoverLogicURL()
                } else if (total_cards === 1 && total_expired_cards === 0) {
                    setCollectCard(needToCollect.TWO)
                } else if (total_cards === 2 && total_expired_cards === 1) {
                    setCollectCard(needToCollect.TWO)
                } else {
                    setCollectCard(needToCollect.ZERO)
                }
            })
            .catch((err: any) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err?.statusText || 'Something went wrong',
                    })
                )
            })
    }

    const getChargoverLogicURL = () => {
        setFetchingURL(true)
        getCustomerPortalLoginUrl({
            destination: destinations.addNewCard,
        })
            .then((data: any) => {
                setFetchingURL(false)
            })
            .catch((error: any) => {
                if (error.status === 400) {
                    setCollectCard(needToCollect.ZERO)
                } else {
                    dispatch(
                        showAlert({
                            alertType: 'error',
                            alertText:
                                error?.statusText || 'Something went wrong',
                        })
                    )
                }
            })
    }

    useEffect(() => {
        checkCallectCardCase()
    }, [])

    const shouldRenderExpeditedPage = () => {
        if (selectedYear === 2024 && !hasExpeditedPermission(isBusinessVTO)) {
            const first = expediteFirstDeadline(businessType, industry, isBusinessVTO)
            const second = expediteSecondDeadline(businessType, industry, isBusinessVTO)
            if (!first.hasToShow && !second.hasToShow) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const shouldRenderPaymentPage = () => {
        if (
            collectCard === needToCollect.ZERO ||
            isCardCollected ||
            getAlreadyVisitedData(personalAccount.id)
        ) {
            return false
        } else {
            return true
        }
    }

    if (loading) {
        return (
            <div className={commonClasses.mt32}>
                <Loader />
            </div>
        )
    }

    const moveToNext = (vtoId: string) => {
        history.push(`${PERSONAL_ROUTE}/${selectedYear}/${vtoId}`)
     }

    const createPersonalVto = (nextPage?: any, selectedItem?: string) => {
        let payload: any = {
            type: VtoType[VTO_TYPES.PERSONAL],
            expedite_or_extension_upsell_consent: expeditedConsent,
        }
        if (selectedItem) {
            payload[selectedItem] = true
        }
        setLoading(true)
        ApiRequestHandler(
            createVTO(selectedYear, payload).then((response: any) => {
                if (response) {
                    setVtoDetails(response)
                    nextPage?.()
                } else {
                    setVtoDetails(undefined)
                }
                setLoading(false)
            }),
            () => {
                setLoading(false)
            }
        )
    }

    const checkVTO = () => {
        setApiLoading(true)
        getVtoYearInfo(selectedYear, VTO_TYPES.BUSINESS)
            .then((businessVTOs) => {
                const vto = businessVTOs.length ? businessVTOs[0] : null
                if (!vto || vto.status !== STATUS_SUBMITTED) {
                    setApiLoading(false)
                    setOpenReminderPopup(true)
                } else {
                    setIntroStep(STEPS.SECOND)
                }
            })
            .catch((err) => setApiLoading(false))
    }

    const handleCheckCardCount = () => {
        setApiLoading(true)
        getCreditCardCount()
            .then((response: any) => {
                if (Number(response.total_cards) === collectCard) {
                    setIsCardCollected(true)
                    if (shouldRenderExpeditedPage()) {
                        setIntroStep(STEPS.FIFTH)
                    } else {
                        updateVTO(vtoDetails?.id as string, {}, (steps: any) => {
                            goToVtoSection()
                            setLoading(false)
                        })
                    }
                } else {
                    setCardReminderModal(true)
                }
                setApiLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    /**
     * FIRST STEP -> 
     * SECOND STEP ->
     * THIRD STEP -> 1040 form upload
     * FOURTH STEP -> CollectSecondaryPaymentMethod
     * FIFTH STEP -> ExpediteOption
     */

    const handleGoBack = () => {
        if (introStep === STEPS.SECOND) {// details step
            setIntroStep(STEPS.FIRST)
            return
        } 
        if(introStep === STEPS.THIRD) {//1040 form upload
            setIntroStep(STEPS.SECOND)
            return
        } 
        if (introStep === STEPS.FOURTH) {
            setIntroStep(STEPS.THIRD)
        } else {
            if (introStep === STEPS.FIFTH) {
                if (shouldRenderPaymentPage()) {
                    setIntroStep(STEPS.FOURTH)
                } else {
                    setIntroStep(STEPS.THIRD)
                }
            }
        }
    }

    const goToVtoSection = () => {
        moveToNext(vtoDetails?.id)
        setCurrentStep('1040_form_scan')
        setParentStep('1040_form_scan')
    }

    const showPrevFloatingButton = isMobileDevice ? introStep !== STEPS.FIRST && introStep !== STEPS.SECOND : introStep !== STEPS.FIRST;
    const handleGoToNext = () => {
        if (shouldRenderExpeditedPage()) {
            setIntroStep(STEPS.FIFTH)
        } else {
            updateVTO(vtoDetails?.id as string, {}, (steps: any) => {
                goToVtoSection()
                setLoading(false)
            })
        }
    }

    return (
        <>
                {showPrevFloatingButton && (
                    <div className={styles.prevBtnContainer}>
                        <UiButton
                            btnType="hyperlink"
                            label="Previous"
                            handleClick={handleGoBack}
                            icon={<ArrowBack />}
                        />
                    </div>
                )}
            <BusinessDetailsReminderModal
                open={openReminderPopup}
                handleClose={() => {
                    setOpenReminderPopup(false)
                    setIntroStep(STEPS.SECOND)
                }}
            />
            <div className={styles.toSStyle}>
                {/* <WatchIntroVideo
                    videoWidth="100%"
                    open={openVideoModal}
                    handleClose={() => {
                        setOpenVideoModal(false)
                    }}
                /> */}
                {introStep === STEPS.FIRST && (
                    <IntroStep
                        selectedYear={selectedYear}
                        setTosAccepted={setTosAccepted}
                        goToNextStep={() => {
                            checkVTO()
                        }}
                        tosAccepted={tosAccepted}
                        apiLoading={apiLoading}
                    />
                )}
                {introStep === STEPS.SECOND && (
                    <DetailedStep
                        currentStep={introStep}
                        selectedYear={selectedYear}
                        setTosAccepted={setTosAccepted}
                        goToNextStep={() => {
                            createPersonalVto(() => {
                                setIntroStep(STEPS.THIRD)
                            })
                        }}
                        setOcrFileId={setOcrFileId}
                        setOpenVideoModal={setOpenVideoModal}
                    />
                )}
                {introStep === STEPS.THIRD && (
                    <Individual1040FormUpload
                        vtoDetails={vtoDetails}
                        setIntroStep={setIntroStep}
                        ocrFileId={ocrFileId}
                        goToNextIntroStep={() => {
                            if (shouldRenderPaymentPage()) {
                                setIntroStep(STEPS.FOURTH)
                            } else if (shouldRenderExpeditedPage()) {
                                setIntroStep(STEPS.FIFTH)
                            } else {
                                updateVTO(vtoDetails?.id as string, undefined, (steps: any) => {
                                goToVtoSection()
                                setLoading(false)
                            })
                            }

                        }}
                    />
                    
                )}
                {introStep === STEPS.FOURTH && (
                    <CollectSecondaryPaymentMethod
                        handleCheckCardCount={handleCheckCardCount}
                        collectCard={collectCard}
                        fetchingURL={fetchingURL}
                        bypassSecondaryPayment={
                            personalAccount?.bypass_secondary_payment
                        }
                        isAdmin={isAdmin}
                        handleNext={handleGoToNext}
                        cardBeforePaymentPage={cardBeforePaymentPage}
                    />
                )}
                {introStep === STEPS.FIFTH && (
                    <ExpediteOption
                        handleNextPage={(selectedItem: any) => {
                            updateVTO(vtoDetails?.id as string, { 
                                    expedite_or_extension_upsell_consent: expeditedConsent,
                                    [selectedItem]: true
                             }, (steps: any) => {
                                goToVtoSection()
                                setLoading(false)
                            })
                        }}
                        businessType={businessType}
                        industry={industry}
                        setSelectedExpediteProduct={
                            setSelectedExpediteProduct
                        }
                        expeditedConsent={expeditedConsent}
                        setExpeditedConsent={setExpeditedConsent}
                    />
                )}
            </div>
            
            {/* {introStep === STEPS.FIFTH && (
                <Individual1040FormUpload
                    vtoDetails={vtoDetails}
                    setIntroStep={setIntroStep}
                />
            )} */}
            <CardReminderDialog
                isOpen={cardReminderModal}
                handleClose={() => setCardReminderModal(false)}
            />
        </>
    )
}

export default PersonalTaxIntro
