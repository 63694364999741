import { InvoiceData } from '../../models/dashboardModel';
import { DismissPopupData } from '../../components/dashboard/TaxEngagementPlan/TaxEngagementPlan';
import { getResource, patchResource } from '../axiosService';


export function getAccountProgress(param: any) {
    return getResource(
        'api/user/current/account_progress/status',
        undefined,
        param
    );
}

export function getIncomeStatement(
    account_id: string,
    business_id: string,
    params: any
) {
    return getResource(
        `api/account/${account_id}/business/${business_id}/report/income_statement`,
        undefined,
        params
    );
}

export function getTasksByAccountant(accountId : string){
    return getResource(
        `/api/account/${accountId}/dashboard/tax_engagement_plan_details`
    )
}
export function updateDismissPopup(currentAccountId : string, body: DismissPopupData){
    return patchResource(
        `/api/account/${currentAccountId}/dismiss_popup`, body
    )
}
export function getDismissedPopups(currentAccountId : string){
    return getResource(
        `/api/account/${currentAccountId}/dismissed_popups`
    )
}
export function getInvoicesWidgetData(
    account_id: string,
    business_id: string,
    params?: any
): Promise<InvoiceData> {
    return getResource(
        `api/account/${account_id}/business/${business_id}/invoices/widget`,
        undefined,
        params
    );
}

export function getBalanceSheet(
    account_id: string,
    business_id: string,
    params: any
) {
    return getResource(
        `api/account/${account_id}/business/${business_id}/report/balance_sheet`,
        undefined,
        params
    );
}

/**
 * API to download Guided Discovery tax saving PDF 
 */
export function downloadTaxSavingPdf(
    account_id: string,
    business_id: string
) {
    return getResource<Blob>(
        `api/account/${account_id}/business/${business_id}/guided_disc/generate_pdf`,
        undefined,
        undefined,
        false,
        'blob'
    );
}

export function getAuthToken() {
    return getResource(`api/user/auth_token`, undefined)
}