import UiLottie from '../../../common/ui/UiLottie'
import WaveAnimation from '../../../../assets/animations/Wave.json'
import UiText from '../../../common/ui/UiText'
import { useStyles } from './IntroStep'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ArrowForward } from '@material-ui/icons'
import UiButton from '../../../common/ui/UiButton'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { useVtoContext } from '../../vto/providers/VtoProvider'

const OverviewStep = ({ goToNextStep, hideBtn = false }: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const {
        currentUser: { first_name, last_name },
    } = useCurrentStore()
    const { selectedYear } = useVtoContext();

    return (
        <div className={styles.container}>
            <div className={styles.center}>
                <UiLottie lottieData={WaveAnimation} />
                <UiText
                    className={`${styles.mt16} ${styles.capitalize} ${styles.title}`}
                    variant="suv_150"
                    weight="bold_700"
                >
                    {`Hi, ${first_name} ${last_name},`}
                </UiText>
            </div>
            <div className={`${styles.mt16} ${styles.mb40}`}>
                <UiText variant="suv_150" weight="bold_700">
                    There are three steps to completing your {selectedYear}{' '}
                    taxes. Here’s what to expect.
                </UiText>
            </div>
            {!hideBtn && (
                <div className={styles.fixedButtonContainer}>
                    <UiButton
                        fullWidth
                        btnType="tertiary"
                        endIcon={<ArrowForward />}
                        handleClick={goToNextStep}
                        label="Next"
                    />
                </div>
            )}
        </div>
    )
}

export default OverviewStep
