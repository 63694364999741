import {
    Button,
    Divider,
    Grid,
    IconButton,
    Theme,
    makeStyles,
    useMediaQuery,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import CloseIcon from '@material-ui/icons/Close'
import UiFormField from '../../../../../common/ui/UiFormField'
import { useCurrentStore } from '../../../../../common/hooks/useCurrentStore'
import { DocumentQueryObject } from '../../../../../../models'
import { CalendarTodayOutlined } from '@material-ui/icons'
import moment from 'moment'
import { convertToBytes } from '../../../../../../utils/documentUtil'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { filterDefaultValues } from '../ImportDocuments'
import UiText from '../../../../../common/ui/UiText'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import UiFormControlSelection from '../../../../../common/ui/UiFormControlSelection'
import UiButton from '../../../../../common/ui/UiButton'
import useDeviceSize from '../../../../../../hooks/useDeviceSize'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        formContainer: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(4),
            [theme.breakpoints.down('sm')]: {
                border: 'none !important',
            }
        },
        formContent: {
            padding: '0 2rem 0 1rem',
            [theme.breakpoints.down('xs')]: {
                padding: '0 1rem 0 1rem',
            }
            
        },
        filterContainer: {
            display: 'flex',
            margin: '1rem 0 1rem 0',
            marginLeft: '1rem',
        },
        inputStyles: {
            marginTop: toRem16(8),
        },
        filterStyles: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1.5rem',
        },
        selectStyles: {
            margin: '1rem 0 1rem 0',
        },
        btnContainer: {
            margin: '1rem',
        },
        fixedButtonContainer: {
            width: '100%',
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                width: '100%',
                boxSizing: 'border-box',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                zIndex: 100,
            },
        },
        cancelBtn: {
            color: (colorTheme) => colorTheme.blue200,
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    }
})

const DocumentFilter = ({
    queryObject,
    applyFilters,
    hideFilters,
    resetFilter
}: {
    queryObject: DocumentQueryObject
    applyFilters: (data: any) => void
    hideFilters: () => void
    resetFilter: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { currentConfig } = useCurrentStore()
    const filterConfig = currentConfig.local.filters
    const { isXsDevice } = useDeviceSize();

    const onSubmit = (data: DocumentQueryObject) => {
        let { size, period, fileSizeType, minDate, maxDate } = data
        size.value = convertToBytes(size.value, fileSizeType)
        if (period !== 'all' && period !== 'custom' && period !== 'quarter') {
            minDate = moment()
                .startOf(period as any)
                .unix()
            maxDate = moment().endOf('day').unix()
        } else if (period === 'quarter') {
            minDate = moment().subtract(2, 'month').startOf('month').unix()
            maxDate = moment().endOf('day').unix()
        }
        applyFilters({
            minDate,
            maxDate,
            period,
            size,
            fileSizeType,
        })
    }


    return (
        <Formik
            initialValues={queryObject}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {(formik) => {
                return (
                    <Form className={styles.formContainer}>
                        <div className={styles.filterContainer}>
                            {!isXsDevice && (
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={hideFilters}
                                    style={{
                                        marginRight: '1rem'
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        onClick={hideFilters}
                                    />
                                </IconButton>
                            )}
                            <UiText variant="hatchback_125" weight="medium_500">
                                Filter Documents
                            </UiText>
                        </div>
                        <Divider />
                        <div className={styles.formContent}>
                            <Grid item xs={12} md={12}>
                                <div className={styles.selectStyles}>
                                    <UiFormControlSelection
                                        label="Upload Date"
                                        fieldName="period"
                                        type="select"
                                        optionsData={Object.keys(
                                            filterConfig.periods
                                        ).map((key) => ({
                                            key,
                                            value: (
                                                filterConfig.periods as any
                                            )[key],
                                        }))}
                                        optionKey="value"
                                        optionValue="key"
                                        showFloatingLabel={true}
                                        size={isXsDevice ? 'medium' : 'small'}
                                    />
                                </div>
                            </Grid>
                            <Grid container spacing={1}>
                                {formik.values.period === 'custom' && (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <UiFormField
                                                label={'Start'}
                                                type="date"
                                                fieldName="minDate"
                                                showFloatingLabel={true}
                                                labelSize={false}
                                                maxDate={moment(
                                                    formik.values.maxDate * 1000
                                                ).toDate()}
                                                endIcon={
                                                    <CalendarTodayOutlined fontSize="small" />
                                                }
                                                size={isXsDevice ? 'medium' : 'small'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <UiFormField
                                                label={'End'}
                                                type="date"
                                                fieldName="maxDate"
                                                showFloatingLabel={true}
                                                labelSize={false}
                                                maxDate={moment().toDate()}
                                                endIcon={
                                                    <CalendarTodayOutlined fontSize="small" />
                                                }
                                                size={isXsDevice ? 'medium' : 'small'}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12}>
                                    <UiFormControlSelection
                                        label={'File Size'}
                                        fieldName="size.operator"
                                        type="select"
                                        optionsData={Object.keys(
                                            filterConfig.size.operators
                                        ).map((key) => ({
                                            key,
                                            value: (
                                                filterConfig.size
                                                    .operators as any
                                            )[key],
                                        }))}
                                        showFloatingLabel={true}
                                        optionKey="value"
                                        optionValue="key"
                                        size={isXsDevice ? 'medium' : 'small'}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <div className={styles.inputStyles}>
                                        <UiFormField
                                            label=""
                                            labelSize={false}
                                            fieldName="size.value"
                                            type="number"
                                            size={isXsDevice ? 'medium' : 'small'}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <div className={`${styles.inputStyles} ${styles.flexEnd}`}>
                                        <UiFormControlSelection
                                            fieldName="fileSizeType"
                                            type="toggleGroup"
                                            optionsData={[
                                                {
                                                    text: 'MB',
                                                    value: 'MB',
                                                    width: '60px',
                                                },
                                                {
                                                    text: 'KB',
                                                    value: 'KB',
                                                    width: '60px',
                                                }
                                            ]}
                                            size={isXsDevice ? "medium" : 'small'}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <UiButton btnType='hyperlink' handleClick={resetFilter} customLabel={<UiText variant='motorcycle_90' weight='semi_bold_600'>Clear Filters</UiText>} />                                
                        </div>
                        {!isXsDevice ? (
                            <>
                                <Divider className={styles.selectStyles} />
                                <div className={styles.btnContainer}>
                                    <UiButton
                                        btnType='tertiary'
                                        handleClick={() => {
                                            formik.submitForm()
                                        }}
                                        fullWidth
                                        customLabel={<UiText weight='semi_bold_600'>Apply filters</UiText>}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.fixedButtonContainer}>
                                <UiButton
                                    btnType='tertiary'
                                    handleClick={() => {
                                        formik.submitForm()
                                        hideFilters()
                                    }}
                                    fullWidth
                                    customLabel={<UiText weight='semi_bold_600'>Apply filters</UiText>}
                                />

                                <Button
                                    className={styles.cancelBtn}
                                    variant="text"
                                    onClick={() => hideFilters()}
                                >
                                    <UiText weight="semi_bold_600">
                                        Close
                                    </UiText>
                                </Button>
                            </div>
                        )}
                    </Form>
                )
            }}
        </Formik>
    )
}

export default DocumentFilter
