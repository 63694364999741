import { Button, makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import { Alert } from '@material-ui/lab'
import Icon from '../common/Icon'
import UiButton from '../common/ui/UiButton'
import UiText from '../common/ui/UiText'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        alertContainer: {
            marginBottom: '1.5rem',
            backgroundColor: (colorTheme) => colorTheme.red700,

            [theme.breakpoints.down('sm')]: {
                marginBottom: '1rem',
            },
        },
        reviewButton: {
            color: (colorTheme) => `${colorTheme.blue200} !important`,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        alertRootStyle: {
            backgroundColor: 'transparent',
            boxSizing: 'border-box',
            maxWidth: '1280px',
            margin: '0 auto',
            padding: '6px 2rem',

            '& > .MuiAlert-message': {
                color: (colorTheme) => colorTheme.black100,
                marginLeft: '1rem',
            },

            [theme.breakpoints.down('sm')]: {
                padding: '6px 1rem',
                flexWrap: 'wrap',

                '& > .MuiAlert-message': {
                    flex: 1,
                    '& p': {
                        fontSize: '1rem !important',
                        lineHeight: '1.5rem !important',
                    },
                },

                '& > .MuiAlert-icon': {
                    flexShirk: 0,
                    width: '1.5rem',
                },

                '& > .MuiAlert-icon span': {
                    marginBottom: 'auto',
                },

                '& > .MuiAlert-action': {
                    flexBasis: '100%',
                    paddingLeft: '1.75rem',
                },
            },

            '& > .MuiAlert-action button': {
                color: (colorTheme) => colorTheme.black100,
            },
        },
    }
})

function TaxSeasonReminderAlert() {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const history = useHistory()

    return (
        <div className={styles.alertContainer}>
            <Alert
                classes={{ root: styles.alertRootStyle }}
                icon={<Icon icon='alert' size='40px' />}
                severity="error"
                action={
                    <></>
                }
            >
                <UiText variant="suv_150" weight="bold_700">
                    It's tax season! Just a reminder, your accountant cannot
                    begin working on your taxes until you have confirmed your
                    tax information. <span className={styles.reviewButton} onClick={() =>
                            history.push('/tax-organizer/business')
                        }>Review Now</span>
                </UiText>
            </Alert>
        </div>
    )
}
export default TaxSeasonReminderAlert
