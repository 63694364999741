import React, { useEffect } from 'react'

const MyloAdEmbedded = ({ xcid }: { xcid: string }) => {  
    useEffect(() => {
        const options = {
            tracking: {
              campaignid: '24ACT007',
              xcid,
            }
        }

        const myloId =  document.getElementById('myload')
        const windowMylo  = (window as any).mylo || {}
        if(windowMylo) {
            windowMylo?.embeds?.buyflow.Commercial(
                myloId,
                options
            )
        }


        document.addEventListener('DOMContentLoaded', function() {
            var loader: any = document.getElementById('module-window');
            loader.addEventListener('load', function() {
                var scrollableDiv: any = document.getElementById('myload');
                scrollableDiv.scrollTop = 0; // Ensure scroll starts from the top
                loader.style.maxHeight = '20rem'
            });
        });
        
    },[])

    return (
        <div id="myload"></div>
    )
}

export default MyloAdEmbedded