import { Button, Menu, MenuItem, Theme, makeStyles } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
    TaxPrepStatus,
    TaxPreparationTitle,
} from '../../../../../../models/vto/VtoInfo'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { commonStyles, toRem16 } from '../../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import { useEffect, useState } from 'react'
import WatchIntroVideo from '../WatchVideoModal'
import Icon from '../../../../../common/Icon'
import { useHistory } from 'react-router-dom'
import { useVtoContext } from '../../../providers/VtoProvider'
import DocumentsUploadModal from '../../../../../dashboard/tax-prep/modals/DocumentsUploadModal'
import {
    DISCARDED_SCHEDULE_C,
    renderFor,
    TAX_TYPE_8879_FLOW,
} from '../../../../../dashboard/tax-prep/constants/tax-prep.const'
import { getVtoPdfUrl } from '../../../../../../services/apiService'
import { useCurrentStore } from '../../../../../common/hooks/useCurrentStore'
import {
    isACAStatus,
    isForm8879Received,
    show8879ESignFlow,
    showDocumentUpload,
} from './TaxprepStatusUtils'
import UiButton from '../../../../../common/ui/UiButton'
import Form8879UploadModal from '../../../../../dashboard/tax-prep/modals/Form8879UploadModal'
import Form8879ESignModal from '../../../../../dashboard/tax-prep/modals/Form8879ESignModal'
import useModal from '../../../../../dashboard/tax-prep/tax-prep-statuses/hooks/useModal'
import UiText from '../../../../../common/ui/UiText'
import { getVtoYearInfo } from '../../../../../../services/vto/vtoCore'
import { VTO_TYPES } from '../../../models/VtoDetails.model'
import { ApStatusMapping, STATUS_SUBMITTED } from './constant'
import BusinessDetailsReminderModal from '../../../../personal-tax-information/BusinessDetailsReminderModal'
import Loader from '../../../../../common/Loader'
import TaxStepFileUpload from '../../../steps/UploadStep/TaxStepFileUpload'
import { downloadFile } from '../../../common/VtoUtils';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DocumentIcon from '../../../../../../assets/icons-svg/DownloadDocumentIcon.svg'
import { useWidgetContext } from '../../../../../dashboard/tax-prep/provider/WidgetProvider';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        buttonContainer: {
            margin: `1rem 0 1rem 0`,
            display: 'flex',
            '& button': {
                flex: 1,
                fontWeight: 600,
            },
        },
        leftButton: {
            marginRight: '1rem',
            display: 'block',

            [theme.breakpoints.down('sm')]:{
                marginRight: 0
            }
        },
        rightButton: {
            marginLeft: toRem16(16),
        },
        downloadBtn: {
            background: (colorTheme) => colorTheme.primary,
            '&:hover': {
                background: (colorTheme) => colorTheme.primary,
            }
        },
        fileUploadBlock: {
            display: 'flex',
            flexDirection: 'column'
        },
        flexContainer: {
            width: '100%', 
            marginTop: '1rem'
        },
        downloadContainer: {
            width: '100%', 
            marginTop: '1rem',
            background: (colorTheme) => colorTheme.primary,
            '&:hover': {
                background: (colorTheme) => colorTheme.primary,
            }
        },
        taxInfoDownload: {
            marginTop: '1rem',
            marginLeft: '0.5rem'
        },
        btnContent: {
            display: 'flex',    
            flex: 1,
            marginTop: '1rem',
        },
        btnContainer: {
            display: 'flex',    
            flex: 1,
            '& button': {
                width: '90%',
                [theme.breakpoints.down('xs')]: {
                    width: 'auto'
                }
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                '& button': {
                    width: '100%',
                },
                '& button:nth-of-type(2)': {
                    marginLeft: '0 !important',
                    marginTop: '1rem',
                },
            },
        },
        editContainer: {
            width: '100%'
        },
        downloadMenu: {
            '& ul': {
                '& a': {
                    display: 'block',
                    width: '350px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: colorTheme => colorTheme.black100,
                    fontSize: '15px',
                    padding: '16px',
                    borderBottom: colorTheme => `1px solid ${colorTheme.grey200} !important`,
                },
                '& img': {
                    marginRight: theme.spacing(1),
                },
            },
        },
    }
})

const SUBMITTED_STATUS = 1
const stepsWithButtons = [
    'Started',
    'Info Submitted',
    'Preparation',
    'Client Review',
    'E-File',
    'Completed',
]

const TaxPrepStepButton = ({ step }: { step: TaxPrepStatus }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const history = useHistory()
    const classes = commonStyles()
    const {
        selectedYear,
        currentBusinessVto,
        currentPersonalVto,
        isBusinessVTO,
    } = useVtoContext()
    const { taxPrepData } = useWidgetContext()
    const widgetData = isBusinessVTO ? taxPrepData.business : taxPrepData.personal
    const widgetTaxApStatus = widgetData?.tax_ap_status
    const { currentAccountId } = useCurrentStore()
    const currentVto = isBusinessVTO ? currentBusinessVto : currentPersonalVto
    const vtoId = isBusinessVTO
        ? currentBusinessVto?.id
        : currentPersonalVto?.id
    const vtoData: any = isBusinessVTO ? currentBusinessVto : currentPersonalVto
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [openDocumentsUploadModal, setOpenDocumentsUploadModal] =
        useState(false)
    const [reminderModal, setReminderModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [taxInfoDownloadUrl, setTaxInfoDownloadUrl] = useState('')
    const [vtoPdfURL, setVtoPdfURL] = useState('')
    const { open, openModal, closeModal } = useModal()
    const {
        open: openEsign,
        openModal: openEsignModal,
        closeModal: closeEsignModal,
    } = useModal()
    const [avatarEl, setAvatarEl] = useState<null | HTMLElement>(null)

    const isVTOSubmitted = vtoData?.status === SUBMITTED_STATUS
    const taxReturns = vtoData?.uploads?.tax_returns as any[]
    const isDownloadBtnDisabled = !taxReturns.length
    const taxReturnDownloadLink = taxReturns.length && taxReturns?.[taxReturns.length - 1]?.download_link


    const handleClose = () => {
        setAnchorEl(null)
    }
    
    const downloadDocument = (downloadURL: string) => {
        downloadFile(downloadURL)
    }


    const showUploadbtn = showDocumentUpload(vtoData?.tax_ap_status)
    const showESignFlow = show8879ESignFlow(vtoData, isBusinessVTO)
    const isDisableUpload = () =>
        isACAStatus(vtoData.tax_ap_status) ? isForm8879Received(vtoData) : false

    const openEsignModalHandler = (e: any) => {
        e.stopPropagation()
        if (TAX_TYPE_8879_FLOW.includes(vtoData.tax_ap_task_type)) {
            openEsignModal()
        } else {
            openModal()
        }
    }

    const checkVTO = () => {
        setLoader(true)
        getVtoYearInfo(selectedYear, VTO_TYPES.BUSINESS).then(
            (response: any) => {
                if (response?.length) {
                    setLoader(false)
                    const vto = response?.length ? response[0] : null
                    if (vto?.status !== STATUS_SUBMITTED) {
                        setReminderModal(true)
                    } else {
                        moveToVTO()
                    }
                } else {
                    moveToVTO()
                }
            }
        )
    }

    const EditVtoContainer = () => {
        return (
            <div className={styles.editContainer}>
                <UiButton
                    customLabel={
                        <UiText weight="semi_bold_600">
                            Continue
                        </UiText>
                    }
                    btnType="tertiary"
                    handleClick={() => {
                        if (isBusinessVTO) {
                            moveToVTO()
                        } else {
                            checkVTO()
                        }
                    }}
                    fullWidth={true}
                />
            </div>
        )
    }

    const moveToVTO = () => {
        history.push(
            `/tax-organizer/${
                isBusinessVTO ? 'business' : 'personal'
            }/${selectedYear}/${vtoId}`
        )
    }

    const FileDownloadList = () => {
        return (
            <Menu
                open={Boolean(avatarEl)}
                anchorEl={avatarEl}
                getContentAnchorEl={null}
                className={styles.downloadMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                onClose={(e) => {
                    setAvatarEl(null)
                }}
                MenuListProps={{ disablePadding: true }}
                elevation={4}
                onClick={(e) => e.stopPropagation()}
            >
                {taxReturns?.map((file: any) => (
                    <MenuItem
                    key={file.filename}
                    component={'a'}
                    target='_blank'
                    href={file.download_link}
                >
                    <img src={DocumentIcon} alt="Download document" />
                    <span>{file.filename}</span>
                </MenuItem>
                ))}
            </Menu>
        )
    }

    useEffect(() => {
        const vtoId = currentVto?.id
        const taxInfoUrl = getVtoPdfUrl(
            currentAccountId,
            selectedYear,
            vtoId
        )
        setTaxInfoDownloadUrl(taxInfoUrl)
    }, [currentAccountId, isBusinessVTO, selectedYear])

    const RenderButton = () => {
        switch (step.title as TaxPreparationTitle) {
            case 'Started':
            case 'Info Submitted':
                return (
                    <>
                        {' '}
                        {loader ? (
                            <Loader />
                        ) : (
                            <>
                                {!isVTOSubmitted && <EditVtoContainer />}
                            </>
                        )}
                    </>
                )
            case 'Preparation':
                if(!isVTOSubmitted)  {
                    return <EditVtoContainer />
                }
                return (
                    <div className={`${styles.btnContainer} ${styles.fileUploadBlock}`}>
                        {showUploadbtn ? (
                            <TaxStepFileUpload isUploadCenter={false} />
                        ) : (
                            <TaxStepFileUpload isUploadCenter={false} />
                        )}
                    </div>
                )
            case 'Internal Review':
                if(!isVTOSubmitted)  {
                    return <EditVtoContainer />
                }
                return ''

            case 'Client Review':
                return (
                    <div className={classes?.fullWidth}>
                        {!isVTOSubmitted ? (
                            <EditVtoContainer />
                        ) : showESignFlow ? (
                            <UiButton
                                handleClick={openEsignModalHandler}
                                label={"Complete Form 8879"}
                                btnType="tertiary"
                                fullWidth={true}
                            />
                        ) : showUploadbtn ? <TaxStepFileUpload isUploadCenter={false} /> : null}
                            {isVTOSubmitted && (
                                <div className={styles.btnContent}>
                                    <div className={styles.btnContainer}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={styles.downloadBtn}
                                            startIcon={
                                                <Icon
                                                    icon={'download'}
                                                    svgColor={colorTheme.primaryWhite}
                                                />
                                            }
                                            onClick={(e) =>{
                                                e.stopPropagation()
                                                setAvatarEl(e.currentTarget)
                                            }}
                                            disabled={isDownloadBtnDisabled}
                                        >
                                            Download
                                            {!!avatarEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </Button>
                                        <FileDownloadList />
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className={styles.rightButton}
                                            startIcon={
                                                <Icon
                                                    icon={'download'}
                                                    svgColor={colorTheme.grey500}
                                                />
                                            }
                                            onClick={() => downloadDocument(taxInfoDownloadUrl)}
                                            disabled={!isVTOSubmitted}
                                        >
                                            Tax Info
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                )
            case 'E-File':
                return (
                    <div className={classes?.fullWidth}>
                        { !isVTOSubmitted && <EditVtoContainer />}

                        <div className={styles.flexContainer}>
                            <div className={styles.btnContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.downloadBtn}
                                    startIcon={
                                        <Icon
                                            icon={'download'}
                                            svgColor={colorTheme.primaryWhite}
                                        />
                                    }
                                    endIcon={<ExpandMore fontSize='large' />}
                                    onClick={() =>
                                        downloadDocument(taxReturnDownloadLink)
                                    }
                                    disabled={isDownloadBtnDisabled}
                                >
                                    Download
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={styles.rightButton}
                                    startIcon={
                                        <Icon
                                            icon={'download'}
                                            svgColor={colorTheme.grey500}
                                        />
                                    }
                                    onClick={() => downloadDocument(taxInfoDownloadUrl)}
                                    disabled={!isVTOSubmitted}
                                >
                                    Tax Info
                                </Button>
                            </div>
                        </div> 
                    </div>
                )
            case 'Completed':
                if(!isVTOSubmitted)  {
                    return <EditVtoContainer />
                }
                return (
                    <>
                        {!isVTOSubmitted && <EditVtoContainer />}
                        <div className={styles.btnContainer}>
                            { widgetTaxApStatus !== DISCARDED_SCHEDULE_C ?<>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.downloadBtn}
                                    startIcon={
                                        <Icon
                                            icon={'download'}
                                            svgColor={colorTheme.primaryWhite}
                                        />
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setAvatarEl(e.currentTarget)
                                    }}
                                    disabled={isDownloadBtnDisabled}
                                >
                                    Download
                                    {!!avatarEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Button>
                                <FileDownloadList />
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={styles.rightButton}
                                    startIcon={
                                        <Icon
                                            icon={'download'}
                                            svgColor={colorTheme.grey500}
                                        />
                                    }
                                    onClick={() => downloadDocument(taxInfoDownloadUrl)}
                                    disabled={!isVTOSubmitted}
                                >
                                    Tax Info
                                </Button>
                            </> : <UiButton
                                    btnType="tertiary"
                                    handleClick={
                                        ()=>{
                                            downloadDocument(taxInfoDownloadUrl)
                                        }
                                    }
                                    label="View Tax Info"
                                    disabled={!isVTOSubmitted}
                                />}
                            
                        </div>
                    </>
                   
                )

            default:
                if(!isVTOSubmitted)  {
                    return  <div className={styles.editContainer}><EditVtoContainer /></div>
                }
                break
        }
    }

    const RenderAdditionalInfo = () => {
        return (
            <div className={styles.btnContainer}>
                <TaxStepFileUpload isUploadCenter={false} />
            </div>
        )
    }

    return (
        <>
            {stepsWithButtons.includes(step?.title) ? (
                <div className={styles.buttonContainer}>
                    <BusinessDetailsReminderModal
                        open={reminderModal}
                        handleClose={() => {
                            moveToVTO()
                        }}
                    />
                    <WatchIntroVideo
                        videoWidth='100%'
                        open={openVideoModal}
                        handleClose={() => {
                            setOpenVideoModal(false)
                        }}
                    />
                    <DocumentsUploadModal
                        open={openDocumentsUploadModal}
                        title={'Upload Tax Documents'}
                        handleClose={() => setOpenDocumentsUploadModal(false)}
                        vtoType={renderFor.BUSINESS}
                        taxYear={selectedYear}
                        vtoId={vtoId as any}
                        taxApId={vtoData?.tax_ap_id}
                    />
                    <Form8879UploadModal
                        open={open}
                        handleClose={closeModal}
                        vtoType={
                            isBusinessVTO
                                ? renderFor.BUSINESS
                                : renderFor.PERSONAL
                        }
                        taxYear={selectedYear}
                        taxPrepStatus={vtoData?.tax_ap_status}
                        taxApId={vtoData?.tax_ap_id}
                        flow={'upload'}
                    />

                    <Form8879ESignModal
                        open={openEsign}
                        handleClose={closeEsignModal}
                        vtoType={
                            isBusinessVTO
                                ? renderFor.BUSINESS
                                : renderFor.PERSONAL
                        }
                        taxYear={selectedYear}
                        vtoId={currentVto?.id}
                        taxPrepStatus={vtoData?.tax_ap_status}
                        taxApId={vtoData?.tax_ap_id}
                    />
                    {currentVto?.tax_ap_status ===  ApStatusMapping.ADDITIONAL_INFO_REQUEST_SENT ?  RenderAdditionalInfo() : RenderButton()}
                </div>
            ) : (
                <div className={styles.buttonContainer}>
                    {!isVTOSubmitted && <EditVtoContainer />}
                    <BusinessDetailsReminderModal
                        open={reminderModal}
                        handleClose={() => {
                            moveToVTO()
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default TaxPrepStepButton
