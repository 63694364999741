import { ChangeEvent } from 'react';
import React from 'react';
import { FastField, Field, FastFieldProps, FieldProps, getIn } from 'formik';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    makeStyles,
    Theme,
    IconButton,
    OutlinedInput,
    Paper
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    TimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MaskedInput from 'react-text-mask';
import { Autocomplete } from '@material-ui/lab';
import { dayStartTimestamp } from '../../../utils/dateUtil';
import { parseISO } from 'date-fns';
import { validDateRange } from '../../../utils/sfUtils';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import moment from 'moment';
import { useThemeContext } from '../whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { toRem16 } from '../../../styles/commonStyles';

const useToggleGroupStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    buttonLabel: {
        'text-transform': 'capitalize !important',
        'color': 'inherit'
    },
    sizeSmall: {
        height: '28px',
    },
    sizeLarge: {
        height: '44px',
    },
    selectedStyle: {
        color:  (colorTheme) => `${colorTheme.primaryWhite} !important`,
        backgroundColor: (colorTheme) => `${colorTheme.grey500} !important`,
    },
    buttonStyle: {
        color:  (colorTheme) => colorTheme.black100,
        fontWeight: 600,
        fontSize: '14px'
    },
    errorText:{
        color: (colorTheme) => colorTheme.red400
    },
    grouped: {
        margin: theme.spacing(0.5),
        border: "none",
        "&:not(:first-child)": {
          borderRadius: toRem16(4),
        },
        "&:first-child": {
          borderRadius: toRem16(4),
        },
      },
      paper: {
        display: 'flex',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: toRem16(6),
        [theme.breakpoints.up('sm')]: {
            borderRadius: toRem16(8)
        }
      },
}))
interface UiFormControlSelectionProps {
    type:
        | 'text'
        | 'number'
        | 'textarea'
        | 'select'
        | 'multiselect'
        | 'radio'
        | 'checkbox'
        | 'date'
        | 'datetime'
        | 'time'
        | 'file'
        | 'mask'
        | 'toggle'
        | 'toggleButton'
        | 'stringDate'
        | 'currency'
        | 'KeyboardDatePicker'
        | 'toggleGroup'  
        | 'password'
        | 'autocomplete'
        | 'KeyboardDatePickerCustom';
    fastField?: boolean;
    fieldName?: string;
    placeholder?: string;
    defaultValue?: string;
    label?: string;
    readOnly?: boolean;
    multiple?: boolean;
    optionsData?: any[];
    radioOptionsData?: { value: any; label: any }[];
    optionKey?: string;
    optionValue?: string;
    datePickerVariant?: 'dialog' | 'inline' | 'static';
    disableDatePickerToolbar?: boolean;
    dateFormat?: string;
    disableFutureDate?: boolean;
    disablePastDate?: boolean;
    provideDayStartDate?: boolean;
    shouldDisableDateFunction?: any;
    maxDate?: Date;
    minDate?: Date;
    disableTimePickerToolbar?: boolean;
    minutesStep?: number;
    mask?: any[];
    onDateChange?: any
    onChange?:
        | ((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
        | undefined;
    onBlur?: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    value?: any;
    rows?: number;
    rowsMax?: number;
    maxLength?: number;
    minLength?: number;
    max?: number;
    step?: number | 'any';
    min?: number;
    required?: boolean;
    errorMessage?: string;
    inlineLable?: any;
    validationFunction?: any;
    labelClass?: string;
    disabled?: boolean;
    dateType?: 'string';
    showFloatingLabel?: boolean;
    autoOk?: boolean;
    secondaryTextKey?: string;
    startIcon?: any;
    endIcon?: any;
    onKeyPress?: any;
    onKeyUp?: any;
    onFocus?: any;
    onKeyDown?: any;
    error?: boolean;
    helperText?: string;
    nonIsoDate?: boolean;
    shouldDisableDate?: any;
    cypressId?: string;
    className?: string;
    fullWidth?: boolean;
    showPlaceholderAsOption?: boolean;
    customClass?: any
    hideCalendarPlaceholder?: boolean;
    disableRipple?: boolean;
    size?: 'small' | 'medium'
    checkboxColor?: 'default' | 'primary' | 'secondary'
}

export default function UiFormControlSelection({
    type,
    fastField = true,
    fieldName,
    placeholder,
    defaultValue,
    label,
    readOnly,
    multiple,
    optionsData = [],
    radioOptionsData = [],
    optionKey,
    optionValue,
    datePickerVariant = 'inline',
    disableDatePickerToolbar = false,
    dateFormat,
    disableFutureDate,
    disablePastDate,
    provideDayStartDate,
    shouldDisableDateFunction,
    maxDate,
    minDate,
    disableTimePickerToolbar = false,
    minutesStep,
    mask,
    value,
    onDateChange,
    onChange,
    onBlur,
    rows = 3,
    rowsMax = 5,
    max,
    min,
    maxLength,
    minLength,
    required,
    errorMessage = 'Required',
    validationFunction,
    labelClass,
    inlineLable,
    disabled,
    dateType,
    showFloatingLabel = false,
    autoOk = true,
    secondaryTextKey,
    startIcon,
    endIcon,
    onKeyPress,
    onKeyUp,
    onFocus,
    onKeyDown,
    error,
    helperText,
    step,
    nonIsoDate,
    shouldDisableDate,
    cypressId,
    className,
    fullWidth,
    showPlaceholderAsOption = true,
    customClass,
    hideCalendarPlaceholder,
    size = 'small',
    checkboxColor = 'default'
}: UiFormControlSelectionProps) {
    const { colorTheme } = useThemeContext()
    const toggleGroupStyles = useToggleGroupStyles(colorTheme)
    const TextMaskInput = (props: any) => {
        const { inputRef, mask, ...other } = props;
        return (
            <MaskedInput
                {...other}
                ref={(ref: any) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                guide={false}
                mask={mask}
                showMask
                data-cy={cypressId}
            />
        );
    };


    const getValueFromFormObject =  (formValues: any, fieldName: string, optionKey: any, optionsData: any) =>{
        const result =  getIn(formValues, fieldName)
        let valueData = '';
         optionsData.filter((value : any) =>{
          if(value[optionKey] === result){
            valueData = value;
          }
        })
        return valueData
      }
    
    const getFormControlBasedOnType = ({
        field,
        form = {},
        meta = {},
    }: any) => {
        const getDateValue = (date: any) => {
            if (dateType === 'string') {
                return moment(date).unix() * 1000;
            }
            return date * 1000;
        };

        const setDateValue = (input: any) => {
            if (type === 'KeyboardDatePicker' || type === 'KeyboardDatePickerCustom') {
                if (!input) return;
                if (
                    // new Date() < new Date(input) || // commenting out this code since this condition seems incorrect as if we select any future date if will set the field value to empty string.
                    new Date(input) < new Date(validDateRange.min)
                ) {
                    form.setFieldValue(fieldName, '');
                    return;
                }
            }
            if (dateType === 'string') {
                const dateObj = new Date(input);
                const date =
                    dateObj.getDate() < 10
                        ? `0${dateObj.getDate()}`
                        : dateObj.getDate();
                const month =
                    dateObj.getMonth() < 9
                        ? `0${dateObj.getMonth() + 1}`
                        : dateObj.getMonth() + 1;
                const dateStr = `${dateObj.getFullYear()}-${month}-${date}`;
                form.setFieldValue(fieldName, dateStr);
            } else {
                form.setFieldValue(
                    fieldName,
                    Math.floor(new Date(value).valueOf() / 1000)
                );
            }
        };

        const onDateBlur = (event: any) => {
            const value = event.target.value
            if (value === '') {
                form.setFieldValue(fieldName, '')
                form.setFieldTouched(fieldName, true)
                form.setFieldError(fieldName, 'Required')
            }
        }

        const errorMessage = (error: any) => {
            if (error && error.includes('Invalid Date')) {
                return 'Invalid date format'
            }
            return error
        }

        switch (type) {
            case 'text':
                return (
                    <TextField
                        classes={customClass}
                        fullWidth
                        label={showFloatingLabel && label}
                        placeholder={placeholder}
                        size={size}
                        variant='outlined'
                        defaultValue={defaultValue}
                        data-cy={cypressId}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        inputProps={{
                            ...field,
                            maxLength,
                            minLength,
                            readOnly,
                            onBlur: (event) => {
                                field?.onBlur && field.onBlur(event);
                                form?.setFieldValue &&
                                    form.setFieldValue(
                                        fieldName,
                                        event.target.value?.trim()
                                    );
                            },
                            onFocus: onFocus,
                            onKeyPress: onKeyPress,
                            id: cypressId
                        }}
                        InputProps={{
                            startAdornment: startIcon && (
                                <InputAdornment position='start'>
                                    {startIcon}
                                </InputAdornment>
                            ),
                            endAdornment: endIcon && (
                                <InputAdornment position='end'>
                                    {endIcon}
                                </InputAdornment>
                            ),
                        }}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        helperText={
                            helperText || meta.touched || form.submitCount > 0
                                ? meta.error || helperText
                                : ''
                        }
                        disabled={disabled}
                        onBlur={(event) => {
                            if (onBlur) onBlur(event);
                            field?.onBlur(event);
                        }}
                        className={customClass}
                    />
                );
            case 'mask':
                return (
                    <TextField
                        fullWidth
                        label={showFloatingLabel && label}
                        placeholder={placeholder}
                        size={size}
                        variant='outlined'
                        inputProps={{
                            ...field,
                            onChange: onChange,
                            onBlur: (event) => {
                                field.onBlur(event);
                                field.onChange(event);
                                if (minLength && minLength > 0) {
                                    form.setFieldValue(
                                        fieldName,
                                        event.target.value?.replaceAll(
                                            /[^a-z0-9]+/gi,
                                            ''
                                        ).length === minLength
                                            ? event.target.value?.replaceAll(
                                                  /[^a-z0-9]+/gi,
                                                  ''
                                              )
                                            : ''
                                    );
                                }
                            },
                            onFocus: onFocus,
                            mask,
                            id: cypressId
                        }}
                        InputProps={{ inputComponent: TextMaskInput as any }}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        helperText= {helperText || meta.touched || form.submitCount > 0
                            ? meta.error || helperText
                            : ''}
                        disabled={disabled}
                        data-cy={cypressId}
                    />
                );
            case 'number':
                return (
                    <TextField
                        fullWidth
                        label={showFloatingLabel && label}
                        placeholder={placeholder}
                        size={size}
                        variant='outlined'
                        inputProps={{
                            ...field,
                            max,
                            min,
                            maxLength,
                            minLength,
                            step,
                        }}
                        InputProps={{
                            startAdornment: startIcon && (
                                <InputAdornment position='start'>
                                {startIcon}
                                </InputAdornment>
                            ),
                            endAdornment: endIcon && (
                                <InputAdornment position='end'>
                                    {endIcon}
                                </InputAdornment>
                            ),
                            id: cypressId 
                        }}
                        type='number'
                        data-cy={cypressId}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        helperText={
                            meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''
                        }
                        disabled={disabled}
                        onFocus={onFocus}
                        onBlur={(event) => {
                            if (onBlur) onBlur(event);
                            field.onBlur(event);
                        }}
                        {...(onChange ? { onChange: (event: any) => {
                            onChange?.(event)
                        } } : {})}
                        value={value}
                    />
                );
            case 'file':
                return (
                    <TextField
                        fullWidth
                        label={showFloatingLabel && label}
                        placeholder={placeholder}
                        size={size}
                        variant='outlined'
                        {...field}
                        type='file'
                        data-cy={cypressId}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        helperText={
                            meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''
                        }
                        disabled={disabled}
                    />
                );
            case 'textarea':
                return (
                    <TextField
                        fullWidth
                        label={showFloatingLabel && label}
                        placeholder={placeholder}
                        size={size}
                        data-cy={cypressId}
                        variant='outlined'
                        className={customClass ?? ''}
                        inputProps={{
                            ...field,
                            onBlur: (event) => {
                                field.onBlur(event);
                                form.setFieldValue(
                                    fieldName,
                                    event.target.value?.trim()
                                );
                            },
                            id: cypressId
                        }}
                        multiline
                        rows={rows}
                        rowsMax={rowsMax}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        helperText={
                            meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''
                        }
                        disabled={disabled}
                    />
                );
            case 'password':
                return(
                    <FormControl
                        variant='outlined'
                        fullWidth
                        size={size}
                        data-cy={cypressId}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        disabled={disabled}
                    >
                         {showFloatingLabel && label ? (
                            <InputLabel>{label}</InputLabel>
                        ) : null}
                            <OutlinedInput
                        type={field.showPassword ? 'text' : 'password'}
                        value={field.value}
                        onChange={(event: any) => {
                                form.setFieldValue(
                                    fieldName,
                                    event?.target?.value
                                );
                        }}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={(event) =>{
                                meta.showPassword = !field.showPassword;
                            }}
                            onMouseDown={() =>{}}
                            >
                            {field.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                     <FormHelperText>
                            {meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''}
                        </FormHelperText>
                    </FormControl>
                )
            case "autocomplete":
                    return (
                        <Autocomplete
                        size={size}
                        disabled={disabled}
                        options={optionsData as any}
                        onChange={(event: any, item: any) => {
                            if (!optionValue || !item) {
                                form.setFieldValue(fieldName, '')
                                return
                            }
                            form.setFieldValue(fieldName, item[optionValue])
                            onChange?.(item)
                        }}
                        onBlur={() => {
                            meta.touched = true
                        }}
                        getOptionLabel={(option: any) => {
                            return optionKey && option ? option[optionKey] : option
                        }}
                        value={getValueFromFormObject(
                            form.values,
                            field.name,
                            optionValue,
                            optionsData
                        )}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                error={
                                    (meta.touched || form.submitCount > 0) &&
                                    (meta.error ? true : false)
                                }
                                helperText={meta.touched || form.submitCount > 0 ? meta.error : ''}
                                disabled={disabled}
                            />
                        )}
                    />
                    );
            case 'select':
                return (
                    <FormControl
                        variant='outlined'
                        fullWidth={fullWidth ?? true}
                        size={size}
                        data-cy={cypressId}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        disabled={disabled}
                    >
                        {showFloatingLabel && label ? (
                            <InputLabel>{label}</InputLabel>
                        ) : null}
                        <Select
                            autoWidth
                            displayEmpty
                            multiple={multiple}
                            {...field}
                            data-cy={cypressId}
                            disabled={disabled}
                            defaultValue={placeholder ? '' : undefined}
                            {...(className ? { className: className } : {})}
                            label={
                                showFloatingLabel && label ? label : undefined
                            }
                            {...(onChange
                                ? {
                                      onChange: (event: any) => {
                                            form.setFieldValue(
                                                fieldName,
                                                event?.target?.value
                                            );
                                          onChange?.(event);
                                      },
                                  }
                                : {})}
                        >
                            {showPlaceholderAsOption && (
                                <MenuItem value="" disabled>
                                    {placeholder || label}
                                </MenuItem>
                            )}
                            {optionsData.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={
                                        optionValue
                                            ? option[optionValue]
                                            : option
                                    }
                                    disabled={option?.disabled}
                                    id={optionKey ? option[optionKey] : option}
                                >
                                    <div>
                                        {optionKey ? option[optionKey] : option}
                                        {secondaryTextKey && (
                                            <>
                                                <br />
                                                <Typography variant='caption'>
                                                    {option[secondaryTextKey]}
                                                    {secondaryTextKey
                                                        .split('.')
                                                        .reduce(
                                                            (p, c) => p?.[c],
                                                            option
                                                        )}
                                                </Typography>
                                            </>
                                        )}
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            {helperText || meta.touched || form.submitCount > 0
                                ? meta.error || helperText
                                : ''}
                        </FormHelperText>
                    </FormControl>
                );

            case 'multiselect':
                return (
                    <FormControl
                        variant='outlined'
                        fullWidth
                        size={size}
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        disabled={disabled}
                    >
                        <InputLabel>{label ? label : undefined}</InputLabel>
                        <Select
                            multiple
                            {...field}
                            disabled={disabled}
                            data-cy={cypressId}
                            label={
                                showFloatingLabel && label ? label : undefined
                            }
                            onChange={(event: any) => {
                                form.setFieldValue(
                                    fieldName,
                                    event?.target?.value
                                );
                            }}
                        >
                            {optionsData.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={
                                        optionValue
                                            ? option[optionValue]
                                            : option
                                    }
                                >
                                    {optionKey ? option[optionKey] : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'radio':
                return (
                    <FormControl
                        fullWidth
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                        disabled={disabled}
                    >
                        <RadioGroup
                            {...field}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                form.setFieldValue(
                                    fieldName,
                                    event?.target?.value
                                );
                                onChange?.(event);
                            }}
                        >
                            {radioOptionsData.map((option, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.label}
                                        className={labelClass}
                                        data-cy={cypressId}
                                    />
                                );
                            })}
                        </RadioGroup>
                        <FormHelperText>
                            {meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''}
                        </FormHelperText>
                    </FormControl>
                );
            case 'toggle':
                return (
                    <FormControl
                        fullWidth
                        error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }
                    >
                        <Box>
                            {optionsData.map((option, index) => {
                                return (
                                    <Button
                                        key={index}
                                        variant={
                                            field.value === option.value
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        data-cy={cypressId}
                                        color={
                                            field.value === option.value
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        onClick={() => {
                                            form.setFieldValue(
                                                field.name,
                                                option.value
                                            );
                                        }}
                                        style={{ margin: '10px 10px 10px 0' }}
                                    >
                                        {option.label}
                                    </Button>
                                );
                            })}
                        </Box>
                        <FormHelperText>
                            {meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''}
                        </FormHelperText>
                    </FormControl>
                );
            case 'checkbox':
                return (
                    <>
                        <FormControlLabel
                            disabled={disabled}
                            control={
                                <Checkbox
                                    checked={field.value}
                                    disabled={disabled}
                                    color={checkboxColor ? checkboxColor : 'default'} 
                                    data-cy={cypressId}
                                    onChange={(value) => {
                                        onChange?.(value)
                                        form.setFieldValue(fieldName, value);
                                    }}
                                    {...field}
                                />
                            }
                            label={inlineLable}
                        />
                        <FormHelperText error={
                            (meta.touched || form.submitCount > 0) &&
                            (meta.error ? true : false)
                        }>
                            {meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''}
                        </FormHelperText>
                    </>
                       
                );
            case 'datetime':
            case 'time':
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <TimePicker
                            autoOk
                            inputVariant='outlined'
                            label={showFloatingLabel && label}
                            disableToolbar={disableTimePickerToolbar}
                            size={size}
                            margin='none'
                            data-cy={cypressId}
                            {...field}
                            value={field.value && field.value * 1000}
                            onChange={(value: any) => {
                                form.setFieldValue(
                                    fieldName,
                                    Math.floor(new Date(value).valueOf() / 1000)
                                );
                            }}
                            minutesStep={minutesStep}
                            disabled={disabled}
                            emptyLabel={placeholder || label}
                            fullWidth
                            helperText={
                                meta.touched || form.submitCount > 0
                                    ? meta.error
                                    : ''
                            }
                            error={
                                (meta.touched || form.submitCount > 0) &&
                                (meta.error ? true : false)
                            }
                        />
                    </MuiPickersUtilsProvider>
                );
            case 'stringDate':
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            variant={datePickerVariant}
                            label={showFloatingLabel && label}
                            inputVariant='outlined'
                            format={dateFormat ?? 'DD MMM YYYY'}
                            margin='none'
                            data-cy={cypressId}
                            disableFuture={disableFutureDate}
                            disablePast={disablePastDate}
                            maxDate={maxDate}
                            minDate={minDate}
                            size={size}
                            disableToolbar={disableDatePickerToolbar}
                            emptyLabel={placeholder || 'DD MMM YYYY'}
                            shouldDisableDate={shouldDisableDate}
                            fullWidth
                            {...field}
                            value={
                                field.value ? getDateValue(field.value) : null
                            }
                            onChange={setDateValue}
                            helperText={
                                meta.touched || form.submitCount > 0
                                    ? meta.error
                                    : ''
                            }
                            error={
                                (meta.touched || form.submitCount > 0) &&
                                (meta.error ? true : false)
                            }
                            disabled={disabled}
                            autoOk={autoOk}
                            InputProps={{
                                startAdornment: startIcon && (
                                    <InputAdornment position='start'>
                                        {startIcon}
                                    </InputAdornment>
                                ),
                                endAdornment: endIcon && (
                                    <InputAdornment position='end'>
                                        {endIcon}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            case 'date':
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            variant={datePickerVariant}
                            inputVariant='outlined'
                            format={dateFormat || 'DD MMM YYYY'}
                            margin='none'
                            disableFuture={disableFutureDate}
                            disablePast={disablePastDate}
                            maxDate={maxDate}
                            minDate={minDate}
                            size={size}
                            data-cy={cypressId}
                            disableToolbar={disableDatePickerToolbar}
                            emptyLabel={placeholder || label}
                            fullWidth={fullWidth ?? true}
                            {...field}
                            value={field.value ? field.value * 1000 : null}
                            autoOk={true}
                            onChange={(value: any) => {
                                let dateTimeStamp: any = new Date(
                                    value
                                ).valueOf();
                                if (provideDayStartDate) {
                                    dateTimeStamp =
                                        dayStartTimestamp(dateTimeStamp);
                                } else {
                                    dateTimeStamp = Math.floor(
                                        dateTimeStamp / 1000
                                    );
                                }
                                form.setFieldValue(fieldName, dateTimeStamp);
                                onDateChange?.(dateTimeStamp)
                                onChange && onChange(value)
                            }}
                            helperText={meta.touched ? meta.error : ''}
                            error={meta.touched && (meta.error ? true : false)}
                            disabled={disabled}
                            shouldDisableDate={shouldDisableDateFunction}
                            label={showFloatingLabel && label}
                            InputProps={{
                                startAdornment: startIcon && (
                                    <InputAdornment position='start'>
                                        {startIcon}
                                    </InputAdornment>
                                ),
                                endAdornment: endIcon && (
                                    <InputAdornment position='end'>
                                        {endIcon}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            case 'currency':
                return (
                    <TextField
                        fullWidth
                        label={showFloatingLabel && label}
                        placeholder={placeholder}
                        size={size}
                        data-cy={cypressId}
                        variant='outlined'
                        inputProps={{
                            ...field,
                            maxLength,
                            minLength,
                            readOnly,
                            id: cypressId
                        }}
                        error={
                            error ||
                            ((meta.touched || form.submitCount > 0) &&
                                (meta.error ? true : false))
                        }
                        helperText={
                            helperText ||
                            (meta.touched || form.submitCount > 0
                                ? meta.error
                                : '')
                        }
                        disabled={disabled}
                        onBlur={onBlur}
                        onChange={onChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    $
                                </InputAdornment>
                            ),
                        }}
                        className={customClass}
                    />
                );
            case 'KeyboardDatePicker':
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant={datePickerVariant}
                            label={showFloatingLabel && label}
                            inputVariant='outlined'
                            format={dateFormat || 'DD MMM YYYY'}
                            margin='none'
                            data-cy={cypressId}
                            disableFuture={disableFutureDate}
                            disablePast={disablePastDate}
                            maxDate={maxDate}
                            minDate={minDate}
                            size={size}
                            disableToolbar={disableDatePickerToolbar}
                            emptyLabel={hideCalendarPlaceholder ? "" : placeholder || 'DD MMM YYYY'}
                            fullWidth
                            {...field}
                            value={
                                field.value
                                    ? getDateValue(
                                          nonIsoDate
                                              ? field.value
                                              : parseISO(field.value)
                                      )
                                    : null
                            }
                            onChange={setDateValue}
                            helperText={
                                meta.touched || form.submitCount > 0
                                    ? meta.error
                                    : ''
                            }
                            error={
                                (meta.touched || form.submitCount > 0) &&
                                (meta.error ? true : false)
                            }
                            disabled={disabled}
                            autoOk={autoOk}
                            clearable
                        />
                    </MuiPickersUtilsProvider>
                );
            case 'KeyboardDatePickerCustom':
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            variant={datePickerVariant}
                            label={showFloatingLabel && label}
                            inputVariant='outlined'
                            format={dateFormat || 'DD MMM YYYY'}
                            margin='none'
                            data-cy={cypressId}
                            disableFuture={disableFutureDate}
                            disablePast={disablePastDate}
                            maxDate={maxDate}
                            minDate={minDate}
                            size={size}
                            disableToolbar={disableDatePickerToolbar}
                            emptyLabel={hideCalendarPlaceholder ? "" : placeholder || 'DD MMM YYYY'}
                            fullWidth
                            {...field}
                            value={
                                field.value
                                    ? getDateValue(
                                            nonIsoDate
                                                ? field.value
                                                : parseISO(field.value)
                                        )
                                    : null
                            }
                            onChange={setDateValue}
                            onBlur={(event) => {
                                onDateBlur(event);
                                field?.onBlur(event);
                            }}
                            helperText={
                                meta.touched || form.submitCount > 0
                                    ? errorMessage(meta.error)
                                    : ''
                            }
                            error={
                                (meta.touched || form.submitCount > 0) &&
                                (meta.error ? true : false)
                            }
                            disabled={disabled}
                            autoOk={autoOk}
                            clearable
                        />
                    </MuiPickersUtilsProvider>
                );    
            case 'toggleGroup': 
                return (
                    <FormControl
                        fullWidth={fullWidth}
                        disabled={disabled}
                    >   
                        {label && <Box my={1}>{label}</Box>}
                        <Paper elevation={0} className={toggleGroupStyles.paper}>
                        <ToggleButtonGroup
                            value={field.value}
                            exclusive
                            size={size === 'medium' ? 'large' : size}
                            onChange={(event, value) => {
                                if(value) {
                                    form.setFieldValue(fieldName, value);
                                }   
                            }}
                            classes={{
                                grouped: toggleGroupStyles.grouped,
                              }}
                        >
                            {optionsData.map((option) => (
                                <ToggleButton
                                    disableRipple
                                    style={{width: option?.width}}
                                    value={option.value}
                                    data-cy={cypressId}
                                    classes={{ root: toggleGroupStyles.buttonStyle,
                                               label: toggleGroupStyles.buttonLabel, 
                                              sizeSmall: toggleGroupStyles.sizeSmall,
                                              sizeLarge: toggleGroupStyles.sizeLarge,
                                              selected:  toggleGroupStyles.selectedStyle}}>
                                    {option.text}
                                </ToggleButton>
                            ))}   
                        </ToggleButtonGroup>
                        </Paper>
                        <FormHelperText>
                            {meta.touched || form.submitCount > 0
                                ? meta.error
                                : ''}
                        </FormHelperText>
                    </FormControl>
                )    
            default:
                return null;
        }
    };

    const validateProps =
        required && errorMessage
            ? { validate: (value: any) => (!value ? errorMessage : '') }
            : validationFunction
            ? { validate: validationFunction }
            : {};

    return fieldName ? (
        fastField ? (
            <>
                <FastField name={fieldName} {...validateProps}>
                    {(props: FastFieldProps<any>) =>
                        getFormControlBasedOnType({ ...props })
                    }
                </FastField>
            </>
        ) : (
            <>
                <Field name={fieldName} {...validateProps}>
                    {(props: FieldProps<any>) =>
                        getFormControlBasedOnType({ ...props })
                    }
                </Field>
            </>
        )
    ) : (
        getFormControlBasedOnType({
            field: {
                value,
                onChange,
            },
        })
    );
}
