import { useEffect } from 'react';
import Routing from '../routing/Routing';
import { connect } from 'react-redux';
import LogoutModal from '../auth/LogoutModal';
import ErrorModal from '../core/ErrorBoundary/ErrorModal';
import { ApplicationStore } from '../../models';
import UiSnackbarAlert from '../common/ui/UiSnackbarAlert';
import { showAlert, showInfo } from '../../store/actions/feedback';
import UiErrorInfoDialog from '../common/ui/UiErrorInfoDialog';
import ReactGA from 'react-ga';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeCustom } from '../../styles/ThemeCustom';

function App(props: any) {
    const customTheme = ThemeCustom();

    useEffect(() => {
        ReactGA.initialize((window as any).ApiConfig.analytics_id);
    }, []);

    window.addEventListener('beforeunload', () => {
        window?.embeddedservice_bootstrap?.userVerificationAPI?.clearSession()
            .then(() => {});
    });

    return (
        <ErrorBoundary>
            <>
                <Routing />
                <ThemeProvider theme={customTheme}>
                    <LogoutModal />{/**to do - check logout modal */}
                    <ErrorModal
                        openModal={props?.error?.showError || false}
                        errorType={props?.error?.errorCode}
                    />
                    { // Added and condition to avoid creating empty div
                        props?.alertData?.alertText && <UiSnackbarAlert
                        open={props?.alertData?.alertText ? true : false}
                        message={props?.alertData?.alertText}
                        handleClose={() =>
                            props.showAlert({ alertText: '', alertType: undefined })
                        }
                        severity={props?.alertData?.alertType}
                    />
                    }
                    
                    { // Added and condition to avoid creating empty div
                        props?.info?.infoData && <UiErrorInfoDialog
                        open={props?.info?.infoData ? true : false}
                        handleClose={() => props.showInfo({ infoData: '' })}
                        message={props?.info?.infoData}
                    />
                    }
                </ThemeProvider>
            </>
        </ErrorBoundary>
    );
}
const mapStateToProps = (state: ApplicationStore) => ({
    error: state.feedback.error,
    alertData: state.feedback.alert,
    info: state.feedback.info,
});
export default connect(mapStateToProps, { showAlert, showInfo })(App);
