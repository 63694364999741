import { useState, useEffect, useCallback, useRef } from 'react'
import {
    createVto,
    getVtoYearInfo,
    getVTOMetaData,
    getVTOConfig,
    getVtoDetails,
    updateVtoData,
} from '../../../../services/vto/vtoCore'
import { VtoInfo } from '../../../../models/vto/VtoInfo'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { ApiRequestHandler } from '../../../../services/apiService/RequestService'
import { useHistory, useLocation } from 'react-router-dom'
import {
    BusinessSection,
    FAQ,
    PersonalSection,
    VTOConfigData,
    VTOMetaData,
    VTO_TYPES,
} from '../models/VtoDetails.model'
import { showError } from '../../../../services/formService'

import vto2023BusinessSteps from '../../../../config/vto/2023/business_section.json'
import vto2023PersonalSteps from '../../../../config/vto/2023/personal_section.json'
import vto2024BusinessSteps from '../../../../config/vto/2024/business_section.json'
import vto2024PersonalSteps from '../../../../config/vto/2024/personal_section.json'

import { hasPermission } from '../../../../services/aclService'
import {
    buildFileConditions,
    createDataOriginMap,
    createMap,
    getTheDiscountedPrice,
    makeAnnualFrequency,
} from '../common/VtoUtils'
import { decryptData } from '../../../../utils/maskUtil'
import { vtoProductDetails } from '../../../../services/vto/vtoProducts'
import { allowedVtoSteps } from '../common/StepsUtil'
import { NOT_ALLOWED_STEPS_IN_MENU } from '../models/vto.const'
import { loadProducts } from '../../../../store/actions/products'
import { useDispatch } from 'react-redux'
import { DefaultVTOYear, yearIndexMapping } from '../core/Utility'
import { isEmpty } from '../../../../utils/appUtil'

const BUSINESS_VTO_ROUTE = `/tax-organizer/business`
const PERSONAL_VTO_ROUTE = `/tax-organizer/personal`

const MetaConfigs: any = {
    vto2023: [vto2023BusinessSteps, vto2023PersonalSteps],
    vto2024: [vto2024BusinessSteps, vto2024PersonalSteps],
}
const NO_INDEX = -1

const checkNestedKey = (vto: any, key: any) => {
    // Split the key by dots
    const keys = key.split('.')
    let current = vto

    // Traverse the object according to each key segment
    for (const k of keys) {
        if (current[k] === undefined) {
            return false // Return false if the key segment does not exist
        }
        current = current[k]
    }

    return true // Return true if all segments exist
}
// index of preselected year dropdown
const yearDropdownIndex = Object.keys(yearIndexMapping).find(
    (key) => yearIndexMapping[key] === DefaultVTOYear
);

export const useVtoInfo = () => {
    const [loading, setLoading] = useState(false)
    const [questionLoading, setQuestionLoading] = useState(false)
    const [showYearDropdown, setShowYearDropdown] = useState(true)
    const [dropdownIndex, SetDropdownIndex] = useState(yearDropdownIndex) // will update later with actual value
    const [currentBusinessVto, setCurrentBusinessVto] = useState<VtoInfo>()
    const [currentQuestion, setCurrentQuestion] = useState(-1)
    const [currentPersonalVto, setCurrentPersonalVto] = useState<VtoInfo>()
    const [currentVtoDetails, setCurrentVtoDetails] = useState<any>() //TODO Add types
    const [currentYearMetaData, setCurrentYearMetaData] =
        useState<VTOMetaData>() //TODO need to add types
    const [currentVtoConfig, setCurrentVtoConfig] = useState<VTOConfigData>()
    const [fullSectionView, setFullSectionView] = useState<boolean>(false)
    const [vtoSubmitStep, setVtoSubmitStep] = useState<number>(1)
    const [currentYearVTOProducts, setCurrentYearVTOProducts] = useState<any[]>(
        []
    )
    const [dataOriginMap, setDataOriginMap] = useState({})

    const [currentStepFaqs, setCurrentStepFaqs] = useState<FAQ[]>([])
    const [openFaqs, setOpenFaqs] = useState(false)
    const [currentVto, setCurrentVto] = useState<any>(null)
    const [productPriceMap, setProductPriceMap] = useState<any>({})
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const routes = location.pathname.split('/')
    const year = routes[routes.length - 2]
    const [selectedYear, SetSelectedYear] = useState(Number(year) || 2024)
    const { currentConfig, currentAccount, personalAccount, product } =
        useCurrentStore()

    const isPersonalVTO = location.pathname.includes(PERSONAL_VTO_ROUTE)
    const isBusinessVTO = location.pathname.includes(BUSINESS_VTO_ROUTE)
    const [currentStep, setCurrentStep] = useState<string>(
        isBusinessVTO ? 'business_type' : '1040_form_scan'
    )
    const vtoConfig = currentConfig.apiConfig.vto
    const vtoType = isPersonalVTO ? VTO_TYPES.PERSONAL : VTO_TYPES.BUSINESS
    const currentVtoYearConfig = currentVtoConfig?.[`year${selectedYear}`] ?? {}
    const [parentStep, setParentStep] = useState<any>({})
    // Filtered Menu Items for VTO (excluding submit steps)
    const [menuItems, setMenuItems] = useState<
        PersonalSection[] | BusinessSection[] | undefined
    >([])
    // Filtered Steps for VTO (including submit steps)
    const [vtoAllowedSteps, setVtoAllowedSteps] = useState<
        PersonalSection[] | BusinessSection[] | undefined
    >([])
    const [configProductDetails, setConfigProductDetails] = useState<any>('')
    const [highlightIncompleteSections, setHighlightIncompleteSections] =
        useState<boolean>(false)

    const questionsContainerRef = useRef<HTMLDivElement>(null)
    const [firstIncompleteStep, setFirstIncompleteStep] = useState<string>('')
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

    useEffect(() => {
        if (Number(year)) {
            SetSelectedYear(Number(year))
        }
    }, [location])

    const fetchDataFromApi = useCallback(async () => {
        try {
            const [currentVto, metaData, configData] = await Promise.all([
                getVtoYearInfo(selectedYear, vtoType),
                getVTOMetaData(selectedYear),
                getVTOConfig(),
            ])

            return {
                currentVto,
                metaData,
                configData,
            }
        } catch (error: any) {
            setLoading(false)
            showError(error?.statusText)
        }
    }, [selectedYear, vtoType])

    const loadMetaConfigs = useCallback(() => {
        return {
            business: MetaConfigs[`vto${selectedYear}`][0],
            personal: MetaConfigs[`vto${selectedYear}`][1],
        }
    }, [selectedYear])

    const submitValues = (values: any, callback?: any) => {
        const updatedVTOdata = { ...values }
        const successHandler = callback ? callback : (allowedSteps: any) => {
            goToNextStep(allowedSteps)
        }
        updateVTO(
            currentVtoDetails.id, 
            updatedVTOdata, 
            successHandler, 
            true
        )
    }

    useEffect(() => {
        const productDetailsMap: any = {}
        const productKeys = Object.keys(currentYearVTOProducts)
        if (product.products.length && productKeys.length) {
            productKeys.forEach((productKey: any) => {
                const foundProduct = product.products.find(
                    (item: any) =>
                        item.product_id === currentYearVTOProducts[productKey]
                )
                const annualPrice =
                    foundProduct &&
                    makeAnnualFrequency(foundProduct?.price_book)
                productDetailsMap[productKey] = {
                    annualPrice: annualPrice,
                    discountPrice: getTheDiscountedPrice(
                        annualPrice,
                        productKey,
                        currentVtoYearConfig
                    ),
                    discount:
                        currentVtoYearConfig.product_discounts[productKey],
                }
            })
            setProductPriceMap(productDetailsMap)
        }
    }, [product, currentYearVTOProducts])

    const decryptSSN = (data: any) => {
        if (!data.personalInfo) {
            return data
        }
        if (data.personalInfo.general.ssn) {
            data.personalInfo.general.ssn = decryptData(
                data.personalInfo.general.ssn
            )
            console.log("personal ENCRYPT SSN", data?.personalInfo?.general?.ssn)
        }

        if (data.personalInfo.general.spouses_ssn) {
            data.personalInfo.general.spouses_ssn = decryptData(
                data.personalInfo.general.spouses_ssn
            )
            console.log("personal DECRYPT SSN", data?.personalInfo?.general?.ssn)
        }
        if (data.personalInfo.dependants) {
            data.personalInfo.dependants.forEach((dependent: any) => {
                if (dependent.ssn) {
                    dependent.ssn = decryptData(dependent.ssn)
                }
            })
        }
        return data
    }

    const emptyArrayToEmptyObjectFormat = (data: any) => {
        const vtoData = data
        if (Array.isArray(data.user_answered_questions)) {
            data.user_answered_questions = {}
        }
        if (Array.isArray(vtoData.business.home_expenses.list)) {
            vtoData.business.home_expenses.list = {}
        }
        if (Array.isArray(vtoData.business.balance_expenses.list)) {
            vtoData.business.balance_expenses.list = {}
        }
        if (Array.isArray(vtoData.income.farm_revenue.list)) {
            vtoData.income.farm_revenue.list = {}
        }
        if (Array.isArray(vtoData.income.farm_expenses.list)) {
            vtoData.income.farm_expenses.list = {}
        }
        if (
            isPersonalVTO &&
            Array.isArray(vtoData.personalInfo.education_contributions.list)
        ) {
            vtoData.personalInfo.education_contributions.list = {}
        }
        if (Array.isArray(vtoData.deductions.moving_expenses.list)) {
            vtoData.deductions.moving_expenses.list = {}
        }
        if (Array.isArray(vtoData.deductions.tuition_expenses.list)) {
            vtoData.deductions.tuition_expenses.list = {}
        }
        if(Array.isArray(vtoData.admin_answered_questions)){
            vtoData.admin_answered_questions = {}
        }
        if(Array.isArray(vtoData.user_answered_questions)){
            vtoData.user_answered_questions = {}
        }
        if (isEmpty(vtoData.business.tax_id_fields) || JSON.stringify(vtoData.business.tax_id_fields) === '[[]]') {
            vtoData.business.tax_id_fields = [{}]
        }

        const SoleData: any[] = []
        if (
            vtoData.soleProprietorships &&
            vtoData.soleProprietorships.length > 0
        ) {
            vtoData.soleProprietorships.forEach(function (item: any) {
                let balanceExpenseItems =
                    item.balance_expenses && item.balance_expenses.list
                let homeExpensesList =
                    item.home_expenses && item.home_expenses.list
                if (
                    !!balanceExpenseItems &&
                    Array.isArray(balanceExpenseItems)
                ) {
                    item.balance_expenses.list = {}
                }
                if (!!homeExpensesList && Array.isArray(homeExpensesList)) {
                    item.home_expenses.list = {}
                }
                SoleData.push(item)
            })
            if (SoleData.length) {
                vtoData.soleProprietorships = SoleData
            }
        }
        return vtoData
    }

    /**
     * vtoData is currentVTODetails
     * metaData is currentYearMetaData
     */
    const formatStepsData = (metaData: any, vtoData: any) => {
        const currentVTOSteps =
            metaData?.Steps[isPersonalVTO ? 'personal' : 'business']
        const steps = allowedVtoSteps(currentVTOSteps, vtoData, metaData)
        return steps
    }

    /* fetch current vto data details */
    /**
     * decryptSSN: decrypt ssn data
     * emptyArrayToEmptyObjectFormat: convert empty array to empty object for list data in vto
     * formatStepsData: format steps data for rendering in vto
     * setMenuItems: set menu items for vto
     * fetchVtoDetails: fetch vto details from api
     * @param vtoId: vto id
     */
    const fetchVtoDetails = useCallback(
        async (vtoId, vtoMetaData) => {
            setLoading(true)
            try {
                const currentVtoDetails = await getVtoDetails(
                    selectedYear,
                    vtoId
                )
                const updatedData = decryptSSN(currentVtoDetails)
                const formattedData = emptyArrayToEmptyObjectFormat(updatedData)
                const steps = formatStepsData(vtoMetaData, formattedData)
                setVtoAllowedSteps(steps)
                // EXCLUDE SUBMIT STEPS FROM MENU
                const filteredSteps = steps.filter(
                    (step: any) =>
                        !NOT_ALLOWED_STEPS_IN_MENU.includes(step.name)
                )
                setMenuItems(filteredSteps)
                setCurrentVtoDetails(formattedData)
                // this changes for the prefilled text inputs in the UI.
                const modelObject = createMap(formattedData)
                setDataOriginMap(
                    createDataOriginMap(modelObject, formattedData)
                )
                setLoading(false)
            } catch (error: any) {
                console.log("**error in fetchVtoDetails**", error)
                setLoading(false)
                showError(error?.statusText)
            }
        },
        [selectedYear]
    )

    const importBalanceSheetDetails = async () => {
        try {
            const updatedVTOdata = {
                business: {
                    software_for_bookkeeping: 'clientbooks',
                    import_bookkeeping_data: true,
                },
            }
            await updateVTO(
                currentVtoDetails.id,
                updatedVTOdata,
                () => {},
                false,
                false
            )
        } catch (error: any) {
            showError(error?.statusText)
        }
    }

    /* format api data for section rendering */
    const formatSectionData = useCallback((metaData: any) => {
        const sections = metaData?.Sections
        let formattedSections = {}
        sections.forEach((section: any) => {
            const sectionName = section?.name
            formattedSections = { ...formattedSections, [sectionName]: section }
        })

        return formattedSections
    }, [])

    const getFileConditions = useCallback((vtoDetails: any, metaData: any) => {
        return buildFileConditions(vtoDetails, metaData)
    }, [])

    const getVtoMetaConfig = useCallback(
        (metaData, vtoConfigData, vtoDetails) => {
            const sectionData = formatSectionData(metaData)

            if (![2023, 2024].includes(selectedYear)) {
                setCurrentYearMetaData({
                    ...metaData,
                    formattedSections: { ...sectionData },
                    ...vtoConfigData[`year${selectedYear}`]?.files,
                })
            } else {
                const metaConfig = loadMetaConfigs()

                const currentYearMetaConfig: any = {
                    ...metaData,
                    formattedSections: { ...sectionData },
                    Steps: {
                        business: metaConfig.business,
                        personal: metaConfig.personal,
                    },
                    files: vtoConfigData[`year${selectedYear}`]?.files,
                }

                const fileConditions = getFileConditions(
                    vtoDetails,
                    currentYearMetaConfig
                )

                const updatedConfig = {
                    ...currentYearMetaConfig,
                    FileConditions: { ...fileConditions },
                }

                return updatedConfig
            }
        },
        [formatSectionData, loadMetaConfigs, selectedYear, getFileConditions]
    )
    /**
     * fetch specific product details and store in state
     * these details are used in VTO > internal product purchase
     * i.e personal VTO > Sole Prep
     */
    const getProductDetails = useCallback(async (vtoProducts: any) => {
        try {
            const productKeys = [
                'schedule_c',
                'schedule_e',
                'business_schedule_e',
            ]
            const productIds = productKeys.map((key) => vtoProducts[key])

            const productDetails = await Promise.all([
                vtoProductDetails(productIds[0]),
                vtoProductDetails(productIds[1]),
                vtoProductDetails(productIds[2]),
            ])

            let data = {}
            productKeys.forEach((key, index) => {
                data = {
                    ...data,
                    [key]: productDetails[index],
                }
            })

            setConfigProductDetails({ ...data })
        } catch (error: any) {}
    }, [])

    const getAllVTODetails = useCallback(() => {
        setLoading(true)
        fetchDataFromApi()
            .then((res: any) => {
                const currentVto = res?.currentVto?.[0] ?? null
                const configData = res?.configData ?? null

                vtoType === VTO_TYPES.BUSINESS
                    ? setCurrentBusinessVto(currentVto)
                    : setCurrentPersonalVto(currentVto)
                const metaConfig = getVtoMetaConfig(
                    res?.metaData,
                    configData,
                    currentVto
                )
                metaConfig && currentVto?.id
                    ? fetchVtoDetails(currentVto?.id, metaConfig)
                    : setLoading(false)

                const allVTOConfig: any = configData as any

                setCurrentVtoConfig(configData)
                setCurrentYearVTOProducts(
                    allVTOConfig[`year${selectedYear}`]['product']
                )
                setCurrentYearMetaData({ ...metaConfig })
                getProductDetails(
                    allVTOConfig[`year${selectedYear}`]['product']
                )
            })
            .catch((error) => {
                setLoading(false)
                console.log("**error in GET ALL**", error)
                showError(error?.statusText)
            })
    }, [
        fetchDataFromApi,
        fetchVtoDetails,
        getProductDetails,
        selectedYear,
        getVtoMetaConfig,
        vtoType,
    ])

    const refetchCurrentYearMetaData = () => {
        fetchDataFromApi().then((res: any) => {
            const currentVto = res?.currentVto?.[0] ?? null
            const configData = res?.configData ?? null

            vtoType === VTO_TYPES.BUSINESS
                ? setCurrentBusinessVto(currentVto)
                : setCurrentPersonalVto(currentVto)
            const metaConfig = getVtoMetaConfig(
                res?.metaData,
                configData,
                currentVto
            )

            setCurrentYearMetaData({ ...metaConfig })
        })
    }

    // Update VTO data
    const updateVTO = (
        vtoId: string,
        updatedData: any,
        successHandler?: (steps: any) => void,
        isQuestionSubmit: boolean = false,
        showLoader: boolean = true
    ) => {
        showLoader &&
            (isQuestionSubmit ? setQuestionLoading(true) : setLoading(true))
        ApiRequestHandler(
            updateVtoData(selectedYear, vtoId, updatedData).then(
                (response: any) => {
                    if (response) {
                        const decryptedData = decryptSSN(response)
                        const formattedData =
                            emptyArrayToEmptyObjectFormat(decryptedData)
                        /**
                         * create Nested Object as key and value pair
                         * business.address.address: "",
                         * business.form_date: "2005-12-16"
                         * */
                        const modelObject = createMap(formattedData)
                        /**
                         * business.address.address: 'default',
                         * business.form_date: "prefilled"
                         */
                        const originMap = createDataOriginMap(modelObject, formattedData)
                        setDataOriginMap(originMap)
                        const steps = formatStepsData(
                            currentYearMetaData,
                            formattedData
                        )
                        const menuItems = steps.filter(
                            (step: any) =>
                                !NOT_ALLOWED_STEPS_IN_MENU.includes(step.name)
                        )
                        setVtoAllowedSteps(steps)
                        setMenuItems(menuItems)
                        setQuestionLoading(false)
                        vtoType === VTO_TYPES.BUSINESS
                            ? setCurrentBusinessVto(formattedData as VtoInfo)
                            : setCurrentPersonalVto(formattedData as VtoInfo)
                        setCurrentVtoDetails(formattedData as VtoInfo)
                        successHandler?.(menuItems)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        setQuestionLoading(false)
                    }
                }
            ),
            () => {
                setLoading(false)
                setQuestionLoading(false)
            }
        )
    }

    const createBusinessVto = () => {
        setLoading(true)
        ApiRequestHandler(
            createVto(selectedYear, VTO_TYPES.BUSINESS).then(
                (response: VtoInfo) => {
                    if (response) {
                        setLoading(false)
                        setCurrentBusinessVto(response as VtoInfo)
                    } else {
                        setLoading(false)
                        setCurrentBusinessVto(undefined)
                    }
                }
            ),
            () => {
                setLoading(false)
            }
        )
    }

    const createPersonalVto = () => {
        setLoading(true)
        ApiRequestHandler(
            createVto(selectedYear, VTO_TYPES.PERSONAL).then(
                (response: VtoInfo) => {
                    if (response) {
                        setLoading(false)
                        setCurrentPersonalVto(response as VtoInfo)
                        setCurrentVtoDetails(response)
                    } else {
                        setLoading(false)
                        setCurrentPersonalVto(undefined)
                        setCurrentVtoDetails(undefined)
                    }
                }
            ),
            () => {
                setLoading(false)
            }
        )
    }

    /* load section questions on section menu click */
    const onMenuItemClick = (key: string) => {
        setCurrentStep(key)
    }

    const hasVTOPermission = () => {
        const account = isBusinessVTO ? currentAccount : personalAccount
        return hasPermission(
            `VTO${selectedYear}_${isBusinessVTO ? 'BUSINESS' : 'PERSONAL'}`,
            account
        )
    }

    useEffect(() => {
        const currentVto = isBusinessVTO
            ? currentBusinessVto
            : currentPersonalVto
        setCurrentVto(currentVto)
    }, [isBusinessVTO, currentBusinessVto, currentPersonalVto])

    useEffect(() => {
        if (!product.products.length) {
            dispatch(loadProducts('upsell_products=true'))
        }
    }, [dispatch, product.products.length])

    useEffect(() => {
        getAllVTODetails()
    }, [getAllVTODetails, selectedYear, loadMetaConfigs])

    useEffect(() => {
        setOpenFaqs(true);
        if (!questionsContainerRef.current) return
        setTimeout(
            (questionsContainerRef) => {
                questionsContainerRef?.current?.scrollTo({ top: 0 })
            },
            0,
            questionsContainerRef
        )
    }, [currentStep])

    useEffect(()=> {
        if(firstIncompleteStep){
            setCurrentStep(firstIncompleteStep)
        }
    },[firstIncompleteStep])

    const getCurrentStepIndexes = (): {
        parentIndex: number
        menuIndex: number
    } => {
        let parentIndex: number = NO_INDEX
        let menuIndex: number = NO_INDEX
        if (!menuItems) return { parentIndex, menuIndex }
        menuItems.forEach((step: any, index: number) => {
            step.sections.forEach((parentStep: any, childIndex: number) => {
                if ([currentStep].includes(parentStep as string)) {
                    parentIndex = childIndex
                    menuIndex = index
                }
            })
        })

        return { parentIndex, menuIndex }
    }

    const getSectionLastIndex = (
        menuItems: any[],
        menuIndex: number
    ): number => {
        return menuItems[menuIndex].sections.length - 1
    }

    const goToNextStep = (vtoSteps: any) => {
        if (!vtoSteps) return

        const { parentIndex, menuIndex } = getCurrentStepIndexes()
        const LAST_INDEX = vtoSteps.length - 1
        const sectionLastIndex = getSectionLastIndex(vtoSteps, menuIndex)
        if (parentIndex === sectionLastIndex) {
            if (menuIndex !== LAST_INDEX) {
                setCurrentStep(vtoSteps[menuIndex + 1].sections[0])
                setParentStep(vtoSteps[menuIndex + 1])
            }
        } else {
            setCurrentStep(vtoSteps[menuIndex].sections[parentIndex + 1])
        }
    }

    const goToPreviousStep = () => {
        if (!menuItems) return

        const { parentIndex, menuIndex } = getCurrentStepIndexes()
        if (parentIndex === 0) {
            if (menuIndex > 0) {
                const sectionLastIndex = getSectionLastIndex(
                    menuItems,
                    menuIndex - 1
                )
                setCurrentStep(
                    menuItems[menuIndex - 1].sections[sectionLastIndex]
                )
                setParentStep(menuItems[menuIndex - 1])
            }
        } else if (parentIndex > 0) {
            setCurrentStep(menuItems[menuIndex].sections[parentIndex - 1])
        }
    }

    const handlePrev = () => {
        const currentPath = history.location.pathname
        const newPath = currentPath.substring(0, currentPath.lastIndexOf('/'))
        history.push(newPath)
    }

    return {
        loading,
        setLoading,
        selectedYear,
        SetSelectedYear,
        dropdownIndex,
        SetDropdownIndex,
        currentBusinessVto,
        currentPersonalVto,
        vtoConfig,
        createBusinessVto,
        createPersonalVto,
        updateVTO,
        setCurrentBusinessVto,
        setCurrentPersonalVto,
        onMenuItemClick,
        isPersonalVTO,
        isBusinessVTO,
        currentYearMetaData,
        currentVtoDetails,
        setCurrentVtoDetails,
        currentStep,
        setCurrentStep,
        currentVtoConfig,
        setCurrentVtoConfig,
        currentQuestion,
        setCurrentQuestion,
        fetchVtoDetails,
        importBalanceSheetDetails,
        fullSectionView,
        setFullSectionView,
        vtoSubmitStep,
        setVtoSubmitStep,
        setShowYearDropdown,
        showYearDropdown,
        currentYearVTOProducts,
        setCurrentYearVTOProducts,
        hasVTOPermission,
        currentVtoYearConfig,
        questionLoading,
        submitValues,
        parentStep,
        setParentStep,
        configProductDetails,
        highlightIncompleteSections,
        setHighlightIncompleteSections,
        menuItems,
        setMenuItems,
        goToPreviousStep,
        goToNextStep,
        handlePrev,
        dataOriginMap,
        questionsContainerRef,
        vtoAllowedSteps,
        currentVto,
        refetchCurrentYearMetaData,
        currentStepFaqs,
        setCurrentStepFaqs,
        openFaqs,
        setOpenFaqs,
        productPriceMap,
        firstIncompleteStep,
        setFirstIncompleteStep,
        showUnsavedChangesModal,
        setShowUnsavedChangesModal,
        setDataOriginMap
    }
}
