import {
    Box,
    Button,
    Divider,
    Drawer,
    Theme,
    makeStyles,
    useMediaQuery,
} from '@material-ui/core'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import Icon from '../../../../common/Icon'
import { InfoOutlined } from '@material-ui/icons'
import { showInfo } from '../../../../../store/actions/feedback'
import { useDispatch } from 'react-redux'
import { useCurrentStore } from '../../../../common/hooks/useCurrentStore'
import { isValidDocumentMime } from '../../../../../utils/documentUtil'
import { useRef, useState } from 'react'
import UiText from '../../../../common/ui/UiText'
import ImportDocuments from './ImportDocuments'
import { useVtoContext } from '../../providers/VtoProvider'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import {
    showError,
} from '../../../../../services/formService'
import { COLORS } from '../../../../../variables/colors'
import UiButton from '../../../../common/ui/UiButton'
import {
    DOCUMENTS_UPLOAD,
    MIME_IMAGES,
    MIME_TYPES,
    renderFor,
} from '../../../../dashboard/tax-prep/constants/tax-prep.const'
import { taxDocumentUpload } from '../../../../../services/apiService'
import { VtoType } from '../../../../dashboard/tax-prep/models/tax-prep.interface'
import Loader from '../../../../common/Loader'

export const getIconType = (mime: string) => {
    switch (mime) {
        case 'image/jpeg':
            return 'Image-Attachment'
        case 'image/png':
            return 'Image-Attachment'
        case 'application/pdf':
            return 'PDF'
        default:
            return 'Generic'
    }
}

export const acceptedFormat = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'text/csv',
]

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        topMargin: {
            marginTop: '1rem',
        },
        labeledTop: {
            marginTop: '0.5rem',
        },
        mailingInfo: {
            marginTop: toRem16(40),
        },
        alertMessage: {
            marginTop: '1rem',
            marginBottom: '1rem',
            backgroundColor: (colorTheme) => colorTheme.red300 + ' !important',
            color: (colorTheme) => colorTheme.primaryBlack + ' !important',
        },
        dropzone: {
            padding: '0.8rem',
            marginTop: '1rem',
            width: '110%',
            border: (colorTheme) => '2px dashed ' + colorTheme.grey200,
            [theme.breakpoints.down('xs')]: {
                padding: '1rem',
            },
        },
        uploadContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        textContent: {
            margin: '0 1rem 0 1rem',
        },
        fileList: {
            display: 'flex',
            marginTop: '1rem',
            alignItems: 'center',
            marginBottom: toRem16(15),
            '& span': {
                padding: '0 0 0 0.5rem',
            },
        },
        fileName: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '15px',
            '& span': {
                padding: '0 0 0 0.5rem',
            },
        },
        renderedFiles: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        dividerStyles: {
            marginTop: '1rem',
            marginBottom: '0.5rem',
        },
        noDocumentText: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        fileUploadContainer: {
            marginLeft: '1.45rem',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '0',
            },
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            marginTop: '1rem',
            '& > button': {
                marginTop: '0.5rem',
            },
        },
        addDocument: {
            width: '100%',
            marginBottom: '1rem',
            '& .MuiButtonBase-root': {
                width: '100%',
            },
        },
        uploadBtn: {
            background: (colorTheme) => colorTheme.primaryWhite,
            color: (colorTheme) => colorTheme.black100,
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '0.053rem 1rem',
            borderRadius: '0.25rem',
            '&:hover': {
                background: (colorTheme) => colorTheme.primaryWhite,
            },
        },
        uploadBtnMobile: {
            background: (colorTheme) => colorTheme.tertiary,
            color: (colorTheme) => colorTheme.primaryWhite,
            '&:hover': {
                background: (colorTheme) => colorTheme.tertiary,
                color: (colorTheme) => colorTheme.primaryWhite,
            },
        },
        drawerPaper: {
            width: '100%',
            height: '18rem',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
        },
        drawerHeader: {
            padding: '0 1rem 0.75rem 1rem',
        },
        drawerContent: {
            padding: '1rem',
            maxHeight: `calc(${toRem16(400)} - ${toRem16(74)})`,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
                '& button': {
                    width: '100%',
                },
            },
            '& > div:first-of-type': {
                marginBottom: '0.5rem',
            },
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            maxHeight: toRem16(24),
        },
        importContent: {
            '& > button p': {
                fontWeight: 600,
                fontSize: '1rem !important',
            },
        },
        cancelBtn: {
            marginTop: '1.5rem',
            '& .MuiButton-label .MuiTypography-button': {
                color: (colorTheme) => colorTheme.blue200,
                fontSize: '1rem',
                fontWeight: 600,
            },
        },
        uploadedFiles: {
            marginTop: '1rem',
            '& > div': {
                marginBottom: '0.5rem',
            },
        },
        loaderContainer:{
            maxHeight: toRem16(70)
        }
    }
})

const TaxStepFileUpload = ({
    isUploadCenter = true,
}: {
    isUploadCenter?: boolean
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const dispatch = useDispatch()
    const dropzoneRef = useRef<DropzoneRef>(null)
    const { currentConfig } = useCurrentStore()
    const {
        selectedYear,
        isBusinessVTO,
        currentBusinessVto,
        currentPersonalVto,
    } = useVtoContext()

    const vtoType = isBusinessVTO ? renderFor.BUSINESS : renderFor.PERSONAL
    const vtoData: any = isBusinessVTO ? currentBusinessVto : currentPersonalVto
    const currentStore = useCurrentStore()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [allUploadedFiles, setAllUploadedFiles] = useState<any>()
    const [fileList, setFileList] = useState<any>([])
    const [anchorEl, setAnchorEl] = useState<boolean>(false)
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    const fileType = isBusinessVTO ? DOCUMENTS_UPLOAD.BUSINESS : DOCUMENTS_UPLOAD.PERSONAL
    const allowedMimeTypes =
        currentConfig.apiConfig.file_center.allowed_mime_types
    const allowedDocumentTypes =
        currentConfig.apiConfig.file_center.allowed_extensions
    const maxFileSize = currentConfig.apiConfig.file_center.max_file_size

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )

    const handleOpen = () => {
        setAnchorEl(true)
    }

    const handleClose = () => {
        setAnchorEl(false)
    }

    const getFormattedVtoType = (vtoType: string): VtoType => {
        if (vtoType === renderFor.BUSINESS) {
            return DOCUMENTS_UPLOAD.BUSINESS
        }
        return DOCUMENTS_UPLOAD.PERSONAL
    }

    const limitCheck = (files: File[]) => {
        for (var file of files) {
            if (file.size > maxFileSize) {
                sizeLimitMessage(file)
                return true
            }
        }
        return false
    }

    const pushNewlyAddedFiles = (newUploadedFiles: File[]): void => {
        newUploadedFiles.forEach((files) => {
            setAllUploadedFiles((oldArray: any) => [...oldArray, files])
        })
    }

    const isFilesAlreadyUploaded = (allUploadedFiles: any[]) => {
        return allUploadedFiles && allUploadedFiles.length > 0
    }

    const setNewlyUploadedFiles = (files: File[]) => {
        if (isFilesAlreadyUploaded(allUploadedFiles)) {
            pushNewlyAddedFiles(files)
        } else {
            setAllUploadedFiles([...files])
        }
    }

    const sizeLimitMessage = (file: File) => {
        dispatch(
            showInfo({
                infoData: (
                    <>
                        <Box textAlign="center" width="100%">
                            <InfoOutlined fontSize="large" />
                        </Box>
                        <div>File {file.name} is too large.</div>
                        <div>
                            Please downsize the file. If you use a scanner
                            please rescan the image at a lesser dpi.
                        </div>
                    </>
                ),
            })
        )
    }

    const uploadFiles = async (files: File[]) => {
        const year = selectedYear.toString()
        const type = getFormattedVtoType(vtoType)
        const taxApId = vtoData?.tax_ap_id
        const accountId = currentStore.currentAccountId
        if (limitCheck(files)) {
            return
        }
        setIsLoading(true)
        if (!taxApId) {
            return
        }
        taxDocumentUpload(type, year, accountId, taxApId, files)
            .then((res: any) => {
                setNewlyUploadedFiles(res)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error.statusText)
            })
    }

    const getUploadedDocImageType = (mimeType: string) => {
        if (
            mimeType === MIME_TYPES.IMG_JPEG ||
            mimeType === MIME_TYPES.IMG_PNG
        ) {
            return MIME_IMAGES.ATTACHMENT_IMG
        }
        if (mimeType === MIME_TYPES.APP_PDF) {
            return MIME_IMAGES.PDF
        }
        return MIME_IMAGES.GENERIC
    }

    const getImageBasedOnMimeType = (mimeType: string) => {
        const imageType = getUploadedDocImageType(mimeType)
        return (
            <img
                src={
                    require(`../../../../../assets/icons-svg/${imageType}.svg`)
                        .default
                }
                alt="document"
            />
        )
    }

    const renderUploadedFiles = () => {
        if (!allUploadedFiles) return
        return allUploadedFiles.map((file: any) => {
            return (
                <div key={file.file_links[0]?.filename} className={styles.fileName}>
                    {getImageBasedOnMimeType(
                        file.mime_type || file.file_links[0]?.mime_type
                    )}{' '}
                    <span>{file.filename || file.file_links[0]?.filename}</span>
                </div>
            )
        })
    }

    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }

        if (isFileTypeUnsupported(files)) {
            handleFileUnsupported()
            return
        }
        uploadFiles(files)
    }

    const handleFileUnsupported = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <div>File(s) has unsupported format.</div>
                        <div>
                            Please upload only{' '}
                            <b>{acceptedFormat.join(', ')}</b>
                        </div>
                    </div>
                ),
            })
        )
    }

    return (
        <>
            <div className={isUploadCenter ? styles.fileUploadContainer : ''}>
                {isLoading ? (
                    <div className={styles.loaderContainer}>
                        <Loader  />
                    </div>
                ) : (
                    <Dropzone onDrop={handleUploadFile} ref={dropzoneRef}>
                        {({ getRootProps, getInputProps }) => {
                            return (
                                <div
                                    {...getRootProps({})}
                                    className={styles.dropzone}
                                    style={{
                                        display: smDevice ? 'none' : 'block',
                                    }}
                                >
                                    <div>
                                        <input
                                            {...getInputProps()}
                                            name="files[]"
                                            accept={acceptedFormat.toString()}
                                        />
                                    </div>
                                    <div className={styles.uploadContainer}>
                                        <Button
                                            disabled={false}
                                            variant="contained"
                                            color="primary"
                                            startIcon={
                                                <Icon
                                                    icon="upload"
                                                    svgColor={
                                                        colorTheme.primaryWhite
                                                    }
                                                />
                                            }
                                        >
                                            <UiText
                                                variant="motorcycle_90"
                                                weight="semi_bold_600"
                                            >
                                                Upload File
                                            </UiText>
                                        </Button>
                                        <UiText
                                            className={styles.textContent}
                                            variant="motorcycle_90"
                                            textColor="textSecondary"
                                        >
                                            Or
                                        </UiText>
                                        <div>
                                            <UiText>
                                                Drag & drop file here to upload
                                            </UiText>
                                            <UiText
                                                variant="motorcycle_90"
                                                textColor="textSecondary"
                                            >
                                                Supported file types: .csv, .pdf,
                                                .png, .jpeg, .jpg
                                            </UiText>
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    </Dropzone>
                )}

                {smDevice && (
                    <div className={styles.buttonContainer}>
                        <div className={styles.addDocument}>
                            <Button
                                className={styles.uploadBtn}
                                disabled={false}
                                //@ts-ignore
                                onClick={handleOpen}
                                startIcon={<Icon icon="add" />}
                            >
                                <UiText weight="semi_bold_600">
                                    Add Document
                                </UiText>
                            </Button>
                        </div>
                    </div>
                )}
                {!smDevice && (
                    <ImportDocuments
                        setFileList={setFileList}
                        fileType={fileType}
                    />
                )}
                <div className={styles.uploadedFiles}>
                    {renderUploadedFiles()}
                </div>   
            </div>
            {isUploadCenter && (
                <Divider variant="fullWidth" className={styles.dividerStyles} />
            )}
            {smDevice && (
                <Drawer
                    id="filter-drawer"
                    anchor="bottom"
                    open={anchorEl}
                    onClose={handleClose}
                    classes={{ paper: styles.drawerPaper }}
                >
                    <div className={styles.drawerHeader}>
                        <div role='button' onClick={handleClose} className={styles.iconContainer}>
                            <RemoveRoundedIcon
                                fontSize="large"
                                classes={{
                                    root: styles.iconRoot,
                                }}
                            />
                        </div>
                        <UiText variant="hatchback_125" weight="semi_bold_600">
                            Add Documents
                        </UiText>
                    </div>
                    <div className={styles.drawerContent}>
                        <div>
                            <Button
                                className={styles.uploadBtnMobile}
                                disabled={false}
                                //@ts-ignore
                                onClick={() => dropzoneRef.current.open()}
                                startIcon={
                                    <Icon
                                        icon="upload"
                                        svgColor={COLORS.primaryWhite}
                                    />
                                }
                            >
                                <UiText weight="semi_bold_600">Upload</UiText>
                            </Button>
                        </div>
                        <div>
                            <UiText
                                variant="motorcycle_90"
                                textColor="textSecondary"
                            >
                                Supported file types: .csv, .pdf, .png, .jpeg, .jpg
                            </UiText>
                        </div>
                        <div className={styles.importContent}>
                            <ImportDocuments
                                setFileList={setFileList}
                                fileType={fileType}
                            />
                            <UiButton
                                customClass={styles.cancelBtn}
                                btnType="hyperlink"
                                label={'Cancel'}
                                handleClick={handleClose}
                            />
                        </div>
                    </div>
                </Drawer>
            )}
        </>
    )
}

export default TaxStepFileUpload
