import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { hasPermission } from '../../../../services/aclService'
import {
    BANK_SETUP_AD_BLOCK,
    BASIC_BOOKKEEPING_PERMISSION,
    INVOICE_INFO_CARD,
    MERCHANT_SETUP_AD_BLOCK,
    TRANSACTION_INFO_CARD,
    CARRYOVER_TAX_BENEFITS_CARD,
    UPLOAD_1040_INFO_CARD,
    LAST_1040_POPUP_DATE,
} from '../Todo.const'
import { 
    isBlockDismissed,
    isBankSetupCompletedOrDismissed, 
    isMerchantSetupCompletedORDismissed
} from '../../AccountSetupItems/AccountSetupUtility'
import { useDashboardContext } from '../../Provider/DashboardProvider'
import type { AccountProgress } from '../../../../models/dashboardModel'
import { useWidgetContext } from '../../tax-prep/provider/WidgetProvider'
import { VTO_SUBMITTED_STATUS } from '../../tax-prep/constants/tax-prep.const'
import { getPrevYearOptions } from '../../tax-prep/utils'
import { getFromLocalStorage } from '../../../../services/localStorageService'

export const useTodoBlockInfo = () => {
    const { currentAccount, currentAccountId, personalAccount } = useCurrentStore()
    const [refetch1040Info, setRefetch1040Info] = useState(false);
    const [hasFetched1040Data, setHasFetched1040Data] = useState(false);

    const { invoiceData, 
        dismissPopupInfo,
        accountProgress, 
        personalVto,
        prevYearTaxDocs,
     } = useDashboardContext()
    const bookkeepingInfo = accountProgress?.bookkeeping_popups
    const hasBookkeepingPermissions = hasPermission(
        BASIC_BOOKKEEPING_PERMISSION,
        currentAccount
    )

    const isBankMarkedCompleted = isBankSetupCompletedOrDismissed(accountProgress as AccountProgress, dismissPopupInfo)
    const isMerchantCompleted = isMerchantSetupCompletedORDismissed(accountProgress as AccountProgress, dismissPopupInfo)
    const { taxPrepData: {show_fincen_data_collection_form: showFincenCard} } = useWidgetContext()
    const prevYear = getPrevYearOptions()[2];
    const plaidVerificationStatus = accountProgress?.show_payroll_bank_verification_card
    const showPayrollMigrationInfoCard = accountProgress?.show_payroll_bank_migration_card
    

    const bankAccountConnected =
        accountProgress?.connected_bank_account ||
        accountProgress?.bank_account_created
    const showBankSetupCard = bankAccountConnected
        ? false
        : !isBlockDismissed(BANK_SETUP_AD_BLOCK)

    const hasInvoice = !!invoiceData?.late_count
    const showInvoiceCard = hasInvoice && !isBlockDismissed(INVOICE_INFO_CARD)

    const merchantConnected = accountProgress?.merchant_account_setup
    const showMerchantCard = merchantConnected
        ? false
        : !isBlockDismissed(MERCHANT_SETUP_AD_BLOCK)

    const noUncategorizedTransaction =
        accountProgress?.uncategorized_transaction_count === 0
    const showTransactionCard =
        !noUncategorizedTransaction && !isBlockDismissed(TRANSACTION_INFO_CARD)

    const showBookkeepingCard =
        !!bookkeepingInfo?.length && hasBookkeepingPermissions

    const submittedPrevYearVto = personalVto?.[0]?.status === VTO_SUBMITTED_STATUS;
    const documentCount = prevYearTaxDocs?.items?.length ? prevYearTaxDocs.items.filter((item: any) => item.type === 'personal').length : 0;
    const show1040Card = !hasFetched1040Data ? true : !isBlockDismissed(UPLOAD_1040_INFO_CARD) && !(personalAccount.upload_1040_consent === false) && !submittedPrevYearVto && documentCount === 0;
    const show1040Popup = !hasFetched1040Data ? false :  has2WeeksPassed() && show1040Card;
    
    const bookKeepingTodoCount = useCallback(() => {
        return showBookkeepingCard
            ? bookkeepingInfo?.filter(
                  (item: any) => !dismissPopupInfo.includes(item.pop_up)
              ).length || 0
            : 0
    }, [bookkeepingInfo, dismissPopupInfo, showBookkeepingCard])

    useEffect(() => {
        setHasFetched1040Data(personalVto && Object.keys(prevYearTaxDocs || {}).length > 0 && personalAccount);
    },[personalVto, prevYearTaxDocs, personalAccount])
    /**
     * TODO - need to integrate with API
     */
    const showCarryoverTaxBenefitCard = !accountProgress?.carryover_tax_benefits && !isBlockDismissed(CARRYOVER_TAX_BENEFITS_CARD)

    const getTodoCount = useCallback(() => {
        const cardTodosCount = [
            isBankMarkedCompleted && showBankSetupCard,
            isMerchantCompleted && showMerchantCard,
            showTransactionCard,
            showInvoiceCard,
            showCarryoverTaxBenefitCard,
            showFincenCard,
            showPayrollMigrationInfoCard,
            plaidVerificationStatus,
            show1040Card
        ].filter((item) => !!item).length
        return bookKeepingTodoCount() + cardTodosCount
    }, [
        bookKeepingTodoCount,
        showBankSetupCard,
        showInvoiceCard,
        showMerchantCard,
        showTransactionCard,
        showCarryoverTaxBenefitCard,
        isBankMarkedCompleted,
        isMerchantCompleted,
        showFincenCard,
        showPayrollMigrationInfoCard,
        plaidVerificationStatus,
        show1040Card
    ])

    function has2WeeksPassed() {
        const lastPopupTimestamp = getFromLocalStorage(
            `${currentAccountId}_${LAST_1040_POPUP_DATE}`
        )
        if (!lastPopupTimestamp) return true
        const lastPopupDate = moment.unix(lastPopupTimestamp)
        const todayDate = moment()
        const differenceInDays = todayDate.diff(lastPopupDate, 'days')
        const isMoreThanTwoWeeks = differenceInDays > 14
        return isMoreThanTwoWeeks
    }

    const count = getTodoCount()
    const isAllTodoCompleted = count === 0

    return {
        count,
        isAllTodoCompleted,
        showBankSetupCard,
        showInvoiceCard,
        showMerchantCard,
        showTransactionCard,
        showCarryoverTaxBenefitCard,
        hasBookkeepingPermissions,
        bookkeepingInfo,
        showPayrollMigrationInfoCard,
        plaidVerificationStatus,
        show1040Card,
        setRefetch1040Info,
        show1040Popup,
    }
}
