import { Hidden, makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { useVtoContext } from '../../vto/providers/VtoProvider'
import UiText from '../../../common/ui/UiText'
import { mergeStyle, toRem16 } from '../../../../styles/commonStyles'
import UiLottie from '../../../common/ui/UiLottie'
import WaveAnimation from '../../../../assets/animations/Wave.json'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import WatchIntroVideo from '../../vto/core/components/WatchVideoModal'
import { useState } from 'react'
import useDeviceSize from '../../../../hooks/useDeviceSize'
import UiButton from '../../../common/ui/UiButton'
import IntroVideo from '../../vto/core/components/IntroVideo'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        maxWidth: '640px',
        margin: '0 auto',
        color: (colorTheme) => colorTheme.black100,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            '& p': {
                alignSelf: 'flex-start',
            },
        },
    },
    mt16: {
        marginTop: '1rem',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    mt32: {
        marginTop: '2rem',
    },
    counterConatiner: {
        display: 'flex',
        gap: toRem16(16),
        textAlign: 'left',
        marginBottom: toRem16(16),
    },
    counterItem: {
        backgroundColor: (colorTheme) => colorTheme.teal200,
        color: (colorTheme) => colorTheme.grey400,
        borderRadius: '50%',
        minWidth: '40px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        fontWeight: 600,
    },
    mb40: {
        marginBottom: toRem16(40),
    },
    mb10: {
        marginBottom: toRem16(10),
    },
    spacer: {
        height: '60px',
    },
    stepInfo: {
        '& > :nth-child(1), & > :nth-child(2)': {
            marginBottom: toRem16(12),
        },
    },
}))

export default function Welcome({ step, nextPageButton }: any) {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { selectedYear } = useVtoContext()
    const {
        currentUser: { first_name, last_name },
    } = useCurrentStore()

    const { isSmDevice } = useDeviceSize()
    return (
        <div className={styles.container}>
            <div className={styles.center}>
                <UiLottie lottieData={WaveAnimation} />
                <UiText
                    className={mergeStyle(styles.mt16, styles.capitalize)}
                    variant="suv_150"
                    weight="bold_700"
                >
                    {`Hi, ${first_name} ${last_name},`}
                </UiText>
            </div>
            <div className={mergeStyle(styles.mt16, styles.mb40)}>
                <UiText variant="suv_150" weight="bold_700">
                    There are three steps to completing your {selectedYear}{' '}
                    taxes. Here’s what to expect.
                </UiText>
            </div>
            <div className={`${styles.mt32} ${styles.mb40}`}>
                <div className={styles.counterConatiner}>
                    <div className={styles.counterItem}>1</div>
                    <UiText weight="semi_bold_600">
                        Your accountants have collected data from you throughout
                        the year and compiled it here
                    </UiText>
                </div>
                <div className={styles.counterConatiner}>
                    <div className={styles.counterItem}>2</div>
                    <UiText weight="semi_bold_600">
                        <div className={styles.stepInfo}>
                            <div>
                                Your accountant cannot begin working on your
                                taxes until you have confirmed your tax
                                information and submitted this form.
                            </div>
                            <div>
                                The video below will help you get started.
                                Review and answer all questions to the best of
                                your ability. If you have questions or concerns,
                                leave them in the comments at the end.
                            </div>
                            <div>
                                You can access instant help at any time in this
                                process after you click the “Next” button below.
                            </div>
                        </div>
                    </UiText>
                </div>
                <div className={styles.counterConatiner}>
                    <div className={styles.counterItem}>3</div>
                    <UiText weight="semi_bold_600">
                        Once you submit, an accountant will immediately begin
                        working on your return and will contact you when they
                        are ready to review.
                    </UiText>
                </div>
            </div>
            <div className={styles.mb40}>{nextPageButton()}</div>
            <div className={styles.mb10}>
                <IntroVideo width={'100%'} />
            </div>
            <Hidden mdUp>
                <div className={styles.spacer}></div>
            </Hidden>
        </div>
    )
}
