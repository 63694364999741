import {
    Checkbox,
    FormControlLabel,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { toRem16 } from '../../../../styles/commonStyles'
import UiText from '../../../common/ui/UiText'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import UiButton from '../../../common/ui/UiButton'
import {
    businessTypes,
    expediteFirstDeadline,
    makeAnnualFrequency,
    stopShowingExpeditedProduct,
} from '../../vto/common/VtoUtils'
import { useEffect, useMemo, useState } from 'react'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { useVtoContext } from '../../vto/providers/VtoProvider'
import useDeviceSize from '../../../../hooks/useDeviceSize'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    container: {
        maxWidth: toRem16(640),
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '2rem',
        },
    },
    cardsContainer: {
        translate: '-71px',
        display: 'flex',
        gap: '1rem',
        marginBottom: '3rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            translate: 0,
        },
    },
    card: {
        flex: 1,
        boxSizing: 'border-box',
        minWidth: toRem16(384),
        padding: '24px 16px',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        borderRadius: '1rem',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            minHeight: toRem16(270),
            minWidth: 'unset',
            width: '100%',
        },
    },
    topHeading: {
        margin: `${toRem16(40)} 0`,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    details: {
        marginTop: toRem16(32),
    },
    textGap: {
        marginBottom: toRem16(16),
    },
    inputBox: {
        margin: `${toRem16(16)} 0`,
    },
    nextPageButton: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
}))

const ExpediteOption = ({
    handleNextPage,
    businessType,
    industry,
    setSelectedExpediteProduct,
    expeditedConsent,
    setExpeditedConsent,
    handlePrev,
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { currentYearVTOProducts, isBusinessVTO }: any = useVtoContext()
    const { product } = useCurrentStore()
    const [expediteProduct, setExpediteProduct] = useState<any>()
    const { isXsDevice } = useDeviceSize()
    const [dueDate, setDueDate] = useState<string>('April 15')

    useEffect(() => {
        if (product.products.length) {
            const vtoType = isBusinessVTO ? 'business' : 'personalInfo'
            const vtoProductName = `${vtoType}.upsell.expedite_tax_prep`

            const expediteProduct = product.products.find(
                (item: any) =>
                    item.product_id === currentYearVTOProducts[vtoProductName]
            )
            setExpediteProduct(expediteProduct)
        }
    }, [product])

    useEffect(() => {
        if (isBusinessVTO) {
            if (businessTypes.nonProfit.includes(industry)) {
                setDueDate('May 15')
            } else {
                if (businessTypes.sCorp.includes(businessType)) {
                    setDueDate('March 15')
                } else if (businessTypes.cCorp.includes(businessType)) {
                    setDueDate('April 15')
                }
            }
        }
    }, [])

    const deadline = useMemo(
        () => expediteFirstDeadline(businessType, industry, isBusinessVTO),
        [businessType, industry, isBusinessVTO]
    )
    const hideExpeditedProduct = useMemo(
        () => stopShowingExpeditedProduct(businessType, industry, isBusinessVTO),
        [businessType, industry]
    )

    const handleSelectData = (selectedItem: any) => {
        setSelectedExpediteProduct(selectedItem)
        handleNextPage(selectedItem)
    }
    return (
        <div className={classes.container}>
            <div className={classes.topHeading}>
                <UiText variant="suv_150" weight="bold_700">
                    {deadline.hasToShow
                        ? `Would you like your refund earlier?`
                        : `Notice: You have missed the deadline for providing your tax information in time`}
                </UiText>
                <UiText className={classes.details}>
                    {deadline.hasToShow
                        ? `If you are entitled to a refund, expedited processing will
                    ensure you receive your money more quickly and that you
                    won't incur penalties and fees from the IRS or your state.`
                        : `Your taxes are due by ${dueDate}, so in order to ensure you don’t incur penalties and fees, you will need to choose one of these options:`}
                </UiText>
            </div>
            <div className={classes.cardsContainer}>
                {!hideExpeditedProduct && (
                    <div className={classes.card}>
                        <UiText className={classes.textGap}>
                            Expedited Filing
                        </UiText>
                        <UiText weight="bold_700">
                            {`$${
                                expediteProduct
                                    ? makeAnnualFrequency(
                                          expediteProduct.price_book
                                      )
                                    : 0
                            }`}
                        </UiText>
                        <UiText className={classes.textGap}>
                            We can still file your taxes on time with expedited
                            processing*
                        </UiText>
                        <UiButton
                            fullWidth
                            customClass={classes.textGap}
                            btnType="tertiary"
                            handleClick={() =>
                                handleSelectData('expedite_upsell_opted')
                            }
                            customLabel={
                                <UiText
                                    variant="motorcycle_90"
                                    weight="semi_bold_600"
                                >
                                    Get Expedited Tax Filing
                                </UiText>
                            }
                        />
                        <UiText variant="moped_75">
                            *In order to file your taxes it is crucial you have
                            reviewed the information here and respond to
                            inquiries from your Accountants immediately.
                        </UiText>
                    </div>
                )}
                <div className={classes.card}>
                    <UiText className={classes.textGap}>
                        File for a Tax Extension
                    </UiText>
                    <UiText weight="bold_700">Free</UiText>
                    <UiText className={classes.textGap}>
                        You can extend your tax filing to a later date.
                    </UiText>
                    <UiButton
                        fullWidth
                        btnType="secondary"
                        handleClick={() =>
                            handleSelectData('extension_upsell_opted')
                        }
                        customLabel={
                            <UiText weight="semi_bold_600">
                                File for a Tax Extension
                            </UiText>
                        }
                    />
                </div>
            </div>
            <div className={classes.nextPageButton}>
                <UiText
                    variant={isXsDevice ? 'hatchback_125' : 'suv_150'}
                    weight={isXsDevice ? 'bold_700' : 'regular_400'}
                >
                    Submit Taxes Normally
                </UiText>
            </div>
            <div className={classes.inputBox}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={expeditedConsent}
                            onChange={(event) => {
                                setExpeditedConsent(event.target.checked)
                            }}
                        />
                    }
                    label={
                        <UiText textAlign="left" variant="motorcycle_90">
                            I understand that my taxes might not be filed on
                            time and can result in penalties from the IRS
                        </UiText>
                    }
                />
            </div>
            <div className={classes.nextPageButton}>
                <UiButton
                    btnType="hyperlink"
                    handleClick={handleNextPage}
                    disabled={!expeditedConsent}
                    customLabel={
                        <UiText weight="semi_bold_600" variant="motorcycle_90">
                            Continue To My Draft Tax Return
                        </UiText>
                    }
                />
            </div>
        </div>
    )
}

export default ExpediteOption
