import { Divider, makeStyles } from '@material-ui/core'
import UiFormOptions from './UiFormOptions'
import { hasConditionMatched } from '../../../common/VtoUtils'
import { useFormikContext } from 'formik'

const useStyles = makeStyles((theme) => {
    return {
        questionSectionContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'calc(100vh - 5.5rem)',
            },
        },
        questionContainer: {
            width: '100%',
            // maxWidth: '30rem',
            marginTop: '1rem',
        },
    }
})

const getQuestions = (questions: any, props?: any, values?: any) => {
    const { parentModel, arrayFieldValue } = props?.otherProps || {}
    return parentModel
        ? questions?.filter((question: any) => {
              const showCondition = question?.show_condition
              return hasConditionMatched(showCondition, arrayFieldValue, values)
          })
        : questions?.filter((question: any) => {
              const showCondition = question?.show_condition
              return hasConditionMatched(showCondition, values)
          })
}

const UiFormQuestionSet = (props: any) => {
    const styles = useStyles()
    const { values } = useFormikContext()

    const { questions, item_title, otherProps, options } = props || {}
    const { parentModel, index: parentIndex } = otherProps || {}
    const filteredQuestions: any[] = getQuestions(questions, props, values)

    return (
        <div className={styles.questionContainer}>
            {options?.divided && <Divider variant={'fullWidth'} />}
            {filteredQuestions?.map((question: any, index: number) => {
                if (!question?.title) {
                    const questionWithTitle = { ...question, title: item_title }
                    return (
                        <UiFormOptions
                            key={index}
                            {...questionWithTitle}
                            index={parentIndex}
                            parentModel={parentModel}
                            isSummaryPage={true}
                            arrayFieldValue={otherProps?.arrayFieldValue}
                            selectOptions={otherProps?.selectOptions}
                        />
                    )
                }
                return (
                    <UiFormOptions
                        key={index}
                        {...question}
                        parentModel={parentModel}
                        index={parentIndex}
                        isSummaryPage={true}
                        arrayFieldValue={otherProps?.arrayFieldValue}
                        selectOptions={otherProps?.selectOptions}
                    />
                )
            })}
        </div>
    )
}

export default UiFormQuestionSet
